import React, { useContext } from 'react';
import Head from '../../../layout/head/Head';
import { BlockDes, BlockHead, BlockTitle, Icon, PaginationComponent, RSelect } from '../../../components/Component';
import Content from '../../../layout/content/Content';
import { AgentWarFrontContext } from './AgentWarFrontContainer';
import LeadSourceFilter from '../../components/filter-components/LeadSourceFilter';
import Skeleton from 'react-loading-skeleton';
import { Badge, Button, Card, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';
import { getTimeUntil, hasValue } from '../../../utils/Utils';
import { addMilliseconds } from 'date-fns';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../../redux/selectors/user';
import { newRoles } from '../../../utils/envConfig';
import SelectAgent from '../../../components/SelectAgent';
import CardInner from '../../../components/cards/CardInner';

const sortOptions = [
  { label: 'Newest First', value: 'recent' },
  { label: 'Oldest First', value: 'oldest' }
];
const AgentWarFrontView = () => {
  const authUser = useSelector(loggedInUserSelector);

  const { queryParams, updateQueryParams, data, isLoading, isError, isFetching, refetch } =
    useContext(AgentWarFrontContext);

  const isAdmin = authUser && (authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin);

  const isAgentSelected = hasValue(queryParams.agents);

  const noDataFound = !isLoading && !isError && !data?.found;

  const adminMessage = queryParams?.agents?.label ? (
    <span>
      {`The fresh hot leads generated for`}{' '}
      <span className="text-primary fw-semibold">{queryParams?.agents?.label}</span> today
    </span>
  ) : (
    'The leads generated for agents today'
  );

  return (
    <React.Fragment>
      <Head title={isAdmin ? 'Agent War Front' : 'My Fresh Leads'}></Head>
      <Content>
        <BlockHead className={'pb-0'}>
          <BlockTitle>
            {isAdmin ? 'Agent War Front' : 'My Fresh Leads'}
            {isFetching && <Spinner size={'lg'} type="grow" className="ms-4 fw-light" color="primary" />}
          </BlockTitle>
          <BlockDes>{isAdmin ? adminMessage : 'The fresh hot leads generated for you today'}</BlockDes>
        </BlockHead>
        <div className="d-flex flex-wrap justify-content-end" style={{ gap: '0.5rem' }}>
          <div className="">
            <LeadSourceFilter
              stateVar={queryParams?.leadSource}
              hotOnly
              onChange={(option) => {
                updateQueryParams({ leadSource: option });
              }}
              className={'status-div'}
            />
            {queryParams?.leadSource && (
              <span
                className="fs-6 position-absolute text-danger"
                style={{ top: 7, right: 30 }}
                role="button"
                onClick={() => updateQueryParams({ leadSource: null })}
              >
                <Icon name={'cross'} />
              </span>
            )}
          </div>
          {isAdmin ? (
            <div>
              <SelectAgent
                selectedAgent={queryParams?.agents}
              onSelectAgent={(option) => {
                updateQueryParams({ agents: option });
              }}
              isClearable
              />
            </div>
          ) : null}
          <RSelect
            options={sortOptions}
            placeholder="Sort by"
            className="status-div-small"
            value={sortOptions.find((x) => x.value === queryParams?.sortOrder)}
            onChange={(option) => {
              updateQueryParams({ sortOrder: option });
            }}
          />
        </div>
        <div className="d-flex justify-content-start align-items-center mb-2" style={{ gap: '0.5rem' }}>
          <Button size="sm" color="primary" onClick={() => refetch()} disabled={isFetching || (isAdmin && !isAgentSelected)}>
            {isFetching ? 'Loading' : 'Refresh'}
            {isFetching ? <Spinner size={'sm'} className="ms-2" /> : <Icon name="reload" className={'ms-2'} />}
          </Button>
          <Badge color="light" className="py-1 px-2 align-self-stretch">
            {data?.found ? `${data?.found} lead(s) found` : 'No results currently'}
          </Badge>
        </div>

        {isAdmin && !isAgentSelected ? (
          <Card className="p-4 d-flex flex-column align-items-center">
            <p className="text-center fw-bold fs-6 pt-4">Please select an agent to view their fresh leads</p>
            <div className="pb-4">
              <SelectAgent
                selectedAgent={queryParams?.agents}
                onSelectAgent={(option) => {
                  updateQueryParams({ agents: option });
                }}
                isClearable
              />
            </div>
          </Card>
        ) : null}

        {isLoading && !isError ? <LoaderTable /> : null}

        {isError ? (
          <Card className="p-4">
            <p className="text-danger text-center">
              Failed to fetch hot leads. <br />
              <span className="text-primary" role="button" onClick={() => refetch()}>
                Try again
              </span>
            </p>
          </Card>
        ) : null}

        {!isLoading && !isError && data?.found ? (
          <div className="">
            <div className="nk-tb-list border" style={{ borderRadius: '6px', overflow: 'hidden' }}>
              <TableHeader />
              {data?.data?.map((item, idx) => {
                const timeDiff = -(Date.now() - new Date(item?.user?.lastActivityDate).getTime());
                const isOverdue = timeDiff < 0;
                const dueTime =
                  !item?.user?.lastActivityIsComplete && item?.user?.lastActivityDate
                    ? getTimeUntil(
                        addMilliseconds(new Date(), -(Date.now() - new Date(item?.user?.lastActivityDate).getTime())),
                        { addSuffix: true, roundTime: false }
                      )
                    : '-';
                return (
                  <div
                    className="nk-tb-item nk-row-compact bg-white"
                    key={`hot-lead-table-item-${item?.id ?? idx + 1}`}
                  >
                    <div className="nk-tb-col text-center">
                      <Link to={`/user-profile/${item?.user?.id}/user-profile-setting`} target="_blank">
                        {truncate(`${item?.user?.firstName} ${item?.user?.lastName ?? ''}`, { length: 35 })}
                        <Icon name="external" className={'ms-2'} />
                      </Link>
                    </div>
                    <div className="nk-tb-col text-center">{item?.user?.phone ?? 'Unknown'}</div>
                    <div className="nk-tb-col text-center text-nowrap py-4">
                      <div className="position-relative">
                        <span>
                          {item?.completedDate
                            ? getTimeUntil(
                                addMilliseconds(new Date(), -(Date.now() - new Date(item?.completedDate).getTime())),
                                { addSuffix: true, roundTime: false }
                              )
                            : '-'}
                        </span>
                      </div>
                    </div>
                    <div className="nk-tb-col text-center fw-semibold">
                      <span className={`${isOverdue ? 'text-danger' : ''}`}>{dueTime}</span>
                    </div>
                    <div className="nk-tb-col text-center">{item?.user?.leadSourceData?.name ?? 'Unknown'}</div>
                  </div>
                );
              })}
            </div>

            <div className="d-flex justify-content-end mt-3">
              <PaginationComponent
                currentPage={data?.page ?? queryParams?.page ?? 1}
                itemPerPage={data?.itemsPerPage ?? 10}
                totalItems={data?.found ?? 0}
                paginate={(page) => {
                  updateQueryParams({ page });
                }}
              />
            </div>
          </div>
        ) : null}

        {(isAdmin && isAgentSelected && noDataFound) || (!isAdmin && noDataFound) ? (
          <Card className="p-4">
            <p className="text-center fw-bold fs-6">No fresh hot leads found</p>
          </Card>
        ) : null}
      </Content>
    </React.Fragment>
  );
};

const TableHeader = () => {
  return (
    <div className="nk-tb-item nk-tb-head bg-white">
      <div className="nk-tb-col fw-semibold text-secondary text-center">
        <span>Name</span>
      </div>
      <div className="nk-tb-col fw-semibold text-secondary text-center">
        <span className="text-center">Phone</span>
      </div>
      <div className="nk-tb-col fw-semibold text-secondary text-center">
        <span>Assigned At</span>
      </div>
      <div className="nk-tb-col fw-semibold text-secondary text-center">
        <span>Due Date</span>
      </div>
      <div className="nk-tb-col fw-semibold text-secondary text-center">
        <span>Lead Source</span>
      </div>
    </div>
  );
};

const LoaderTable = ({ rowCount = 10 }) => {
  return (
    <div className="">
      <div className="nk-tb-list border rounded">
        <TableHeader />
        {Array(rowCount)
          .fill(0)
          .map((_, idx) => (
            <div className="nk-tb-item bg-white" key={`hot-lead-table-skeleton-${idx}`}>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
              <div className="nk-tb-col">
                <Skeleton />
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex mt-2 justify-content-end">
        <Skeleton width={200} height={30} />
      </div>
    </div>
  );
};

export default AgentWarFrontView;
