import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./route/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout/Index";
import Login from "./pages/auth/Login";
import { QueryClient, QueryClientProvider } from "react-query";
import ResetPassword from "./pages/auth/ResetPassword";
import 'react-loading-skeleton/dist/skeleton.css';

const App = (props) => {
    const queryClient = new QueryClient();
    return (
        <BrowserRouter>
            <>
                <QueryClientProvider client={queryClient}>
                    <Routes>
                        <Route path={`/auth-login`} element={<Login />} />
                        <Route path={`/reset-password`} element={<ResetPassword />} />
                        {/*Main Routes*/}
                        <Route element={<ProtectedRoutes />}>
                            <Route path="*" element={<Layout />} />
                        </Route>
                    </Routes>
                    <ToastContainer />
                </QueryClientProvider>
            </>
        </BrowserRouter>
    );
};
export default App;
