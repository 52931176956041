/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Input, Row, Spinner } from 'reactstrap';
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon
} from '../../../../../components/Component';
import CardInner from '../../../../../components/cards/CardInner';
import LoadingComponent from '../../../../components/loader/tableLoader';
import { useState } from 'react';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateGlobalSettings } from '../../../../../redux/actions/settings';
import { showToast } from '../../../../../utils/toast/toast';
import { hasValue } from '../../../../../utils/Utils';

const OperationsSettingsEdit = ({ settingsState, setIsEditState, refreshFn }) => {
  const { globalSettings, loading, error } = settingsState;
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    businessHours: {
      start: '',
      end: ''
    },
    preferredExpirationTime: ''
  });

  const { updateLoading, updateError } = useSelector((state) => ({
    loading: state.settings.loading,
    error: state.settings.error
  }));

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const updatePayload = {
        businessHours: {
          start: hasValue(payload.businessHours.start)
            ? payload.businessHours.start
            : globalSettings.global.businessHours.start,
          end: hasValue(payload.businessHours.end) ? payload.businessHours.end : globalSettings.global.businessHours.end
        },
        preferredExpirationTime: hasValue(payload.preferredExpirationTime)
          ? payload.preferredExpirationTime
          : globalSettings.global.preferredExpirationTime
      };
      // refreshFn will run after the dispatch is successful
      dispatch(updateGlobalSettings(axios, { settings: updatePayload }, refreshFn));
    } catch (error) {
      if (updateError) {
        showToast(updateError, 'error');
        console.log(updateError);
      } else {
        console.log(error);
        showToast('An error occurred while updating the settings.', 'error');
      }
    } finally {
      setIsEditState(false);
    }
  };

  return (
    <CardInner size="lg">
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Operations Settings</BlockTitle>
            <BlockDes>
              <p>General settings for the flow of operations. Allows configuration of business rules and workflows.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <Button color="success" onClick={submitForm} className="d-none d-lg-inline" style={{ marginTop: '-2rem' }}>
              {updateLoading ? <Spinner size={'sm'} /> : 'Save'}
            </Button>
            <Button
              color="light"
              className="d-none d-lg-inline"
              style={{ marginTop: '-2rem', marginLeft: '0.5rem' }}
              onClick={() => setIsEditState(false)}
            >
              Cancel
            </Button>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <a href="#" className="toggle btn btn-icon btn-trigger mt-n1" data-target="userAside">
              <Icon name="menu-alt-r" />
            </a>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {error ? (
        <BlockHeadContent>
          <h6 className="text-danger">Failed to get settings</h6>
        </BlockHeadContent>
      ) : loading ? (
        <LoadingComponent />
      ) : (
        <>
          <BlockContent>
            <div className="gy-3">
              <div className="g-item">
                <span className="form-label">Business Hours</span>
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label className="form-label mt-3" htmlFor="businessHoursStart">
                        From
                      </label>
                      <Input
                        name="businessHoursStart"
                        type="time"
                        defaultValue={`${globalSettings.global.businessHours.start ?? ''}`}
                        className="w-100"
                        onChange={(e) => {
                          const timeValue = e.target.value;
                          if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(timeValue)) {
                            setPayload((prev) => ({
                              ...prev,
                              businessHours: {
                                ...prev.businessHours,
                                start: timeValue
                              }
                            }));
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group">
                      <label className="form-label mt-3" htmlFor="businessHoursEnd">
                        To
                      </label>
                      <Input
                        name="businessHoursEnd"
                        type="time"
                        defaultValue={`${globalSettings.global.businessHours.end ?? ''}`}
                        className="w-100"
                        onChange={(e) => {
                          const timeValue = e.target.value;
                          if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(timeValue)) {
                            setPayload((prev) => ({
                              ...prev,
                              businessHours: {
                                ...prev.businessHours,
                                end: timeValue
                              }
                            }));
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="g-item">
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <label className="form-label p-0 m-0" htmlFor="preferredNotificationTime">
                    Preferred Notification Time
                  </label>
                  <Input
                    type="time"
                    name="preferredNotificationTime"
                    defaultValue={globalSettings.global.preferredExpirationTime ?? '10:00'}
                    className="w-50"
                    onChange={(e) => {
                      const timeValue = e.target.value;
                      if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(timeValue)) {
                        setPayload((prev) => ({
                          ...prev,
                          preferredExpirationTime: timeValue
                        }));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </BlockContent>

          <Button color="success" type="submit" className="d-lg-none mt-3" onClick={submitForm}>
            Save
          </Button>
          <Button
            color="light"
            className="d-lg-none mt-3"
            style={{ marginLeft: '0.5rem' }}
            onClick={() => setIsEditState(false)}
          >
            Cancel
          </Button>
        </>
      )}
    </CardInner>
  );
};

export default OperationsSettingsEdit;
