import AutoComplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { Spinner } from 'reactstrap';
import { PlaceTypes } from '../../../../utils/envConfig';

const GooglePlaceAutoComplete = ({
  region = 'ae',
  onPlaceChange = (data = { placeId: null, placeName: null, lat: null, lng: null }, err) => {},
  type = PlaceTypes.Communities,
  ...props
}) => {
  const handlePlaceChange = async (place) => {
    try {
      const geoData = await geocodeByAddress(place.label ?? '');
      const data = {
        placeId: place.value.place_id,
        placeName: place.label,
        lat: geoData[0].geometry.location.lat(),
        lng: geoData[0].geometry.location.lng(),
        ...place
      };
      onPlaceChange(data, null);
    } catch (error) {
      onPlaceChange(null, error);
    }
  };

  return (
    <AutoComplete
      selectProps={{
        onChange: (place) => handlePlaceChange(place),
        noOptionsMessage: (input) =>
          input.inputValue ? `No ${type.label} matches "${input.inputValue}"` : `Enter ${type.label} Name`,
        loadingMessage: (input) => (
          <>
            Searching for "{input.inputValue}"...
            <Spinner size={'sm'} className='ms-2' />
          </>
        ),
        placeholder: `Look for a ${type.label}`,
        ...props
      }}
      autocompletionRequest={{
        types: [type.value],
        componentRestrictions: { country: region },
      }}
    />
  );
};

export default GooglePlaceAutoComplete;
