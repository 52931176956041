import { useQuery } from "react-query";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { getCommunitiesWithAuthContext } from "../../../api/communities/getCommunitiesWithAuthContext";
import { timeAsMilliseconds } from "../../../utils/Utils";
import { showToast } from "../../../utils/toast/toast";
import { RSelect } from "../../../components/Component";

const ONE_HOUR = timeAsMilliseconds({ hours: 1 });

const PlacesDropdown = ({
  onChangeCallback,
  selected,
  styles,
  className,
  placeholder,
  isMulti,
  ...props
}) => {
  const axios = useAxiosPrivate();

  const { data: places, isLoading: placesLoading } = useQuery({
    queryKey: ['places'],
    queryFn: () => getCommunitiesWithAuthContext(axios),
    refetchInterval: ONE_HOUR,
    staleTime: ONE_HOUR,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onError: (error) => {
      console.log(error);
      showToast('Could not fetch places', 'error', 5000);
    }
  });

  const options = (places ?? [])?.map((place) => ({
    ...place,
    label: place.community,
    value: place.id ?? place._id,
  }));

  return (
    <RSelect
      isLoading={placesLoading}
      options={options}
      onChange={onChangeCallback}
      value={selected}
      isMulti={isMulti}
      styles={styles}
      className={className}
      placeholder={placeholder}
      {...props}
    />
  )
};

export default PlacesDropdown;
