import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  DataTable,
  Icon,
  PaginationComponent,
  PreviewCard,
  RSelect,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import AddDeveloperModal from "../components/popup/AddDeveloperModal";
import AddFooterTemplate from "./components/AddFooterTemplate";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getImage, OffPlanStatus, webURL } from "../../utils/envConfig";
import { useSelector, useDispatch } from "react-redux";
import { fetchOffplan } from "../../redux/actions/offplans";
import OffPlanDataTableRow from "./OffPlanDataTableRow";
import ConfirmModal from "../../components/miscellaneous/ConfirmModal";
import QueryString from "query-string";
import { debounce } from "lodash";
import Head from "../../layout/head/Head";
import LoadingComponent from "../../pages/components/loader/tableLoader"
const OffPlan = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const { offplans: offplansList,loading:loadingOffplans } = useSelector((state) => state?.offplans);
  const optionsRef = useRef();
  const viewRef = useRef();

  const [viewType, setViewType] = useState("normal");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFooterModal, setIsOpenFooterModal] = useState(false);
  const [onSearch, setOnSearch] = useState(true);
  const toggleSearch = () => setOnSearch(!onSearch);
  const [tablesm, updateTableSm] = useState(false);
  const [showModalUnpublish, setShowModalUnpublish] = useState(false);
  const [showModalPublish, setShowModalPublish] = useState(false);
  const [offPlans, setOffPlans] = useState([]);
  const [offplanId, setUpdateOffplanId] = useState(null);
  const [developers, setDevelopers] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedBeds, setSelectedBeds] = useState([]);
  const [selectedDevelopers, setSelectedDevelopers] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [query, setQuery] = useState({
    page: 1,
    per_page: 12,
    minPrice: 0,
    maxPrice: 0,
    beds: 0,
    community: null,
    developer: null,
    sort_by: "createdAt",
    sort_dir: "DESC",
  });
  
  const getDevelopers = async () => {
    try {
      const { data } = await axios.get(`/off-plan/plan-developer`);
      setDevelopers(data);
    } catch (err) {}
  };
  const getCommunities = async () => {
    try {
      const { data } = await axios.get(`/community`);
      setCommunities(
        data?.map((c) => ({
          ...c,
          label: c?.community,
          value: c?.slug,
        }))
      );
    } catch (err) {}
  };
  const debounceKeyType = {
    search: "search",
    maxPrice: "maxPrice",
    minPrice: "minPrice",
  };
  
  const debounceSearch = useMemo(() => {
    const search = (val, keyType) => {
      setQuery((c) => ({
        ...c,
        [keyType]: val,
        page: 1,
      }));
    };

    return debounce(search, 1000);
  }, []);

  const handleClickOutside = (e) => {
    const { current: wrap } = optionsRef;
    if (wrap && !wrap.contains(e.target)) {
      optionsRef.current?.classList?.remove("!h-36", "border");
    }
  };

  const handleClickOutsideView = (e) => {
    const { current: wrap } = viewRef;
    if (wrap && !wrap.contains(e.target)) {
      viewRef.current?.classList?.remove("!h-28", "border");
    }
  };

  useEffect(() => {
    const queryFilters = {};
    Object.keys(query).map((key) => {
      if (key && query[key]) {
        queryFilters[key] = query[key];
      }
      return key;
    });
    const queryString = QueryString.stringify(queryFilters);
    let link = `/off-plan?${queryString}`;
    dispatch(fetchOffplan(axios, link));
  }, [axios, dispatch, query]);

  useEffect(() => {
    const getOffPlans = async (data) => {
      try {
        if (!data) return;
        const result = await Promise.all(
          data?.filteredOutput?.map(async (plan) => {
            const headerImage = await getImage(plan?.headerImage, axios);
            return {
              ...plan,
              headerImage: headerImage?.signedRequest,
            };
          })
        );
        setOffPlans({
          ...data,
          filteredOutput: result,
        });
      } catch (err) {}
    };

    getOffPlans(offplansList);
  }, [axios, offplansList]);
  
  useEffect(() => {
    getDevelopers();
    getCommunities();
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsideView);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideView);
    };
  }, []);

  const statusChangeOffPlanPublish = async () => {
    try {
      await axios.put(`/off-plan/${offplanId}`, { status: "Published" });

      toast.success("Published Successfully");
      setOffPlans((prev) => ({
        ...prev,
        filteredOutput: prev?.filteredOutput?.filter(
          (plan) => plan?._id !== offplanId
        ),
        documentsCount: prev?.documentsCount - 1,
        filteredCount: prev?.filteredCount - 1,
      }));
      setShowModalPublish(false);
    } catch (err) {
    } finally {
      setLoading(!loading);
    }
  };

  const statusChangeOffPlanUnpublish = async () => {
    try {
      await axios.put(`/off-plan/${offplanId}`, { status: "Unpublished" });
      toast.success("Unpublish Successfully");
      setOffPlans((prev) => ({
        ...prev,
        filteredOutput: prev?.filteredOutput?.filter(
          (plan) => plan?._id !== offplanId
        ),
        documentsCount: prev?.documentsCount - 1,
        filteredCount: prev?.filteredCount - 1,
      }));
      setShowModalUnpublish(false);
    } catch (err) {
    } finally {
      setLoading(!loading);
    }
  };

  const clearFilters = () => {
    setQuery((c) => ({
      ...c,
      page: 1,
      beds: null,
      community: null,
      developer: null,
      maxPrice: 0,
      minPrice: 0,
      per_page: 12,
      sort_by: "createdAt",
      sort_dir: "DESC",
    }));
    setSelectedCommunities([]);
    setMaxPrice("");
    setMinPrice("");
    setSelectedBeds([]);
    setSelectedDevelopers([]);
    setSearchText("")
  };
  return (
  <React.Fragment>
 <Head title="Offplans"/>
 <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle>All Off Plan Properties</BlockTitle>
          </BlockHeadContent>
          <div className="toggle-expand-content" style={{ display: "block" }}>
            <ul className="nk-block-tools g-3">
              <li>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator btn btn-primary text-white"
                  >
                    <span>Add</span>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem tag="a" href="/add-off-plan">
                          <Icon name="building" />
                          <span>Add New Off Plan</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          onClick={() => setIsOpen(!isOpen)}
                          href="#"
                        >
                          <Icon name="home" />
                          <span>Add Developer</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={() =>
                            setIsOpenFooterModal(!isOpenFooterModal)
                          }
                        >
                          <Icon name="video" />
                          <span>Add Footer Template</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
            <AddDeveloperModal
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
            />
            <AddFooterTemplate
              isOpen={isOpenFooterModal}
              toggle={() => setIsOpenFooterModal(!isOpenFooterModal)}
            />
          </div>
        </BlockBetween>
        <div
          className="toggle-expand-content mt-4  "
          style={{ display: "flex", justifyContent: "end" }}
        >
          <ul className="nk-block-tools g-3">
            <li>
              <div className="form-group">
                <div className="form-control-wrap">
                  <div className="form-control-wrap">
                    <div className=" input-group">
                      {" "}
                      <Input
                        value={minPrice}
                        type="number"
                        min={0}
                        placeholder="Min Starting Price"
                        onChange={(e) => {
                          setMinPrice(e.target.value);
                          debounceSearch(
                            e.target.value,
                            debounceKeyType.minPrice
                          );
                        }}
                      />
                      <div className="input-group-addon">TO</div>
                      <Input
                        type="number"
                        value={maxPrice}
                        placeholder="Max Starting Price"
                        onChange={(e) => {
                          setMaxPrice(e.target.value);
                          debounceSearch(
                            e.target.value,
                            debounceKeyType.maxPrice
                          );
                        }}
                        min={minPrice}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="">
              <div className="form-group">
                <RSelect
                  closeMenuOnSelect={false}
                  defaultData={[]}
                  value={selectedBeds}
                  onChange={(e) => {
                    setSelectedBeds(e);
                    setQuery((c) => ({
                      ...c,
                      beds: e?.map((x) => x?.value),
                    }));
                  }}
                  isMulti
                  options={[
                    ...[1, 2, 3, 4, 6].map((c) => ({ label: c, value: c })),
                    { label: "7+", value: 7 },
                  ]}
                  placeholder="Num of Beds"
                  className="rselect-propertyListing "
                />
              </div>
            </li>
            <li className="">
              <div className="form-group">
                <RSelect
                  closeMenuOnSelect={false}
                  value={selectedCommunities}
                  isMulti
                  options={communities}
                  onChange={(e) => {
                    setSelectedCommunities(e);
                    setQuery((c) => ({
                      ...c,
                      community: e?.map((x) => x?.value),
                    }));
                  }}
                  placeholder="Choose Community"
                  className="rselect-propertyListing "
                />
              </div>
            </li>
            <li className="">
              <div className="form-group">
                <RSelect
                  closeMenuOnSelect={false}
                  isMulti
                  onChange={(e) => {
                    setQuery((c) => ({
                      ...c,
                      developer: e?.map((x) => x?.id ?? x?._id),
                    }));
                    setSelectedDevelopers(e);
                  }}
                  value={selectedDevelopers}
                  options={developers.map((dev) => ({
                    ...dev,
                    label: dev.name,
                    value: dev._id,
                  }))}
                  placeholder="Choose Developer"
                  className="rselect-propertyListing "
                />
              </div>
            </li>
          </ul>
        </div>
        <div className="w-100 text-end my-2">
          {Object.keys(query)
            ?.map((x) => {
              if (
                x === "per_page" ||
                x === "sort_by" ||
                x === "sort_dir" ||
                x === "page"
              )
                return;
              else return query[x] ? x : undefined;
            })
            ?.filter((x) => x !== undefined)?.length ? (
            <Badge
              color="danger"
              className="cursor-pointer"
              onClick={clearFilters}
            >
              <Icon name={"cross-sm"} /> Clear Filters
            </Badge>
          ) : null}
        </div>
      </BlockHead>
      <PreviewCard>
        <div className="d-flex justify-content-end mb-3">
          <Button
            onClick={() =>
              setViewType(viewType === "normal" ? "list" : "normal")
            }
            color={"secondary"}
          >
            <Icon name={"list"} className={"pe-1 "}></Icon>
            {viewType === "normal" ? "List View" : "Grid View"}
          </Button>
        </div>
        {viewType === "normal" ? (
          <Row>
            {!loadingOffplans&&offPlans?.filteredOutput?.map((plan) => (
              <Col key={plan?.id} sm="6" xxl="4">
                <div>
                  <Card className="card-bordered mb-4">
                    <div style={{ position: "relative", height: "17rem" }}>
                      <div className="relative">
                        <Link to={`${webURL}/off-plan-dubai/${plan?.slug}`}>
                          <CardImg
                            top
                            // src={ "https://img.gtsstatic.net/reno/imagereader.aspx?imageurl=https%3A%2F%2Fsir.azureedge.net%2F1259i215%2Fvgs4w7dp0hky4d2scx96n1sxq6i215&option=N&h=472&permitphotoenlargement=false" }
                            src={plan?.headerImage}
                            alt=""
                            className="p-2 "
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                    <CardBody className="card-inner">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <CardTitle
                          className="d-inline-block custom-margin-bottom"
                          tag="h5"
                          style={{
                            height: "2rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span>{plan?.developmentName}</span>
                        </CardTitle>
                        <div>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              href="toggle"
                              onClick={(ev) => ev.preventDefault()}
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag={Link}
                                    to={`/edit-off-plan/${plan?.id}?tab=1`}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li>
                                {plan?.status && plan?.status === OffPlanStatus.Published && (
                                  <li>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUpdateOffplanId(plan?.id);
                                        setShowModalUnpublish(true);
                                      }}
                                      tag="a"
                                    >
                                      <Icon name="archive"></Icon>
                                      <span>Unpublish</span>
                                    </DropdownItem>
                                  </li>
                                )}
                                {plan?.status !== OffPlanStatus.Published && (
                                  <li>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUpdateOffplanId(plan?.id);
                                        setShowModalPublish(true);
                                      }}
                                      tag="a"
                                    >
                                      <Icon name="archive"></Icon>
                                      <span>Publish</span>
                                    </DropdownItem>
                                  </li>
                                )}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                      <CardText className="custom-margin-bottom">
                        {plan?.status === OffPlanStatus.Unpublished && (
                          <div className="col">
                            <Badge color="danger">{plan?.status}</Badge>
                          </div>
                        )}
                      </CardText>
                      {plan?.status === OffPlanStatus.Draft && (
                        <CardText className="custom-margin-bottom">
                          <div className="d-flex flex-wrap align-items-center" style={{ gap: "0.5rem" }}>
                            <Badge color="info">{plan?.status}</Badge>
                            <Link to={`/edit-off-plan/${plan?.id}`} target="_blank">Continue Adding Information</Link>
                          </div>
                        </CardText>
                      )}
                      {!([OffPlanStatus.Unpublished, OffPlanStatus.Draft].includes(plan?.status)) && (
                        <CardText className="custom-margin-bottom">
                          <div className="col">
                            <Badge color="primary">{plan?.status ?? OffPlanStatus.Published}</Badge>
                          </div>
                        </CardText>
                      )}
                      <CardText className="custom-margin-bottom">
                        <span className="custom-p-property ">
                          <Icon name="location"></Icon> {plan?.community?.name},{" "}
                          {plan?.emirate}
                        </span>{" "}
                      </CardText>
                      <CardText className="custom-margin-bottom">
                        <span className="custom-margin-bottom">
                          <Icon name="money"></Icon> Downpayment :{" "}
                          {plan?.downpayment}%
                        </span>
                      </CardText>
                      <CardText className="custom-margin-bottom">
                        <span className="custom-margin-bottom">
                          <Icon name="calendar"></Icon> Completion Date :{" "}
                          {plan?.completionDate}
                        </span>
                      </CardText>
                      <CardText className="custom-margin-bottom">
                        <Icon name="user"></Icon> Developer :{" "}
                        {plan?.developer?.name}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))}
            {loadingOffplans &&<LoadingComponent/>}
            {offPlans && offPlans?.filteredOutput?.length > 0 ? (
              <PaginationComponent
                currentPage={query.page}
                paginate={(page) => setQuery((c) => ({ ...c, page }))}
                itemPerPage={query.per_page}
                totalItems={offplansList?.filteredCount}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No offPlans Found</span>
              </div>
            )}
          </Row>
        ) : (
          <DataTable>
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3"></div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        query.per_page === 10 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            per_page: 10,
                                          }));
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        query.per_page === 15 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            per_page: 15,
                                          }));
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        query.per_page === 50 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            per_page: 50,
                                          }));
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        query.per_page === 100 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            per_page: 100,
                                          }));
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li
                                      className={
                                        query.sort_by === "DESC" ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            sort_by: "createdAt",
                                            sort_dir: "DESC",
                                          }));
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        query.sort_by === "ASC" ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setQuery((c) => ({
                                            ...c,
                                            page: 1,
                                            sort_by: "createdAt",
                                            sort_dir: "ASC",
                                          }));
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setQuery((c) => ({
                          ...c,
                          page: 1,
                          search: "",
                        }));
                        toggleSearch();
                        setSearchText("")
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search Off Plan by Title "
                      value={searchText}
                      onChange={(e) =>
                       {
                        setSearchText(e.target.value);
                     debounceSearch(e.target.value, debounceKeyType.search)}
                      }
                    />
                    <Button className="search-submit btn-icon  bg-transparent text-secondary border-0">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span>Title</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Starting Price</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Location</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Down Payment</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Date</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Developer</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Type</span>
                </DataTableRow>
                <DataTableRow></DataTableRow>
              </DataTableHead>
              {!loadingOffplans&&offPlans?.filteredOutput?.length ? (
                offPlans?.filteredOutput?.map((item, index) => (
                  <OffPlanDataTableRow
                    offplan={item}
                    index={index}
                    key={index + item?.id}
                  />
                ))
              ) : (
                <>{!loadingOffplans?
                   <DataTableItem>
                  <DataTableRow>
                    <span>No records to display</span>
                  </DataTableRow>
                </DataTableItem>:null}
                </>
               
              )}
              {loadingOffplans&&<LoadingComponent/>}
            </DataTableBody>
            <PreviewCard>
              {offPlans && offPlans?.filteredOutput?.length > 0 ? (
                <PaginationComponent
                  currentPage={query.page}
                  paginate={(page) => setQuery((c) => ({ ...c, page }))}
                  itemPerPage={query.per_page}
                  totalItems={offplansList?.filteredCount}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No offPlans Found</span>
                </div>
              )}
            </PreviewCard>
          </DataTable>
        )}
      </PreviewCard>
      {/* ****** Modal Start ****** */}
      {showModalUnpublish ? (
        <ConfirmModal
          msg={"Unpublish"}
          name={"The OffPlan"}
          open={showModalUnpublish}
          handleClick={(e) => {
            e.preventDefault();
            setShowModalUnpublish(true);
          }}
          handleConfirm={statusChangeOffPlanUnpublish}
          handlecloseModal={() => setShowModalUnpublish(false)}
          action="Unpublish"
          loading={deleting}
        />
      ) : null}

      {showModalPublish ? (
        <ConfirmModal
          msg={"Publish"}
          name={"The OffPlan"}
          open={showModalPublish}
          handleClick={(e) => {
            e.preventDefault();
            setShowModalPublish(true);
          }}
          handleConfirm={statusChangeOffPlanPublish}
          handlecloseModal={() => setShowModalPublish(false)}
          action="Publish"
        />
      ) : null}

      {/* ****** Modal End ****** */}
    </Content>
    </React.Fragment>
    
  );
};
export default OffPlan;
