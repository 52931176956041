/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Spinner,
    UncontrolledDropdown,
  } from "reactstrap";
  import {
    BlockBetween,
    BlockContent,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
  } from "../../../../../components/Component";
  import CardInner from "../../../../../components/cards/CardInner";
  import LoadingComponent from "../../../../components/loader/tableLoader";
  import { useState } from "react";
  import { useForm } from "react-hook-form";
  import { useSelector } from "react-redux";
  import { useDispatch } from "react-redux";
  import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
  import { updateGlobalSettings } from "../../../../../redux/actions/settings";
  import { toast } from "react-toastify";
  
  const LeadSettingsEdit = ({ settingsState, setIsEditLeadSettings, refreshFn }) => {
    const { globalSettings, loading, error } = settingsState;
    const { register, handleSubmit } = useForm();
    const axios = useAxiosPrivate();
    const dispatch = useDispatch();
  
    const [leadNewFlagDateLimit, setLeadNewFlagDateLimit] =
      useState("days");
  
    const { updateLoading, updateError } = useSelector((state) => ({
      loading: state.settings.loading,
      error: state.settings.error,
    }));
  
    const submitForm = async (formData) => {
      try {
        const payload = {
          settings: {
            explorerNewLeadDuration: `${formData.explorerNewLeadDuration} days`,
          },
        };
        // refreshFn will run after the dispatch is successful
        dispatch(updateGlobalSettings(axios, payload, refreshFn));
      } catch (error) {
        if (updateError) {
          toast.error(updateError);
          console.log(updateError);
        } else {
          console.log(error);
          toast.error("An error occurred while updating the settings.");
        }
      } finally {
        setIsEditLeadSettings(false);
      }
    };
  
    return (
      <CardInner size="lg">
        <form action="" onSubmit={handleSubmit(submitForm)}>
          <BlockHead size="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h4">Leads Settings</BlockTitle>
                <BlockDes>
                  <p>Settings for leads.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="success"
                  type="submit"
                  className="d-none d-lg-inline"
                  style={{ marginTop: "-2rem" }}
                >
                  {updateLoading ? <Spinner size={"sm"} /> : "Save"}
                </Button>
                <Button
                  color="light"
                  className="d-none d-lg-inline"
                  style={{ marginTop: "-2rem", marginLeft: "0.5rem" }}
                  onClick={() => setIsEditLeadSettings(false)}
                >
                  Cancel
                </Button>
              </BlockHeadContent>
              <BlockHeadContent className="align-self-start d-lg-none">
                <a
                  href="#"
                  className="toggle btn btn-icon btn-trigger mt-n1"
                  data-target="userAside"
                >
                  <Icon name="menu-alt-r" />
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          {error ? (
            <BlockHeadContent>
              <h6 className="text-danger">Failed to get settings</h6>
            </BlockHeadContent>
          ) : loading ? (
            <LoadingComponent />
          ) : (
            <>
              <BlockContent>
                <div className="gy-3">
                  <div className="g-item">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <label
                        className="form-label mt-2"
                        htmlFor="newLeadFlagDateLimit"
                      >
                        New lead flag date limit after creation date
                      </label>
                      <div className="form-group flex-grow-1 d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                        <Input
                          type="number"
                          className="w-45"
                          name="explorerNewLeadDuration"
                          defaultValue={
                            parseInt(globalSettings?.global?.explorerNewLeadDuration?.split(" ")[0]) ?? ""
                          }
                          innerRef={register()}
                        />
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="transparent"
                            className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                          >
                            {leadNewFlagDateLimit}
                          </DropdownToggle>
                          <DropdownMenu>
                            <ul className="link-list-opt no-bdr">
                              {["days"].map((interval, idx) => (
                                <li key={`unassign-interval-${idx}`}>
                                  <DropdownItem
                                    onClick={() =>
                                      setLeadNewFlagDateLimit(interval)
                                    }
                                  >
                                    <span>{interval}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockContent>
              <Button color="success" type="submit" className="d-lg-none mt-3">
                Save
              </Button>
              <Button
                color="light"
                className="d-lg-none mt-3"
                style={{ marginLeft: "0.5rem" }}
                onClick={() => setIsEditLeadSettings(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </form>
      </CardInner>
    );
  };
  
  export default LeadSettingsEdit;
  