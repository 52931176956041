import React, { useMemo, useState } from "react";
import { Button, Col, Spinner } from "reactstrap";
import { RSelect } from "../../Component";
import DatePicker from "react-datepicker";
import { ActivityTaskTypeOptions } from "../constants";
import { addDays, addMinutes, addYears } from "date-fns";
import PropertiesDropDown from "../components/PropertiesDropDown";
import { useActivityFormState } from "../context/ActivityProvider";
import { showToast } from "../../../utils/toast/toast";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { LeadStatus, newRoles } from "../../../utils/envConfig";


const fifteenDaysFromNow = addDays(new Date(), 15);
const twoMonthsFromNow = addDays(new Date(), 60);
const sixMonthsFromNow = addDays(new Date(), 30 * 6);

/**
 * @typedef {Object} SelectedDate
 * @property {Date|null} date
 * @property {Date|null} time
 */

/**
 * @typedef {Object} MutationQueryResult
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutate - the mutation function to call
 * @property {function(any, { onSuccess, onError, onSettled }): void} mutateAsync - the mutation function to call, but async which returns an awaitable promise
 * @property {"error" | "idle" | "loading" | "success"} status - The status of the mutation
 * @property {unknown | undefined} data - The last successfully resolved data for the query.
 * @property {unknown | undefined} error - The error object for the query, if an error was encountered.
 * @property {() => void} reset - A function to clean the mutation internal state (i.e., it resets the mutation to its initial state).
 */

/**
 * @typedef {Object} CreationMutations
 * @property {MutationQueryResult?} completeActivityFn
 * @property {MutationQueryResult} createActivityFn
 */

/**
 * A component that renders the form used to create tasks.
 *
 * @param {Object} props - The props for the component.
 * @param {User} props.user - The user associated that this task is associated with
 * @param {UserActivity?} props.completedActivity - The activity being completed.
 * @param {() => void} props.toggleFn - The function used to toggle the visibility of the modal rendering this form
 * @param {boolean} props.showTitle - specify whether a title should be rendered or not.
 * @param {boolean} props.isTaskCompletion - specifies whether this activity is created after a task completion.
 * @param {CreationMutations} props.mutations - the mutation functions to call to create an activity.
 * @returns {JSX.Element} - The rendered table component.
 */
const ActivityCreationForm = ({
  user,
  completedActivity,
  toggleFn,
  showTitle,
  isTaskCompletion,
  mutations,
}) => {
  const queryClient = useQueryClient();

  const authUser = useSelector((state) => state.user.loggedInUser);
  const isAdmin = useMemo(() => (
    
    authUser &&
    ( authUser.role === newRoles.Admin ||
        authUser.role === newRoles.MasterAdmin )
  ), [authUser]);

  const {
    activityCreationPayload,
    setActivityCreationPayload,
    formErrors,
    setFormErrors,
    switchTab,
  } = useActivityFormState();

  const validNewTaskDate = useMemo(() => {
    if (user?.lead_status === LeadStatus.Prospect) {
      return twoMonthsFromNow;
    } else if (user?.lead_status === LeadStatus.Won) {
      return sixMonthsFromNow;
    }
    return fifteenDaysFromNow;
  }, [user?.lead_status]);

  /**
   * State for managing the payload sent by this form.
   * @type {[SelectedDate, import("react").Dispatch<import("react").SetStateAction<SelectedDate>>]}
   */
  const [selectedDate, setSelectedDate] = useState({
    date: new Date(),
    time: new Date(),
  });
  
  const combinedDateTime = useMemo(
    () =>
      new Date(
        selectedDate?.date?.getFullYear(),
        selectedDate?.date?.getMonth(),
        selectedDate?.date?.getDate(),
        selectedDate?.time?.getHours(),
        selectedDate?.time?.getMinutes()
      ),
    [selectedDate?.date, selectedDate?.time]
  );
  const resultDate = useMemo(
    () => addMinutes(combinedDateTime, 0), // CHANGE THIS IF YOU WANT TO ADD A DELAY
    [combinedDateTime]
  );

  const currentDate = new Date();

  const isFormValid = () => {
    if (!selectedDate?.time) {
      setFormErrors((prev) => ({ ...prev, time: "Please select a time." }));
      return;
    }
    if (!selectedDate?.date) {
      setFormErrors((prev) => ({ ...prev, date: "Please select a date." }));
      return;
    }
    if (!activityCreationPayload?.type) {
      setFormErrors((prev) => ({
        ...prev,
        type: "Please select a task type.",
      }));
      return false;
    }
    if (!activityCreationPayload?.description) {
      setFormErrors((prev) => ({
        ...prev,
        description: "Please select some feedback.",
      }));
      return false;
    }
    // Date validation
    if (resultDate < currentDate) {
      setFormErrors((prev) => ({ ...prev, invalidDate: true }));
      showToast("Date should not be in the past", "error");
      return;
    } else if (resultDate > fifteenDaysFromNow) {
      setFormErrors((prev) => ({ ...prev, invalidDate: true }));
      showToast("Date should be within 15 days from now", "error");
      return;
    }

    return true;
  };

  /**
   * function to submit the form
   *
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const submitForm = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    if (isTaskCompletion) {
      const createTask = () => {
        const payload = {
          ...activityCreationPayload,
          date: resultDate,
          agentId: isAdmin ? (user?.currentAgent?.id ?? user?.currentAgent?._id) : null,
          user_id: user?.id ?? user?._id,
        };
        mutations?.createActivityFn.mutate(payload, {
          onSuccess: () => {
            showToast("Completed task and created new task.", "success");
            setActivityCreationPayload({});
            toggleFn();
          },
          onSettled: () => {
            queryClient.invalidateQueries("userActivities");
            queryClient.invalidateQueries(["user", user?.id ?? user?._id]);
            setFormErrors((_) => ({
              notes: null,
              description: null,
              type: null,
            }));
            toggleFn && typeof toggleFn === "function" && toggleFn();
            switchTab(0);
          },
        });
      };
      // have to call update tasks as well
      mutations?.completeActivityFn.mutate(
        completedActivity?.id ?? completedActivity?._id,
        {
          onSuccess: () => {
            createTask();
          },
        }
      );
    } else {
      // Only have to create an activity in this case
      const payload = {
        ...activityCreationPayload,
        date: resultDate,
        agentId: isAdmin ? (user?.currentAgent?.id ?? user?.currentAgent?._id) : null,
        user_id: user?.id ?? user?._id,
      };
      mutations.createActivityFn.mutate(payload, {
        onSuccess: () => {
          showToast("Created new task successfully.", "success");
          setActivityCreationPayload({});
          toggleFn && typeof toggleFn === "function" && toggleFn();
        },
        onSettled: () => {
          queryClient.invalidateQueries("userActivities");
          setFormErrors((_) => ({
            notes: null,
            description: null,
            type: null,
          }));
          switchTab(0);
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="p-2">
        {showTitle ? <h5 className="title">Add Task</h5> : null}
        <div className={showTitle ? "mt-4" : ""}>
          <form className="row gy-4" onSubmit={submitForm}>
            <Col sm="6">
              <div className="form-group">
                <label htmlFor="" className="form-label">
                  Client Name
                </label>
                {user ? (
                  <input
                    disabled
                    type="text"
                    name="title"
                    value={`${user?.first_name} ${user?.last_name}`}
                    className="form-control"
                  />
                ) : null}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Task Type</label>
                <RSelect
                  options={ActivityTaskTypeOptions}
                  defaultValue={selectedDate?.selectedType}
                  className={
                    formErrors?.type ? "border border-danger rounded" : ""
                  }
                  onChange={(e) => {
                    setFormErrors((prev) => ({ ...prev, type: null }));
                    setActivityCreationPayload((prev) => ({
                      ...prev,
                      type: e.value,
                    }));
                  }}
                />
                {formErrors?.type ? (
                  <span className="text-danger">{formErrors?.type}</span>
                ) : null}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Date</label>
                <DatePicker
                  minDate={new Date()}
                  selected={selectedDate?.date}
                  onChange={(date) => {
                    setFormErrors((prev) => ({ ...prev, date: null }));
                    setSelectedDate((prev) => ({ ...prev, date: date }));
                  }}
                  maxDate={validNewTaskDate}
                  className={`form-control date-picker ${
                    formErrors?.date || formErrors?.invalidDate
                      ? "border border-danger rounded"
                      : ""
                  }`}
                />
                {formErrors?.date ? (
                  <div className="mt-1 text-danger">{formErrors?.date}</div>
                ) : null}
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Time</label>
                <div className="form-control-wrap has-timepicker focused">
                  <DatePicker
                    selected={selectedDate?.time}
                    onChange={(date) => {
                      setFormErrors((prev) => ({ ...prev, time: null }));
                      setSelectedDate((prev) => ({ ...prev, time: date }));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={`form-control date-picker ${
                      formErrors?.time || formErrors?.invalidDate
                        ? "border border-danger rounded"
                        : ""
                    }`}
                  />
                  {formErrors?.time ? (
                    <div className="mt-1 text-danger">{formErrors?.time}</div>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <label className="form-label">Property</label>
                <PropertiesDropDown
                  defaultOption={selectedDate?.property}
                  onChange={(e) =>
                    setActivityCreationPayload((prev) => ({
                      ...prev,
                      property_list_id: e.value,
                    }))
                  }
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="form-group">
                <label className="form-label">Feedback</label>
                <textarea
                  name="notes"
                  value={activityCreationPayload?.description ?? undefined}
                  onBlur={isFormValid}
                  onChange={(e) => {
                    setFormErrors((prev) => ({ ...prev, description: null }));
                    setActivityCreationPayload((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className={`form-control no-resize ${
                    formErrors?.description ? "border-danger" : ""
                  }`}
                />
                {formErrors?.description ? (
                  <div className="mt-1 text-danger">
                    {formErrors?.description}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <ul className="d-flex justify-content-end mt-3">
                <ul className="align-end gx-2 gy-2">
                  <li>
                    <Button
                      color="primary"
                      size="md"
                      type="submit"
                      disabled={
                        mutations.createActivityFn?.status === "loading" ||
                        mutations.completeActivityFn?.status === "loading"
                      }
                    >
                      {mutations.createActivityFn?.status === "loading" ||
                      mutations.completeActivityFn?.status === "loading" ? (
                        <Spinner size={"sm"} />
                      ) : (
                        `Add Task`
                      )}
                    </Button>
                  </li>
                  {toggleFn && typeof toggleFn === "function" ? (
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleFn();
                        }}
                        className="bg-secondary text-white"
                      >
                        Cancel
                      </Button>
                    </li>
                  ) : null}
                </ul>
              </ul>
            </Col>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActivityCreationForm;
