import { PropertyListStatus } from '../../../utils/envConfig';
import TooltipCard from '../../tooltip/TooltipCard';

const PropertyListHoverCard = ({ listing }) => {
  const publishedState =
    listing?.status === PropertyListStatus.Published
      ? { label: 'Published', color: 'success' }
      : { label: 'Unpublished', color: 'danger' };

  return (
    <TooltipCard text={listing?.referNo}>
      <div className="cursor-default">
        <span className="fw-bold text-secondary">
          {listing?.propertyTitle}
        </span>
        <div className="d-flex flex-wrap my-2" style={{ gap: '.5rem' }}>
          <span className="badge bg-primary-dim text-primary">{listing?.listingType}</span>
          <span className={`badge bg-${publishedState.color}-dim text-${publishedState.color}`}>
            {publishedState.label}
          </span>
        </div>
        <div>
          <div className="d-flex align-items-center my-2">
            <span>{`${listing?.emirate ?? 'Dubai'}, ${listing?.communityName}, ${listing?.buildingName}`}</span>
          </div>
        </div>
      </div>
    </TooltipCard>
  );
};

export default PropertyListHoverCard;
