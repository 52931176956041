

import React, { useMemo, useState } from 'react'
import RevenueView from './RevenueView'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import { removeNulls } from "../deal-commissions/utils";

const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

const RevenueViewContainer = () => {
   const axios = useAxiosPrivate();
  
    const initialQueryParams = useMemo(
        () => ({
        fromDate: null,
        toDate: null,
        transactions: {
          fromPaymentDate: null,
          toPaymentDate: null,
        },
      }),
      []
    );
  
    const [queryParams, setQueryParams] = useState(initialQueryParams);
  
    const { data, isError, error, isLoading } = useQuery(
      ["revenue", queryParams],
      async () => {
        const res = await axios.get("/deals/revenue", {
          params: removeNulls(queryParams),
        });
        return res?.data;
      },
      {
        staleTime: FIFTEEN_MINUTES,
        refetchOnWindowFocus: false,
        refetchInterval: THIRTY_MINUTES,
      }
    );
  
  return (
    <div style={{marginTop:"120px", marginLeft: "20px"}}>
      <RevenueView    finances={{ data, isError, error, isLoading }}
      queryParams={queryParams}
      setQueryParams={setQueryParams}/>
    </div>
  )
}

export default RevenueViewContainer