import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import PropertyCard from "./PropertyCard";
import propertyCardQueries from "../../queries/propertyCardQueries";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PropertiesCardsHeader from "./PropertiesCardsHeader";
import { PaginationComponent } from "../Component";
import { ScreenBreakPoints } from "../../utils/envConfig";
import Skeleton from "react-loading-skeleton";

const THIRTY_MINUTES = 30 * 60 * 1000;
const itemsPerPage = 6;

/**
 * The container to render property cards in the user profile
 *
 * @param {Object} props - The props for the component.
 * @param {User} props.lead - The user associated with the activities
 * @returns {JSX.Element} - The rendered table component.
 */
const PropertiesCardsContainer = ( { lead } ) =>
{
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const axios = useAxiosPrivate();

    const paginate = useCallback(( pageNumber ) => setCurrentPage( pageNumber ), []);
    const leadId = useMemo(() => (lead?.id ?? lead?._id), [lead]);

    const { queryKey, queryFn } = propertyCardQueries.list( leadId, currentPage, itemsPerPage, axios );
    const { data: leadPropertyCardsData, isLoading, error } = useQuery( queryKey, queryFn, {
        refetchOnWindowFocus: false,
        refetchInterval: THIRTY_MINUTES,
        staleTime: THIRTY_MINUTES,
        enabled: !!leadId
    } );

    if ( error )
    {
        if ( error.message === 'Property cards not found' )
        {
            return <div>Property cards not found for lead ID: { lead?.id }</div>;
        }
        return <div>Error loading property cards: { error.message }</div>;
    }

    const currentItems = leadPropertyCardsData?.data;
    const total = leadPropertyCardsData?.found;

    let columnsClass = "col-12";
    if ( window.innerWidth >= ScreenBreakPoints.md )
    {
        columnsClass = "col-6";
    }
    if ( window.innerWidth >= ScreenBreakPoints.xxl )
    {
        columnsClass = "col-4";
    }


    return (
        <div id="propertyCards">
            <div className="mt-5">
                <PropertiesCardsHeader lead={ lead } />
            </div>
            <div className="row">
                {isLoading ? (
                    new Array(itemsPerPage).fill(0).map( ( _, idx ) => (
                        <div key={ `card-skeleton-${ idx }` } className={ columnsClass }>
                            <Skeleton style={{ width: '21.5rem', height: '15.8rem', marginTop: '2.5rem' }} />
                        </div>
                    ) )
                ) : null}
                { !isLoading && currentItems && currentItems.length > 0 &&
                    currentItems.map( ( item, idx ) => (
                        <div key={ `card-${ idx }` } className={ columnsClass }>
                            <PropertyCard data={ item } lead={ lead } />
                        </div>
                    ) )
                }
            </div>
            { currentItems && currentItems.length > 0 &&
                <div className="mt-5">
                    <PaginationComponent
                        itemPerPage={ itemsPerPage }
                        totalItems={ total }
                        paginate={ paginate }
                        currentPage={ currentPage }
                    />
                </div> }

        </div>
    );
};

export default PropertiesCardsContainer;
