/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  fetchAllCommunityTeams,
} from "../../../redux/actions/community-teams";
import { Button } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  PaginationComponent,
} from "../../../components/Component";
import queryString from "query-string";
import { TEAM_FIELDS_TO_DISPLAY } from "../constants";
import TeamEditModal from "./TeamEditModal";
import TeamCreateModal from "./TeamCreateModal";
import LoadingComponent from "../../components/loader/tableLoader";
import { deepEqual } from "../utils";
import MembersList from "./team/MembersList";
import CommunitiesList from "./team/CommunitiesList";

const ListCommunityTeamsUI = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [tablesm, setTableSm] = useState(false);
  const [isSearchClosed, setIsSearchClosed] = useState(true);
  const [searchText, setSearchText] = useState(query?.search ?? "");
  const [editTeamDetails, setEditTeamDetails] = useState({});
  const [isTeamCreationModalOpen, setIsTeamCreationModalOpen] = useState(false);
  const [isTeamEditModalOpen, setIsTeamEditModalOpen] = useState(false);

  const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
  const [perPage, setPerPage] = useState(
    query?.limit ? Number(query?.limit) : 10
  );

  // Filters state
  const [filterCommunities, setFilterCommunities] = useState([]);
  const [selectedCommunityFilters, setSelectedCommunityFilters] = useState([]);
  const [filterMembers, setFilterMembers] = useState([]);
  const [selectedMemberFilter, setSelectedMemberFilter] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  const queryCommunityTeams = (params, updateRoute = true) => {
    const teamParams = {
      limit: params?.limit ?? query?.limit ?? perPage,
      search: params?.search ?? query?.search,
      page: params?.page ?? query?.page,
      members: params?.members ?? query?.members,
      communities: params?.communities ?? query?.communities,
    };

    const queryParams = new URLSearchParams();

    Object.keys(teamParams).forEach((key) => {
      const value = teamParams[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => queryParams.append(key, subValue));
        // eslint-disable-next-line eqeqeq
      } else if (value != undefined && value !== "null") {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();
  

    if (updateRoute) {
      const navigateParams = {};

      if (location.search.length) {
        navigateParams.replace = true;
      }
      navigate(`${"/list-community-teams"}?${queryString}`, navigateParams);
    }
    return queryString;
  };

  useEffect(() => {
    const fetchAllAgents = async () => {
      const agentRes = await axios.get("/agent");
      setFilterMembers(agentRes?.data);
    };

    const fetchAllCommunities = async () => {
      const communityRes = await axios.get("/community");
      setFilterCommunities(communityRes?.data);
    };
    fetchAllAgents();
    fetchAllCommunities();
  }, []);

  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);

  // we want to run this useEffect each time query changes its value
  useEffect(() => {
    const queryString = queryCommunityTeams({});
    dispatch(fetchAllCommunityTeams(axios, queryString));

    setPage(query?.page ? Number(query?.page) : 1);
    setPerPage(query?.limit ? Number(query?.limit) : 10);

    setSearchText(query?.search ?? "");
  }, [location.search, refresh]);

  // Redux state
  // eslint-disable-next-line no-unused-vars
  let { allCommunityTeams, teamsLoading, teamsError } = useSelector(
    (state) => ({
      allCommunityTeams: state?.communityTeam?.communityTeams,
      teamsLoading: state?.communityTeam?.loading,
      teamsError: state?.communityTeam?.error,
    })
  );

  const authUser = useSelector((state) => state?.user?.loggedInUser);

  // FILTER OPTIONS
  let memberFilterOptions = Object.values(filterMembers).map((item) => ({
    label: `${item?.user?.first_name} ${item?.user?.last_name}`,
    value: item?.userId ? item?.userId : item?.user?.id,
  }));

  let communityFilterOptions = Object.values(filterCommunities).map((item) => ({
    label: item.community,
    value: item.id,
  }));

  // utility functions
    const toggleTeamCreationModal = () => {
    setIsTeamCreationModalOpen(!isTeamCreationModalOpen);
  };

  const toggleTeamEditModal = () => {
    setIsTeamEditModalOpen(!isTeamEditModalOpen);
  };

  const handleSearchSubmit = (formEvent) => {
    formEvent.preventDefault();
    setSearchText(formEvent.target.elements.searchField.value);
    queryCommunityTeams({ limit: perPage, page: 1, search: searchText });
  };

  const handleEditModal = (team) => {
    setEditTeamDetails(team);
    toggleTeamEditModal();
  };

  const isAdmin = authUser && (authUser.roles?.includes("MasterAdmin") || authUser.roles?.includes("Admin"));

  const CommunityTeamTableItem = ({ team, index }) => {
    return (
      <DataTableItem key={index}>
        <DataTableRow className={'text-center'}>
          <Link to={`/community-team-details/${team?.id}`}>
            {team.teamName}
          </Link>
        </DataTableRow>
        <DataTableRow>
          <MembersList members={team.members} leader={team?.leader} />
        </DataTableRow>
        <DataTableRow className={'text-center'}>
          <CommunitiesList communities={team.communities} />
        </DataTableRow>
        <DataTableRow>
          <div
            className="tb-odr-btns"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              color="info"
              size="sm"
              className="btn btn-dim me-1"
              onClick={() => navigate(`/community-team-details/${team?.id}`)}
            >
              View
            </Button>
            {isAdmin ? (
              <Button
                color="primary"
                size="sm"
                className="btn btn-dim me-1"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditModal(team);
                }}
              >
                Edit
              </Button>
            ) : null}
          </div>
        </DataTableRow>
      </DataTableItem>
    );
  };

  const CommunityTeamTableItemMemo = React.memo(CommunityTeamTableItem, deepEqual);

  return (
    <React.Fragment>
      <Head title="Master Communities"></Head>
      <Content>
        {/* ================ PAGE HEADER =================== */}
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Master Communities
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  There are a total of {allCommunityTeams.found ?? 0} Master Communities.
                </p>
              </BlockDes>
            </BlockHeadContent>
            {/* =========== TEAM CREATION BUTTON ============== */}
            {isAdmin ? (
              <BlockHeadContent>
                <Button
                  className="toggle d-none d-md-inline-flex"
                  color="primary"
                  onClick={toggleTeamCreationModal}
                >
                  <Icon name="plus"></Icon>
                  <span>Add Master Community</span>
                </Button>
              </BlockHeadContent>
            ) : null}
            {/* =========== TEAM CREATION BUTTON ============== */}
          </BlockBetween>
          <ul
            className="nk-block-tools g-3"
            style={{
              display: "flex",
              justifyContent: "end",
              margin: "auto",
            }}
          >
            <li>
              <RSelect
                placeholder="Filter By Place"
                className="status-div"
                isMulti
                value={
                  selectedCommunityFilters?.length > 0
                    ? selectedCommunityFilters.map((community) => ({
                        label: community.community,
                        value: community.id,
                      }))
                    : []
                }
                options={[
                  {
                    label: "All Communities",
                    value: "all",
                  },
                  ...communityFilterOptions,
                ]}
                onChange={(selectedOptions) => {
                  if (
                    selectedOptions.some((option) => option.value === "all")
                  ) {
                    setSelectedCommunityFilters([]);
                    queryCommunityTeams({ communities: [], page: 1 });
                  } else {
                    setSelectedCommunityFilters(
                      selectedOptions.map((option) => ({
                        community: option.label,
                        id: option.value,
                      }))
                    );
                    queryCommunityTeams({
                      communities: selectedOptions?.map(
                        (option) => option.value
                      ),
                      page: 1
                    });
                  }
                }}
              />
            </li>
            <li>
              <RSelect
                placeholder="Filter By Agent"
                className="status-div"
                value={
                  selectedMemberFilter
                    ? {
                        label: selectedMemberFilter?.label,
                        value: selectedMemberFilter?.value,
                      }
                    : null
                }
                options={[
                  {
                    label: "All Agents",
                    value: "all",
                  },
                  ...memberFilterOptions,
                ]}
                onChange={(selectedOption) => {
                  if (selectedOption.value === "all") {
                    setSelectedMemberFilter(null);
                    queryCommunityTeams({ members: [] });
                  } else {
                    setSelectedMemberFilter(selectedOption);
                    queryCommunityTeams({
                      members: selectedOption?.value,
                      page: 1
                    });
                  }
                }}
              />
            </li>
          </ul>
        </BlockHead>
        {/* ================ END OF PAGE HEADER =================== */}
        {/* ================ MAIN BLOCK =================== */}
        <Block>
          <DataTable className="card-stretch">
            {/* ============= SEARCH CARD ================ */}
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setIsSearchClosed(!isSearchClosed);
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => setTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => setTableSm(false)}
                              >
                                <Icon className="arrow-left"></Icon>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${
                  !isSearchClosed && "active"
                }`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearchText("");
                        queryCommunityTeams({ search: "" });
                        setIsSearchClosed(!isSearchClosed);
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <form onSubmit={handleSearchSubmit}>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                        placeholder="Search by Master Community name..."
                        value={searchText}
                        name="searchField"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </form>
                    <Button className="search-submit btn-icon bg-transparent text-secondary border-0">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* ============= END OF SEARCH CARD ================ */}

            {/* ============= DATA TABLE ================ */}
            <DataTableBody>
              {/* ============= HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
              {!teamsLoading ? (
                <React.Fragment>
                  <DataTableHead>
                    {TEAM_FIELDS_TO_DISPLAY.map((item, idx) => (
                      <DataTableRow key={idx} className={'text-center'}>
                        <span className="sub-text">{item}</span>
                      </DataTableRow>
                    ))}
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1 my-n1"></ul>
                    </DataTableRow>
                  </DataTableHead>
                  {/* ============= END OF HEADERS (SELECTED FIELDS TO DISPLAY) ================ */}
                  {allCommunityTeams.results &&
                    allCommunityTeams.results.map((team, idx) => (
                      <CommunityTeamTableItemMemo
                        key={idx}
                        team={team}
                        index={idx}
                      />
                    ))}
                </React.Fragment>
              ) : <LoadingComponent /> }
            </DataTableBody>
            {/* ============= END OF DATA TABLE ================ */}
          </DataTable>
          <div className="card-inner">
            {allCommunityTeams?.results?.length > 0 ? (
              <PaginationComponent
                noDown
                currentPage={page ?? 1}
                paginate={(page) => {
                  setPage(page);
                  queryCommunityTeams({ page: page });
                }}
                itemPerPage={perPage ?? 10}
                totalItems={allCommunityTeams?.found}
              />
            ) : !teamsLoading ? (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            ) : null}
          </div>
        </Block>
        {/* ================ END OF MAIN BLOCK =================== */}
        {/* ================== MODALS ====================== */}

        <TeamCreateModal
          isOpen={isTeamCreationModalOpen}
          toggleFn={toggleTeamCreationModal}
          refreshFn={toggleRefresh}
        />

        {Object.keys(editTeamDetails).length ? (
          <TeamEditModal
            key={editTeamDetails.id} // Ensure React treats it as a new instance for different teams
            communityTeam={editTeamDetails}
            leaderId={editTeamDetails?.leader?.id}
            isOpen={isTeamEditModalOpen}
            toggleFn={toggleTeamEditModal}
            refreshFn={toggleRefresh}
          />
        ) : null}

        {/* ================== END OF MODALS ====================== */}
      </Content>
    </React.Fragment>
  );
};

export default ListCommunityTeamsUI;
