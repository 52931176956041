import * as HoverCard from '@radix-ui/react-hover-card';
import { Icon } from '../Component';

const TooltipCard = ({ icon, text, iconClassName, textClassName, openDelay, children }) => {
  return (
    <HoverCard.Root
     openDelay={openDelay ?? 300}
     closeDelay={100}
    >
      <HoverCard.Trigger>
        {icon ? <Icon className={iconClassName ?? ''} name={icon} /> : <span role='button' className={textClassName ?? ''}>{text}</span>}
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content className='HoverCardContent'>
          {children}
          <HoverCard.Arrow className="HoverCardArrow" />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}

export default TooltipCard;
