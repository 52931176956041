import {
    Button,
    Col,
    Collapse,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import { Icon, PreviewCard, RSelect } from '../../../components/Component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOffplans } from '../../../redux/actions/offplans';
import { useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
    BathRooms,
    BedRooms,
    DealType,
    formatValue,
    formatValueToDecimal
} from '../../../utils/envConfig';
import { fetchPropTypesList } from '../../../redux/actions/property';
import { toast } from 'react-toastify';
import SearchClientSelect from '../../../components/SearchClientSelect';
import { useConfirm } from '../../../hooks/useConfirm';
import ConfirmModal from './ConfirmModal';
import AddLeadStepOne from '../../leads/components/AddLeadStepOne';
import SelectDevelopment from '../../../components/SelectDevelopment';
import { safeParseNumber } from '../../../utils/Utils';
import PropTypesDropdown from '../../components/filter-components/PropTypesFilter';

// we need a regex that specifies that string must not start or end with - or _ and must not contain any other special characters apart from - and _ and must not contain any spaces and we cannot have more than one - or _ in a row
const regex = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;

const PrimaryOffPlanProperty = ({
    dealType = null,
    users = [],
    next = () => {}
}) => {
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();

    const agents = useSelector((state) => state?.user?.agents);

    const { cancel, isConfirmed, isOpen, proceed, prompt } = useConfirm();

    const { propertyTypes, propTypesLoading } = useSelector(
        (state) => ({
            propertyTypes: state?.property?.propertyTypes,
            propTypesLoading: state?.property?.propTypesLoading
        })
    );

    const bedsOptions = BedRooms?.map((room) => ({ value: room, label: room }));

    const bathOptions = BathRooms?.map((room) => ({
        value: room,
        label: room
    }));

    const offplans = useSelector((state) => state?.offplans?.offplans_list);

    const offplansOptions =
        offplans && offplans?.filteredOutput?.length
            ? offplans?.filteredOutput?.map((item) => ({
                  ...item,
                  value: item?.developmentName,
                  label: item?.developmentName
              }))
            : [];

    const [errors, setErrors] = useState({});

    const [openAddClient, setopenAddClient] = useState(false);

    const [dealInfo, setDealInfo] = useState({
        client: null,
        development: null,
        propertyType: null,
        beds: null,
        baths: null,
        size: '',
        unitId: '',
        agreedPrice: '',
        agreedCommission: '',
        agreedCommissionValue: ''
    });

    const [formattedCommission, setFormattedCommission] = useState('');

    const [assignedAgent, setAssignedAgent] = useState(null);
    const [assigning, setAssigning] = useState(null);

    const [loading, setLoading] = useState(false);
    const [isMortgage, setIsMortgage] = useState(false);
    const [isSellerMortgage, setIsSellerMortgage] = useState(false);

    const toggleModalAddClient = () => {
        setopenAddClient(!openAddClient);
    };

    useEffect(() => {
        !offplans?.length && dispatch(getOffplans(axios));
        !propertyTypes?.length && dispatch(fetchPropTypesList(axios));
    }, []);

    const handleAssignAgent = async () => {
        try {
            // handle assigning client to agent
            const userAgentParams = {
                agent_id: dealInfo?.agent?.id ?? dealInfo?.agent?._id,
                user_id: dealInfo?.client?.id ?? dealInfo?.client?._id
            };

            const { data: assignedagent } = await axios.post(
                '/usersAgents',
                userAgentParams
            );

            return assignedAgent;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    const createNewOffplanProperty = async () => {
        try {
            const propertyDetails = {
                propertyType: dealInfo?.propertyType?.propertyType,
                beds: dealInfo?.beds?.value,
                baths: dealInfo?.baths?.value,
                size: dealInfo?.size,
                unitId: dealInfo?.unitId,
                agreedSalesPrice: safeParseNumber(dealInfo?.agreedPrice),
                agreedCommission: safeParseNumber(dealInfo?.agreedCommission),
                offPlanId:
                    dealInfo?.development?.id ?? dealInfo?.development?._id
            };
            const { data: offplanListing } = await axios.post(
                '/propList/listingOffplan',
                propertyDetails
            );
            return offplanListing;
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    const validateDealData = () => {
        const errors = {};

        if (
            !dealInfo.client ||
            (!dealInfo?.client?.id && !dealInfo?.client?._id)
        ) {
            errors.client = 'Select a valid client';
        }

        if (
            !dealInfo.development ||
            (!dealInfo?.development?.id && !dealInfo?.development?._id)
        ) {
            errors.development = 'Select a valid off plan.';
        }

        if (
            !dealInfo.agent ||
            (!dealInfo?.agent?.id && !dealInfo?.agent?._id)
        ) {
            errors.agent = 'Select a valid agent';
        }

        return Object.keys(errors)?.length > 0 ? errors : null;
    };

    const validatePropertyData = () => {
        const errors = {};

        if (
            !dealInfo.propertyType ||
            (!dealInfo?.propertyType?.id && !dealInfo?.propertyType?._id)
        ) {
            errors.propertyType = 'Select property type';
        }

        if (!dealInfo.beds || !dealInfo?.beds?.value) {
            errors.beds = 'Select number of bedrooms';
        }

        if (!dealInfo.baths || !dealInfo?.baths?.value) {
            errors.baths = 'Select number of bathrooms';
        }

        // if dealInfos.size is not a number or is less than 1, we throw error
        if (
            !dealInfo.size ||
            isNaN(+dealInfo.size) ||
            +dealInfo.size <= 0 /*&& dealInfo.size?.length > 0*/ ||
            dealInfo?.size?.length > 10
        ) {
            errors.size = !dealInfo?.size
                ? 'Property size is required'
                : dealInfo.size <= 0
                ? 'Size cannot be less than 1'
                : 'invalid property size';
        }

        const agreedPrice = Number(
            dealInfo.agreedPrice?.trim()?.replaceAll(',', '')
        );

        if (
            !agreedPrice ||
            isNaN(agreedPrice) ||
            agreedPrice > Number.MAX_SAFE_INTEGER ||
            agreedPrice <= 0
        ) {
            errors.agreedPrice = isNaN(agreedPrice)
                ? 'Price must be a number'
                : agreedPrice > Number.MAX_SAFE_INTEGER
                ? 'Price is too large'
                : agreedPrice <= 0
                ? 'Price cannot be less than 1'
                : 'Agreed price is required';
        }

        const agreedCommission = typeof dealInfo?.agreedCommission === 'string'
                ? Number(dealInfo.agreedCommission.trim())
                : Number(dealInfo?.agreedCommission);

        if (
            agreedCommission > 100 ||
            isNaN(Number(dealInfo?.agreedCommission))
        ) {
            errors.agreedCommission = isNaN(Number(dealInfo?.agreedCommission))
                ? 'Must be a valid number'
                : 'Cannot be more than 100%';
        }

        const agreedCommissionValue = Number(
            dealInfo?.agreedCommissionValue?.trim()?.replaceAll(',', '')
        );

        if (
            agreedCommissionValue > agreedPrice ||
            agreedCommissionValue > Number.MAX_SAFE_INTEGER ||
            isNaN(agreedCommissionValue) ||
            agreedCommissionValue < 0
        ) {
            errors.agreedCommissionValue =
                agreedCommissionValue > Number.MAX_SAFE_INTEGER
                    ? 'Value too large'
                    : isNaN(agreedCommissionValue)
                    ? 'Must be a number'
                    : agreedCommissionValue < 0
                    ? 'Cannot be less than 0'
                    : 'Cannot be more than listing price';
        }

        if (!dealInfo?.unitId || !regex.test(dealInfo?.unitId)) {
            errors.unitId = !dealInfo?.unitId
                ? 'Unit ID number is required'
                : 'Invalid unit ID. Unit ID must not start or end with - or _, or contain any special characters apart from - and _ and must not contain any spaces';
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };

    const addNewDeal = async () => {
        try {
            // setLoading(true);
            // validate inputs
            const invalidDealData = validateDealData();

            const invalidPropertyData = validatePropertyData();

            if (invalidDealData || invalidPropertyData) {
                setErrors((prev) => ({
                    ...prev,
                    ...invalidDealData,
                    ...invalidPropertyData
                }));

                return;
            }

            // we want to confirm if assignedAgent?.id(selectedAgent) is the same as selectedClient's assigned agent (dealInfo?.agent?.id):
            // if above is true, then we proceed else we want the user to assign selected client to selected agent or cancel deal creation.

            if (dealInfo?.agent?.id !== assignedAgent?.id) {
                // let user to assign client to agent
                const isConfirm = await isConfirmed(
                    <>
                        <h6>
                            <b>
                                Selected Client not assigned to selected agent.
                            </b>
                        </h6>
                        <div>
                            By clicking <b>Assign</b>, you accept to assign this
                            client to selected agent.
                        </div>
                    </>
                );

                if (!isConfirm) {
                    return;
                }

                // handle assigning client to agent
                const assignedAgent = await handleAssignAgent();

                setAssignedAgent({
                    ...dealInfo?.agent
                });
            }

            setLoading(true);

            const newListingOffplan = await createNewOffplanProperty();

            if (
                !newListingOffplan ||
                (!newListingOffplan?.id && !newListingOffplan?._id)
            ) {
                console.log('Error creating property');
                toast.error('Unexpected error occured. Please try again later');
                return;
            }

            const dealData = {
                category: dealType,
                type: DealType.Offplan,
                user_id: dealInfo?.client?.id ?? dealInfo?.client?._id,
                isMortgage: dealInfo?.isMortgage ?? dealInfo?.isMortgage,
                mortgageProvider: dealInfo?.mortgageProvider ?? dealInfo?.mortgageProvider,
                offplan_id:
                    dealInfo?.development?.id ?? dealInfo?.development?._id,
                new_listing_offplan_id:
                    newListingOffplan?.id ?? newListingOffplan?._id, // when off-plan property is created, pass id of created property here.
                agreedSalePrice: +dealInfo?.agreedPrice
                    ?.trim()
                    ?.replaceAll(',', ''),
                agreedCommission: +dealInfo?.agreedCommission,
                assignedAgent: dealInfo?.agent?.id ?? dealInfo?.agent?._id
            };

            const { data: deal } = await axios.post('/deals', dealData);

            // save deal id to localStorage to refetch data when we progress deal
            localStorage.setItem('new_deal', JSON.stringify(deal));
            // call next function to move to next step
            next();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <PreviewCard className="bg-lighter">
            <Row className="gy-2">
                <Label>Choose Client</Label>
                <Col sm="10">
                    <SearchClientSelect
                        placeholder="Search clients by name or phone"
                        value={dealInfo?.client}
                        onChange={(client) => {
                            const assignedAgent =
                                client?.currentAgent && client?.currentAgent?.id
                                    ? {
                                          ...client?.currentAgent,
                                          label: `${
                                              client?.currentAgent?.userId
                                                  ?.first_name ?? ''
                                          } ${
                                              client?.currentAgent?.userId
                                                  ?.last_name ?? ''
                                          }`,
                                          value: `${
                                              client?.currentAgent?.userId
                                                  ?.first_name ?? ''
                                          } ${
                                              client?.currentAgent?.userId
                                                  ?.last_name ?? ''
                                          }`
                                      }
                                    : null;

                            setDealInfo((prev) => ({
                                ...prev,
                                client,
                                agent: assignedAgent
                            }));

                            setAssignedAgent(assignedAgent);

                            setErrors((prev) => ({
                                ...prev,
                                client: null,
                                agent: null
                            }));
                        }}
                        className={errors?.client && 'border border-danger'}
                    />
                    {errors?.client && (
                        <small className="text-danger ff-italic">
                            {errors?.client}
                        </small>
                    )}
                </Col>
                <Col>
                    <Button
                        onClick={(ev) => {
                            ev.preventDefault();
                            toggleModalAddClient();
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </Col>

                {dealInfo?.client && (
                    <>
                        <Label>Assigned Agent</Label>
                        <Col sm="10">
                            <RSelect
                                disabled={true}
                                placeholder="Select Agent"
                                value={dealInfo?.agent}
                                options={
                                    dealInfo?.agent &&
                                    dealInfo?.agent?.id === assignedAgent?.id
                                        ? []
                                        : agents &&
                                          agents?.length &&
                                          agents?.map((agent) => ({
                                              ...agent,
                                              label: `${agent?.user?.first_name} ${agent?.user?.last_name}`
                                          }))
                                }
                                onChange={(agent) => {
                                    setDealInfo((prev) => ({ ...prev, agent }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        agent: null
                                    }));
                                }}
                                className={
                                    errors?.client && 'border border-danger'
                                }
                            />

                            {errors?.agent && (
                                <small className="text-danger ff-italic">
                                    {errors?.agent}
                                </small>
                            )}
                        </Col>
                        {dealInfo?.agent?.id !== assignedAgent?.id && (
                            <Col>
                                <Button
                                    onClick={async (ev) => {
                                        try {
                                            ev.preventDefault();

                                            setAssigning(true);

                                            const assignedAgent =
                                                await handleAssignAgent();

                                            setAssignedAgent({
                                                ...dealInfo?.agent
                                            });
                                        } catch (err) {
                                            console.log(err);
                                        } finally {
                                            setAssigning(false);
                                        }
                                    }}
                                >
                                    {assigning ? (
                                        <>
                                            <Spinner /> Please Wait
                                        </>
                                    ) : (
                                        'Assign'
                                    )}
                                </Button>
                            </Col>
                        )}
                    </>
                )}

                <Label>Choose Development</Label>
                <Col sm="11">
                    <SelectDevelopment
                        defaultOptions={offplansOptions}
                        selectedDevelopment={dealInfo?.development}
                        onChange={(dev) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                development: dev
                            }));

                            setErrors((prev) => ({
                                ...prev,
                                development: null
                            }));
                        }}
                        className={
                            errors?.development && 'border border-danger'
                        }
                        isClearable
                    />
                    {errors?.development && (
                        <small className="text-danger ff-italic">
                            {errors?.development}
                        </small>
                    )}
                </Col>
                <Label>Developer</Label>
                <Col sm="11">
                    <Input
                        placeholder="Prefill Developer"
                        readOnly
                        value={dealInfo?.development?.developer?.name}
                        className={
                            errors?.development && 'border border-danger'
                        }
                    />
                </Col>

                <Col
                    sm="12"
                    className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                >
                    <h6 className="text-white">Property Details</h6>
                </Col>
                <Col sm="11">
                    <Label>Type of Property</Label>
                    <PropTypesDropdown
                        axiosInstance={axios}
                        dispatch={dispatch}
                        onChangeCallback={(propertyType) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                propertyType
                            }));
                            setErrors((prev) => ({
                                ...prev,
                                propertyType: null
                            }));
                        }}
                        selected={dealInfo?.propertyType}
                        className={
                          errors.propertyType && 'border border-danger'
                        }
                        placeholder="Type of Property"
                    />
                    {errors?.propertyType && (
                        <small className="ff-italic text-danger">
                            {errors?.propertyType}
                        </small>
                    )}
                </Col>
                <Col sm="11">
                    <Label>Beds</Label>
                    <RSelect
                        options={bedsOptions}
                        placeholder="Beds"
                        value={dealInfo?.beds}
                        onChange={(beds) => {
                            setDealInfo((prev) => ({ ...prev, beds }));
                            setErrors((prev) => ({ ...prev, beds: null }));
                        }}
                        className={errors?.beds && 'border border-danger'}
                    />
                    {errors?.beds && (
                        <small className="ff-italic text-danger">
                            {errors?.beds}
                        </small>
                    )}
                </Col>
                <Col sm="11">
                    <Label>Baths</Label>
                    <RSelect
                        options={bathOptions}
                        placeholder="Baths"
                        value={dealInfo?.baths}
                        onChange={(baths) => {
                            setDealInfo((prev) => ({ ...prev, baths }));
                            setErrors((prev) => ({ ...prev, baths: null }));
                        }}
                        className={errors?.baths && 'border border-danger'}
                    />
                    {errors?.baths && (
                        <small className="text-danger ff-italic">
                            {errors?.baths}
                        </small>
                    )}
                </Col>
                <Col sm="11">
                    <Label>Size</Label>
                    <div className="form-control-wrap">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Sqft</span>
                            </div>
                            <Input
                                className={`${
                                    errors?.size && `border border-danger`
                                }  `}
                                placeholder="Enter Size"
                                type="number"
                                value={dealInfo?.size}
                                onKeyDown={(e) => {
                                    const notAllowedKeys = ['e', 'E', '+', '-'];

                                    if (notAllowedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setErrors((prev) => ({
                                        ...prev,
                                        size: ''
                                    }));
                                    setDealInfo((prev) => ({
                                        ...prev,
                                        size: e.target.value
                                    }));
                                }}
                                onBlur={(e) => {
                                    const { value } = e.target;
                                    const size = +value;
                                    // if size is more than 10 digits or size is not a number, we throw error
                                    if (
                                        value?.length > 10 ||
                                        isNaN(size) ||
                                        (size <= 0 && value?.length > 0)
                                    ) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            size:
                                                size <= 0
                                                    ? 'Size cannot be less than 1'
                                                    : 'Invalid size'
                                        }));

                                        return;
                                    }
                                }}
                            />
                        </div>
                        {errors && errors?.size && (
                            <small className="text-danger ff-italic">
                                {errors?.size}
                            </small>
                        )}
                    </div>
                </Col>

                <Col sm="11">
                    <Label>Unit ID Number</Label>
                    <Input
                        className={`${
                            errors?.unitId && `border border-danger`
                        }`}
                        placeholder="Unit ID Number"
                        value={dealInfo?.unitId}
                        onChange={(e) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                unitId: e.target.value
                            }));
                            setErrors((prev) => ({
                                ...prev,
                                unitId: ''
                            }));
                        }}
                        onBlur={(e) => {
                            // We want to ensure that the unit id is not more than 20 digits and does not contain other special characters apart of minus(-) and underscore(_) and they cannot be the first characters as well
                            const { value } = e.target;
                            if (value.length > 20) {
                                setErrors((prev) => ({
                                    ...prev,
                                    unitId: 'Unit ID cannot be more than 20'
                                }));
                                return;
                            }

                            if (!regex.test(value) /*&& value.length > 0*/) {
                                setErrors((prev) => ({
                                    ...prev,
                                    unitId: 'Invalid unit ID. Unit ID must not start or end with - or _, or contain any special characters apart from - and _ and must not contain any spaces'
                                }));
                                return;
                            }
                        }}
                    />
                    {errors?.unitId && (
                        <div className="text-danger ff-italic">
                            <small>{errors?.unitId}</small>
                        </div>
                    )}
                </Col>
                <Col sm="11">
                    <Label>Agreed Sale Price</Label>
                    <div className="form-control-wrap">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">AED</span>
                            </div>
                            <Input
                                placeholder="Enter Agreed Sales Price"
                                type="text"
                                value={dealInfo?.agreedPrice}
                                onChange={(e) => {
                                    const value = +e.target.value
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    setDealInfo((prev) => ({
                                        ...prev,
                                        agreedPrice:
                                            value > 0
                                                ? formatValue(value)
                                                : e.target.value
                                    }));
                                    setErrors((prev) => ({
                                        ...prev,
                                        agreedPrice: null
                                    }));
                                }}
                                onBlur={(e) => {
                                    const value = +e.target.value
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    if (isNaN(value) || value <= 0) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedPrice:
                                                value <= 0
                                                    ? 'Cannot be less than zero or empty or zero'
                                                    : 'Invalid price'
                                        }));
                                        return;
                                    }

                                    // if sale price is greater than biggest integer, we throw error
                                    if (value > Number.MAX_SAFE_INTEGER) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedPrice:
                                                'Sale price is too large'
                                        }));
                                        return;
                                    }

                                    const agreedCommission =
                                        +dealInfo?.agreedCommission;
                                    const agreedCommissionValue =
                                        +dealInfo?.agreedCommissionValue
                                            ?.trim()
                                            ?.replaceAll(',', '');

                                    if (
                                        agreedCommission &&
                                        agreedCommission <= 100 &&
                                        agreedCommission > 0
                                    ) {
                                        setDealInfo((prev) => ({
                                            ...prev,
                                            agreedCommissionValue: formatValue(
                                                (value * agreedCommission) / 100
                                            )
                                        }));
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedCommission: '',
                                            agreedCommissionValue: ''
                                        }));
                                        return;
                                    }

                                    if (
                                        agreedCommissionValue &&
                                        agreedCommissionValue <= value
                                    ) {
                                        const agreedCommission =
                                            (agreedCommissionValue / value) *
                                            100;

                                        setDealInfo((prev) => ({
                                            ...prev,
                                            agreedCommission: agreedCommission
                                        }));

                                        setFormattedCommission(
                                            formatValueToDecimal(
                                                agreedCommission
                                            )
                                        );

                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedCommission:
                                                agreedCommission > 100
                                                    ? 'Cannot be greater than 100'
                                                    : '',
                                            agreedCommissionValue:
                                                agreedCommission > 100
                                                    ? 'Cannot be greater than sale price'
                                                    : ''
                                        }));
                                    }
                                }}
                                className={
                                    errors?.agreedPrice &&
                                    'border border-danger'
                                }
                            />
                        </div>
                    </div>
                    {errors?.agreedPrice && (
                        <small className="text-danger ff-italic">
                            {errors?.agreedPrice}
                        </small>
                    )}
                </Col>
                <Col sm="11">
                    <Label>Agreed Commission</Label>
                </Col>
                <Col sm="5">
                    <div className="form-control-wrap">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">%</span>
                            </div>
                            <Input
                                placeholder="Enter Agreed Commission (%)"
                                type="number"
                                min={'1'}
                                max={'100'}
                                value={formattedCommission}
                                onKeyDown={(e) => {
                                    const notAllowedKeys = ['e', 'E', '+', '-'];

                                    if (notAllowedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setDealInfo((prev) => ({
                                        ...prev,
                                        agreedCommission: e.target.value
                                    }));
                                    setFormattedCommission(e.target.value);
                                    setErrors((prev) => ({
                                        ...prev,
                                        agreedCommission: null
                                    }));
                                }}
                                onBlur={async (e) => {
                                    const value = +e.target.value;

                                    const trimmedPrice = +dealInfo?.agreedPrice
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    const agreedPrice = isNaN(trimmedPrice)
                                        ? 0
                                        : trimmedPrice;

                                    if (isNaN(value)) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedCommission: 'Invalid value'
                                        }));
                                        return;
                                    }

                                    if (value > 100) {
                                        const confirmed = await isConfirmed(
                                            'Commission rate greater than 100%. Do you want to convert to value instead?'
                                        );

                                        if (confirmed) {
                                            const commissionRate =
                                                (value / agreedPrice) * 100;

                                            setDealInfo((prev) => ({
                                                ...prev,
                                                agreedCommissionValue:
                                                    formatValue(value),
                                                agreedCommission: commissionRate
                                            }));
                                            setFormattedCommission(
                                                formatValueToDecimal(
                                                    commissionRate
                                                )
                                            );
                                            commissionRate > 100 &&
                                                setErrors((prev) => ({
                                                    ...prev,
                                                    agreedCommission:
                                                        'Cannot be more than 100%',
                                                    agreedCommissionValue:
                                                        'Cannot be more than sale price'
                                                }));
                                            return;
                                        } else {
                                            setErrors((prev) => ({
                                                ...prev,
                                                agreedCommission:
                                                    'Cannot be greater than 100%'
                                            }));
                                            return;
                                        }
                                    }

                                    const commissionValue =
                                        (agreedPrice * value) / 100;

                                    setDealInfo((prev) => ({
                                        ...prev,
                                        agreedCommissionValue:
                                            formatValue(commissionValue)
                                    }));
                                }}
                                className={
                                    errors?.agreedCommission &&
                                    'border border-danger'
                                }
                            />
                        </div>
                    </div>
                    {errors?.agreedCommission && (
                        <small className="text-danger ff-italic">
                            {errors?.agreedCommission}
                        </small>
                    )}
                </Col>

                <Col sm="6">
                    <div className="form-control-wrap">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">AED</span>
                            </div>
                            <Input
                                placeholder="Agreed Commission (AED)"
                                type="text"
                                value={dealInfo?.agreedCommissionValue}
                                onChange={(e) => {
                                    const value = +e.target.value
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    setDealInfo((prev) => ({
                                        ...prev,
                                        agreedCommissionValue:
                                            value > 0
                                                ? formatValue(value)
                                                : e.target.value
                                    }));

                                    setErrors((prev) => ({
                                        ...prev,
                                        agreedCommissionValue: null
                                    }));
                                }}
                                onBlur={(e) => {
                                    const value = +e.target.value
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    if (isNaN(value)) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedCommissionValue:
                                                'Invalid value'
                                        }));
                                        return;
                                    }

                                    const trimmedPrice = +dealInfo?.agreedPrice
                                        ?.trim()
                                        ?.replaceAll(',', '');

                                    const agreedPrice = isNaN(trimmedPrice)
                                        ? 0
                                        : trimmedPrice;

                                    const commissionRate =
                                        (value / agreedPrice ?? 1) * 100;

                                    setDealInfo((prev) => ({
                                        ...prev,
                                        agreedCommission: commissionRate
                                    }));
                                    setFormattedCommission(
                                        formatValueToDecimal(commissionRate)
                                    );
                                    commissionRate > 100 &&
                                        setErrors((prev) => ({
                                            ...prev,
                                            agreedCommission:
                                                'Cannot be greater than 100%',
                                            agreedCommissionValue:
                                                'Cannot be more than sale price'
                                        }));
                                }}
                                className={
                                    errors?.agreedCommissionValue &&
                                    'border border-danger'
                                }
                            />
                        </div>
                    </div>
                    {errors?.agreedCommissionValue && (
                        <small className="text-danger ff-italic">
                            {errors?.agreedCommissionValue}
                        </small>
                    )}
                </Col>
                <Col
                    sm="12"
                    className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded"
                >
                    <h6 className="text-white">Mortgage Details</h6>
                </Col>
                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.isMortgage ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch1"
                    name="isMortgage"
                    onChange={(e) => {
                        setDealInfo((prev) => ({
                        ...prev,
                        isMortgage: e.target.checked,
                        }));
                        setIsMortgage(e.target.checked);
                        setErrors((prev) => ({
                        ...prev,
                        isMortgage: null,
                        }));
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch1">
                    Buyer Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isMortgage}>
                    <Label>Buyer Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.unitId && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        value={dealInfo?.mortgageProvider}
                        onChange={(e) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                mortgageProvider: e.target.value
                            }));
                            setErrors((prev) => ({
                                ...prev,
                                mortgageProvider: null
                            }));
                        }}
                    />
                </Collapse>
                </Col>

                <Col
                sm="3"
                className={`d-flex align-items-center ${errors?.isSellerMortgage ? "align-self-center" : "align-self-end mb-2"}`}
                style={{ gap: "1.5rem" }}
                >
                <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
                    <input
                    type="checkbox"
                    className={`custom-control-input`}
                    id="customSwitch2"
                    name="isSellerMortgage"
                    onChange={(e) => {
                        setDealInfo((prev) => ({
                        ...prev,
                        isSellerMortgage: e.target.checked,
                        }));
                        setIsSellerMortgage(e.target.checked);
                        setErrors((prev) => ({
                        ...prev,
                        isSellerMortgage: null,
                        }));
                    }}
                    />
                    <label className="custom-control-label" for="customSwitch2">
                    Seller Mortgage
                    </label>
                </div>
                </Col>
                <Col sm="9">
                <Collapse isOpen={isSellerMortgage}>
                    <Label>Seller Mortgage Provider</Label>
                    <Input
                        className={`${
                            errors?.sellerMortgageProvider && `border border-danger`
                        }`}
                        placeholder="Mortgage Provider"
                        value={dealInfo?.sellerMortgageProvider}
                        onChange={(e) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                sellerMortgageProvider: e.target.value
                            }));
                            setErrors((prev) => ({
                                ...prev,
                                sellerMortgageProvider: null
                            }));
                        }}
                    />
                </Collapse>
                </Col>

                <div className="clearfix">
                    <ul>
                        <li>
                            <Button
                                color="primary"
                                type="button"
                                onClick={addNewDeal}
                                disabled={
                                    Object.entries(errors).find(
                                        ([key, value]) =>
                                            value || value?.length > 0
                                    ) || loading
                                }
                            >
                                {loading ? (
                                    <>
                                        <Spinner size="sm" color="light" />
                                        Please Wait
                                    </>
                                ) : (
                                    'Next'
                                )}
                            </Button>
                        </li>
                    </ul>
                </div>
            </Row>

            <Modal
                size="lg"
                isOpen={openAddClient}
                toggle={toggleModalAddClient}
                backdrop="static"
            >
                <ModalHeader
                    toggle={toggleModalAddClient}
                    close={
                        <button
                            className="close"
                            onClick={toggleModalAddClient}
                        >
                            <Icon name="cross" />
                        </button>
                    }
                >
                    Add New Client
                </ModalHeader>
                <ModalBody>
                    <AddLeadStepOne
                        callback={(data) => {
                            setDealInfo((prev) => ({
                                ...prev,
                                client: {
                                    ...data,
                                    value: `${data?.first_name} ${data?.last_name}`,
                                    label: `${data?.first_name} ${data?.last_name}`
                                },
                                agent: data?.currentAgent
                                    ? {
                                          ...data?.currentAgent,
                                          value: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                                          label: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`
                                      }
                                    : null
                            }));

                            setAssignedAgent((prev) =>
                                data?.currentAgent
                                    ? {
                                          ...data?.currentAgent,
                                          value: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                                          label: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`
                                      }
                                    : null
                            );

                            setopenAddClient(false);
                        }}
                    />
                </ModalBody>
            </Modal>
            <ConfirmModal
                cancel={cancel}
                isOpen={isOpen}
                proceed={proceed}
                prompt={prompt}
                cancelColor="secondary"
                cancelText="Cancel"
                // isLoading={isLoading}
                proceedColor="primary"
                proceedText="Proceed"
            />
        </PreviewCard>
    );
};
export default PrimaryOffPlanProperty;
