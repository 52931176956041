/**
 * @typedef {Object} TableCheckboxProps
 * @property {Breakpoint | null} [size] - The checkbox size.
 * @property {string} [name] - The checkbox name.
 * @property {string} [id] - The checkbox & label id.
 * @property {string} [className] - Additional CSS class names to apply.
 * @property {boolean} [checked] - Whether the checkbox is checked or not.
 * @property {boolean} [disabled] - Whether the checkbox is disabled or not.
 * @property {React.CSSProperties} [style] - Inline styles to apply.
 * @property {React.ChangeEventHandler<HTMLInputElement>} [onChange] - Change event handler for the checkbox.
 */

/**
 * TableCheckbox component renders a checkbox meant for use within a table.
 *
 * @param {TableCheckboxProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered checkbox.
 */
const TableCheckbox = ({ size, name, id, className, checked, disabled, style, onChange, ...props }) => {
  return (
    <div
      className={`${className ? className : ''} custom-control custom-checkbox notext ${
        size ? `custom-control-${size}` : ''
      }`}
      style={style ?? {}}
    >
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={name}
        id={id}
        className={disabled ? 'custom-control-input cursor-default' : 'custom-control-input'}
        disabled={disabled ?? false}
      />
      <label className={disabled ? 'custom-control-label cursor-default' : 'custom-control-label'} htmlFor={id}></label>
    </div>
  );
};

export default TableCheckbox;
