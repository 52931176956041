import { useEffect, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import RSelectAsync from './select/ReactSelectAsync';

export default function SelectLeadSource({
  onChange = (agent) => {},
  defaultOptions = [],
  value,
  isClearable,
  className,
  notStyled = false,
  ...props
}) {
  const axios = useAxiosPrivate();
  const [initialOptions, setInitialOptions] = useState(defaultOptions);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  // Fetch initial options when component mounts
  useEffect(() => {
    const fetchInitialOptions = async () => {
      try {
        const response = await axios.get('/leadsource');
        const options = response?.data?.data?.map((item) => ({
          ...item,
          value: item?.id,
          label: `${item?.name}`
        }));
        setInitialOptions(options);
      } catch (err) {
        console.error('Error fetching initial leadSources:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialOptions();
  }, [axios]);

  async function searchByName(input,callback) {
    try {
      // If input is empty, return initial options without making an API call
      // if (!input?.trim()) {
      //   return initialOptions;
      // }
      const response = await axios.get(`/leadsource?search=${input}&page=${page}`);
      
      callback( response?.data?.data?.map((item) => ({
        ...item,
        value: item?.id,
        label: `${item?.name}`
      })));
    } catch (err) {
      console.error('Error fetching leadSources:', err);
      return [];
    }
  }

  const noOptionsMessage = () => {
    return 'No matching leadSources found';
  };

  const loadingMessage = () => {
    return 'Loading lead sources...';
  };

  return (
    <RSelectAsync
      loadOptionsFn={searchByName}
      onChange={onChange}
      placeholder="Choose LeadSource"
      className={notStyled ? '' : className}
      defaultOptions={initialOptions}
      value={value}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      isLoading={isLoading}
      {...props}
    />
  );
}