import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { findUpper } from "../../../utils/Utils";
import
{
  Agent,
  ListingType,
  Status,
  propActiveStatus,
  getImage,
  formatValue,
} from "../../../utils/envConfig";
import moment from 'moment';

import
{
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import
{
  Badge,
} from "reactstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ProductDataTableRow = ( { property } ) =>
{

  const axios = useAxiosPrivate();

  const [ price, setPrice ] = useState( 0 );
  const [ monthPrice, setMonthPrice ] = useState( 0 );
  const [ imgAgentUrl, setAgentImgUrl ] = useState( null );
  const [ imgAgentLoading, setAgentImgLoading ] = useState( true );
  const [ lastTask, setLastTask ] = useState( null );
  const [ optDefaultEditAgent, setOptDefaultEditAgent ] = useState( {
    label: "Select Agent",
    value: null,
  } );
  const [ propAgent, setPropAgent ] = useState( undefined );

  async function fetchTasks ()
  {
    try
    {
      const response = await axios.get( `/activities/property/${ property.id }` );
      const tasks = response.data;

      if ( tasks.length > 0 )
      {
        const lastTaskDate = tasks[ tasks.length - 1 ].date;
        const formattedDate = moment( lastTaskDate ).fromNow();
        setLastTask( formattedDate );
      }
    } catch ( error )
    {
      // console.error( error );
    }
  }
  fetchTasks();
  function calculateTimeDifference ( createdTime )
  {
    const currentTime = moment();
    const createdMoment = moment( createdTime );
    const timeDifference = moment.duration( currentTime.diff( createdMoment ) );

    return timeDifference;
  }


  useEffect( () =>
  {
    const getUrl = async ( file ) =>
      {
        const resData = await getImage( file, axios );
        const s3url = resData.signedRequest;
    
        if ( s3url )
        {
          return s3url;
        }
      };

    if ( property?.agent_id )
    {
      let arr = [];
      if ( property?.agent?.user?.photo )
      {
        setAgentImgLoading( true );
        let promise = getUrl( property?.agent?.user?.photo );
        promise
          .then( ( url ) => setAgentImgUrl( url ) )
          .finally( () => setAgentImgLoading( false ) );
      } else
      {
        setAgentImgUrl( "./img/profile.png" );
        setAgentImgLoading( false );
      }
      arr.push( {
        value: property?.agent?.user?.id,
        label:
          "" +
          property?.agent?.user?.first_name +
          " " +
          property?.agent?.user?.last_name,
      } );
      setPropAgent( arr );
      setOptDefaultEditAgent( arr );
    } else
    {
      setAgentImgLoading( false );
    }

    if (
      property.listingType === ListingType[ 0 ] ||
      property.listingType === ListingType[ 2 ]
    )
    {
      setPrice( property.askingPrice );
      setMonthPrice( property.askingPrice / 12 );
    } else
    {
      setPrice( property?.oneCheqPrice );
      setMonthPrice( property.emi );
    }
  }, [ property, axios ] );

  return (
    <DataTableItem key={ property.id }>
      <DataTableRow className="nk-tb-col-check">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input"
            defaultChecked={ property.checked }
            id={ property.id + "uid1" }
            key={ Math.random() }
          //   onChange={ ( e ) => onSelectChange( e, property.id ) }
          />
          <label
            className="custom-control-label"
            htmlFor={ property.id + "uid1" }
          ></label>
        </div>
      </DataTableRow>
      <DataTableRow size="mb">
        <Link
          className="tb-amount link-primary"
          to={ `/property-list/${ property?.id }` }
        >
          { property?.referNo }
        </Link>
      </DataTableRow>
      <DataTableRow className="d-sm-none d-xxl-inline-block">
        <Link to={ `/property-list/${ property?.id }` } className="d-sm-none d-xxl-inline-block">
          { property?.agentStatus === Agent[ 0 ] ? (
            <div className="user-card">
              <UserAvatar
                theme={ "purple" }
                text={ findUpper( property.name ) }
                image={ imgAgentUrl }
              ></UserAvatar>
              <div className="user-info">
                <span className="tb-lead">
                  { property?.agent?.user?.first_name +
                    " " +
                    property?.agent?.user?.last_name }
                  <span
                    className={ `dot dot-${ property.status === "Active"
                      ? "success"
                      : property.status === "Pending"
                        ? "warning"
                        : "danger"
                      } d-md-none ms-1` }
                  ></span>
                </span>
              </div>
            </div>
          ) : (
            Agent[ 1 ]
          ) }
        </Link>
      </DataTableRow>
      <DataTableRow size="mb">
        <span className="tb-amount ">{ property?.propertyTitle }</span>
      </DataTableRow>
      <DataTableRow size="md">
        <span>AED { formatValue( price ) }</span>
      </DataTableRow>
      <DataTableRow size="lg">
        <Badge color="outline-danger">{ property?.listingType }</Badge>
      </DataTableRow>
      <DataTableRow size="lg">
        <span>
          { `${ property?.propertyOwner?.first_name } ${ property?.propertyOwner?.last_name }` }{ " " }
        </span>
      </DataTableRow>
      <DataTableRow size="md">
        <span>{ property?.propertyOwner?.phone }</span>
      </DataTableRow>
      <DataTableRow size="md">
        { property?.status === Status[ 0 ] ? (
          <Badge color="success">{ propActiveStatus[ 0 ] }</Badge>
        ) : (
          <Badge color="danger">{ propActiveStatus[ 1 ] }</Badge>
        ) }
      </DataTableRow>
      <DataTableRow size="md" className="d-sm-none d-xxl-inline-block"> <span className="d-sm-none d-xxl-inline-block" >{ lastTask }
      </span></DataTableRow>
      <DataTableRow size="md" className="d-sm-none d-xxl-inline-block">
        <span className="d-sm-none d-xxl-inline-block"> 
        {calculateTimeDifference( property?.updatedAt ).humanize() }
         </span>
      </DataTableRow>
      <DataTableRow size="md" className="d-sm-none d-xxl-inline-block">
        <span className="d-sm-none d-xxl-inline-block"> { calculateTimeDifference( property?.createdAt ).humanize() }</span>
      </DataTableRow>
    </DataTableItem>
  );
};
export default ProductDataTableRow;
