import React from "react";
import { Block } from "../../../components/Component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import DnDKitDemo from "./DnDKitDemo";
import InputFile from "./InputFile";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../../../utils/toast/toast";

const AddOffPlanStepThree = ( props ) =>
{
    let { setFinalPlanDetails, setFilesToUpload } = props;
    const {
        active: activeAmenities,
        errorMessage: amenitiesStatus,
        dragenter: amenitiesDragEnter,
        dragleave: amenitiesDragLeave,
        drop: amenitiesDropVideo,
        handleFileChange: amenitiesFileChange,
        files: amenitiesFiles,
        setFiles: setAmenitiesFiles,
        removeImage: removeAmenityImage,
    } = useDragAndDrop({
        multiple: true,
        inputType: "image",
        maxFilesLength: 10,
        minFilesLength: 8,
        labelName: "title",
    });
    
    const { handleSubmit } = useForm();
    const submitForm = ( data ) =>
    {
        // props.next();
        addAmenitiesToFinal();
        
    };
    const addAmenitiesToFinal = async () => {
       if (!amenitiesFiles.length || amenitiesFiles.length < 4) {
            showToast("Please add at least 4 amenities", "error");
            return;
        }

        const nullAmenity = amenitiesFiles.find(
            (item) => !item.file || !item.title
        );

        if (nullAmenity) {
            showToast("All amenities must have a name and an image.", "error");
            return;
        }

        let filesToUpload = [];

        const amenityList = amenitiesFiles?.map((item) => {
            const fileName = `offPlans/amenities/${uuid()}.${item?.file?.name
                ?.split(".")
                ?.pop()}`;

            filesToUpload.push({
                file: item?.file,
                type: item?.file?.type ?? "image/*",
                fileName,
            });

            return {
                photo: fileName,
                title: item?.title,
                description: item?.description,
            };
        });

        setFilesToUpload((prev) => ({ ...prev, amenities: filesToUpload }));

        setFinalPlanDetails((prev) => ({
            ...prev,
            amenities: amenityList,
        }));
        props.next();
    };

    return (
        <React.Fragment>
            <Head title="Off Plan"></Head>
            <Block>
                <form id='add-development-form'
                    onSubmit={ handleSubmit( submitForm ) }>
                    <div>
                        <InputFile
                            active={activeAmenities}
                            dragenter={amenitiesDragEnter}
                            dragleave={amenitiesDragLeave}
                            drop={amenitiesDropVideo}
                            errorMessage={amenitiesStatus}
                            files={amenitiesFiles}
                            handleFileChange={amenitiesFileChange}
                            inputType="image"
                            removeImage={removeAmenityImage}
                            multiple
                            inputProps={{
                                accept: "image/*",
                            }}
                        />
                    </div>
                    {amenitiesFiles&&amenitiesFiles.length>0 &&
                    <DnDKitDemo images={amenitiesFiles} setImages={setAmenitiesFiles} removeImage={removeAmenityImage}/>
                    }
                    <div className="actions clearfix">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
                            <li>
                                <Button color="primary" type="submit">
                                    Next
                                </Button>
                            </li>
                            <li>
                                <Button color="light" onClick={ props.prev } disabled>
                                    Previous
                                </Button>
                            </li>
                        </ul>
                    </div>
                </form>
            </Block></React.Fragment>
    );
};

export default AddOffPlanStepThree;
