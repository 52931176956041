import React, { Suspense, useCallback, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  RSelect
} from '../../../components/Component';
import EarningsHeader from './components/EarningsHeader';
import { Button } from 'reactstrap';
import LoadingFallback from './components/board-components/LoadingFallback';
import { financialTypeOptions } from './constants';
import { useQueryClient } from 'react-query';
import DatePicker from 'react-multi-date-picker';

export const delayImport = async (importFn, delay = 2000) => {
  return Promise.all([
    importFn(),
    new Promise((resolve) => setTimeout(resolve, delay))
  ]).then(([moduleExports]) => moduleExports);
};

// helper functions to construct column config
export const getColumnData = (stateDeals) => ({
  data: stateDeals.pages.flatMap((page) => page.data),
  commission: stateDeals.pages.reduce(
    (total, page) => total + (page.pageCommissions || 0),
    0
  )
});

export const createColumnConfig = (
  stateDeals,
  query,
  id,
  text,
  theme
) => ({
  data: getColumnData(stateDeals),
  isLoading: query.isLoading,
  isError: query.isError,
  id,
  text,
  theme,
  fetchNextPageFn: query.fetchNextPage,
  queryParams: query.queryParams,
  updateQueryParamsFn: query.setQueryParams,
  hasNextPage: stateDeals.pages[stateDeals.pages.length - 1]?.hasNextPage ?? false,
  nextPage: stateDeals.pages[stateDeals.pages.length - 1]?.nextPage ?? null
});

const OffPlanDealsBoard = React.lazy(() =>
  import('./components/boards/off-plan/OffPlanDealsBoard')
);
const SecondaryRentalsDealsBoard = React.lazy(() =>
  import('./components/boards/secondary-rentals/SecondaryRentalsDealsBoard')
);
const SecondarySalesDealsBoard = React.lazy(() =>
  import('./components/boards/secondary-sales/SecondarySalesDealsBoard')
);

const DealsKanban = () => {
  const queryClient = useQueryClient();

  const [activeBoard, setActiveBoard] = useState('off-plans');
  const [filters, setFilters] = useState({ 
    financialType: null,
    fromCreationDate: null, 
    toCreationDate: null  
  });
  const [localState, setLocalState] = useState({
    financialType: undefined,
    startDate: null,
    endDate: null
  });

  const handleStartDateChange = (dateValue) => {
    if (dateValue) {
      const date = new Date(dateValue);
      date.setHours(0, 0, 0, 0);
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();

      setLocalState(prev => ({
        ...prev,
        startDate: date
      }));
      setFilters(prev => ({
        ...prev,
        fromCreationDate: adjustedDate 
      }));
      queryClient.invalidateQueries();
    } else {
      setLocalState(prev => ({
        ...prev,
        startDate: null
      }));
      setFilters(prev => ({
        ...prev,
        fromCreationDate: null
      }));
      queryClient.invalidateQueries();
    }
  };

  const handleEndDateChange = (dateValue) => {
    if (dateValue) {
      const date = new Date(dateValue);
      date.setHours(23, 59, 59, 999);
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString(); 

      setLocalState(prev => ({
        ...prev,
        endDate: date
      }));
      setFilters(prev => ({
        ...prev,
        toCreationDate: adjustedDate 
      }));
      queryClient.invalidateQueries();
    } else {
      setLocalState(prev => ({
        ...prev,
        endDate: null
      }));
      setFilters(prev => ({
        ...prev,
        toCreationDate: null
      }));
      queryClient.invalidateQueries();
    }
  };

  const renderActiveBoard = () => {
    switch (activeBoard) {
      case 'off-plans':
        return <OffPlanDealsBoard filters={filters} />;
      case 'secondary-sales':
        return <SecondarySalesDealsBoard filters={filters} />;
      case 'secondary-rentals':
        return <SecondaryRentalsDealsBoard filters={filters} />;
      default:
        return null;
    }
  };

  const isOffPlanBoard = activeBoard === 'off-plans';
  const isSecondarySalesBoard = activeBoard === 'secondary-sales';
  const isSecondaryRentalsBoard = activeBoard === 'secondary-rentals';

  const clearFilters = useCallback(() => {
    setLocalState((prev) => ({
      ...prev,
      financialType: null,
      startDate: null,
      endDate: null
    }));
    setFilters((prev) => ({
      ...prev,
      financialType: null,
      fromCreationDate: null,
      toCreationDate: null
    }));
  }, []);

  return (
    <React.Fragment>
      <Head title="Deals Kanban Board"></Head>
      <Content>
        <BlockHead size={'sm'}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Deals Board</BlockTitle>
              <div className="mt-1">
                <div className="d-flex flex-wrap" style={{ gap: '.5rem' }}>
                  <Button
                    className={`border ${
                      isOffPlanBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFilters();
                      setActiveBoard('off-plans');
                    }}
                  >
                    Off-Plans
                  </Button>
                  <Button
                    className={`border ${
                      isSecondarySalesBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFilters();
                      setActiveBoard('secondary-sales');
                    }}
                  >
                    Sales
                  </Button>
                  <Button
                    className={`border ${
                      isSecondaryRentalsBoard
                        ? 'border-primary bg-primary-dim text-primary'
                        : 'border-light text-dark bg-light'
                    }`}
                    size="sm"
                    onClick={() => {
                      clearFilters();
                      setActiveBoard('secondary-rentals');
                    }}
                  >
                    Rentals
                  </Button>
                  <div className="">
                    <RSelect
                      className="status-div"
                      placeholder="Financial Type"
                      options={financialTypeOptions}
                      value={localState.financialType}
                      isClearable
                      onChange={(option) => {
                        setLocalState((prev) => ({
                          ...prev,
                          financialType: option
                        }));
                        setFilters((prev) => ({
                          ...prev,
                          financialType: option?.value
                        }));
                        // invalidate all queries, since this applies to all boards
                        queryClient.invalidateQueries();
                      }}
                    />
                  </div>
                  <div className="form-control-wrap datepicker-zindex mb-2 mb-lg-0">
                <div className="input-daterange date-picker-range input-group datepicker-zindex">
                <DatePicker
                  value={localState.startDate}
                  onChange={handleStartDateChange}
                  format="YYYY-MM-DD"
                  maxDate={localState.endDate}
                  render={<input className="form-control datepicker-zindex" />}
                />
                <div className="input-group-addon mx-auto flex-grow-1 d-flex justify-content-center">
                  TO
                </div>
                <DatePicker
                  value={localState.endDate}
                  onChange={handleEndDateChange}
                  format="YYYY-MM-DD"
                  minDate={localState.startDate}
                  render={<input className="form-control datepicker-zindex" />}
                />
                </div>
              </div>
                </div>
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <EarningsHeader />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-kanban" style={{ overflow: 'auto', msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
            <Suspense fallback={<LoadingFallback />}>
              {renderActiveBoard()}
            </Suspense>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default DealsKanban;
