import {
  formatDistanceToNow,
  intervalToDuration,
  formatDuration,
} from "date-fns";
import { PropertyCardStatus } from "../../utils/envConfig";
import { PROPERTY_TYPES } from "./constats";
import { isDecimal } from "validator";

/**
 * Calculates the time elapsed from a given date to now and returns it as a string with a relative time suffix.
 * If the input date is not provided, the function returns undefined.
 *
 * @param {Date|string} date - The date from which to calculate the elapsed time until now.
 * @returns {string|undefined} A string representing the time elapsed from the given date to now, with a relative time suffix (e.g., "5 days ago"). Returns undefined if no date is provided.
 */
function getElapsedTimeFromNow(date) {
  if (!date) {
    return undefined;
  }
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}

/**
 * Calculates the time left until a specific date from the current moment, adding a specified interval in milliseconds to the date before calculation.
 * If the calculated time is past, returns "Expired!".
 * If no date is provided, the function returns undefined.
 *
 * @param {Date|string} date - The target date from which to calculate the time left.
 * @param {number} intervalMillis - The interval in milliseconds to add to the target date before calculating the time left.
 * @returns {string|undefined} A string representing the formatted duration until the date after the interval, or "Expired!" if the date has passed. Returns undefined if no date is provided.
 */
function timeLeftUntil(date, intervalMillis) {
  if (!date) {
    return undefined;
  }
  const dateAfterInterval = new Date(date).getTime() + intervalMillis;
  const now = new Date();

  if (now >= dateAfterInterval) {
    return "Expired!";
  }

  const duration = intervalToDuration({ start: now, end: dateAfterInterval });

  return formatDuration({
    days: duration.days,
    hours: duration.hours,
    minutes: duration.minutes,
    seconds: duration.seconds,
  });
}

/**
 * Formats a JavaScript Date object to an ISO string, optionally setting the time to the start or end of the day.
 * The function returns undefined and logs an error to the console if the provided date is not a valid Date object.
 *
 * @param {Date} date - The date to format.
 * @param {boolean} [toStart=true] - A flag indicating whether to set the time to the start (00:00:00.000) or end (23:59:59.999) of the day. Defaults to true (start of the day).
 * @returns {string|undefined} The formatted ISO date string, either at the start or end of the day based on the toStart flag. Returns undefined if the input is not a valid Date object.
 */
function formatToIso(date, toStart = true) {
  if (!(date instanceof Date)) {
    console.log("Invalid date instance");
    return;
  }

  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;

  const day = date.getDate();
  const formattedDate = day < 10 ? `0${day}` : `${day}`;

  const year = date.getFullYear();

  const dateStartFormat = "T00:00:00.000Z";
  const dateEndFormat = "T23:59:59.999Z";

  return toStart
    ? `${year}-${formattedMonth}-${formattedDate}${dateStartFormat}`
    : `${year}-${formattedMonth}-${formattedDate}${dateEndFormat}`;
}

/**
 * returns an object saved as a string in local storage with the given key
 * uses JSON.parse to convert the string back to an object.
 * If the key is not found, returns null.
 *
 * @param {string} key local storage key
 * @return {object} the object saved in local storage with the given key, or null if the key is not found.
 * @example
 * const obj = getLocalStorageObj('myKey');
 * console.log(obj); // { name: 'John', age: 30 }
 */
const getLocalStorageObj = (key) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : null;
};

/**
 * Finds duplicate pairs in an array and returns their indices.
 * This function assumes that each value in the array is duplicated at most twice.
 * If a value appears more than twice, only the indices of the first two occurrences are returned.
 *
 * @param {Array} array - The input array to search for duplicate values.
 * @returns {Array<Array<number>>} An array of pairs, where each pair is an array containing the indices of duplicated values.
 *
 * @example
 * findDuplicatePairsInArray(["a", "b", "c", "b", "c"]);
 * returns [[1, 3], [2, 4]]
 */
const findDuplicatePairsInArray = (array) => {
  const indexMap = new Map();

  array.forEach((value, index) => {
    // If value is not in the map, add it with an array containing the current index
    if (!indexMap.has(value)) {
      indexMap.set(value, [index]);
    } else {
      // If value is already in the map, push the current index to the array
      indexMap.get(value).push(index);
    }
  });

  // Filter out the entries with only one index and convert to array of tuples
  const duplicateIndices = Array.from(indexMap)
    .filter(([_, indices]) => indices.length === 2)
    .map(([_, indices]) => indices);

  return duplicateIndices;
};

/**
 * Retrieves the bootstrap theme color associated with a given Property Card Status.
 * The function maps Property Card Status values to predefined bootstrap color names.
 *
 * @param {PropertyCardStatus} status - The status of the property card.
 * @returns {string} The bootstrap theme color name associated with the given status.
 */
const getStatusColor = (status) => {
  /**
   * Get the theme color corresponding with each Property Card Status possible
   */
  switch (status) {
    case PropertyCardStatus.Verified:
      return "success";
    case PropertyCardStatus.Invalid:
      return "danger";
    case PropertyCardStatus.Unverified:
      return "light";
    case PropertyCardStatus.PocketListing:
      return "info";
    case PropertyCardStatus.ConvertedToListing:
      return "azure";
    default:
      return "primary";
  }
};

/**
 * Retrieves the CSS class name(s) for styling based on a given Property Card Status.
 * This function is designed to provide consistent visual representation for property card statuses across the application.
 *
 * @param {PropertyCardStatus} status - The status of the property card.
 * @returns {string} A string containing CSS class name(s) that correspond to the visual representation of the given status.
 */
const getStatusStyling = (status) => {
  /**
   * Get the theme color corresponding with each Property Card Status possible
   */
  switch (status) {
    case PropertyCardStatus.Verified:
      return "badge badge-dot has-bg bg-success text-teal border-0 d-none d-sm-inline-flex";
    case PropertyCardStatus.Invalid:
      return "badge badge-dot has-bg bg-danger text-danger border-0 d-none d-sm-inline-flex";
    case PropertyCardStatus.Unverified:
      return "badge badge-dot has-bg bg-light border-0 d-none d-sm-inline-flex";
    case PropertyCardStatus.PocketListing:
      return "badge badge-dot has-bg bg-info text-info border-0 d-none d-sm-inline-flex";
    case PropertyCardStatus.ConvertedToListing:
    case PropertyCardStatus.Sold:
      return "badge badge-dot has-bg bg-primary text-primary border-0 d-none d-sm-inline-flex";
    default:
      return "badge badge-dot has-bg bg-light border-0 d-none d-sm-inline-flex";
  }
};

/**
 *
 * @returns the locale string of the current browser client
 */
const getLocale = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;

const currencyFormatter = ({ isDecimal, decimalPlaces = 2 }) => new Intl.NumberFormat(getLocale(), {
  style: "currency",
  currency: "AED",
  maximumFractionDigits: isDecimal ? decimalPlaces : 0
});

const getPropTypeRegex = (propType) => {
  const excludedEntries = [
    'Apartment',
    'Flat',
    'Villa',
    'Townhouse',
    'Townhouses',
    'Stacked Townhouses',
    'Hotel Apartment',
    'Hotel Apartments',
    'HotelApartment',
    'HotelApartments',
    'Building',
    'Land',
    'Plot',
    'Shop',
    'Store',
    'Office',
    'Warehouse',
    'Commercial',
    'Residential Flats',
    'Residential Flat',
  ];
  const othersRegex = `(?!${excludedEntries.join('|')}).*`;
  switch (propType) {
    case PROPERTY_TYPES.Apartment:
      return '(Apartment|Flat|Residential Flats|Residential Flat)';
    case PROPERTY_TYPES.Plot:
      return '(Land|Plot)';
    case PROPERTY_TYPES.Commercial:
      return '(Shop|Store|Office|Warehouse|Commercial)';
    case PROPERTY_TYPES.Townhouse:
      return '(Townhouse|Town House|Townhouses|Stacked Townhouses)';
    case PROPERTY_TYPES.HotelApartment:
      return '(Hotel Apartment|HotelApartment|Hotel Apartments|HotelApartments)';
    case PROPERTY_TYPES.Villa:
      return '(Villa|Villas|Building|Vila)';
    case 'Other':
      return othersRegex;
    default:
      return propType
  }
}

export {
  formatToIso,
  getLocalStorageObj,
  findDuplicatePairsInArray,
  getElapsedTimeFromNow,
  timeLeftUntil,
  getStatusColor,
  getStatusStyling,
  currencyFormatter,
  getPropTypeRegex,
};
