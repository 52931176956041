import { useQuery } from 'react-query';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { timeAsMilliseconds } from '../../../utils/Utils';
import { RSelect } from '../../../components/Component';
import { components } from 'react-select';
import { Spinner } from 'reactstrap';
import getAllTeamLeaders from '../../../api/agents/getAllTeamLeaders';

const AgentTeamFilter = ({ stateVar, onChange, placeholder = null }) => {
  const axios = useAxiosPrivate();

  const query = useQuery(['teamLeaders'], () => getAllTeamLeaders(axios), {
    refetchInterval: timeAsMilliseconds({ hours: 1 }),
    refetchOnWindowFocus: false
  });

  return (
    <RSelect
      options={query.data?.data?.map((leader) => ({
        value: leader?.userId,
        label: `Team ${leader?.user?.first_name}`,
        ...leader
      }))}
      components={{ Option: Option, NoOptionsMessage: NoOptionsMessage }}
      loadingMessage={() => <Spinner />}
      placeholder={placeholder ? placeholder : "Choose Team"}
      value={stateVar}
      error={query.error}
      isLoading={query.isLoading}
      onChange={onChange}
    />
  );
};

const NoOptionsMessage = (props) => {
  const { error } = props.selectProps;

  return error ? (
    <div className="p-2 text-danger">Failed to load team leaders: {error.message}</div>
  ) : (
    <components.NoOptionsMessage {...props}>No options available</components.NoOptionsMessage>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between">
        <span>{`${props?.data?.label}`}</span>
        <span className={`badge bg-primary rounded-pill`}>
          {`${props?.data?.teamMembersCount} Members`}
        </span>
      </div>
    </components.Option>
  );
};

export default AgentTeamFilter;
