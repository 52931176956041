/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Icon } from "../../../../components/Component";

const NavigationPanel  = ({ navLinks = [], setCurrentSettings, currentSettings }) => {
  /**
   * Component to render a side navigation menu
   * accepts navLinks as an array of objects containing { name, iconName, anchor }
   */

  return (
    <div className="card-inner p-0">
      <ul className="link-list-menu">
        {navLinks.map((link, idx) => (
          <li key={`nav-link-${idx}`}>
            <a 
              href={`#${link.anchor ?? ""}`} 
              className={currentSettings === link.name ? "active" : ""} 
              onClick={() => {
                setCurrentSettings(link.name);
              }}
            >
              {link.logo ?? <Icon name={link.iconName ?? "dot"} />}
              <span className={link.logo ? "ms-3" : ""}>{link.title ?? link.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationPanel;

