import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";

export const QuillComponent = () =>
{
  let placeholder = "Hello World!";
  const { quillRef } = useQuill( { placeholder } );
  return (
    <div style={ { width: "100%", height: "100%" } }>
      <div ref={ quillRef } />
    </div>
  );
};

export const QuillComponentMinimal = () =>
{
  const modules = {
    toolbar: [
      [ "bold", "italic", "underline", "strike" ],
      [ { align: [] } ],

      [ { list: "ordered" }, { list: "bullet" } ],
      [ { indent: "-1" }, { indent: "+1" } ],
    ],
  };

  const placeholder = "Compose an epic...";

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "video",
    "color",
    "background",
    "clean",
  ];
  const { quillRef } = useQuill( { modules, formats, placeholder } );
  return (
    <div style={ { width: "100%", height: "100%" } }>
      <div ref={ quillRef } />
    </div>
  );
};

export const QuillComponentNew = ( { data, setFormData,onChange} ) =>
{
  const { quill, quillRef } = useQuill();

  useEffect( () =>
  {
    if ( quill )
    {
      if ( quillRef.current )
      {
        // Set initial content
        const initialContent = data;
        const delta = quill.clipboard.convert( initialContent );
        quill.setContents( delta );
      }
      const textChangeHandler = ( delta, oldDelta, source ) =>
      {
        const content = quill.root.innerHTML;
        if ( setFormData && typeof setFormData === 'function')
        {
          setFormData( ( prevState ) => ( {
            ...prevState,
            propertyDesc: content,

          } ) );
        }
        onChange( content)
      };

      quill.on( "text-change", textChangeHandler );
      return () =>
      {
        quill.off( "text-change", textChangeHandler ); // Cleanup event listener on unmount
      };
    }
  }, [ quill, quillRef ] );

  return (
    <div style={ { width: "100%", height: "100%" } }>
      <div ref={ quillRef } />
    </div>
  );
};
export const QuillComponentEdit = ( { data, setDesc } ) =>
{
  const { quill, quillRef } = useQuill();

  useEffect( () =>
  {
    if ( quill )
    {
      if ( quillRef.current )
      {
        // Set initial content
        const initialContent = data;
        const delta = quill.clipboard.convert( initialContent );
        quill.setContents( delta );
      }
      const textChangeHandler = ( delta, oldDelta, source ) =>
      {
        const content = quill.root.innerHTML;
        setDesc( content );
      };

      quill.on( "text-change", textChangeHandler );
      return () =>
      {
        quill.off( "text-change", textChangeHandler ); // Cleanup event listener on unmount
      };
    }
  }, [ quill, quillRef ] );

  return (
    <div style={ { width: "100%", height: "100%" } }>
      <div ref={ quillRef } />
    </div>
  );
};

