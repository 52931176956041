import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import
{
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
} from "../../components/Component";
import { Step, Steps } from "react-step-builder";
import AddOffPlanStepOne from "./components/AddOffPlanStepOne";
import AddOffPlanStepThree from "./components/AddOffPlanStepThree";
import AddOffPlanStepFour from "./components/AddOffPlanStepFour";
import AddOffPlanStepFive from "./components/AddOffPlanStepFive";
import AddOffPlanStepSix from "./components/AddOffPlanStepSix";
import PaymentPlanStep from "./components/PaymentPlanStep";

const AddNewDevelopment = () =>
{

    const [development, setDevelopment] = useState({
        developmentName: "",
        developmentDesc: "",
        emirate: null,
        community: null,
        slug: "",
        downpayment: "",
        completionDate: "",
        propertyType: null,
        keywords: "",
        metaDescription: "",
        developerName: "",
        developerDesc: "",
        templateVersion: "1",
        metaTitle: "",
    });
    const [finalePlanDetails, setFinalPlanDetails] = useState({});

    const [filesToUpload, setFilesToUpload] = useState({
        amenities: [],
        devPhotos: [],
        others: [],
        devLogo: null,
    });


    
    const Header = ( props ) =>
    {
        return (
            <div className="steps clearfix">
                <ul>
                    <li className={ props.current >= 1 ? "first done" : "first" }>
                        <a
                            href="#wizard-01-h-0"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="number">01</span>{ " " }
                            <h5>General Info</h5>
                        </a>
                    </li>
                     <li className={ props.current >= 2 ? "first done" : "first" }>
                        <a
                            href="#wizard-01-h-0"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="number">02</span>{ " " }
                            <h5>Payment Plan</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 3 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-1"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="number">03</span>{ " " }
                            <h5>Amenities</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 4 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-3"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">04</span>
                            <h5>Developer and Footer Template</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 5 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-4"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">05</span>
                            <h5>Development Images</h5>
                        </a>
                    </li>
                    <li className={ props.current === 6 ? "last done" : "last" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => ev.preventDefault() }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">06</span>
                            <h5>Files</h5>
                        </a>
                    </li>

                </ul>
            </div>
        );
    };
    const config = {
        before: Header,
    };

    return (
        <React.Fragment>
            <Head title="Off Plan"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle>Add New Off Plan</BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <PreviewCard>
                        <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                            <Steps config={ config }>
                                <Step component={ AddOffPlanStepOne } finalePlanDetails={finalePlanDetails} setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ PaymentPlanStep } setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepThree } setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepFour } setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepFive } setFinalPlanDetails={setFinalPlanDetails} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                                <Step component={ AddOffPlanStepSix } finalePlanDetails={finalePlanDetails} setFinalPlanDetails={setFinalPlanDetails} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} development={development} setDevelopment={setDevelopment}/>
                            </Steps>
                        </div>
                    </PreviewCard>
                </Block>
            </Content >
        </React.Fragment> );
};

export default AddNewDevelopment;