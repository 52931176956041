import React, { useMemo, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import activityQueries from "../../queries/activityQueries";
import ActivitiesTableView from "./ActivitiesTableView";
import { useQuery } from "react-query";
import '../../api/global-types/types';
import { ActivityProvider } from "./context/ActivityProvider";
import { ActivityStatus } from "../../utils/envConfig";

const DEFAULT_PAGE_SIZE = 10;
const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

/**
 * @typedef {Object} Props
 * @property {User} user - The user information to fetch activities for.
 */

/**
 * Container component for managing activities table's state.
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} - The rendered container component.
 */
const ActivitiesContainer = ( { user } ) =>
{
  const axios = useAxiosPrivate();
  const { userId } = useMemo(
    () => ( {
      userId: user?.id ?? user?._id,
    } ),
    [ user ]
  );

  /**
  * State for managing query parameters.
  * @type {[QueryState, import("react").Dispatch<import("react").SetStateAction<QueryState>>]}
 */
  const [ queryParams, setQueryParams ] = useState( {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    type: ''
  } );

  const { queryKey, queryFn: fetchUserActivities } = activityQueries.getByUser( axios, userId, {
    params: { ...queryParams }
  } );

  const { data, isLoading, error } = useQuery(queryKey, fetchUserActivities, {
    staleTime: FIFTEEN_MINUTES,
    refetchOnWindowFocus: false,
    refetchInterval: THIRTY_MINUTES, // refetch query every 30 minutes
  });

  const { hasPendingOrOverdue } = useMemo(
    () => ({
      hasPendingOrOverdue: data?.activities?.some((activity) =>
        [ActivityStatus.Overdue, ActivityStatus.Pending].includes(
          activity?.status ?? ""
        )
      ),
    }),
    [data]
  );

  return (
    <ActivityProvider>
      <ActivitiesTableView
        activitiesState={{ activities: data?.activities, totalActivities: data?.totalItems,  isLoading: isLoading, error: error }}
        user={user}
        hasPendingOrOverdue={hasPendingOrOverdue}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </ActivityProvider>
  );
};

export default ActivitiesContainer;