/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockHead,
  Button,
  Block,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import AgentCard from "./community-team-details/AgentCard";
import CommunityInfoCard from "./community-team-details/CommunityInfoCard";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { fetchCommunityTeam } from "../../../redux/actions/community-teams";
import AddMemberModal from "./community-team-details/AddMemberModal";
import AddCommunityModal from "./community-team-details/AddCommunityModal";
import TeamEditModal from './TeamEditModal';

const CommunityTeamDetailsUI = () => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();

  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const teamDetails = useSelector(
    (state) => state?.communityTeam?.communityTeam
  );
  const [addMemberModal, setAddMemberModal] = useState(false);
  const toggleAddMemberModal = () => setAddMemberModal(!addMemberModal);

  const [addCommunityModal, setAddCommunityModal] = useState(false);
  const toggleAddCommunityModal = () =>
    setAddCommunityModal(!addCommunityModal);

  const [editTeamModal, setEditTeamModal] = useState(false);
  const toggleEditTeamModal = () => setEditTeamModal(!editTeamModal);

  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    if (id) {
      dispatch(fetchCommunityTeam(axios, id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  const isAdmin =
    authUser &&
    (authUser.roles?.includes("MasterAdmin") ||
      authUser.roles?.includes("Admin"));

  return (
    <React.Fragment>
      <Head title="Community Team Details"></Head>
      {teamDetails ? (
        <Content>
          {/* ============= PAGE HEADER =============== */}
          <BlockHead
            size="sm"
            className={"d-flex align-items-end justify-content-between"}
          >
            <div className="align-self-stretch">
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex mb-2"
                onClick={() => navigate(-1)}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate(-1);
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
              <BlockTitle tag="h3" page className="mt-2">
                Master Community:{" "}
                <strong className="text-primary small">
                  {teamDetails?.teamName}
                </strong>
              </BlockTitle>
            </div>
            {isAdmin && teamDetails?.teamName !== "Off-Plan" ? (
              <div className="">
                <Button outline color="secondary" onClick={toggleEditTeamModal}>
                  <Icon name="edit" />
                  <span className="mx-1">Manage Master Community</span>
                </Button>
              </div>
            ) : null}
          </BlockHead>
          {/* ============= END OF PAGE HEADER =============== */}
          {/* ============= MAIN CARD CONTENT ================ */}
          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <div className="card-inner">
                    <Block className={""}>
                      {/* ============= MEMBER INFO ================= */}
                      <div className="nk-data data-list">
                        <BlockHead className="px-1">
                          <BlockBetween>
                            <BlockHeadContent>
                              <BlockTitle>
                                <h5 className="nk-block-title title">
                                  Agents
                                </h5>
                              </BlockTitle>
                            </BlockHeadContent>
                            {isAdmin ? (
                              <BlockHeadContent>
                                <Button
                                  color={"primary"}
                                  onClick={toggleAddMemberModal}
                                >
                                  <Icon name="plus" />
                                  <span>Add Agent</span>
                                </Button>
                              </BlockHeadContent>
                            ) : null}
                          </BlockBetween>
                        </BlockHead>
                        <div className="row g-2">
                          {teamDetails?.members?.map((member, idx) => (
                            <div
                              key={`member-${idx}`}
                              className="col-12 col-md-6 col-xxl-3"
                            >
                              <AgentCard
                                user={member}
                                isAdmin={isAdmin}
                                communityTeamId={id}
                                refreshFn={toggleRefresh}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* ============= END OF MEMBER INFO ================= */}

                      {/* =============== COMMUNITIES INFO =============== */}
                      {teamDetails?.communities?.length ? <div className="nk-data data-list">
                        <BlockHead className="px-1 my-2">
                          <BlockBetween>
                            <BlockHeadContent>
                              <BlockTitle>
                                <h5 className="nk-block-title title">
                                  Places
                                </h5>
                              </BlockTitle>
                            </BlockHeadContent>
                            {isAdmin && teamDetails?.teamName !== "Off-Plan" ? (
                              <BlockHeadContent>
                                <Button
                                  color={"primary"}
                                  onClick={toggleAddCommunityModal}
                                >
                                  <Icon name="plus" />
                                  <span>Add Place</span>
                                </Button>
                              </BlockHeadContent>
                            ) : null}
                          </BlockBetween>
                        </BlockHead>
                        <div className="row px-1 py-2">
                          {teamDetails?.communities?.map((community, idx) => (
                            <div
                              key={`community-${idx}`}
                              className="col-12 col-md-6 col-xxl-3"
                            >
                              <CommunityInfoCard
                                communityTeamId={id}
                                community={community}
                                refreshFn={toggleRefresh}
                                isAdmin={isAdmin}
                              />
                            </div>
                          ))}
                        </div>
                      </div> : null}
                      {/* =============== END OF COMMUNITIES INFO =============== */}
                    </Block>
                  </div>
                </div>
              </div>
            </Card>
          </Block>
          {/* ============= END OF MAIN CARD CONTENT ================ */}
          <AddMemberModal
            communityTeamId={id}
            existingMembers={teamDetails?.members}
            isOpen={addMemberModal}
            toggleFn={toggleAddMemberModal}
            refreshFn={toggleRefresh}
          />
          <AddCommunityModal
            communityTeamId={id}
            existingCommunities={teamDetails?.communities}
            isOpen={addCommunityModal}
            toggleFn={toggleAddCommunityModal}
            refreshFn={toggleRefresh}
          />
          <TeamEditModal
            communityTeam={teamDetails}
            isOpen={editTeamModal}
            toggleFn={toggleEditTeamModal}
            refreshFn={toggleRefresh}
          />
        </Content>
      ) : null}
    </React.Fragment>
  );
};
export default CommunityTeamDetailsUI;
