import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import DatePicker from 'react-datepicker';
import {
  Block,
  BlockDes,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  Row,
  Col,
  DataTable,
  DataTableBody
} from '../../../components/Component';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Badge
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  activeStatus,
  newRoles,
} from '../../../utils/envConfig';
import { toast } from 'react-toastify';
import { endOfDay, format, startOfDay } from 'date-fns';
import { Link } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import LoadingComponent from '../loader/tableLoader';
import DndFlagComponent from '../dnd/dndFlag';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { timeAsMilliseconds } from '../../../utils/Utils';
import LeadSourceFilter from '../filter-components/LeadSourceFilter';

const LandLordTable = ({
  title,
  initialData = [],
  name,
  role = null,
  hide = false
}) => {
  console.log('Rendered LandLordTable with role', role);
  const axios = useAxiosPrivate();
  // State Variables
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState('');
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [saveLoading, setSaveLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [filterSearch, setFilterSearch] = useState(null);
  const [filterRole, setFilterRole] = useState(role);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterLeadSource, setFilterLeadSource] = useState(null);
  const [dateValue, setDateValue] = useState({ startDate: '', endDate: '' });

  const [errors, setErrors] = useState({});
  const { register } = useForm();
  const [tableSm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [sortState, setSortState] = useState('DESC');
  const [onSearchText, setSearchText] = useState('');
  const [searchBoxState, setSearchBoxState] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');

  const onFilterChange = (e) => {
    setSearchBoxState(e.target.value);
    debounce(() => setSearchText(e.target.value), 1000)();
  };

  const fetchLandlords = async () => {
    let link = `/search/user/filter?page=${currentPage}&sort_by=${sortBy}&per_page=${itemsPerPage}&sort_dir=${sortState}`;

    if (dateValue.startDate && dateValue.endDate) {
      const fromDate = startOfDay(new Date(dateValue.startDate)).toISOString();
      const toDate = endOfDay(new Date(dateValue.endDate)).toISOString();
      link += `&from_date=${fromDate}&to_date=${toDate}`;
    }

    if (onSearchText) {
      link = link.concat(`&search=${encodeURIComponent(onSearchText.trim())}`);
    }

    if (filterRole) {
      link = link.concat(`&roles[]=${filterRole}`);
    }

    const activeValue = filterStatus ? filterStatus === activeStatus[0] : true;

    link = link.concat(`&active=${activeValue}`);

    if (filterLeadSource) {
      const leadSources = Array.isArray(filterLeadSource) ? filterLeadSource : [filterLeadSource];
      leadSources.forEach((source) => {
        link = link.concat(`&lead_source_many=${source.label}`);
      })
    }

    const res = await axios.get(link);
    const result = res.data;
    return { ...result, findUsersOutput: (result?.findUsersOutput ?? [])?.map((x) => ({ ...x, checked: false })) };
  }

  const { data: landlordsData, isLoading: isLandlordsLoading } = useQuery(
    ['landlords', { currentPage, sortBy, itemsPerPage, sort: sortState, onSearchText, filterLeadSource, filterRole, filterStatus, dateValue }],
    fetchLandlords,
    { 
      staleTime: timeAsMilliseconds({ hours: 1 }),
      refetchOnWindowFocus: false,
      refetchInterval: timeAsMilliseconds({ hours: 1 }),
    }
  );

  // Reset selection when data changes
  useEffect(() => {
    setSelectedIds(new Set());
  }, [landlordsData]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search !== filterSearch) {
      setCurrentPage(1);
      if (search) {
        setFilterSearch(search);
      } else {
        setFilterSearch(null);
        setSearch(null);
      }
    }
  };

  const handleRoleOption = (e) => {
    setCurrentPage(1);
    if (e) {
      setFilterRole(e);
    } else {
      setFilterRole(null);
    }
  };

  const handleStatusOption = (status) => {
    setCurrentPage(1);
    if (status === 'Active') {
      setFilterStatus(status);
    } else {
      setFilterStatus(status);
    }
  };

  const handleEditModal = (e, id) => {
    e.preventDefault();
    setUserId(id);
    fetchOneUser(id);
    toggle('edit');
  };

  const editMember = async (e) => {
    setSaveLoading(false);
    e.preventDefault();
    if (!validateEdit(formData)) {
      return;
    }

    try {
      setSaveLoading(true);
      const newFormData = {
        ...formData,
        phone: formData?.phone?.trim()?.replaceAll(' ', ''),
        active: formData.active === activeStatus[0]
      };
      const userResponse = await axios.patch(`/users/${userId}`, newFormData);
      const userID = userResponse.data.id;

      const agentID = await axios.get(`/agent/user/${userID}`);

      if (formData.role === newRoles.Agent) {
        if (!agentID.data.id) {
          const formDataNew = {
            userId: userID
          };
          await axios.post(`/agent`, formDataNew);
          toast.success('Created agent for this user');
        }
      }

      toast.success('User modified with changes!');
      onFormCancel();
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
      toast.error('User not modified with changes!');
    }
  };

  // const [ data, setData ] = useState( orderData );
  const [smOption, setSmOption] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    active: activeStatus[0],
    role: ''
  });

  async function fetchOneUser(userId) {
    try {
      await axios
        .get(`/users/${userId}`)
        .then((user) => {
          setUser(user.data);
          setFormData({
            firstName: user.data?.first_name,
            lastName: user.data?.last_name,
            email: user.data?.email,
            phone: user.data?.phone,
            active: user.data?.active ? activeStatus[0] : activeStatus[1],
            role: user.data?.role
          });
        })
        .catch((err) => {
          toast.error('User not available !');
        });
    } catch (err) {
      toast.error('Server error. Try Again !');
    }
  }

  const [view, setView] = useState({
    add: false,
    details: false,
    edit: false
  });

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === 'add',
      details: type === 'details',
      edit: type === 'edit'
    });
  };

  // selects all the order
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = (landlordsData?.findUsersOutput ?? []).map(user => user.id);
      setSelectedIds(new Set(allIds));
    } else {
      setSelectedIds(new Set());
    }
  };

  // selects one order
  const handleSelectOne = (e, id) => {
    setSelectedIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  // resets forms
  const resetForm = () => {
    setFormData({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
      active: activeStatus[0],
      role: undefined
    });
    setUser(null);
  };

  const validateEdit = (formData) => {
    let isError = false;

    if (formData.hasOwnProperty('first_name')) {
      if (!formData.first_name || formData.first_name.length < 2) {
        toast.error('Enter a valid First Name');
        isError = true;
      }
    }

    if (!formData.phone || !formData.phone.trim()) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(formData.phone, 'AE');

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(formData.phone, 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!formData.role) {
      toast.error('Select the Role');
      return false;
    }

    if (isError) {
      toast.error('Invalid fields. Please check your inputs.');
    }

    return isError ? false : true;
  };
  // function to load detail data
  const loadDetail = (id, user) => {
    setUser(user);
  };
  // function to close the form modal
  const onFormCancel = () => {
    setView({ add: false, details: false, edit: false });
    resetForm();
  };
  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleSearch = () => setonSearch(!onSearch);

  let clearBadge =
    dateValue.startDate !== '' ||
    dateValue.endDate !== '' ||
    filterLeadSource;

  const isAllSelected = (landlordsData?.findUsersOutput ?? []).length > 0 && selectedIds.size === (landlordsData?.findUsersOutput ?? []).length;

  return (
    <React.Fragment>
      <Head title={title}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {title}
              </BlockTitle>
              <BlockDes className="text-soft mb-3">
                <p>
                  You have total {landlordsData?.filteredCount ?? 0} {name + 's'}.
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          <div className="d-flex justify-content-end align-items-end">
            <ul className="nk-block-tools g-3">
              <li style={{ listStyleType: 'none' }}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                  >
                    {filterStatus ? filterStatus : 'Active'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <DropdownItem
                          onClick={() => handleStatusOption('Active')}
                        >
                          <span>Active</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          onClick={() => handleStatusOption('Inactive')}
                        >
                          <span>Inactive</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>

              <li>
                <LeadSourceFilter
                  className={'lead-source-div'}
                  stateVar={filterLeadSource}
                  onChange={(option) => {
                    setCurrentPage(1);
                    setFilterLeadSource((_) => option);
                  }}
                />
              </li>

              {!hide && (
                <li style={{ listStyleType: 'none' }}>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="transparent"
                      className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                    >
                      <Icon name="users" />{' '}
                      <span>
                        {filterRole ? filterRole : 'Select Role'}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-list-opt no-bdr">
                        {filterRole && (
                          <li>
                            <DropdownItem
                              onClick={(ev) => {
                                handleRoleOption(null);
                              }}
                            >
                              <span>All</span>
                            </DropdownItem>
                          </li>
                        )}
                        {newRoles &&
                          Object.keys(newRoles)
                            .sort((a, b) =>
                              newRoles[a]?.name?.localeCompare(
                                newRoles[b]?.name
                              )
                            )
                            .map((r, index) => (
                              <li key={index + r}>
                                <DropdownItem
                                  onClick={(e) => handleRoleOption(newRoles[r])}
                                >
                                  <span>{newRoles[r]}</span>
                                </DropdownItem>
                              </li>
                            ))}
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              )}

              <li>
              <div className="form-group datepicker-zindex">
                    <div className="form-control-wrap datepicker-zindex">
                      <div className="input-daterange date-picker-range input-group datepicker-zindex">
                        <DatePicker
                          selected={
                            dateValue?.startDate ? new Date(dateValue?.startDate) : null
                          }
                          onChange={(date) => {
                            setDateValue((dateValue) => ({ ...dateValue, startDate: date }));
                          }}
                          selectsStart
                          startDate={
                            dateValue?.startDate ? new Date(dateValue?.startDate) : null
                          }
                          endDate={dateValue?.endDate ? new Date(dateValue?.endDate) : null}
                          wrapperClassName="start-m"
                          className="form-control datepicker-zindex"
                        />{" "}
                        <div className="input-group-addon">TO</div>
                        <DatePicker
                          selected={
                            dateValue?.endDate ? new Date(dateValue?.endDate) : null
                          }
                          onChange={(date) => {
                            setDateValue((dateValue) => ({ ...dateValue, endDate: date }));
                          }}
                          startDate={
                            dateValue?.startDate ? new Date(dateValue?.startDate) : null
                          }
                          endDate={dateValue?.endDate ? new Date(dateValue?.endDate) : null}
                          selectsEnd
                          minDate={
                            dateValue?.startDate ? new Date(dateValue?.startDate) : null
                          }
                          wrapperClassName="end-m"
                          className="form-control datepicker-zindex"
                        />
                      </div>
                    </div>
                  </div>
              </li>
            </ul>
          </div>
          <Row>
          <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
            {filterLeadSource && (
              <div className="selected-options d-flex flex-wrap">
                {(Array.isArray(filterLeadSource) ? filterLeadSource : [filterLeadSource]).map((option, index) => (
                  <Badge
                    className="selected-option bg-primary me-1"
                    key={index}
                  >
                    {option.label}{" "}
                    <Icon
                      name="cross-sm"
                      className="ms-1"
                      onClick={() => setFilterLeadSource(null)}
                    />
                  </Badge>
                ))}
              </div>
            )}

            {clearBadge && (
              <Badge className="selected-option bg-primary me-1" color="danger">
                {"Clear All"}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    setFilterLeadSource(null);
                    setDateValue((dateValue) => ({ ...dateValue, startDate: '', endDate: '' }));
                    handleRoleOption("");
                  }}
                />
              </Badge>
            )}
          </Col>
          </Row>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3"></div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleSearch();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tableSm ? 'active' : ''
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tableSm ? 'content-active' : ''
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 10 ? 'active' : ''
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 15 ? 'active' : ''
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 50 ? 'active' : ''
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(50);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemsPerPage === 100 ? 'active' : ''
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setCurrentPage(1);
                                          setItemsPerPage(100);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li
                                      className={
                                        sortState === 'DESC' ? 'active' : ''
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortBy('createdAt');
                                          setSortState('DESC');
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={sortState === 'ASC' ? 'active' : ''}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortBy('createdAt');
                                          setSortState('ASC');
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && 'active'}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearch('');
                        setFilterSearch('');
                        setSearchText('');
                        toggleSearch();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search User by name or email or phone"
                      value={searchBoxState}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button
                      onClick={(e) => handleSearch(e)}
                      className="search-submit btn-icon  bg-transparent text-secondary border-0"
                    >
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact>
              <DataTableHead className="nk-tb-item">
                <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="pid-all"
                      checked={isAllSelected}
                      onChange={(e) => handleSelectAll(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pid-all"
                    ></label>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortBy("first_name");
                      setSortState(sortState === "ASC" ? "DESC" : "ASC");
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="sub-text">
                      Name
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortBy("phone");
                      setSortState(sortState === "ASC" ? "DESC" : "ASC");
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="sub-text">
                      Phone{' '}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortBy("lead_source");
                      setSortState(sortState === "ASC" ? "DESC" : "ASC");
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="sub-text">
                      Lead source{' '}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortBy("createdAt");
                      setSortState(sortState === "ASC" ? "DESC" : "ASC");
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="sub-text">
                      Created{' '}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <div
                    onClick={(ev) => {
                      ev.preventDefault();
                      setSortBy("role");
                      setSortState(sortState === "ASC" ? "DESC" : "ASC");
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="sub-text">
                      Role{' '}
                      <Icon className="ms-1">
                        <span>&darr;</span>
                        <span>&uarr;</span>
                      </Icon>
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools">
                  <ul className="nk-tb-actions gx-1 my-n1"></ul>
                </DataTableRow>
              </DataTableHead>
              {isLandlordsLoading ? (
              <LoadingComponent />
              ) : (
              <React.Fragment>
              {landlordsData?.findUsersOutput &&
                [...landlordsData?.findUsersOutput].map((item, index) => (
                  <DataTableItem key={index + 'user'}>
                    <DataTableRow className="nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={selectedIds.has(item?.id ?? item?._id)}
                          id={index + 'oId-all'}
                          key={Math.random()}
                          onChange={(e) => handleSelectOne(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={index + 'oId-all'}
                        ></label>
                      </div>
                    </DataTableRow>
                    <DataTableRow>
                      <Link
                        to={`/user-profile/${item?.id}/user-profile-setting`}
                      >
                        {item?.first_name} {item?.last_name}
                      </Link>
                    </DataTableRow>
                    <DataTableRow>
                    {item?.DndStatus?
                      <DndFlagComponent className={"d-flex flex-column align-items-start pe-2"} lead_status={item?.lead_status} phoneNumber={item?.phone}/>: <span className='text-center'>{item?.phone ?? ""}</span>}
                    </DataTableRow>
                    <DataTableRow>
                      <span href="#id" onClick={(ev) => ev.preventDefault()}>
                        {item?.lead_source}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>
                        {item?.created_at &&
                          format(
                            new Date(item?.created_at),
                            'dd/MM/yyyy hh:mm a'
                          )}
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <Badge color={item?.active ? 'success' : 'danger'}>
                        {item?.active ? activeStatus[0] : activeStatus[1]}
                      </Badge>

                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.role}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            loadDetail(item?.id, item);
                            toggle('details');
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={'view' + index}
                            icon="eye"
                            direction="top"
                            text="View Details"
                          />
                        </li>
                        <li key={index + item.id}>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="btn btn-icon dropdown-toggle btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt no-bdr">
                                <li key={index + Math.random()}>
                                  <a
                                    href={`/user-profile/${item?.id}/user-profile-setting`} 
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>User Details</span>
                                  </a> 
                                </li>
                                <li key={index + item?.id}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(e) => {
                                      handleEditModal(e, item.id);
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                ))}
                 </React.Fragment>
            )}
            </DataTableBody>
            {/* </div> */}
            {!isLandlordsLoading &&
            <PreviewAltCard>
              {landlordsData?.findUsersOutput?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemsPerPage}
                  totalItems={landlordsData?.filteredCount}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">
                    No {filterStatus} {name + 's'} Found
                  </span>
                </div>
              )}
            </PreviewAltCard>
            }
          </DataTable>
        </Block>

        <Modal
          isOpen={view.details}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a href="#cancel" className="close">
              {' '}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="nk-tnx-details mt-sm-3">
              <div className="nk-modal-head mb-3">
                <h5 className="title">User Details</h5>
              </div>
              <Row className="gy-3 mb-3">
                <Col lg={6}>
                  <span className="sub-text">First Name</span>
                  <span className="caption-text">{user?.first_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Last Name</span>
                  <span className="caption-text">{user?.last_name}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Email</span>
                  <span className="caption-text">{user?.email}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Status</span>
                  <span className="caption-text">
                    {user?.active ? activeStatus[0] : activeStatus[1]}
                  </span>
                </Col>
              </Row>
              <div className="nk-modal-head mb-3">
                <h5 className="title">Preferences</h5>
              </div>
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Unit Number</span>
                  <span className="caption-text">
                    {user?.preference?.plotNumber
                      ? user?.preference?.plotNumber
                      : '-'}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Rooms</span>
                  <span className="caption-text">
                    {user?.preference?.rooms ? user?.preference?.rooms : '-'}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Type of property</span>
                  <span className="caption-text">
                    {user?.preference?.typeOfProperty
                      ? user?.preference?.typeOfProperty
                      : '-'}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Building/Area</span>
                  <span className="caption-text">
                    {user?.preference?.area ? user?.preference?.area : '-'}
                  </span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Location</span>
                  <span className="caption-text">
                    {user?.preference?.location
                      ? user?.preference?.location
                      : '-'}
                  </span>
                </Col>
              </Row>
              <div className="col-md-6 justify-content-start d-flex">
                <Button
                  outline
                  color="secondary"
                  className="mt-3"
                  onClick={() =>
                    (window.location.href = `/user-profile/${user?.id}/user-profile`)
                  }
                >
                  More Details
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={view.edit} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Edit User</h5>
              <div className="mt-4">
                <form onSubmit={(e) => editMember(e)}>
                  <Row className="g-3">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          First Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            defaultValue={formData?.firstName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                first_name: e.target.value
                              })
                            }
                            ref={register({
                              required: 'This field is required'
                            })}
                          />
                          {errors.firstName && (
                            <span className="invalid">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Last Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            defaultValue={formData?.lastName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                last_name: e.target.value
                              })
                            }
                            ref={register({
                              required: 'This field is required'
                            })}
                          />
                          {errors.lastName && (
                            <span className="invalid">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="customer">
                          Email
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            readOnly
                            value={formData?.email}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group mb-2">
                        <label className="form-label" htmlFor="first-name">
                          Phone{' '}
                        </label>
                        <PhoneInput
                          disabled={formData.DndStatus}
                          className="phoneInput"
                          defaultCountry="AE"
                          value={!formData.DndStatus&&formData?.phone}
                          name="phone"
                          placeholder={
                            formData.DndStatus
                              ? "Can't edit DND numbers"
                              : "Enter Your Phone *"
                          }
                          onChange={(value) =>
                            setFormData({ ...formData, phone: value })
                          }
                        />
                      </div>
                    
                    </Col>
                   
                    <Col size="12" className="text-end">
                      <Button color="primary" size="lg" disabled={saveLoading}>
                        {saveLoading ? 'Updating...' : 'Edit User'}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default LandLordTable;
