import { useEffect, useState } from 'react';
import { Icon } from '../../../../components/Component';
import { Button, Input } from 'reactstrap';

/**
 * @typedef {Object} SearchComponentProps
 * @property {import('../types').DealTransactionQueryState} queryParams
 * @property {React.Dispatch<React.SetStateAction<import('../types').DealTransactionQueryState>>} setQueryParams
 * @property {string?} [placeholder] - Placeholder text for the search input
 * @property {string?} [className] - Additional CSS class for the form
 * @property {React.CSSProperties?} [containerStyle] - Additional CSS style for the form
 * @property {React.CSSProperties?} [inputFieldStyle] - Additional CSS style for the input element
 * @property {string?} [searchFieldName] - The name of the search field in the query params (optional)
 */

/**
 * SearchComponent component that renders a search bar that triggers on submit.
 *
 * @param {SearchComponentProps} props - The properties for the component.
 * @returns {JSX.Element}
 */
const SearchComponent = ({
  queryParams,
  setQueryParams,
  placeholder,
  id,
  className,
  containerStyle,
  inputFieldStyle,
  searchFieldName = 'search'
}) => {
  const [searchText, setSearchText] = useState(queryParams?.[searchFieldName] ?? '');

  useEffect(() => {
    setSearchText(queryParams?.[searchFieldName] ?? '');
  }, [queryParams, searchFieldName]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setQueryParams?.((prev) => ({
      ...prev,
      [searchFieldName]: searchText,
      page: 1
    }));
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);
  };

  const handleClear = () => {
    setSearchText('');
    setQueryParams?.((prev) => ({
      ...prev,
      [searchFieldName]: null,
      page: 1
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={`form-control-wrap ${className || ''}`} style={containerStyle}>
        <div className="form-icon form-icon-left">
          <Icon name={'search'} />
        </div>
        <Input
          id={id}
          className="form-control"
          style={inputFieldStyle ? inputFieldStyle : { width: 'clamp(10rem, 15rem, 25rem)' }}
          placeholder={placeholder || 'Search by Deal Reference ID'}
          value={searchText}
          onChange={handleChange}
        />
        {searchText && (
          <Button
            className="btn-close"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: '20%',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '1.2rem',
              padding: '0',
              cursor: 'pointer'
            }}
          ></Button>
        )}
      </div>
    </form>
  );
};

export default SearchComponent;
