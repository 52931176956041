import React from 'react';

const ToggleSwitch = ({ label, id, checked, onChange }) => {
  return (
    <React.Fragment>
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          checked={checked}
          onChange={onChange}
          id={id}
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </React.Fragment>
  );
};

export default ToggleSwitch;
