import React, { useState } from 'react'
import Head from "../../../layout/head/Head";
import { Block, } from '../../../components/Component'
import { useForm } from "react-hook-form";
import { Col, Row, Button, } from "reactstrap";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { HttpStatus } from '../../../utils/envConfig';
import { showToast } from '../../../utils/toast/toast';
import { useLocation, useNavigate } from 'react-router-dom';


function PaymentPlanStep(props) {

  const { errors, register, handleSubmit } = useForm();
  const location = useLocation();
  const navigate = useNavigate();

  const { development, setFinalPlanDetails, edit = false , offPlanUpdate} = props;
  const [hasPaymentDuringConstr, setHasPaymentDuringConstr] = useState(development?.paymentScheme?.onDuringConstructionPercent != undefined || development?.paymentScheme?.onDuringConstruction != undefined );
  const [hasPostHandover, setHasPostHandover] = useState(development?.paymentScheme?.postHandoverCostPercent != undefined || development?.paymentScheme?.postHandoverCost != undefined);



  const axios = useAxiosPrivate();

  const submitForm = async (data) => {
  
    const payload = {
      ...development,
        downpayment: parseFloat(
        data.downpayment
      ),
      paymentScheme: {
        onBooking: parseFloat(
          data.downpayment
        ),
        onDuringConstruction: Number(
          data.construction_cost
        ),
        onDuringConstructionPercent: parseFloat(
          data.construction_cost_percent
        ),
        postHandoverCost: Number(
          data.post_handover_cost
        ),
        postHandoverCostPercent: parseFloat(
          data.post_handover_percent
        ),
        onCompletion: parseFloat(
          data.handoverpayment
        ),
        downpayment: parseFloat(
          data.downpayment
        ),
      },
    }


    setFinalPlanDetails( ( prev ) => ( {
      ...prev,
      ...payload
    } ) );
    if (edit) {
      let payload = {
         downpayment: data.downpayment,
        paymentScheme: {
          onBooking: Number(
            data.downpayment
          ),
          onDuringConstruction: Number(
            data.construction_cost
          ),
          onDuringConstructionPercent: Number(
            data.construction_cost_percent
          ),
          postHandoverCost: Number(
            data.post_handover_cost
          ),
          postHandoverCostPercent: Number(
            data.post_handover_percent
          ),
          onCompletion: Number(
            data.handoverpayment
          ),
          downpayment: Number(
            data.downpayment
          ),
        }
      }
      data && await offPlanUpdate(payload);
    }

    navigate(`${location.pathname}?tab=3`, { replace: true });
    props.next();
  }


  return (
    <>
      <Head title="Payment Options"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label">Down Payment *</label>
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon3">
                    %
                  </span>
                  <input
                    id="downpayment"
                    type="number"
                    step="0.1"
                    name="downpayment"
                    defaultValue={development?.downpayment}
                    onChange={(e) => {
                      const { value } = e?.target;

                      if (isNaN(value) || parseFloat(value) > 100 || parseFloat(value) < 0) {
                        return;
                      };
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "E" || e.key === "e" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    className="form-control"
                    ref={register({ required: "Downpayment required",  max: {
                            value: 100,
                            message: "Cannot exceed 100%",
                          },
                          min: {
                            value: 0,
                            message: "Cannot go below 0%",
                          }, })}
                  />
                </div>
                {errors.downpayment && (
                  <span className="invalid">{errors.downpayment.message}</span>
                )}
              </div>
            </Col>

            <Col sm="6">
              <div className="form-group">
                <label className="form-label">On Handover *</label>
                <div class="input-group">
                  <span class="input-group-addon" id="basic-addon3">
                    %
                  </span>
                  <input
                    id="handoverpayment"
                    type="number"
                    step="0.1"
                    name="handoverpayment"
                    defaultValue={development?.paymentScheme?.onCompletion}
                    onChange={(e) => {
                      const { value } = e?.target;

                      if (isNaN(value) || Number(value) > 100) {
                        return;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "E" || e.key === "e" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    className="form-control"
                    ref={register({ required: "Handover payment is required", max: {
                            value: 100,
                            message: "Cannot exceed 100%",
                          },
                          min: {
                            value: 0,
                            message: "Cannot go below 0%",
                          }, },)}
                  />
                </div>
                {errors.handoverpayment && (
                  <span className="invalid">{errors.handoverpayment.message}</span>
                )}
              </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col sm="6">
              <div className="form-group">
                <div class="custom-control custom-checkbox">
                  <input checked ={hasPaymentDuringConstr} type="checkbox" class="custom-control-input" id="duringConstructionCheck" onChange={(value) => setHasPaymentDuringConstr(!hasPaymentDuringConstr)} />
                  <label class="custom-control-label title h4 text-soft" for="duringConstructionCheck">Does it have any payments during construction</label>
                </div>
              </div>
            </Col>

          </Row>
          <br></br>
          {
            hasPaymentDuringConstr && (
              <div>
                <Row>
                  <Col sm="6">
                    <label className="form-label">% payment for On During Construction</label>
                    <div class="input-group">
                      <span class="input-group-addon" id="basic-addon3">
                        %
                      </span>
                      <input
                        id="construction_cost"
                        type="number"
                        step="0.1"
                        name="construction_cost_percent"
                        defaultValue={development?.paymentScheme?.onDuringConstructionPercent}
                        onChange={(e) => {
                          const { value } = e?.target;

                          if (isNaN(value) || Number(value) > 100) {

                            return;
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "E" || e.key === "e" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        className="form-control"
                        ref={register({
                          required: hasPaymentDuringConstr ? "Construction  payment % is required" : null,
                          max: {
                            value: 100,
                            message: "Cannot exceed 100%",
                          },
                          min: {
                            value: 0,
                            message: "Cannot go below 0%",
                          },
                        })}
                      />
                      {errors.construction_cost_percent && (
                        <span className="invalid">{errors.construction_cost_percent.message}</span>
                      )}
                    </div>
                  </Col>
                  <Col sm="6">
                    <label className="form-label">No: of months</label>
                    <div class="input-group">
                      <span class="input-group-addon" id="basic-addon3">
                        Months
                      </span>
                      <input
                        id="construction_cost"
                        type="number"
                        name="construction_cost"
                        defaultValue={development?.paymentScheme?.onDuringConstruction}
                        onChange={(e) => {
                          const { value } = e?.target;

                          if (isNaN(value) || parseFloat(value) > 100  || parseFloat(value) < 0) {
                            return;
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "E" || e.key === "e" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        className="form-control"
                        ref={register({
                          required: hasPaymentDuringConstr ? "Construction payment amount is required" : null,
                        })}
                      />
                      {errors.construction_cost && (
                        <span className="invalid">{errors.construction_cost.message}</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <br></br>

              </div>
            )
          }

          <Row>
            <Col sm="6">
              <div className="form-group">
                <div class="custom-control custom-checkbox">
                  <input checked={hasPostHandover == true} type="checkbox" class="custom-control-input" id="postHandoverCheck" onChange={(value) => setHasPostHandover(!hasPostHandover)} />
                  <label class="custom-control-label title h4 text-soft" for="postHandoverCheck">Does it have any payments post handover</label>
                </div>
              </div>
            </Col>

          </Row>
          <br></br>
          {
            hasPostHandover && (
              <Row>
                <Col sm="6">
                  <label className="form-label">% payment post handover</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      %
                    </span>
                    <input
                      id="construction_cost"
                      type="number"
                      step="0.1"
                      name="post_handover_percent"
                      defaultValue={development?.paymentScheme?.postHandoverCostPercent}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || parseFloat(value) > 100 || parseFloat(value) < 0) {
                          return;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      ref={register({
                        required: hasPostHandover ? "Post handover % is required" : null, max: {
                          value: 100,
                          message: "Cannot exceed 100%",
                        }, min: {
                          value: 0,
                          message: "Cannot go below 0%",
                        },
                      })}
                    />
                    {errors.post_handover_percent && (
                      <span className="invalid">{errors.post_handover_percent.message}</span>
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <label className="form-label">No: of months</label>
                  <div class="input-group">
                    <span class="input-group-addon" id="basic-addon3">
                      Months
                    </span>
                    <input
                      id="construction_cost"
                      type="number"
                      name="post_handover_cost"
                      defaultValue={development?.paymentScheme?.postHandoverCost}
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (isNaN(value) || Number(value) > 100) {
                          return;
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "E" || e.key === "e" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      className="form-control"
                      ref={register({ required: hasPostHandover ? "Post handover amount is required" : null })}
                    />
                    {errors.post_handover_cost && (
                      <span className="invalid">{errors.post_handover_cost.message}</span>
                    )}
                  </div>
                </Col>
              </Row>
            )
          }

          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="submit"
                  className="rounded-lg border bg-[#3399db] text-white py-2 px-6"
                >
                  {edit ? "Save & Next " : "Next"}
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block >
    </>
  )
}

export default PaymentPlanStep;
