import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

export default async function getAllTeamLeaders(axios) {
  try {
    const res = await axios.get("/agent/leaders");

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}
