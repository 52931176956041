/* eslint-disable jsx-a11y/anchor-is-valid */
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import FluidBlockContainer from "../../components/containers/FluidBlockContainer";
import SideBar from "./components/sidebar/Sidebar";
import ExplorerSettings from "./components/settings-components/explorer-settings/ExplorerSettings";
import { NavMenuNames } from "./components/sidebar/constants";
import LeadSettings from "./components/settings-components/leads-settings/LeadSettings";
import { useEffect, useState } from "react";
import OperationsSettings from "./components/settings-components/operations-settings/OperationsSettings";

const AppSettings = () => {
  // State to hold the current settings page name
  const [currentSettings, setCurrentSettings] = useState('');
  
  useEffect(() => {
    // Function to handle hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "");
      if (hash === "explorer") {
        setCurrentSettings(NavMenuNames.Explorer);
      } else if (hash === "leads") {
        setCurrentSettings(NavMenuNames.Leads);
      } else if (hash === "operations") {
        setCurrentSettings(NavMenuNames.Operations);
      }
    };

    // Handle initial hash
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  // Function to render the appropriate settings page based on the current settings
  const renderSettingsPage = (name) => {
    switch (name) {
      case NavMenuNames.Explorer:
        return <ExplorerSettings />;
      case NavMenuNames.Leads:
        return <LeadSettings />;
      case NavMenuNames.Operations:
        return <OperationsSettings />;
      default:
        return <ExplorerSettings />;
    }
  };

  return (
    <>
      <Head title="Dashboard Settings" />
      <Content>
        <FluidBlockContainer>
          <div className="card">
            <div className="card-aside-wrap">
              {/* RIGHT SECTION */}
              {renderSettingsPage(currentSettings)}
              {/* END OF RIGHT SECTION */}
              <SideBar setCurrentSettings={setCurrentSettings} currentSettings={currentSettings} />
            </div>
          </div>
        </FluidBlockContainer>
      </Content>
    </>
  );
};

export default AppSettings;
