import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import OperationsSettingsEdit from "./OperationsSettingsEdit";
import OperationsSettingsView from "./OperationsSettingsView";
import { useEffect, useState } from "react";
import { fetchGlobalSettings } from "../../../../../redux/actions/settings";

const OperationsSettings = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [isEditState, setIsEditState] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    dispatch(fetchGlobalSettings(axios));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const settingsState = useSelector((state) => ({
    globalSettings: state.settings.globalSettings,
    loading: state.settings.loading,
    error: state.settings.error,
  }));

  return settingsState && (isEditState ? (
    <OperationsSettingsEdit
      settingsState={settingsState}
      setIsEditState={setIsEditState}
      refreshFn={toggleRefresh}
    />
  ) : (
    <OperationsSettingsView
      settingsState={settingsState}
      setIsEditState={setIsEditState}
    />
  ));
};

export default OperationsSettings;
