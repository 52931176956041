import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchPropTypesList } from "../../../../../redux/actions/property";
import { propertyTypesSelector } from "../../../../../redux/selectors/property";

const withGeneralPropTypesLogic = (WrappedComponent) => {
  return ({ multiSelect, onChangeCallback, selected, axiosInstance, dispatch, ...props }) => {
    const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);

    const { propertyTypes, propTypesLoading } = useSelector(propertyTypesSelector);

    useEffect(() => {
      const fetchAndSetPropertyTypes = async () => {
        if (!propertyTypes || !propertyTypes?.length) {
          await dispatch(fetchPropTypesList(axiosInstance));
        }
        if (propertyTypes && propertyTypes?.length) {
          const options = propertyTypes
            ?.map((propType) => ({
              ...propType,
              label: propType?.propertyType,
              value: propType?.propertyType,
            })) ?? [];
          setPropertyTypeOptions(options);
        }
      };

      fetchAndSetPropertyTypes();
    }, [axiosInstance, dispatch, propertyTypes, propertyTypes?.length]);

    return (
      <WrappedComponent
        multiSelect={multiSelect}
        onChangeCallback={onChangeCallback}
        selected={selected}
        propTypesFilterOptions={propertyTypeOptions}
        propTypesLoading={propTypesLoading}
        {...props}
      />
    );
  };
};

export default withGeneralPropTypesLogic;
