import { useMemo, useState } from "react";
import DealTransactionsView from "./DealTransactionsView";
import dealQueries from "../../../../queries/dealQueries";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useQuery } from "react-query";

const DEFAULT_PAGE_SIZE = 5;
const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

const DealTransactionsContainer = ({  deal, refetchFn }) => {
  const axios = useAxiosPrivate();

  const { dealId } = useMemo(
    () => ({
      dealId: deal?.id ?? deal?._id,
    }),
    [deal]
  );
  /**
   * State for managing query parameters.
   * @type {[import('./types').TransactionsQueryState, import("react").Dispatch<import("react").SetStateAction<import('./types').TransactionsQueryState>>]}
   */
  const [queryParams, setQueryParams] = useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    paymentMethod: null,
    payeeType: null,
    fromAmount: null,
    toAmount: null,
    fromBalance: null,
    toBalance: null,
    fromDate: null,
    toDate: null,
    fromPaymentDate: null,
    toPaymentDate: null,
  });

  const { queryKey, queryFn: fetchDealTransactions } =
    dealQueries.Transactions.getByDeal(axios, dealId, {
      params: { ...queryParams },
    });

  const { data, isLoading, error } = useQuery(queryKey, fetchDealTransactions, {
    enabled: !!dealId,
    staleTime: FIFTEEN_MINUTES,
    refetchOnWindowFocus: false,
    refetchInterval: THIRTY_MINUTES, // refetch query every 30 minutes
  });

  

  return (
    <>
    <DealTransactionsView
    title="Receivables"
      transactionsState={{
        transactions:  data?.data.filter((e) => (e.isReceivable ?? true)),
        totalTransactions: data?.found,
        isLoading,
        error,
      }}
      deal={deal}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refetchFn={refetchFn}
      isReceivable = {true}
    />
    <DealTransactionsView
    title="Payables"
      transactionsState={{
        transactions: data?.data.filter((e) => !e.isReceivable),
        totalTransactions: data?.found,
        isLoading,
        error,
      }}
      deal={deal}
      isReceivable = {false}  
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refetchFn={refetchFn}
    />
    </>
    
  );
};

export default DealTransactionsContainer;
