import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { activeStatus, Languages, newRoles } from "../../../utils/envConfig";
import { Button, Col, ModalBody, Row } from "reactstrap";
import { Icon, RSelect } from "../../Component";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";

export const PersonalInformation = ( { toggle, user } ) =>
{
    const authuser = useSelector( ( state ) => state?.user?.loggedInUser );
    const queryClient = useQueryClient();

    const { access_token } = authuser || "";
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ access_token }`,
    };
    const axios = useAxiosPrivate();

    const [ selectedLang, setSelectedLang ] = useState( user.languages || [] );
    const [ modalTab, setModalTab ] = useState( "1" );
    const [ formData, setFormData ] = useState( {
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        email: user?.email || "",
        phone: user?.phone || "",
        languages: user?.languages || [],
        lead_source: user?.lead_source || "",
        created_at: user?.created_at || "",
        updated_at: user?.updated_at || "",
        active: user?.active ? activeStatus[ 0 ] : activeStatus[ 1 ],
        role: user?.role || "",
        country: user?.country || "",
        address: user?.address || "",
        city: user?.city || "",
    } );
    const languageOptions = Languages.map( ( language ) => ( {
        value: language,
        label: language,
    } ) );
    const [ countryOptions, setCountryOptions ] = useState( [] );

    useEffect( () =>
    {
        const fetchCountries = async () =>
        {
            try
            {
                const response = await fetch( "https://restcountries.com/v3.1/all" );
                const data = await response.json();
                const formattedOptions = data.map( ( country ) => ( {
                    value: country.name.common,
                    label: country.name.common,
                } ) );
                setCountryOptions( formattedOptions );
            } catch ( error )
            {
                console.error( "Error fetching countries:", error );
            }
        };

        fetchCountries();
    }, [] );

    const updateUserMutation = useMutation(
        async ( newData ) =>
        {
            const newFormData = {
                ...newData,
                phone: newData.phone?.trim()?.replaceAll( " ", "" ),
                active: newData.active === activeStatus[ 0 ],
            };
            const userResponse = await axios.patch( `/users/${ user.id }`, newFormData, {
                headers,
            } );
            return userResponse.data; // Return the updated user data
        },
        {
            onSuccess: () =>
            {
                toast.success( "User modified with changes!" );
                queryClient.invalidateQueries( "user" );
                toggle();
            },
            onError: ( error ) =>
            {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message.includes( "duplicate key" )
                )
                {
                    toast.error( "User not added. Email already exists!" );
                } else
                {
                    toast.error( "User not modified with changes!" );
                }
            },
            onSettled: () =>
            {
                queryClient.invalidateQueries( "userActivities" );
            },
        }
    );

    const validateEdit = ( formData ) =>
    {
        let isError = false;

        if ( formData.hasOwnProperty( "first_name" ) )
        {
            if ( !formData.first_name || formData.first_name.length < 2 )
            {
                toast.error( "Enter a valid First Name" );
                isError = true;
            }
        }

        if ( !formData.phone || !formData.phone?.trim()?.replaceAll( " ", "" ) )
        {
            isError = true;
            toast.error( "Enter valid phone number" );

            const isPossiblePhone = isPossiblePhoneNumber(
                formData.phone?.trim()?.replaceAll( " ", "" ),
                "AE"
            );

            if ( isPossiblePhone )
            {
                const isValidNum = isValidPhoneNumber(
                    formData.phone?.trim()?.replaceAll( " ", "" ),
                    "AE"
                );

                if ( !isValidNum )
                {
                    isError = true;
                    toast.error( "Invalid phone number" );
                }
            } else
            {
                isError = true;
                toast.error( "Phone number is not possible" );
            }
        }

        if ( !formData.role )
        {
            toast.error( "Select the Role" );
            return false;
        }

        if ( isError )
        {
            toast.error( "Invalid fields. Please check your inputs." );
        }

        return isError ? false : true;
    };

    const submitForm = ( e ) =>
    {
        e.preventDefault();

        if ( !validateEdit( formData ) )
        {
            return;
        }

        updateUserMutation.mutate( formData );
    };

    return (
        <ModalBody>
            <a
                href="#cancel"
                onClick={ ( ev ) =>
                {
                    ev.preventDefault();
                    toggle();
                } }
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">Update Profile</h5>
                <ul className="nk-nav nav nav-tabs">
                    <li className="nav-item">
                        <a
                            className={ `nav-link ${ modalTab === "1" && "active" }` }
                            onClick={ ( ev ) =>
                            {
                                ev.preventDefault();
                                setModalTab( "1" );
                            } }
                            href="#personal"
                        >
                            Personal
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={ `nav-link ${ modalTab === "2" && "active" }` }
                            onClick={ ( ev ) =>
                            {
                                ev.preventDefault();
                                setModalTab( "2" );
                            } }
                            href="#address"
                        >
                            Address
                        </a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className={ `tab-pane ${ modalTab === "1" ? "active" : "" }` } id="personal">
                        <Row className="gy-4">
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="full-name">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        defaultValue={ formData?.firstName }
                                        onChange={ ( e ) =>
                                            setFormData( { ...formData, first_name: e.target.value } )
                                        }
                                        placeholder="Enter first name"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="display-name">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        defaultValue={ formData?.lastName }
                                        onChange={ ( e ) =>
                                            setFormData( { ...formData, last_name: e.target.value } )
                                        }
                                        placeholder="Enter last name"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group mb-2">
                                    <label className="form-label" htmlFor="first-name">
                                        Phone{ " " }
                                    </label>
                                    <PhoneInput
                                        className="phoneInput"
                                        defaultCountry="AE"
                                        value={ formData?.phone }
                                        name="phone"
                                        placeholder="Enter Your Phone *"
                                        onChange={ ( value ) => setFormData( { ...formData, phone: value } ) }
                                    />
                                </div>
                            </Col>
                            { user && user?.role === newRoles.Agent && (
                                <Col md="6">
                                    <label className="form-label" htmlFor="first-name">
                                        Languages{ " " }
                                    </label>
                                    <RSelect
                                        placeholder="Select Languages"
                                        options={ languageOptions }
                                        isMulti
                                        value={ selectedLang.map( lang => ( { value: lang, label: lang } ) ) }
                                        onChange={ ( selectedOptions ) =>
                                        {
                                            const formattedLanguages = selectedOptions.map( ( option ) => option.value );
                                            setFormData( {
                                                ...formData,
                                                languages: formattedLanguages,
                                            } );
                                            setSelectedLang( selectedOptions.map( option => option.value ) );
                                        } }
                                    />

                                </Col>
                            ) }
                        </Row>
                        <Row>
                            <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-end">
                                    <li>
                                        <Button
                                            color="primary"
                                            size="lg"
                                            onClick={ submitForm }
                                            disabled={ updateUserMutation.isLoading }
                                        >
                                            { updateUserMutation.isLoading ? "Updating..." : "Update" }
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className={ `tab-pane ${ modalTab === "2" ? "active" : "" }` } id="address">
                        <Row className="gy-4">
                            <Col md="12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="address-l1">
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address-l1"
                                        name="address"
                                        value={ formData?.address }
                                        onChange={ ( e ) =>
                                            setFormData( { ...formData, address: e.target.value } )
                                        }
                                        placeholder="Enter Address"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="address-l2">
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address-l2"
                                        name="city"
                                        value={ formData?.city }
                                        onChange={ ( e ) =>
                                            setFormData( { ...formData, city: e.target.value } )
                                        }
                                        placeholder="Enter City"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="country">
                                        Country
                                    </label>
                                    <RSelect
                                        placeholder="Select Country"
                                        options={ countryOptions }
                                        value={ {
                                            label: formData.country,
                                            value: formData.country,
                                        } }
                                        onChange={ ( selectedOption ) =>
                                            setFormData( {
                                                ...formData,
                                                country: selectedOption.value,
                                            } )
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </ModalBody>
    );
};

export default PersonalInformation;
