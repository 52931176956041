import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ExpiredLeadsView from './ExpiredLeadsView';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { timeAsMilliseconds } from '../../../utils/Utils';
import { getHotExplorerLeads } from '../../../api/expiring-leads/getExpiredHotLeads';
import _ from 'lodash';
import useFetchQuery from '../../../hooks/useFetch';
import { fetchAgentByUserId } from '../../../api/agents/fetchAgentByUserId';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../../redux/selectors/user';
import { newRoles } from '../../../utils/envConfig';
import useMutationQuery from '../../../hooks/useMutationQuery';
import { checkoutExplorerLeads } from '../../../api/property-cards/checkoutExplorerLeads';
import { showToast } from '../../../utils/toast/toast';
import { assignLeads } from '../../../api/user/assignLeads';

/**
 * @typedef {Object} Filter
 * @property {string} label - The label of the filter.
 * @property {string} value - The value of the filter (typically a MongoDB ID).
 * @property {Record<string, any>} [otherFields] - Other optional fields.
 */

/**
 * @typedef {Object} QueryState
 * @property {number} page - The current page number.
 * @property {number} limit - Number of items per page.
 * @property {Filter} leadSource - The lead source.
 */

const ExpiredLeadsContainer = () => {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const location = useLocation();

  const authUser = useSelector(loggedInUserSelector);
  const isAdmin =
    authUser && (authUser.roles?.includes(newRoles.MasterAdmin) || authUser.roles?.includes(newRoles.Admin));

  const decodeQueryState = () => {
    try {
      const encodedState = new URLSearchParams(location.search).get('q');
      if (!encodedState) {
        return {
          page: 1,
          limit: 25,
          leadSource: null,
          leadSourceCategory: null,
          sort: { field: 'createdAt', direction: 'desc' }
        };
      }
      return JSON.parse(atob(encodedState));
    } catch (error) {
      console.error('Failed to decode query state:', error);
      return {
        page: 1,
        limit: 25,
        leadSource: null,
        leadSourceCategory: null,
        sort: { field: 'createdAt', direction: 'desc' }
      };
    }
  };

  /**
   * State hook for managing query parameters.
   * @type {[QueryState, import("react").Dispatch<import("react").SetStateAction<QueryState>>]}
   */
  const [queryState, setQueryState] = useState(decodeQueryState());

  /**
   * State hook for managing selected items.
   * @type {[Set<string>, import("react").Dispatch<import("react").SetStateAction<Set<string>>>]}
   */
  const [selectedItems, setSelectedItems] = useState(new Set());

  const parseLeadSource = (query) => {
    const leadSource = [];

    if (query?.leadSource?.length) {
      leadSource.push(...query?.leadSource);
    }

    if (query?.leadSourceCategory?.sources && query?.leadSourceCategory?.sources?.length) {
      leadSource.push(...(query?.leadSourceCategory?.sources ?? []));
    }

    return leadSource.length ? _.uniqBy(leadSource, (item) => item?.id ?? item?._id) : null;
  }

  useEffect(() => {
    const cleanQueryState = {
      ...queryState,
      leadSource: parseLeadSource(queryState),
      agents: queryState?.agents?.value ? queryState.agents : null,
      sort: queryState?.sort || null
    };

    const encodedState = btoa(JSON.stringify(cleanQueryState));
    window.history.pushState(null, '', `${location.pathname}?q=${encodedState}`);
  }, [queryState, location.pathname]);

  const queryParams = {
    ...queryState,
    agents: queryState?.agents?.value ? (queryState?.agents?.id ?? queryState?.agents?._id ?? queryState?.agents?.value) : null,
    leadSource: parseLeadSource(queryState)?.map((item) => item?.name),
    leadSourceCategory: null
  };

  const { data, isFetching, status, isError, refetch } = useQuery({
    queryKey: ['expired-leads', _.omitBy(queryParams, _.isNil)],
    queryFn: () => getHotExplorerLeads(axios, { params: _.omitBy(queryParams, _.isNil) }),
    refetchOnWindowFocus: false,
    refetchInterval: timeAsMilliseconds({ minutes: 20 }),
    staleTime: timeAsMilliseconds({ minutes: 15 }),
    retry: false
  });

  const agentData = useFetchQuery(axios, {
    fetchFn: fetchAgentByUserId,
    customArgs: [(authUser?.id ?? authUser?._id)?.toString()],
    queryCondition: authUser && authUser?.roles?.includes(newRoles.Agent),
    queryKeyName: 'authAgentData',
    queryKeyDeps: [authUser],
    onFocus: 'no-refetch'
  });

  const mutationQuery = useMutationQuery(
    (payload) => isAdmin ? assignLeads(axios, payload) : checkoutExplorerLeads(axios, payload),
    {
      mutationKey: 'leadAssignment',
      onSettled: () => {
        queryClient.invalidateQueries('expired-leads');
        queryClient.invalidateQueries(['authAgentData', authUser]);
        setSelectedItems((_) => new Set());
      },
      onError: (error) => {
        console.error(error);
        showToast(error?.message ?? 'Assignment failed', 'error');
      },
      onSuccess: (data) => {
        const { leadsCount } = data;
        if (leadsCount && leadsCount >= 0) {
          showToast(`Successfully pulled the lead!`, 'success');
        } else {
          showToast('Successfully assigned leads.', 'success');
        }
      }
    }
  );

  return (
    <ExpiredLeadsView
      data={data}
      isFetching={isFetching}
      status={status}
      isError={isError}
      refetch={refetch}
      queryState={queryState}
      setQueryState={setQueryState}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      agentData={agentData}
      assignmentMutation={mutationQuery}
    />
  );
};

export default ExpiredLeadsContainer;
