import { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";

const BEDROOMS_AUTO_SUGGESTIONS = ['Studio', '1', '2', '3', '4', '5', '6', '7'];

const SuggestionsInput = ({ label, id, stateVar, onChangeCallback, placeholder, autoSuggestionValues = BEDROOMS_AUTO_SUGGESTIONS }) => {
  const [inputValue, setInputValue] = useState(stateVar ?? '');

  return (
    <FormGroup>
      <form className="position-relative" onSubmit={e => { e.preventDefault(); onChangeCallback(inputValue); }}>
          {label && <Label>{label}</Label>}
          <Input
            type="text"
            id={id}
            list={`${id}-datalist`}
            value={inputValue}
            onChange={e => setInputValue(_ => e.target.value)}
            placeholder={placeholder ?? 'Type to search...'}
          />
          <datalist id={`${id}-datalist`}>
            {autoSuggestionValues.map((value) => (
              <option value={value} key={`${id}-${value}`} />
            ))}
          </datalist>
          {inputValue && (
          <Button
            className="btn-close"
            onClick={() => { setInputValue(''); onChangeCallback(null) }}
            style={{
              position: 'absolute',
              top: '20%',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '1.2rem',
              padding: '0',
              cursor: 'pointer'
            }}
          ></Button>
        )}
          <Button className="d-none">submit</Button>
      </form>
    </FormGroup>
  )
};

export default SuggestionsInput;
