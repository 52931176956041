const HEADER_FIELDS = [
  { name: 'global-checkbox', breakpoint: null, isActions: true, isCheckbox: true },
  { name: 'Lead Source', breakpoint: 'md', isActions: false, isCheckbox: false, first: false },
  { name: 'Name', breakpoint: 'md', isActions: false, isCheckbox: false, isSortable: true, sortField: 'lead.name' },
  { name: 'Phone', breakpoint: 'md', isActions: false, isCheckbox: false },
  { name: 'Last Agent', breakpoint: 'md', isActions: false, isCheckbox: false, isSortable: true, sortField: 'agent.name' },
  { name: 'Last Expired At', breakpoint: 'md', isActions: false, isCheckbox: false, isSortable: true, sortField: 'createdAt' },
  { name: 'Expired', breakpoint: 'md', isActions: false, isCheckbox: false, isSortable: true, sortField: 'numberOfExpirations' },
  { name: '', breakpoint: 'md', isActions: true, isCheckbox: false }
];

export { HEADER_FIELDS };
