import { Button, Card, CardImg, Col, Label, Row } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import Head from "../../../layout/head/Head";
import React, { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import InputFile from "./InputFile";
import { deleteFromS3, uploadManyToS3 } from "../../../utils/envConfig";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddOffPlanStepSixEdit = (props) => {
  const axios = useAxiosPrivate();
  let {
    development,
    setDevelopment,
    finalePlanDetails,
    setFinalPlanDetails,
    filesToUpload,
    offPlanUpdate,
    setFilesToUpload,
    headerVideoFilesDev,
    headerFilesDev,
    brochureFilesDev,
    priceFilesDev,
    edit = false,
  } = props;

  const [disabled, setDisabled] = useState(false);
  const [isFinalizingDraft, setIsFinalizingDraft] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderFiles(headerFilesDev);
  }, [headerFilesDev]);

  useEffect(() => {
    setHeaderVideoFiles(headerVideoFilesDev);
  }, [headerVideoFilesDev]);

  useEffect(() => {
    setPriceFiles(priceFilesDev);
  }, [priceFilesDev]);

  useEffect(() => {
    setBrochureFiles(brochureFilesDev);
  }, [brochureFilesDev]);

  const {
    active: activeHeaderVideo,
    errorMessage: headerVideoStatusMessage,
    dragenter: headerVideoDragEnter,
    dragleave: headerVidoDragLeave,
    drop: headerDropVideo,
    handleFileChange: headerVideoChange,
    files: headerVideoFiles,
    setFiles: setHeaderVideoFiles,
    removeImage: removerHeaderVideo,
  } = useDragAndDrop({
    multiple: false,
    inputType: "video",
  });

  const {
    active: activeHeader,
    errorMessage: headerStatusMessage,
    dragenter: headerDragEnter,
    dragleave: headerDragLeave,
    drop: headerDrop,
    handleFileChange: headerImageChange,
    files: headerFiles,
    setFiles: setHeaderFiles,
    removeImage: removerHeaderImage,
  } = useDragAndDrop({
    multiple: false,
    inputType: "image",
  });
  const {
    active: brochureActive,
    errorMessage: brochureStatus,
    dragenter: brochureDragEnter,
    dragleave: brochureDragLeave,
    drop: brochureDrop,
    handleFileChange: brochureFileChange,
    files: brochureFiles,
    setFiles: setBrochureFiles,
    removeImage: removeBrochureImage,
  } = useDragAndDrop({
    inputType: "pdf",
  });

  const {
    active: priceActive,
    errorMessage: priceStatus,
    dragenter: priceDragEnter,
    dragleave: priceDragLeave,
    drop: priceDrop,
    handleFileChange: priceFileChange,
    files: priceFiles,
    setFiles: setPriceFiles,
    removeImage: removePriceImage,
  } = useDragAndDrop({
    inputType: "image",
  });


  const saveAndExit = async () => {
    try {
      setDisabled(true);
      const video = headerVideoFiles[0];
      const headerImage = headerFiles[0];
      const priceImg = priceFiles[0];
      const brochure = brochureFiles[0];

      if (
        !headerImage?.file ||
        !priceImg?.file ||
        !brochure?.file
      )
        return alert("Please add all required images");

      const filesToDelete = [];
      const filesToUpload = [];

      const planDetails = {};

      if (video?.file && typeof video?.file !== "string") {
        const prevName = video?.prevFileName?.split(".")[0];

        const videoExt = video?.file?.name?.split(".")?.pop();

        const fileName = prevName
          ? `${prevName}.${videoExt}`
          : `offPlans/videos/${uuid()}.${videoExt}`;

        filesToDelete.push(video?.prevFileName);

        filesToUpload.push({
          file: video?.file,
          fileName,
          type: "video/*",
        });

        planDetails.video = fileName;
      }

      if (typeof headerImage?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${headerImage?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(headerImage?.prevFileName);

        filesToUpload.push({
          file: headerImage?.file,
          fileName,
          type: "image/*",
        });

        planDetails.headerImage = fileName;
      }
      if (typeof priceImg?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${priceImg?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(priceImg?.prevFileName);

        filesToUpload.push({
          file: priceImg?.file,
          fileName,
          type: "image/*",
        });

        planDetails.priceImage = fileName;
      }

      if (typeof brochure?.file !== "string") {
        const fileName = `offPlans/otherImages/${uuid()}.${brochure?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToDelete.push(brochure?.prevFileName);

        filesToUpload.push({
          file: brochure?.file,
          fileName,
          type: "application/pdf",
        });

        planDetails.brochure = fileName;
      }

      filesToUpload.length && (await uploadManyToS3(filesToUpload, axios));
      await offPlanUpdate({
          ...planDetails
      }, { isFinalizingDraft });

    if (filesToDelete.length) {
      await Promise.all(
          filesToDelete.map(
              async (item) =>
                  await deleteFromS3(item,axios)
          )
      );
    }

    navigate(`/off-plans-list`);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setDisabled(false);
    }
  };

  const { handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const submitForm = async (data) => {
    setIsLoading(true);
    await saveAndExit();
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4">
            <Col sm="12">
              <Label>Header Video *</Label>
              <div>
                <InputFile
                  active={activeHeaderVideo}
                  dragenter={headerVideoDragEnter}
                  dragleave={headerVidoDragLeave}
                  drop={headerDropVideo}
                  errorMessage={headerVideoStatusMessage}
                  files={headerVideoFiles}
                  handleFileChange={headerVideoChange}
                  inputType="video"
                  removeImage={removerHeaderVideo}
                  inputProps={{ accept: "video/*" }}
                />
                {headerVideoFiles.length > 0 && headerVideoFiles[0]?.file && (
                  <section>
                    <Label className="mt-2 mb-2" onClick={() => console.log(headerVideoFiles)}>Uploaded Video</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={headerVideoFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <video
                            src={
                              !headerVideoFiles[0]?.file
                                ? null
                                : typeof headerVideoFiles[0]?.file === "string"
                                ? headerVideoFiles[0]?.file
                                : URL.createObjectURL(headerVideoFiles[0]?.file)
                            }
                            alt={headerVideoFiles[0]?.file?.name}
                          />
                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removerHeaderVideo(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Header Image *</Label>
              <div>
                <InputFile
                  active={activeHeader}
                  dragenter={headerDragEnter}
                  dragleave={headerDragLeave}
                  drop={headerDrop}
                  errorMessage={headerStatusMessage}
                  files={headerFiles}
                  handleFileChange={headerImageChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removerHeaderImage}
                />
                {headerFiles?.length > 0 && headerFiles[0]?.file && (
                  <section>
                    <Label className="mt-2 mb-2" onClick={() => console.log(headerFiles)}>Uploaded Image</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={headerFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <CardImg
                            src={
                              !headerFiles[0]?.file
                                ? null
                                : typeof headerFiles[0]?.file === "string"
                                ? headerFiles[0]?.file
                                : URL.createObjectURL(headerFiles[0]?.file)
                            }
                            alt={headerFiles[0]?.file?.name ?? ""}
                          />

                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removerHeaderImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Price Image *</Label>
              <div>
                <InputFile
                  active={priceActive}
                  dragenter={priceDragEnter}
                  dragleave={priceDragLeave}
                  drop={priceDrop}
                  errorMessage={priceStatus}
                  files={priceFiles}
                  handleFileChange={priceFileChange}
                  inputProps={{ accept: "image/*" }}
                  inputType="image"
                  removeImage={removePriceImage}
                />

                {priceFiles.length > 0 && priceFiles[0]?.file && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Image</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={priceFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          <CardImg
                            src={
                              !priceFiles[0]?.file
                                ? null
                                : typeof priceFiles[0]?.file === "string"
                                ? priceFiles[0]?.file
                                : URL.createObjectURL(priceFiles[0]?.file)
                            }
                            alt={priceFiles[0]?.file?.name}
                          />

                          <Button
                            className="position-absolute"
                            style={{ top: "0", left: "0" }}
                            color="danger"
                            onClick={() => removePriceImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <Label>Development Brochure</Label>
              <div>
                <InputFile
                  active={brochureActive}
                  dragenter={brochureDragEnter}
                  dragleave={brochureDragLeave}
                  drop={brochureDrop}
                  errorMessage={brochureStatus}
                  files={brochureFiles}
                  handleFileChange={brochureFileChange}
                  inputType="pdf"
                  removeImage={removeBrochureImage}
                  inputProps={{ accept: "application/pdf" }}
                />
                {brochureFiles?.length > 0 && brochureFiles[0]?.file && (
                  <section>
                    <Label className="mt-2 mb-2">Uploaded Brochure</Label>
                    <div className="image-list">
                      <LazyLoadComponent key={brochureFiles[0]?.file?.name}>
                        <Card
                          className="position-relative"
                          style={{ width: "15rem" }}
                        >
                          {typeof brochureFiles[0]?.file === 'string'? 
                          <a href={brochureFiles[0]?.file} download>
                      <i className="fas fa-download"></i>
                            <CardImg
                             src={ brochureFiles[0]?.file }
                                // Other props for CardImg
                                alt={brochureFiles[0]?.file?.name}
                            />
                        </a> 
                        :
                        <iframe
                            src={URL.createObjectURL(brochureFiles[0]?.file ?? "")}
                            title="PDF Viewer"
                            // width="100%"
                            // height="500px"
                          />  }                            
                          <Button
                            className="position-absolute"
                            style={{ top: "0", right: "0" }}
                            color="danger"
                            onClick={() => removeBrochureImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li className="d-flex" style={{ gap: "1rem" }}>
                <Button color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
                <Button color="primary" type="submit" onClick={() => setIsFinalizingDraft(true)} disabled={isLoading}>
                  {isLoading ? 'Saving...' : 'Save & Publish'}
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};
export default AddOffPlanStepSixEdit;
