import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import
{
  Col,
  Row,
  Button,
  Label,
  Spinner,
} from 'reactstrap';

import 'react-phone-number-input/style.css';
import { Block, RSelect } from '../../../components/Component';
import Head from '../../../layout/head/Head';
import
{
  newRoles,
  HotLeadSourceFilter,
  HttpStatus
} from '../../../utils/envConfig';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { fetchPropertiesAdmin } from '../../../redux/actions/property';
import { fetchAgents } from '../../../redux/actions/user';
import { showToast } from '../../../utils/toast/toast';
import ErrorModal from '../../../components/modals/ErrorModal';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import PhoneInput from '../../../components/input/contact-input/PhoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';

const AddLeadStepOne = ( { redirect = true, ...props } ) =>
{
  const { errors, register, handleSubmit } = useForm();
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
  const authAgent = useSelector( ( state ) => state?.user?.loggedInAgent );


  // State Variables
  const [ error, setError ] = useState( null );
  const [ firstName, setFirstName ] = useState( undefined );
  const [ phoneError, setPhoneError ] = useState( '' );
  const [ lastName, setLastName ] = useState( undefined );
  const [ phone, setPhone ] = useState( null );
  const [ email, setEmail ] = useState( undefined );
  const [ selectedProperty, setSelectedProperty ] = useState( null );
  const [ leadSources, setLeadSources ] = useState( null );
  const [ selectedLeadSource, setSelectedLeadSource ] = useState( null );
  const [ loading, setLoading ] = useState( false );
  const [ optAgents, setOptAgents ] = useState( [] );
  const [ selectedAgent, setSelectedAgent ] = useState( props?.agent ?? null );
  const [ agentGet, setAgent ] = useState( undefined );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ agentLoading, setAgentLoading ] = useState( false );
  const [ activityNote, setActivityNote ] = useState( undefined );

  useEffect( () =>
  {
    dispatch( fetchPropertiesAdmin( axios ) );
    async function fetchLeadSource ()
    {
      try
      {
        const res = await axios.get(
          `/leadSources/hotleads?filter_out=${ HotLeadSourceFilter }`
        );
        const originalLeadSources = res?.data?.data;

        let filteredLeadSources;

        if ( authUser?.role && authUser?.role === newRoles?.Agent )
        {
          filteredLeadSources = originalLeadSources.filter(
            leadSource => !leadSource.name.startsWith( "Call Center" )
          );
        } else
        {
          filteredLeadSources = originalLeadSources;
        }

        setLeadSources( filteredLeadSources );

      } catch ( err )
      {
        console.log( err );
      }
    }
    fetchLeadSource();
    dispatch( fetchAgents( axios ) );
  }, [ authUser?.role, axios, dispatch ] );

  const agent = useSelector( ( state ) => state.user?.agents );

  useEffect( () =>
  {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter( ( agents ) => agents?.user?.active === true )
        ?.map( ( a ) =>
          options.push( {
            value: a?.id,
            label: a?.user?.first_name + ' ' + a?.user?.last_name
          } )
        );
    setOptAgents( options );
    setAgentLoading( true );
  }, [ agent, selectedAgent ] );

  // Input Validation
  const validate = () =>
  {
    let isError = false;

    if ( errors.selectedLeadSource || !selectedLeadSource )
    {
      isError = true;
      toast.error( 'Choose Leadsource' );
    }

    if ( errors.firstName || !firstName )
    {
      toast.error( 'Enter First Name' );
      isError = true;
    }
    if ( errors.phone || !phone?.trim() )
    {
      isError = true;
      toast.error( 'Enter valid phone number' );
    }
    setLoading( _ => !isError );
    return isError ? false : true;
  };

  // Add Lead Info in DB
  const addLead = async ( e ) =>
  {
    if ( validate() )
    {
      setLoading( true );
      let agentId = selectedAgent ? selectedAgent.toString() : undefined;
      const formData = {
        lead_source: selectedLeadSource?.name ?? selectedLeadSource?.name,
        property_list_id: selectedProperty?.id ?? selectedProperty?._id,
        first_name: firstName,
        last_name: lastName,
        phone: phone?.trim(),
        email,
        agent_id: agentId,
        activityNote: activityNote
      };
      try
      {

        const res = await axios.post( `/users/createLead`, formData );
        props.callback && props?.callback( res?.data );
        toast.success( 'Created new Hotlead successfully' );
        setLoading( false );
        redirect && setTimeout( () => navigate( `/user-profile/${ res?.data?.id ?? res?.data?._id }/user-profile-setting` ), 500 );
      } catch ( err )
      {
        toast.error( err.response.data.message );
        setLoading( false );
      }
    }
  };

  const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
  const toggleErrorModal = () => setIsErrorModalOpen( !isErrorModalOpen );
  const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState( false );
  const toggleConfirmModal = () => setIsConfirmModalOpen( !isConfirmModalOpen );
  const [ existingUser, setExistingUser ] = useState( null );

  const checkIfLeadExists = async (  ) =>
  {
    setLoading( true );
    const phoneNumber = phone.trim();
    if ( !isValidPhoneNumber( phoneNumber ) )
    {
      setLoading( false );
      showToast( "Invalid phone number", "error" );
      return;
    }
    try
    {
      const phoneQuery = encodeURIComponent( phoneNumber );
      const res = await axios.get( `/users/check-exists?phone=${ phoneQuery }` );

      if ( !res.data.exists )
      {
        addLead();
        return;
      }

      const foundUser = res.data?.user;
      const assignedAgent = res.data?.user?.currentAgent;
      setExistingUser( _ => foundUser );

      if ( assignedAgent )
      {
        setIsErrorModalOpen( true );
        setLoading( false );
      } else
      {
        setLoading( false );
        setIsConfirmModalOpen( true );
      }
    } catch ( err )
    {
      setLoading( false );
      showToast( err.response.data.message, 'error' );
    }
  };

  const assignAgentToExistingLead = async () =>
  {
    try
    {
      setSaveLoading( true );
      const formData = {
        agent_id: authAgent ? ( authAgent?.id ?? authAgent?._id ).toString() : selectedAgent.toString(),
        user_ids: [ ( existingUser?.id ?? existingUser?._id ) ],
      };
      const res = await axios.post( "/usersAgents/assign", formData );
      if ( res.status === HttpStatus.OK )
      {
        showToast( 'Assigned lead successfully', 'success' );
      }
    } catch ( err )
    {
      showToast( err.response.data.message, 'error' );
    } finally
    {
      setSaveLoading( false );
      setLoading( false );
      toggleConfirmModal();
      setTimeout( () => navigate( `/user-profile/${ existingUser?.id ?? existingUser?._id }/user-profile-setting` ), 500 );
    }
  };

  return (
    <React.Fragment>
      <Head title="Add New Lead"></Head>
      { agentLoading && (
        <Block>
          <form id="add-development-form" onSubmit={ handleSubmit( checkIfLeadExists ) }>
            <Row className="gy-4">
              <Col sm="6">
                <div className="form-group">
                  <Label className=" form-label">Choose Leadsource *</Label>
                  <div>
                    <RSelect
                      closeMenuOnSelect={ true }
                      defaultData={ [] }
                      placeholder="Choose Leadsource"
                      options={
                        leadSources?.map( ( prop ) => ( {
                          ...prop,
                          label: prop?.name,
                          value: prop?.name
                        } ) ) ?? []
                      }
                      onChange={ ( prop ) =>
                      {
                        setSelectedLeadSource( prop );
                        setError( ( prev ) => ( {
                          ...prev,
                          leadsource: null
                        } ) );
                      } }
                      value={ selectedLeadSource }
                      className="w-100"
                    />
                  </div>
                  { errors?.label && (
                    <span className="invalid">{ errors?.label?.message }</span>
                  ) }
                </div>
              </Col>

              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">First Name *</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      className="form-control"
                      ref={ register( { required: 'This field is required' } ) }
                      value={ firstName }
                      onChange={ ( e ) => setFirstName( e.target.value ) }
                    />
                  </div>
                  { errors?.label && (
                    <span className="invalid">{ errors?.label?.message }</span>
                  ) }
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Last Name </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-control"
                      value={ lastName }
                      onChange={ ( e ) => setLastName( e.target.value ) }
                    />
                  </div>
                  { errors?.label && (
                    <span className="invalid">{ errors?.label?.message }</span>
                  ) }
                </div>
              </Col>

              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Phone *</label>
                  <div className="input-group">
                    <PhoneInput
                      className="phoneInput w-100"
                      defaultCountry="AE"
                      name="phone"
                      placeholder="Phone"
                      value={ phone }
                      onChange={ ( value ) => setPhone( value ) }
                      onError={ ( error ) => setPhoneError( error ) }
                    />
                  </div>
                  { phoneError && (
                    <span className="invalid">{ phoneError }</span>
                  ) }
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <label className="form-label">Email </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={ email }
                      onChange={ ( e ) => setEmail( e.target.value ) }
                    />
                  </div>
                  { errors?.label && (
                    <span className="invalid">{ errors?.label?.message }</span>
                  ) }
                </div>{ ' ' }
              </Col>
              { authUser?.role && (authUser?.role !== newRoles?.Agent && authUser?.role !== newRoles?.AnonymousAgent) && (
                <Col sm="6">
                  <div className="form-group">
                    <Label className=" form-label">Assign Agent</Label>
                    <div>
                      <RSelect
                        options={ optAgents }
                        value={
                          selectedAgent?.label ? selectedAgent?.label : agentGet
                        }
                        placeholder={
                          selectedAgent?.label
                            ? selectedAgent?.label
                            : 'Select Agent'
                        }
                        onChange={ ( selectedOption ) =>
                        {
                          setAgent( selectedOption );
                          setSelectedAgent( selectedOption.value );
                        } }
                      />
                    </div>
                    { errors?.label && (
                      <span className="invalid">{ errors?.label?.message }</span>
                    ) }
                  </div>
                </Col>
              ) }
              { selectedAgent && selectedAgent[ 0 ] && (
                <Col sm="12">
                  <div className="form-group">
                    <label className="form-label">Note</label>
                    <textarea
                      name="notes"
                      value={ activityNote }
                      onChange={ ( e ) => setActivityNote( e.target.value ) }
                      className="form-control no-resize"
                      ref={ register( { required: 'This field is required' } ) }
                    />
                    { errors.desc && (
                      <span className="invalid">{ errors.desc.message }</span>
                    ) }
                  </div>
                </Col>
              ) }
              <Row className="mt-3">
                <Col size="12" className="text-end">
                  <Button color="primary" type="submit" disabled={ loading }>
                    { loading ? (
                      <Spinner
                        style={ { width: '1rem', height: '1rem' } }
                        children={ false }
                      />
                    ) : (
                      'Add Lead'
                    ) }
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </Block>
      ) }
      <ErrorModal
        isOpen={ isErrorModalOpen }
        toggleFn={ toggleErrorModal }
        size='md'
        header={ 'Lead Already Exists!' }
      >
        <p className="text-center fs-6" style={ { maxWidth: "40ch" } }>
          This lead already exists, and they are already assigned to <span className='fw-bold'>{ existingUser?.currentAgent?.userId?.first_name } { existingUser?.currentAgent?.userId?.last_name ?? '' }</span>
        </p>
        { authAgent ? <p className="text-soft">
          Please contact administration for help.
        </p> : null }
        <Button
          className="btn-light btn-mw"
          size="lg"
          onClick={ toggleErrorModal }
        >
          OK
        </Button>
      </ErrorModal>
      <ConfirmationModal
        isOpen={ isConfirmModalOpen }
        toggleFn={ toggleConfirmModal }
        title={ 'Lead Already Exists' }
        actionFn={ assignAgentToExistingLead }
        loadingState={ saveLoading }
      >
        <p className=''>Lead already exists, but they are not assigned to an agent.
          Would you like to assign the lead to { authAgent ? 'yourself' : 'the selected agent' }?</p>
      </ConfirmationModal>
    </React.Fragment>
  );
};
export default AddLeadStepOne;
