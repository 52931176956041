import React, { useEffect, useState} from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import
{
    Block,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
} from "../../components/Component";
import { Step, Steps } from "react-step-builder";
import AddOffPlanStepOne from "./components/AddOffPlanStepOne";
import AddOffPlanStepThreeEdit from "./components/AddOffPlanStepThreeEdit";
import AddOffPlanStepFourEdit from "./components/AddOffPlanStepFourEdit";
import AddOffPlanStepFiveEdit from "./components/AddOffPlanStepFiveEdit";
import AddOffPlanStepSixEdit from "./components/AddOffPlanStepSixEdit";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getImage, OffPlanStatus } from "../../utils/envConfig";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "react-toastify/dist/ReactToastify.css";
import LoadingComponent from "../components/loader/tableLoader";
import { showToast } from "../../utils/toast/toast";
import PaymentPlanStep from "./components/PaymentPlanStep";

const EditDevelopment = () =>
{
    const { id } = useParams();
    const navigate = useNavigate();
    const axios = useAxiosPrivate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    console.log(searchParams);

    const [stepsInstance, setStepsInstance] = useState(null); 
    const [isLoading,setLoading] = useState(false);
    const [propertyTypeList, setPropertyTypeList] = useState( [
        {
            propertyType: null,
            beds: [],
        },
    ]);
    const [amenitiesFilesDev, setAmenitiesFilesDev] = useState([]);
    const [developmentsFilesDev, setDevelopmentsFilesDev] = useState([{}]);

    const [headerFilesDev, setHeaderFilesDev] = useState([{}]);
    const [headerVideoFilesDev, setHeaderVideoFilesDev] = useState([{}]);
    const [brochureFilesDev, setBrochureFilesDev] = useState([{}]);
    const [priceFilesDev, setPriceFilesDev] = useState([{}]);

      const [optDefaultDeveloper, setOptDefaultDeveloper] = useState({label: "",
        value: "",
      });
      const [optDefaultFooter, setOptDefaultFooter] = useState({label: "",
        value: "",
      });
      const [developmentLogoFilesDev, setDevelopmentLogoFilesDev] = useState({
        file:  "",
        label: "",
        image: "",
        prevFileName:"",
    },);

      
    const [development, setDevelopment] = useState({
        _id: "",
        developmentName: "",
        emirate: "",
        community: {},
        slug: "",
        downpayment: "",
        completionDate: "",
        developmentDescription: "",
        propertyType: "",
        beds: [],
        amenities: [],
        developer: {},
        footer: {},
        developmentPhotos: [],
        video: "",
        brochure: "",
        headerImage: "",
        topImage: "",
        map: "",
        priceImage: "",
        keywords:[],
        tags:[],
        metaDescription: "",
        metaTitle: "",
    });
    const offPlanUpdate = async (offPlanData, options = { isFinalizingDraft: false }) => {
        try {
            await axios.put(
              `/off-plan/${id}`,
              options?.isFinalizingDraft ? { ...offPlanData, status: OffPlanStatus.Published } : offPlanData
            );

            showToast("Updated successfully", "success");
            
            options?.isFinalizingDraft && setTimeout(() => navigate('/off-plans-list'), 1500);
        } catch (err) {
            console.log(err)
            showToast("Server error. Try Again!", "error");
        }
    };
    const getDevelopment = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/off-plan/${id}`
            );
        
            const videoIMage = await getImage(data?.video ?? "",axios);
            const headerIMage = await getImage(data?.headerImage ?? "",axios);
            const brochure = await getImage(data?.brochure ?? "",axios);
            const priceImage = await getImage(data?.priceImage ?? "",axios);
            const developmentLogoImage = await getImage(data?.developmentLogo ?? "",axios);
            
            const amenities = await Promise.all(
                data?.amenities?.map(async (item,index) => {
                    const image = await getImage(item?.photo ?? "",axios);
                    
                    return {
                        ...item,
                        id:item?._id,
                        image: image?.signedRequest,
                        prevPhoto: item?.photo,
                        file: image?.signedRequest,
                        index:index,
                    };
                })
            );
          
            setAmenitiesFilesDev(amenities);

            const devFiles = await Promise.all(
                data?.developmentPhotos?.map(async (item,index) => {
                    const image = await getImage(item?.file,axios);
                    return {
                        ...item,
                        image: image?.signedRequest,
                        file: image?.signedRequest,
                        prevPhoto: item?.file,
                        id:item?._id,
                        index:index,
                    };
                })
            );
            setDevelopmentsFilesDev(devFiles);
            setDevelopment({
                developmentName: data.developmentName,
                emirate: data.emirate,
                community: data.community,
                startingPrice: data.startingPrice,
                downpayment: data.downpayment,
                completionDate: data.completionDate,
                propertyType: data.propertyType,
                beds: data.beds,
                developmentDesc: data.developmentDescription,
                keywords: data?.keywords,
                tags: data?.tags,
                metaDescription: data.metaDescription,
                slug: data?.slug,
                _id: data?._id,
                templateVersion: data?.templateVersion ?? "1",
                metaTitle: data?.metaTitle ?? "",
                paymentPlan:data?.paymentPlan,
                paymentScheme:data?.paymentScheme
            });

            setPropertyTypeList(
                data.propertyTypes?.length
                    ? data?.propertyTypes
                    : [
                          {
                              propertyType: null,
                              beds: [],
                          },
                      ]
            );

            setDevelopmentLogoFilesDev([
                {
                    file: developmentLogoImage?.signedRequest ?? "",
                    label: null,
                    image: developmentLogoImage?.signedRequest ?? null,
                    prevFileName: data?.developmentLogo,
                },
            ]);

            setAmenitiesFilesDev(amenities);

           setOptDefaultDeveloper( {label: data?.developer?.name,
            value: data?.developer?._id,
          });
          setOptDefaultFooter( {label: data?.footer?.name,
            value: data?.footer?._id,
          });

          setHeaderVideoFilesDev([
            {
                label: "",
                file: videoIMage?.signedRequest,
                image: videoIMage?.signedRequest,
                prevFileName: data.video,
            },
        ]);

        setHeaderFilesDev([
            {
                label: "",
                file: headerIMage?.signedRequest,
                image: headerIMage?.signedRequest,
                prevFileName: data.headerImage,
            },
        ]);
        setPriceFilesDev([
            {
                label: "",
                file: priceImage?.signedRequest,
                image: priceImage?.signedRequest,
                prevFileName: data?.priceImage,
            },
        ]);

        setBrochureFilesDev([
            {
                label: "",
                file: brochure?.signedRequest,
                image: brochure?.signedRequest,
                prevFileName: data?.brochure,
            },
        ]);

        setLoading(false);

        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };
    // State Variables

    useEffect(() => {
        getDevelopment();
    }, []);

    const [finalePlanDetails, setFinalPlanDetails] = useState({});

    const [filesToUpload, setFilesToUpload] = useState({
        amenities: [],
        devPhotos: [],
        others: [],
        devLogo: null,
    });


    
    const Header = ( props ) =>
    {
        useEffect(() => {
            if (location.search) {
                const targetStep = parseInt(location.search.split('=')[1]);
                if (targetStep >= 1 && targetStep <= 5) {
                    props.jump(targetStep);
                }
            }
        }, [props]);

        const handleTabClick = (event, stepNumber) => {
            event.preventDefault();
            navigate(`${location.pathname}?tab=${stepNumber}`, { replace: true });
            props.jump(stepNumber);
        };

        return (
            <div className="steps clearfix">
                <ul>
                    <li className={ props.current >= 1 ? "first done" : "first" }>
                        <a
                            href="#wizard-01-h-0"
                            onClick={ ( ev ) => handleTabClick(ev, 1) }
                        >
                            <span className="number">01</span>{ " " }
                            <h5>General Info</h5>
                        </a>
                    </li>
                      <li className={ props.current >= 2 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-1"
                            onClick={ ( ev ) => handleTabClick(ev, 2) }
                        >
                            <span className="number">02</span>{ " " }
                            <h5>Payment Plan</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 3 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-1"
                            onClick={ ( ev ) => handleTabClick(ev, 3) }
                        >
                            <span className="number">03</span>{ " " }
                            <h5>Amenities</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 4 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => handleTabClick(ev, 4) }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">04</span>
                            <h5>Developer and Footer Template</h5>
                        </a>
                    </li>
                    <li className={ props.current >= 5 ? "done" : "" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => handleTabClick(ev, 5) }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">05</span>
                            <h5>Development Images</h5>
                        </a>
                    </li>
                    <li className={ props.current === 6 ? "last done" : "last" }>
                        <a
                            href="#wizard-01-h-2"
                            onClick={ ( ev ) => handleTabClick(ev, 6) }
                        >
                            <span className="current-info audible">
                                current step:{ " " }
                            </span>
                            <span className="number">06</span>
                            <h5>Files</h5>
                        </a>
                    </li>

                </ul>
            </div>
        );
    };

    // Initial data loading
    useEffect(() => {
        getDevelopment();
    }, []);

    const config = {
      before: Header,
    };
    
    return (
        <>
                <React.Fragment>
                    <Head title="Off Plan" />
                    <Content>
                        <BlockHead size="sm">
                            <BlockBetween>
                                <BlockHeadContent>
                                    <BlockTitle>Edit Off Plan</BlockTitle>
                                </BlockHeadContent>
                            </BlockBetween>
                        </BlockHead>
                        { isLoading ? <LoadingComponent />: 
                        <Block>
                        <PreviewCard>
                            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                                <Steps config={config} onInit={(steps) => {
                                            console.log("Steps initialized:", steps);
                                            setStepsInstance(steps);
                                        }}>
                                    {propertyTypeList && (
                                        <Step
                                            component={AddOffPlanStepOne}
                                            offPlanUpdate={offPlanUpdate}
                                            edit={true}
                                            propertyTypeListDev={propertyTypeList}
                                            setFinalPlanDetails={setFinalPlanDetails}
                                            setFilesToUpload={setFilesToUpload}
                                            development={development}
                                            setDevelopment={setDevelopment}
                                        />
                                    )}
                                          <Step
                                        component={PaymentPlanStep}
                                        edit={true}
                                        offPlanUpdate={offPlanUpdate}
                                        amenitiesFilesDev={amenitiesFilesDev}
                                        setFinalPlanDetails={setFinalPlanDetails}
                                        setFilesToUpload={setFilesToUpload}
                                        development={development}
                                        setDevelopment={setDevelopment}
                                    />
                                    <Step
                                        component={AddOffPlanStepThreeEdit}
                                        edit={true}
                                        offPlanUpdate={offPlanUpdate}
                                        amenitiesFilesDev={amenitiesFilesDev}
                                        setFinalPlanDetails={setFinalPlanDetails}
                                        setFilesToUpload={setFilesToUpload}
                                        development={development}
                                        setDevelopment={setDevelopment}
                                    />
                                    <Step
                                        component={AddOffPlanStepFourEdit}
                                        offPlanUpdate={offPlanUpdate}
                                        developmentLogoFilesDev={developmentLogoFilesDev}
                                        optDefaultDeveloper={optDefaultDeveloper}
                                        optDefaultFooter={optDefaultFooter}
                                        edit={true}
                                        setFinalPlanDetails={setFinalPlanDetails}
                                        setFilesToUpload={setFilesToUpload}
                                        development={development}
                                        setDevelopment={setDevelopment}
                                    />
                                    <Step
                                        component={AddOffPlanStepFiveEdit}
                                        developmentsFilesDev={developmentsFilesDev}
                                        offPlanUpdate={offPlanUpdate}
                                        edit={true}
                                        setFinalPlanDetails={setFinalPlanDetails}
                                        setFilesToUpload={setFilesToUpload}
                                        development={development}
                                        setDevelopment={setDevelopment}
                                    />
                                    <Step
                                        component={AddOffPlanStepSixEdit}
                                        headerVideoFilesDev={headerVideoFilesDev}
                                        headerFilesDev={headerFilesDev}
                                        brochureFilesDev={brochureFilesDev}
                                        priceFilesDev={priceFilesDev}
                                        offPlanUpdate={offPlanUpdate}
                                        edit={true}
                                        finalePlanDetails={finalePlanDetails ?? {}}
                                        setFinalPlanDetails={setFinalPlanDetails}
                                        filesToUpload={filesToUpload ?? []}
                                        setFilesToUpload={setFilesToUpload}
                                        development={development ?? {}}
                                        setDevelopment={setDevelopment}
                                    />
                                </Steps>
                            </div>
                        </PreviewCard>
                    </Block>}
                    </Content>
                </React.Fragment>
        </>
    );
    
};

export default EditDevelopment;