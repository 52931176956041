import { Button, Spinner } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { HttpStatus, PropertyCardStatus } from "../../../../utils/envConfig";
import { useQuery } from "react-query";
import { timeAsMilliseconds } from "../../../../utils/Utils";
import { showToast } from "../../../../utils/toast/toast";

const unitIdRegex = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;
const unitIdValidationRules = {
  unitId: {
    maxLength: {
      value: 20,
      message: "Unit ID cannot be more than 20 characters",
    },
    pattern: {
      value: unitIdRegex,
      message:
        "Invalid unit ID. Unit ID must not start or end with - or _, or contain any special characters apart from - and _ and must not contain any spaces",
    },
  },
};

const FieldEditView = ({ propertyCardDetail, toggleEdit, refreshFn }) => {
  const axios = useAxiosPrivate();

  const [type, setType] = useState(propertyCardDetail?.propertyType ? { label: propertyCardDetail?.propertyType, value: propertyCardDetail?.propertyType } : undefined);
  const [unitId, setUnitId] = useState(propertyCardDetail?.unitId ?? "");
  const [purpose, setPurpose] = useState(propertyCardDetail?.purpose ?? "");
  const [beds, setBeds] = useState(propertyCardDetail?.beds ?? "");
  const [baths, setBaths] = useState(propertyCardDetail?.baths ?? "");
  const [size, setSize] = useState(propertyCardDetail?.size ?? "");

  const { errors, register, handleSubmit } = useForm();

  const { data: propertyTypes, isLoading: propertyTypesLoading } = useQuery(
    ['property-types'],
    async () => {
      const res = await axios.get(`/propType`);

      return res?.data;
    },
    { cacheTime: timeAsMilliseconds({ hours: 1 }), refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false }
  )

  const isPocketListing =
    propertyCardDetail?.status === PropertyCardStatus.PocketListing;

  const submitForm = async (formData) => {
    if (Object.keys(errors).length) {
      toast.error("Error while submitting the form.");
      console.log(errors);
      toggleEdit();
      return;
    }
    try {
      const res = await axios.put(
        `/property-cards/${propertyCardDetail?.id}`,
        type ? { ...formData, propertyType: type?.value } : formData
      );
      if (res.status === HttpStatus.OK) {
        showToast("Updated info successfully!", "success");
      }
    } catch (error) {
      console.log(error);
      showToast("Error while updating info!", "error");
    } finally {
      toggleEdit();
      refreshFn();
    }
  };

  return (
    <div className="product-info mt-1 me-xxl-5">
      <h4 className="product-title ">
        {propertyCardDetail?.building?.name
          ? propertyCardDetail?.building?.name
          : "Unknown building name"}
      </h4>
      <Icon name="location"></Icon>{" "}
      <span className="ml-2">
        {propertyCardDetail?.community?.community}{" "}
        {propertyCardDetail?.emirate ? `, ${propertyCardDetail?.emirate}` : ""}
      </span>
      <form action="" onSubmit={handleSubmit(submitForm)}>
        <div className="mt-3">
          <ul className="d-flex flex-wrap" style={{ gap: ".5rem" }}>
            <li style={{ cursor: "pointer" }}>
              <div className="form-group">
                <label className="form-label" htmlFor="type">
                  Type
                </label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <RSelect
                      options={(propertyTypes ?? []).map((option) => ({ label: option?.propertyType, value: option?.propertyType }))}
                      onChange={(option) => setType(option)}
                      defaultValue={type}
                      id="type"
                      className="status-div-small"
                      isLoading={propertyTypesLoading}
                      loadingMessage={() => <Spinner />}
                    />
                  </div>
                </div>
              </div>
            </li>
            {isPocketListing ? (
              <li>
                <div className="form-group">
                  <label className="form-label" htmlFor="purpose">
                    Purpose
                  </label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        onChange={(e) => setPurpose(e.target?.value)}
                        name="purpose"
                        id="purpose"
                        defaultValue={purpose}
                        className="form-control"
                        style={{ minWidth: "5rem" }}
                        ref={register()}
                      >
                        <option value="Sell">Sell</option>
                        <option value="Lease">Lease</option>
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            ) : null}

            <li>
              <div className="form-group">
                <label className="form-label" htmlFor="purpose">
                  Unit ID
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(e) => setUnitId(e.target.value)}
                    type="text"
                    name="unitId"
                    id="unitId"
                    className={`form-control ${errors?.unitId && `border border-danger`}`}
                    placeholder="Unit ID..."
                    value={unitId ?? ""}
                    ref={register(unitIdValidationRules.unitId)}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="form-group">
                <label className="form-label" htmlFor="type">
                  Beds
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(e) => setBeds(e.target.value)}
                    type="text"
                    name="beds"
                    id="beds"
                    className="form-control"
                    placeholder="Number of beds..."
                    value={beds ?? ""}
                    ref={register()}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="form-group">
                <label className="form-label" htmlFor="type">
                  Baths
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(e) => setBaths(e.target.value)}
                    type="number"
                    name="baths"
                    id="baths"
                    className="form-control"
                    placeholder="Number of baths..."
                    value={baths ?? ""}
                    ref={register()}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="form-group">
                <label className="form-label" htmlFor="type">
                  Area
                </label>
                <div className="form-control-wrap">
                  <input
                    onChange={(e) => setSize(e.target.value)}
                    type="number"
                    name="size"
                    id="size"
                    className="form-control"
                    placeholder="Area in square feet..."
                    value={size ?? ""}
                    ref={register()}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={`kanban-item-text mt-2`}>
          <div className="form-group">
            <div
              className="mt-4 d-flex justify-content-start"
              style={{ gap: "0.5rem" }}
            >
              <Button color="success" type="submit">
                Save
              </Button>
              <Button
                onClick={() => toggleEdit()}
                className="btn border-0 text-dark bg-gray-dim"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FieldEditView;
