import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../Component";
import { Link, useNavigate } from "react-router-dom";
import DealSkeletonLoader from "./components/DealSkeleotnLoader";
import useResponsiveDealStyles from "./hooks/useResponsiveStyles";
import { format } from "date-fns";
import { DealCategory, DealClientType, DealStatus } from "../../utils/envConfig";
import { currencyFormatter } from "../../pages/explorer/utils";
import { Button, Card } from "reactstrap";
import React from "react";

const ProfileDealsView = ({ dealsData, isAgent = false, agent = null }) => {
  const { skeletonColumnCount, wideScreenCellClass, xxlCellClass } =
    useResponsiveDealStyles();

  const navigate = useNavigate();

  return (
    <div id="deals">
      <Block className={isAgent ? '' : 'mt-5'}>
        <BlockHead size={'lg'}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Deals</BlockTitle>
              <BlockDes>
                <p>
                  The {isAgent ? 'agent' : 'user'}'s most recent Deals.
                  <br />
                  <span className="my-n4" style={{ maxWidth: '40ch', textWrap: 'wrap' }}>
                    Want to see more deals?{' '}
                    <span className="fw-bold">
                      Check out our{' '}
                      <Link
                        target="_blank"
                        referrerPolicy="no-referrer"
                        to={`/list-deals?per_page=10&agent=${agent?.id ?? agent?._id}&page=1`}
                      >
                        Deal List
                      </Link>
                    </span>
                  </span>
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate(`/list-deals`);
                }}
              >
                <Icon name={'eye'} />
                <span className="d-none d-sm-block">View More Deals</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      </Block>
      <Block>
        {!dealsData?.isError && dealsData?.data?.length ? (
          <div className="border rounded">
            <table className="table table-orders pe-2">
              <thead className="tb-odr-head">
                <tr className="tb-odr-item">
                  <th className="text-center">
                    <span>Deal ID</span>
                  </th>
                  <th className="text-center">
                    <span>Created</span>
                  </th>
                  <th className="text-center">
                    <span>Category</span>
                  </th>
                  <th className="d-none d-md-table-cell text-center">
                    <span>Purpose</span>
                  </th>
                  <th className={xxlCellClass}>
                    <span>Price</span>
                  </th>
                  <th className={wideScreenCellClass}>
                    <span>Status</span>
                  </th>
                  <th className={xxlCellClass}>
                    <span>Agent</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tb-odr-body">
                {dealsData?.isLoading ? <DealSkeletonLoader columns={skeletonColumnCount} /> : null}
                {dealsData?.data?.length
                  ? dealsData?.data?.map((deal, id) => (
                      <tr className="tb-odr-item" key={`user-deal-${id}`}>
                        <td className="tb-odr-info bg-white text-center">
                          <span className="tb-odr-id">
                            <Link to={`/deal-details/${deal?.id ?? deal?._id}`}>
                              {deal?.reference_number ?? 'Unknown'}
                            </Link>
                          </span>
                        </td>
                        <td className="tb-odr-info bg-white text-center">
                          <span className="tb-odr-id">
                            {deal?.creation_date &&
                              format(new Date(deal?.creation_date), 'dd/MM/yyyy')}
                          </span>
                        </td>
                        <td className="tb-odr-info bg-white text-center">
                          <span className="tb-odr-id text-wrap">{deal?.category ?? 'Unknown'}</span>
                        </td>
                        <td className="bg-white text-center d-none d-md-table-cell">
                          <span className="tb-odr-badge">
                            {deal?.category === DealCategory.PrimaryOffplan
                              ? 'For Sale'
                              : deal?.purpose}
                          </span>
                        </td>
                        <td className={xxlCellClass + ' bg-white'}>
                          <span className="tb-odr-badge fw-bold">
                            {deal?.agreed_salePrice
                              ? currencyFormatter({ isDecimal: false }).format(
                                  deal?.agreed_salePrice
                                )
                              : 'Unknown'}
                          </span>
                        </td>
                        <td className={wideScreenCellClass + ' bg-white'}>
                          <span className="tb-odr-badge">
                            {<RenderDealStatusBadge status={deal?.status ?? 'Unknown'} />}
                          </span>
                        </td>
                        <td className={xxlCellClass + ' bg-white'}>
                          <div className="">
                            {deal?.category === DealCategory.SecondaryProperty ? (
                              <RenderSecondaryAgent deal={deal} />
                            ) : (
                              <>
                                {deal?.assignedAgent
                                  ? `${deal?.assignedAgent?.user?.first_name} ${
                                      deal?.assignedAgent?.user?.last_name ?? ''
                                    }`
                                  : 'No Agent'}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
        {!dealsData?.isError && !dealsData?.isLoading && !dealsData?.data?.length ? (
          <Card className="p-4">
            <p className="text-center fs-6 fw-bold">
              No deals found for this {isAgent ? 'agent' : 'lead'}.
            </p>
          </Card>
        ) : null}
        {dealsData?.isError ? (
          <Card className="p-4">
            <p className="text-center fs-6 fw-bold text-danger">Failed to fetch Deals.</p>
          </Card>
        ) : null}
      </Block>
    </div>
  );
};

const RenderDealStatusBadge = React.memo(({ status }) => {
  const baseBadgeClass = "badge badge-sm badge-dot has-bg";
  switch (status) {
    case DealStatus.CollectingDocuments:
    case DealStatus.PendingApproval:
      return (
        <span className={`${baseBadgeClass} bg-warning text-warning`}>{status}</span>
      );
    case DealStatus.Approved:
    case DealStatus.Completed:
      return (
        <span className={`${baseBadgeClass} bg-success text-success`}>{status}</span>
      );
    case DealStatus.Rejected:
    case DealStatus.Canceled:
      return (
        <span className={`${baseBadgeClass} bg-danger text-danger`}>{status}</span>
      );
    default:
      return (
        <span className={`${baseBadgeClass} bg-primary text-primary`}>{status}</span>
      );
  }
});

const RenderSecondaryAgent = ({ deal }) => {
  const buyerAgent = deal?.buyerAssignedAgent;
  const sellerAgent = deal?.sellerAssignedAgent;

  const buyerClientType = deal?.buyer_clientType;
  const sellerClientType = deal?.seller_clientType;

  const externalBuyer = deal?.buyerExternalUser;
  const externalSeller = deal?.sellerExternalUser;

  return (
    <>
      {buyerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Buyer Agent:</span>{" "}
          <span>
            {buyerAgent?.user?.first_name ?? ""}{" "}
            {buyerAgent?.user?.last_name ?? ""}
          </span>
        </p>
      ) : buyerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Buyer Agency:</span>{" "}
          <span>
            {externalBuyer?.agency_name ??
              deal?.buyer_external_agentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Buyer Agent:</span> <span>N/A</span>
        </p>
      )}

      {sellerClientType === DealClientType.Alba ? (
        <p>
          <span className="fw-medium">Seller Agent:</span>{" "}
          <span>
            {sellerAgent?.user?.first_name ?? ""}{" "}
            {sellerAgent?.user?.last_name ?? ""}
          </span>
        </p>
      ) : sellerClientType === DealClientType.External ? (
        <p>
          <span className="fw-medium">Seller Agency:</span>{" "}
          <span>
            {externalSeller?.agency_name ??
              deal?.seller_external_agentName ??
              "External Agency"}
          </span>
        </p>
      ) : (
        <p>
          <span className="fw-medium">Seller Agent:</span> <span>N/A</span>
        </p>
      )}
    </>
  );
};

export default ProfileDealsView;
