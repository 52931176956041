const FIELDS = [
  { name: "Name", required: true },
  { name: "Phone", required: true },
  { name: "Lead Source", required: true },
  { name: "Community", required: true },
  { name: "Email", required: false },
  { name: "Agent", required: false },
  { name: "Project", required: false },
  { name: "Building Name", required: false },
  { name: "Building Number", required: false },
  { name: "Type", required: false },
  { name: "Unit Number", required: false },
  { name: "Beds", required: false },
  { name: "Baths", required: false },
  { name: "Size", required: false },
  { name: "Deal Date", required: false },
  { name: "Amount", required: false },
  { name: "Party Type", required: false },
  { name: "Notes", required: false },
  { name: "Cluster", required: false },
];

const FIELD_DATA = Object.freeze({
  Name: { label: "Name", isRequired: true },
  Email: { label: "Email", isRequired: false },
  Phone: { label: "Phone", isRequired: true },
  Agent: { label: "Agent", isRequired: false },
  LeadSource: { label: "Lead Source", isRequired: true },
  Community: { label: "Community", isRequired: true },
  Project: { label: "Project", isRequired: false },
  Cluster: { label: "Cluster", isRequired: false },
  BuildingName: { label: "Building Name", isRequired: false },
  BuildingNumber: { label: "Building Number", isRequired: false },
  Type: { label: "Type", isRequired: false },
  UnitNumber: { label: "Unit Number", isRequired: false },
  Beds: { label: "Beds", isRequired: false },
  Baths: { label: "Baths", isRequired: false },
  Size: { label: "Size", isRequired: false },
  DealDate: { label: "Deal Date", isRequired: false },
  Amount: { label: "Amount", isRequired: false },
  PartyType: { label: "Party Type", isRequired: false },
  Notes: { label: "Notes", isRequired: false },
});

const PROPERTY_LIST_FIELDS = [
  "Reference Number",
  "Property Type",
  "Community Name",
  "Cluster",
  "Building Name",
  "Size (SQFT)",
  "Beds",
  "Baths",
  "Leads",
  "Assigned Agent",
];

const PROPERTY_LIST_FIELDS_POCKETLIST = [
  "Reference Number",
  "Property Type",
  "Purpose",
  "Price",
  "Community Name",
  "Building Name",
  "Size (SQFT)",
  "Beds",
  "Baths",
  "Status",
  "Assigned Agent",
];

const CHECKOUT_LIST_FIELDS = [
  "Reference Number",
  "Property Type",
  "Community Name",
  "Building Name",
  "Unit Number",
  "Size (SQFT)",
  "Beds",
  "Baths",
  "Leads",
  "Expires After",
];

const BEDS_OPTIONS = [
  "All",
  "Studio",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7"
];

const BATHS_OPTIONS = [
  "All",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7"
];

const PURPOSE_OPTIONS = [
  'All',
  'Sell',
  'Lease'
]

const ALLOWED_PROPERTY_TYPES = [
  // 'Commercial',
  'Villa',
  'Apartment',
  'Townhouse',
  'Plot',
  'Hotel Apartment',
]
export const defaultMinPrice=10
export const defaultMaxPrice=10000000

export const DEFAULT_SIZE_RANGE = {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
};


export const PROPERTY_TYPES = Object.freeze({
  Commercial: "Commercial",
  Residential: "Residential",
  Building: "Building",
  Store: "Store",
  Townhouse: "Townhouse",
  Warehouse: "Warehouse",
  Office: "Office",
  Villa: "Villa",
  Apartment: "Apartment",
  Plot: "Plot",
  HotelApartment: "Hotel Apartment",
  Unit: "Unit",
});

const PROPERTY_TYPE_OPTIONS = Object.values(PROPERTY_TYPES).map((val) => ({
  label: val === PROPERTY_TYPES.Apartment ? 'Apartment/Flat': val,
  value: val === PROPERTY_TYPES.Apartment ? '(Apartment|Flat)': val, // DO IT THIS WAY SO IT PASSES IN REGEX
}));

const PARTY_TYPE_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Buyer", value: "Buyer" },
  { label: "Seller", value: "Seller" },
];

const LocalStorageKeys = {
  SelectedCommunities: "selectedCommunitiesFilter",
  SelectedBuildings: "selectedBuildingsFilter",
  SelectedNumberOfBeds: "selectedBedsCount",
  SelectedNumberOfBaths: "selectedBathsCount",
  SelectedPropertyType: "selectedPropertyType",
  SelectedPartyType: "selectedPartyType",
  SizeRange: "sizeRange",
  CurrentPage: "currentPage",
  // Pocket Listing filters, use different keys to not mix up with proeprty card listing page
  PLSelectedCommunities: "selectedPLCommunitiesFilter",
  PLSelectedBuildings: "selectedPLBuildingsFilter",
  PLSelectedNumberOfBeds: "selectedPLBedsCount",
  PLSelectedNumberOfBaths: "selectedPLBathsCount",
  PLSelectedPropertyType: "selectedPLPropertyType",
  PLSelectedPurpose: "selectedPLPurpose",
  PLCurrentPage: "currentPLPage",
  PLPriceRange:"priceRange"
};

export {
  FIELDS,
  FIELD_DATA,
  PROPERTY_LIST_FIELDS,
  BEDS_OPTIONS,
  BATHS_OPTIONS,
  PURPOSE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  PARTY_TYPE_OPTIONS,
  LocalStorageKeys,
  CHECKOUT_LIST_FIELDS,
  PROPERTY_LIST_FIELDS_POCKETLIST,
  ALLOWED_PROPERTY_TYPES
};
