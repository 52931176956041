import * as types from "../types";
export const fetchNotifications = (axios, id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/notifications?userId=${id}`);
    if (data) {
      dispatch({
        type: types.GET_NOTIFICATIONS,
        payload: data.data?.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
