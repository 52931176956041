import React, { createContext, useContext, useState } from 'react';

export const ConvertDealContext = createContext();

export const ConvertDealProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    
    return (
      <ConvertDealContext.Provider value={{ isOpen, setIsOpen, toggle }}>
        {children}
      </ConvertDealContext.Provider>
    )
}

export const useConvertDealModal = () => useContext(ConvertDealContext);
