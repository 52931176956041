import { newRoles } from '../../utils/envConfig';

const menu = [
    {
        heading: 'Alba Homes'
    },
    {
        icon: 'dashboard-fill',
        text: 'Dashboard',
        link: '/',
        roles: '*'
    },
    {
        icon: 'sign-chf-alt',
        text: 'Live Feed',
        link: '/live-feed',
        roles: [
            newRoles.Admin,
            newRoles.MasterAdmin,
            newRoles.Agent,
            newRoles.OfficeAdmin,
            newRoles.SalesManager
        ]
    },

    {
        heading: 'menus'
    },
    {
        icon: 'user-fill',
        text: 'Clients',
        active: false,
        subMenu: [
            {
                text: 'List All Users',
                link: '/user-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'List Landlords',
                link: '/landlord-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },

    {
        icon: 'tile-thumb-fill',
        text: 'Agents',
        active: false,
        subMenu: [
            {
                text: 'List Agents',
                link: '/agent-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'bulb-fill',
        text: 'War Front',
        active: false,
        subMenu: [
            {
                text: 'Agent Reports',
                link: '/agent-reports',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'users-fill',
        text: 'Master Communities',
        active: false,
        subMenu: [
            {
                text: 'All Master Communities',
                link: '/list-community-teams',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                ]
            }
        ]
    },
    {
        icon: 'user-add-fill',
        text: 'Agencies',
        active: false,
        subMenu: [
            {
                text: 'Add agency',
                link: '/add-agency',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },

    {
        icon: 'cc-alt2-fill',
        text: 'Leads',
        active: false,
        subMenu: [
            // {
            //     text: 'Leads Board',
            //     link: '/statusBoard',
            //     roles: [
            //         newRoles.Admin,
            //         newRoles.MasterAdmin,
            //         newRoles.OfficeAdmin,
            //         newRoles.SalesManager,
            //         newRoles.Agent
            //     ]
            // },
            {
                text: 'Leads List',
                link: '/LeadsLists',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent,
                    newRoles.AnonymousAgent
                ]
            },
            {
                text: 'Agent War Front',
                link: '/war-front',
                roles: [
                    newRoles.Agent,
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                ]
            },
            {
                text: 'Create Lead',
                link: '/CreateLead',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent,
                    newRoles.AnonymousAgent
                ]
            },
            {
                text: 'Upload Leads',
                link: '/upload-leads',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
            ,
            {
                text: 'Lead Sources',
                link: '/lead-sources',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },

    {
        icon: 'cc-alt2-fill',
        text: 'DND Numbers',
        active: false,
        subMenu: [
            {
                text: 'DND Numbers List',
                link: '/dnd-numbers',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Upload DND Numbers',
                link: '/upload-dnd-numbers',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'search',
        text: 'Explorer',
        active: false,
        subMenu: [
            {
                text: 'Import Data',
                link: '/importData',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Explore Property Data',
                link: '/list-property-cards',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent
                ]
            },
            {
                text: 'Explore Landlord Leads',
                link: '/list-explorer-leads',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent
                ]
            },
            {
                text: 'Expired Hot Leads',
                link: '/expired-hot-leads',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent
                ]
            },
            {
                text: 'My Assigned Properties',
                link: '/list-checked-out',
                roles: [newRoles.Agent,newRoles.AnonymousAgent]
            }
        ]
    },
    {
        icon: 'cc-alt-fill',
        text: 'Pocket Listing',
        active: false,
        subMenu: [
            {
                text: 'View Pocket Listings',
                link: '/list-pocket-listing',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent,
                    newRoles.AnonymousAgent
                ]
            },
            {
                text: 'Create a Pocket Listing',
                link: '/add-pocket-listing',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent,
                    newRoles.AnonymousAgent
                ]
            }
        ]
    },

    {
        icon: 'property',
        text: 'Property Listing',
        active: false,
        subMenu: [
            {
                text: 'List Properties',
                link: '/property-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent,
                    newRoles.SEO
                ]
            },
            {
                text: 'Add Property',
                link: '/add-property',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },

    {
        icon: 'package-fill',
        text: 'Property Settings',
        active: false,
        subMenu: [
            {
                text: 'Categories',
                link: '/category-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Property Types',
                link: '/propType-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Amenity Categories',
                link: '/amenityCategory-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Amenities',
                link: '/amenity-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Places',
                link: '/community-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.SEO
                ]
            },
            {
                text: 'Building',
                link: '/building-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },

    {
        icon: 'file-docs',
        text: 'Deals',
        active: false,
        subMenu: [
            {
                text: 'List Deals',
                link: '/list-deals',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent
                ]
            },
            {
                text: 'Add Deal',
                link: '/add-deal',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.Agent
                ]
            }
        ]
    },
    {
        icon: 'wallet',
        text: 'Finances',
        active: false,
        subMenu: [
            {
                text: 'Earnings',
                link: '/finances',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                ]
            },
             {
                text: 'Revenue',
                link: '/revenue',
                roles: [
                    newRoles.MasterAdmin,
                ]
            },
            {
                text: 'Transactions',
                link: '/deal-transactions',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                ]
            },
            {
                text: 'Deals Board',
                link: '/deals-kanban',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    // newRoles.Agent,
                ]
            },
        ]
    },
    {
        icon: 'cc-new',
        text: 'Off Plans',
        active: false,
        subMenu: [
            {
                text: 'List Off Plans',
                link: '/off-plans-list',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.SEO
                ]
            },
            {
                text: 'Add Off Plan',
                link: '/add-off-plan',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Footer Templates',
                link: '/footer-templates',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'All Developers',
                link: '/all-developers',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'file-docs',
        text: 'Reports',
        active: false,
        subMenu: [
            {
                text: 'Agent Dashboard',
                link: '/agent-dashboard',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Marketing Dashboard',
                link: '/marketing-dashboard',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Deals Closed Analysis',
                link: '/deals-closed-analysis',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Listing Performance',
                link: '/listing-performance',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            },
            {
                text: 'Off-plan Marketing',
                link: '/offplan-marketing-dashboard',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'call-alt-fill',
        text: 'PBX',
        active: false,
        subMenu: [
            {
                text: 'Call Report List',
                link: '/pbx-report-list',
                roles: [
                    
                ]
            }
        ]
    },
    {
        icon: 'article',
        text: 'Blogs',
        active: false,
        subMenu: [
            {
                text: 'List Blogs',
                link: '/list-blogs',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager,
                    newRoles.SEO
                ]
            }
        ]
    },
    {
        icon: 'users-fill',
        text: 'Staff',
        active: false,
        subMenu: [
            {
                text: 'List All Staff',
                link: '/list-staff',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: 'users-fill',
        text: 'Agent Vacation info',
        active: false,
        subMenu: [
            {
                text: 'Vacation',
                link: '/agent-vacation',
                roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
            }
        ]
    },
    {
        icon: '',
        text: 'Settings',
        link: '/settings',
        display: false,
        roles: [
                    newRoles.Admin,
                    newRoles.MasterAdmin,
                    newRoles.OfficeAdmin,
                    newRoles.SalesManager
                ]
    },
    {
        icon: '',
        text: 'Change Password',
        link: '/change-password',
        display: false,
        roles: '*'
    },
    {
        icon: 'signout',
        text: 'Logout',
        link: '',
        logout: true,
        roles: '*'
    }
];

export function generateMenu(menu = [], userRole) {
    const items = menu?.filter(item => item?.display !== false)?.map((item) => {
        if (item?.link) {
            if (
                item?.roles &&
                (item?.roles === '*' || item?.roles?.includes(userRole))
            ) {
                return item;
            } else {
                return false;
            }
        }

        if (item?.subMenu) {
            const subMenu = generateMenu(item?.subMenu, userRole);

            const hasSub = subMenu?.filter((item) => item)?.length > 0;

            if (hasSub) {
                return {
                    ...item,
                    subMenu: subMenu?.filter((item) => item)
                };
            } else {
                return false;
            }
        }

        return item;
    });

    return items?.filter((item) => item);
}

export function getAllRoutes() {
    const routes = menu
        ?.map((item) => {
            if (item?.link) {
                return item;
            }
            if (item?.subMenu) {
                return item?.subMenu;
            }
            return false;
        })
        ?.filter((item) => item)
        ?.flat();
    return routes;
}

export function getRoute(route = '') {
    const allRoutes = getAllRoutes();
    return allRoutes?.find((item) => item?.link === route);
}

export default menu;
