/* eslint-disable jsx-a11y/anchor-is-valid */
import { BriefcaseBusiness, TableProperties, UserCog } from "lucide-react";
import NavigationPanel from "./NavigationPanel";
import HeaderCard from "./UserSettingsCard";
import { NavMenuNames } from "./constants";

const SideBar = ({ setCurrentSettings, currentSettings }) => {
  const navLinks = [
    { name: NavMenuNames.Explorer, anchor: "explorer", logo: <TableProperties size={16} className="text-muted" />, iconName: "property-alt", title: "Explorer Settings" },
    { name: NavMenuNames.Leads, anchor: "leads", logo: <UserCog size={16} className="text-muted" />, iconName: "property-alt", title: "Leads Settings" },
    { name: NavMenuNames.Operations, anchor: "operations", logo: <BriefcaseBusiness size={16} className="text-muted" />, iconName: "property-alt", title: "Operations Settings" },
  ];

  return (
    <div
      className="card-aside card-aside-left user-aside tiggle-slide d-none d-lg-block toggle-slide-left toggle-break-lg toggle-screen-lg"
      data-toggle-body="true"
      data-content="userAside"
      data-toggle-screen="lg"
      data-toggle-overlay="true"
    >
      <div className="card-inner-group" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: "0px" }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: "0px", bottom: "0px" }}
            >
              <div
                className="simplebar-content-wrapper"
                tabIndex={"0"}
                role="region"
                aria-label="scrollable content"
                style={{ height: "auto", overflow: "hidden" }}
              >
                <div className="simplebar-content" style={{ padding: "0px" }}>
                  {/* LEFT SECTION CONTENT */}
                  <HeaderCard />
                  <NavigationPanel navLinks={navLinks} setCurrentSettings={setCurrentSettings} currentSettings={currentSettings} />
                  {/* END OF LEFT SECTION CONTENT */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: "auto", height: "451px" }}
          ></div>
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: "hidden" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: "0px", display: "none" }}
          ></div>
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: "hidden" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ height: "0px", display: "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
