import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { fetchBuildings } from '../../../redux/actions/property';
import { RSelect } from '../../../components/Component';
import { loggedInAgentSelector } from '../../../redux/selectors/user';
import { FixedSizeList as List } from 'react-window';

const formatBuildingName = (building, formatString = '') => {
  const replacement = {
    $COMMUNITY: building?.community?.community ?? '',
    $BUILDING: building?.name ?? ''
  };

  return formatString
    .replace(/\$COMMUNITY/g, replacement['$COMMUNITY'])
    .replace(/\$BUILDING/g, replacement['$BUILDING']);
};

// The height of each option in pixels
const OPTION_HEIGHT = 35;
const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const itemCount = children?.length ?? 0;
  const initialOffset = options?.indexOf(value) * OPTION_HEIGHT;
  const height = Math.min(maxHeight ?? 0, itemCount * OPTION_HEIGHT);

  return (
    <List
      height={height}
      itemCount={itemCount}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => (
        <div style={style}>{children[index]}</div>
      )}
    </List>
  );
};
const BuildingDropdown = ({

  multiSelect,
  onChangeCallback,
  isDisabled,
  selected,
  selectedCommunities,
  axiosInstance,
  dispatch,
  wide,
  labelFormatString,
  unstyled = false,
  ...props
}) => {
  const { buildings, buildingsLoading } = useSelector((state) => ({
    buildings: state.property?.buildings,
    buildingsLoading: state.property?.buildingsLoading
  }));

  const [buildingFilterOptions, setBuildingFilterOptions] = useState([]);

  const authAgent = useSelector(loggedInAgentSelector);

  useEffect(() => {
    const fetchAndSetBuildings = async () => {
      if (!buildings?.length) {
        await dispatch(fetchBuildings(axiosInstance, {}, { isAgent: !!authAgent }));
        // Exit early as buildings will be empty, and we'll re-run on next render
        return;
      }

      let buildingSet = [];
      if (!selectedCommunities?.length && buildings?.length) {
        buildingSet = buildings;
      } else {
        const selectedCommunitiesObjs = 'communities' in (selectedCommunities[0] ?? {}) ? selectedCommunities?.map(option => option.communities)?.flat() ?? [] : selectedCommunities;
        const selectedCommunityIds = selectedCommunitiesObjs?.map(
          community => community?.id ?? community?._id
        );

        buildingSet =
          buildings?.filter(
            (building) =>
              building?.communityId != null &&
              selectedCommunityIds?.includes(building?.communityId)
          ) ?? [];
      }

      setBuildingFilterOptions(
        Object.values(buildingSet).map((item) => ({
          ...item,
          label: labelFormatString
            ? formatBuildingName(item, labelFormatString)
            : `${item?.name} - ${item?.community?.community}`,
          value: item?.id
        }))
      );
    };

    fetchAndSetBuildings();
  }, [axiosInstance, buildings, dispatch, selectedCommunities, labelFormatString, authAgent]);

  const noOptionsMessage = ({ inputValue }) => {
    if (selectedCommunities?.length) {
        return 'No buildings were found in these communities'
    }
    return inputValue ? `No building found matching the search: ${inputValue}` : 'No buildings found'
  }

  return (
    <RSelect
      placeholder="Building"
      isDisabled={isDisabled}
      className={unstyled ? '' : `status-div ${wide ? 'building-width-custom' : ''}`}
      isMulti={multiSelect}
      isLoading={buildingsLoading}
      loadingMessage={() => <Spinner />}
      value={selected}
      options={buildingFilterOptions}
      components={buildingFilterOptions?.length ? { MenuList } : undefined}
      onChange={(selected) => onChangeCallback(selected)}
      noOptionsMessage={noOptionsMessage}
      {...props}
    />
  );
};

/**
 * BuildingFilter component for selecting buildings.
 *
 * @param {boolean} props.multiSelect - If true, multiple selections are allowed.
 * @param {function} props.onChangeCallback - Callback function when selection changes.
 * @param {Array} props.selected - The currently selected buildings.
 * @param {Array} props.selectedCommunities - The selected communities to filter buildings.
 * @param {Object} props.axiosInstance - Axios instance for making requests.
 * @param {function} props.dispatch - Redux dispatch function.
 * @param {boolean} props.wide - If true, applies wide styling to the dropdown.
 * @param {string} props.labelFormatString - Format string for building labels, uses $COMMUNITY and $BUILDING for fromatting.
 * @returns {JSX.Element} The BuildingDropdown component.
 */
const BuildingFilter = React.memo(BuildingDropdown);
export default BuildingFilter;
