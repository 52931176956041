import {
  Button,
  Col,
  Collapse,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { Icon, PreviewCard, RSelect } from '../../../components/Component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BathRooms,
  BedRooms,
  ContractValidity,
  DealType,
  FurnishingTypes,
  VacancyStates,
  formatValue,
  newListingType,
  newRoles,
  propertyTypesExcludeBedsBaths,
  propertyTypesExcludeBuilding,
  validateEmail
} from '../../../utils/envConfig';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch } from 'react-redux';
import {
  fetchAmenitiesList,
  fetchBuildings,
  fetchCommunitiesList,
  fetchPropTypesList
} from '../../../redux/actions/property';
import SearchClientSelect from '../../../components/SearchClientSelect';
import { useConfirm } from '../../../hooks/useConfirm';
import ConfirmModal from './ConfirmModal';
import AddLeadStepOne from '../../leads/components/AddLeadStepOne';
import RenderAmenitiesModal from './RenderAmenitiesModal';
import PropTypesDropdown from '../../components/filter-components/PropTypesFilter';

const ListingAcquired = ({ dealType = null, users = [], next = () => {} }) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();

  const { cancel, isConfirmed, isOpen, proceed, prompt } = useConfirm();

  const { propertyTypes, propTypesLoading } = useSelector(
      (state) => state?.property
  );

  const agents = useSelector((state) => state?.user?.agents);

  const bedsOptions = BedRooms?.map((room) => ({ value: room, label: room }));

  const bathOptions = BathRooms?.map((room) => ({
      value: room,
      label: room
  }));

  const communities = useSelector((state) => state?.property?.communities);
  const buildings = useSelector((state) => state?.property?.buildings);
  const amenities = useSelector((state) => state?.property?.amenities);

  const communityOptions =
      communities && communities?.length
          ? communities?.map((comm) => ({
                ...comm,
                value: comm?.community,
                label: comm?.community
            }))
          : [];

  const buildingOptions =
      buildings && buildings?.length
          ? buildings?.map((building) => ({
                ...building,
                value: building?.name,
                label: building?.name
            }))
          : [];
  
  const amenitiesOptions =
      amenities && amenities?.length
          ? amenities?.map((amenity) => ({
              ...amenity,
              value: amenity?.id,
              label: amenity?.amenity
          }))
        : [];

  const listingTypeOptions = Object.values(newListingType)?.map((item) => ({
      value: item,
      label: item === 'Buy' ? 'For Sale' : item === 'Rent' ? 'For Rent' : item
  }));

  const [openAddClient, setopenAddClient] = useState(false);

  const [isOffPlanResale, setIsOffPlanResale] = useState(false);

  const [errors, setErrors] = useState({});

  const [dealInfo, setDealInfo] = useState({
      client: null,
      propertyType: null,
      beds: null,
      baths: null,
      size: '',
      community: null,
      subCommunity: '',
      listingPrice: '',
      building: null,
      listingType: null,
      agreedCommission: '',
      agreedCommissionValue: '',
      agent: null,
      amenities: [],
      contractValidity: null,
      contractA: '',
      exclusive: false,
      furnishing: null,
      numberOfCheques: null,
      permitNo: '',
      vacancy: null,
      vacantOnTransfer: false,
      isOffPlanResale: null,
      amountAlreadyPaid: null,
      relatedInfo: null,
  });


  const [loading, setLoading] = useState(false);

  const [newClientDetails, setNewClientDetails] = useState(null);

  const [assignedAgent, setAssignedAgent] = useState(null);
  const [assigning, setAssigning] = useState(null);

  const [checkPropertyType, setCheckPropertyType] = useState(null);

  const isExcludedPropertyType = Object.values(propertyTypesExcludeBedsBaths).includes(checkPropertyType);
  const buildingOptional = Object.values(propertyTypesExcludeBuilding).includes(checkPropertyType);

  useEffect(() => {
    const newErrors = {};
  
    if (isExcludedPropertyType) {
      newErrors.beds = null;
      newErrors.baths = null;
    } else if (checkPropertyType !== null) {
      if (!dealInfo.beds) {
        newErrors.beds = "Select number of bedrooms";
      }
      if (!dealInfo.baths) {
        newErrors.baths = "Select number of bathrooms";
      }
    }

    if (buildingOptional) {
      newErrors.building = null;
    }
  
    setErrors((prev) => ({
      ...prev,
      ...newErrors,
    }));
  }, [checkPropertyType]);

  const validateNewClientDetails = () => {
      const errors = {};

      if (!newClientDetails?.firstName) {
          errors.firstName = 'First name is required';
      }

      if (!newClientDetails?.lastName) {
          errors.lastName = 'Last name is required';
      }

      if (!validateEmail(newClientDetails?.email)) {
          errors.email = 'Invalid email address';
      }

      if (!newClientDetails?.email) {
          errors.email = 'Email is required';
      }

      if (
          !newClientDetails?.selectedAgent ||
          (!newClientDetails?.selectedAgent?.id &&
              !newClientDetails?.selectedAgent?._id)
      ) {
          errors.newClientDetailsSelectedAgent = 'Please select an agent';
      }

      return Object.keys(errors).length ? errors : null;
  };

  const addNewClient = async (e) => {
      // e.preventDefault();
      const invalidData = validateNewClientDetails();

      if (invalidData) {
          setErrors(invalidData);
          return;
      }

      try {
          const params = {
              first_name: newClientDetails?.firstName,
              last_name: newClientDetails?.lastName,
              email: newClientDetails?.email,
              phone: newClientDetails?.phone,
              role: newRoles.User
          };

          const { data } = await axios.post('/users', params);

          const userAgentParams = {
              agent_id:
                  newClientDetails?.selectedAgent?.id ??
                  newClientDetails?.selectedAgent?._id,
              user_id: data?.id ?? data?._id
          };

          const { data: assignedagent } = await axios.post(
              '/usersAgents',
              userAgentParams
          );

          const { data: updatedUser } = await axios.get(
              `/users/${data?.id ?? data?._id}`
          );

          setDealInfo((prev) => ({
              ...prev,
              client: {
                  ...updatedUser,
                  value: `${updatedUser?.first_name} ${updatedUser?.last_name}`,
                  label: `${updatedUser?.first_name} ${updatedUser?.last_name}`
              },
              agent: {
                  ...assignedagent?.agent,
                  label: `${assignedagent?.agent?.user?.first_name ?? ''} ${
                      assignedagent?.agent?.user?.last_name ?? ''
                  }`,
                  value: `${assignedagent?.agent?.user?.first_name ?? ''} ${
                      assignedagent?.agent?.user?.last_name ?? ''
                  }`
              }
          }));

          setAssignedAgent({
              ...assignedagent?.agent,
              label: `${assignedagent?.agent?.user?.first_name ?? ''} ${
                  assignedagent?.agent?.user?.last_name ?? ''
              }`,
              value: `${assignedagent?.agent?.user?.first_name ?? ''} ${
                  assignedagent?.agent?.user?.last_name ?? ''
              }`
          });

          setErrors((prev) => ({
              ...prev,
              agent: null,
              client: null
          }));

          setopenAddClient(false);
      } catch (err) {
          console.log(err);
      }
  };

  useEffect(() => {
      !communities?.length && dispatch(fetchCommunitiesList(axios));
      !amenities?.length && dispatch(fetchAmenitiesList(axios));

      !propertyTypes.length &&
          !propTypesLoading &&
          dispatch(fetchPropTypesList(axios));
  }, [amenities?.length, axios, communities?.length, dispatch, propTypesLoading, propertyTypes.length]);

  useEffect(() => {
      if (!dealInfo?.community?.id) return;
      if (dealInfo.building) {
          dealInfo.building = null;
      }
      dispatch(
          fetchBuildings(axios, { communityId: dealInfo?.community?.id })
      );
  }, [dealInfo?.community?.id]);
  
  const toggleModalAddClient = () => {
      setopenAddClient(!openAddClient);
  };

  const handleAssignAgent = async () => {
      try {
          // handle assigning client to agent
          const userAgentParams = {
              agent_id: dealInfo?.agent?.id ?? dealInfo?.agent?._id,
              user_id: dealInfo?.client?.id ?? dealInfo?.client?._id
          };

          const { data: assignedagent } = await axios.post(
              '/usersAgents',
              userAgentParams
          );

          return assignedAgent;
      } catch (err) {
          console.log(err);
          throw err;
      }
  };

  const createNewListingProperty = async () => {
      try {
          const propertyDetails = {
              property_type_id:
                  dealInfo?.propertyType?.id ?? dealInfo?.propertyType?._id,
              beds: dealInfo?.beds?.value,
              baths: dealInfo?.baths?.value,
              size: dealInfo?.size,
              community_id:
                  dealInfo?.community?.id ?? dealInfo?.community?._id,
              price: +dealInfo?.listingPrice?.trim()?.replaceAll(',', ''),
              subCommunity: dealInfo?.subCommunity,
              building_id: dealInfo?.building?.id ?? dealInfo?.building?._id,
              building_name: dealInfo?.building?.name,
              user_id: dealInfo?.client?.id ?? dealInfo?.client?._id,
              full_name: `${dealInfo?.client?.first_name} ${dealInfo?.client?.last_name}`,
              email: dealInfo?.client?.email,
              phone: dealInfo?.client?.phone,
              type: dealInfo?.listingType?.value,
              multiple: false,
              contractValidity: dealInfo?.contractValidity?.value ?? null,
              furnishing: dealInfo?.furnishing?.value ?? null,
              numberOfCheques: dealInfo?.numberOfCheques,
              exclusive: dealInfo?.exclusive ?? false,
              permitNo: dealInfo?.permitNo ?? null,
              vacantOnTransfer: dealInfo?.vacantOnTransfer ?? false,
              vacancy: dealInfo?.vacancy?.value ?? null,
              contractA: dealInfo?.contractA ?? null,
              amenities: dealInfo?.amenities.map(x => x?.value) ?? [],
              isOffPlanResale: dealInfo?.isOffPlanResale.value === 'Yes',
              amountAlreadyPaid: dealInfo?.amountAlreadyPaid ?? 0,
              relatedInfo: dealInfo?.relatedInfo ?? null,
          };
          const { data: offplanListing } = await axios.post(
              '/propList/request',
              propertyDetails
          );
          return offplanListing;
      } catch (err) {
          console.log(err);
          return null;
      }
  };

  const validateDealData = () => {
      const errors = {};

      if (
          !dealInfo.client ||
          (!dealInfo?.client?.id && !dealInfo?.client?._id)
      ) {
          errors.client = 'Select a valid client';
      }

      if (
          !dealInfo.agent ||
          (!dealInfo?.agent?.id && !dealInfo?.agent?._id)
      ) {
          errors.agent = 'Select a valid agent';
      }

      return Object.keys(errors)?.length > 0 ? errors : null;
  };

  const validatePropertyData = () => {
      const errors = {};

      if (
          !dealInfo?.listingType ||
          !Object.values(newListingType).includes(
              dealInfo?.listingType?.value
          ) ||
          !dealInfo?.listingType?.value
      ) {
          errors.listingType =
              !dealInfo?.listingType || !dealInfo?.listingType?.value
                  ? 'This field is required'
                  : 'Invalid listing type';
      }

      if (
          !dealInfo.propertyType ||
          (!dealInfo?.propertyType?.id && !dealInfo?.propertyType?._id)
      ) {
          errors.propertyType = 'Select property type';
      }


      // if dealInfos.size is not a number or is less than 1, we throw error
      if (
          !dealInfo.size ||
          isNaN(+dealInfo.size) ||
          +dealInfo.size <= 0 /*&& dealInfo.size?.length > 0*/ ||
          dealInfo?.size?.length > 10
      ) {
          errors.size = !dealInfo?.size
              ? 'Property size is required'
              : dealInfo.size <= 0
              ? 'Size cannot be less than 1'
              : 'invalid property size';
      }

      if (!dealInfo?.contractValidity || !dealInfo?.contractValidity?.value?.length) {
          errors.contractValidity = 'Contract Validity is required';
      }

      if (!dealInfo?.furnishing || !dealInfo?.furnishing?.value?.length) {
          errors.furnishing = 'Furnishing type is required';
      }

      if (!dealInfo?.vacancy || !dealInfo?.vacancy?.value?.length) {
          errors.vacancy = 'Vacancy is required';
      }

      if (!dealInfo?.amenities || !dealInfo?.amenities?.length) {
          errors.amenities = 'Amenities are required';
      }
      if (!isExcludedPropertyType && (dealInfo.beds === '' || dealInfo.beds === null)) {
        errors.beds = "Select number of bedrooms";
      }
      if (!isExcludedPropertyType && (dealInfo.baths === '' || dealInfo.baths === null)) {
        errors.baths = "Select number of bathrooms";
      }      
      if (!buildingOptional && 
        (!dealInfo?.building ||
        (!dealInfo?.building?.id && !dealInfo?.building?._id))
      ) {
          errors.building = 'Select building';
      }

      // eslint-disable-next-line eqeqeq
      if (dealInfo?.isOffPlanResale == undefined) {
        errors.isOffPlanResale = 'This field is required';
      }

      // eslint-disable-next-line eqeqeq
      if (dealInfo?.isOffPlanResale != undefined && isOffPlanResale && !dealInfo?.amountAlreadyPaid) {
        errors.amountAlreadyPaid = 'This amount is required';
      }

      const listingPrice = Number(
          dealInfo.listingPrice?.trim()?.replaceAll(',', '')
      );

      if (
          !listingPrice ||
          isNaN(listingPrice) ||
          listingPrice > Number.MAX_SAFE_INTEGER ||
          listingPrice <= 0
      ) {
          errors.listingPrice = isNaN(listingPrice)
              ? 'Price must be a number'
              : listingPrice > Number.MAX_SAFE_INTEGER
              ? 'Price is too large'
              : listingPrice <= 0
              ? 'Price cannot be less than 1'
              : 'Listing price is required';
      }

      const agreedCommission = Number(dealInfo?.agreedCommission?.trim());

      if (
          agreedCommission > 100 ||
          isNaN(Number(dealInfo?.agreedCommission))
      ) {
          errors.agreedCommission = isNaN(Number(dealInfo?.agreedCommission))
              ? 'Must be a valid number'
              : 'Cannot be more than 100%';
      }

      const agreedCommissionValue = Number(
          dealInfo?.agreedCommissionValue?.trim()?.replaceAll(',', '')
      );

      if (
          agreedCommissionValue > listingPrice ||
          agreedCommissionValue > Number.MAX_SAFE_INTEGER ||
          isNaN(agreedCommissionValue) ||
          agreedCommissionValue < 0
      ) {
          errors.agreedCommissionValue =
              agreedCommissionValue > Number.MAX_SAFE_INTEGER
                  ? 'Value too large'
                  : isNaN(agreedCommissionValue)
                  ? 'Must be a number'
                  : agreedCommissionValue < 0
                  ? 'Cannot be less than 0'
                  : 'Cannot be more than listing price';
      }

      if (
          !dealInfo?.community ||
          (!dealInfo?.community?.id && !dealInfo?.community?._id)
      ) {
          errors.community = 'Select community';
      }

      return Object.keys(errors).length > 0 ? errors : null;
  };

  const addNewDeal = async () => {
      try {
          // validate inputs
          const invalidDealData = validateDealData();

          const invalidPropertyData = validatePropertyData();

          if (invalidDealData || invalidPropertyData) {
              setErrors((prev) => ({
                  ...prev,
                  ...invalidDealData,
                  ...invalidPropertyData
              }));

              return;
          }

          // we want to confirm if assignedAgent?.id(selectedAgent) is the same as selectedClient's assigned agent (dealInfo?.agent?.id):
          // if above is true, then we proceed else we want the user to assign selected client to selected agent or cancel deal creation.

          if (dealInfo?.agent?.id !== assignedAgent?.id) {
              // let user to assign client to agent
              const isConfirm = await isConfirmed(
                  <>
                      <h6>
                          <b>
                              Selected Client not assigned to selected agent.
                          </b>
                      </h6>
                      <div>
                          By clicking <b>Assign</b>, you accept to assign this
                          client to selected agent.
                      </div>
                  </>
              );

              if (!isConfirm) {
                  return;
              }

              const assignedAgent = await handleAssignAgent();

              setAssignedAgent({
                  ...dealInfo?.agent
              });
          }

          setLoading(true);

          const newListingOffplan = await createNewListingProperty();

          if (!newListingOffplan) {
              console.log('Error creating property');
              return;
          }

          const dealData = {
              category: dealType,
              type: DealType.Listing,
              user_id: dealInfo?.client?.id ?? dealInfo?.client?._id,
              new_listing_request_id:
                  newListingOffplan?.id ?? newListingOffplan?._id,
              agreedSalePrice: +dealInfo?.listingPrice
                  ?.trim()
                  ?.replaceAll(',', ''),
              agreedCommission: +dealInfo?.agreedCommission,
              assignedAgent: dealInfo?.agent?.id ?? dealInfo?.agent?._id
          };

          const { data: deal } = await axios.post('/deals', dealData);

          // save deal id to localStorage to refetch data when we progress deal
          localStorage.setItem('new_deal', JSON.stringify(deal));
          // call next function to move to next step
          next();
      } catch (err) {
          console.log(err);
      } finally {
          setLoading(false);
      }
  };
  
   const [openAmenitiesModal, setopenAmenitiesModal] = useState(false);
   return (
     <PreviewCard className="bg-lighter">
       <Row className="gy-2">
         <Label>Choose Client *</Label>
         <Col sm="10">
           <SearchClientSelect
             placeholder="Search clients by name or phone"
             value={dealInfo?.client}
             onChange={(client) => {
               const assignedAgent =
                 client?.currentAgent && client?.currentAgent?.id
                   ? {
                       ...client?.currentAgent,
                       label: `${
                         client?.currentAgent?.userId?.first_name ?? ""
                       } ${client?.currentAgent?.userId?.last_name ?? ""}`,
                       value: `${
                         client?.currentAgent?.userId?.first_name ?? ""
                       } ${client?.currentAgent?.userId?.last_name ?? ""}`,
                     }
                   : null;

               setDealInfo((prev) => ({
                 ...prev,
                 client,
                 agent: assignedAgent,
               }));

               setAssignedAgent(assignedAgent);

               setErrors((prev) => ({
                 ...prev,
                 client: null,
                 agent: null,
               }));
             }}
             className={errors?.client && "border border-danger"}
           />

           {errors?.client && (
             <small className="text-danger ff-italic">{errors?.client}</small>
           )}
         </Col>
         <Col>
           <Button
             onClick={(ev) => {
               ev.preventDefault();
               toggleModalAddClient();
             }}
           >
             <Icon name="plus"></Icon>
           </Button>
         </Col>

         {dealInfo?.client && (
           <>
             <Label>Assigned Agent *</Label>
             <Col sm="10">
               <RSelect
                 placeholder="Select Agent"
                 value={dealInfo?.agent}
                 isDisabled={dealInfo?.agent && dealInfo?.agent?.id === assignedAgent?.id}
                 options={
                    agents &&
                      agents?.length &&
                      agents?.map((agent) => ({
                        ...agent,
                        label: `${agent?.user?.first_name} ${agent?.user?.last_name}`,
                    }))
                 }
                 onChange={(agent) => {
                   setDealInfo((prev) => ({ ...prev, agent }));
                   setErrors((prev) => ({
                     ...prev,
                     agent: null,
                   }));
                 }}
                 className={errors?.client && "border border-danger"}
               />

               {errors?.agent && (
                 <small className="text-danger ff-italic">
                   {errors?.agent}
                 </small>
               )}
             </Col>
             {dealInfo?.agent?.id !== assignedAgent?.id && (
               <Col>
                 <Button
                   onClick={async (ev) => {
                     try {
                       ev.preventDefault();

                       setAssigning(true);

                       const assignedAgent = await handleAssignAgent();

                       setAssignedAgent({
                         ...dealInfo?.agent,
                       });
                       // toggleModalAssignAgent();
                     } catch (err) {
                       console.log(err);
                     } finally {
                       setAssigning(false);
                     }
                   }}
                 >
                   {assigning ? (
                     <>
                       <Spinner /> Please Wait
                     </>
                   ) : (
                     "Assign"
                   )}
                 </Button>
               </Col>
             )}
           </>
         )}

         <Col sm="12" className="bg-secondary mt-4 mb-3 pt-2 pb-2 rounded">
           <h6 className="text-white">Property Details</h6>{" "}
         </Col>
         <Col sm="11">
           <Label>Type of Property *</Label>
           <PropTypesDropdown
             axiosInstance={axios}
             dispatch={dispatch}
             onChangeCallback={(propertyType) => {
              setCheckPropertyType(propertyType.value);
               setDealInfo((prev) => ({
                 ...prev,
                 propertyType,
               }));
               setErrors((prev) => ({
                 ...prev,
                 propertyType: null,
               }));
             }}
             selected={dealInfo?.propertyType}
             className=""
             placeholder="Type of Property"
           />
           {errors?.propertyType && (
             <small className="ff-italic text-danger">
               {errors?.propertyType}
             </small>
           )}
         </Col>

         <Col sm="11">
           <Label>Choose Listing Type *</Label>
           <RSelect
             options={listingTypeOptions}
             placeholder="Choose Listing Type"
             value={dealInfo?.listingType}
             onChange={(listingType) => {
               setDealInfo((prev) => ({ ...prev, listingType }));
               setErrors((prev) => ({
                 ...prev,
                 listingType: null,
               }));
             }}
             className={errors?.listingType && "border border-danger"}
           />
           {errors?.listingType && (
             <small className="text-danger ff-italic">
               {errors?.listingType}
             </small>
           )}
         </Col>
         <Col sm="6">
           <Label>Duration of Contract *</Label>
           <RSelect
             options={Object.values(ContractValidity).map((x) => ({
               label: x,
               value: x,
             }))}
             placeholder="Choose Contract Validity"
             value={dealInfo?.contractValidity}
             onChange={(contractValidity) => {
               setDealInfo((prev) => ({
                 ...prev,
                 contractValidity: contractValidity,
               }));
               setErrors((prev) => ({
                 ...prev,
                 contractValidity: null,
               }));
             }}
             className={errors?.contractValidity && "border border-danger"}
           />
           {errors?.contractValidity && (
             <small className="text-danger ff-italic">
               {errors?.contractValidity}
             </small>
           )}
         </Col>
         <Col sm="5">
           <Label>Furnishing *</Label>
           <RSelect
             options={Object.values(FurnishingTypes).map((x) => ({
               label: x,
               value: x,
             }))}
             placeholder="Choose Furnishing Type"
             value={dealInfo?.furnishing}
             onChange={(furnishing) => {
               setDealInfo((prev) => ({ ...prev, furnishing: furnishing }));
               setErrors((prev) => ({
                 ...prev,
                 furnishing: null,
               }));
             }}
             className={errors?.furnishing && "border border-danger"}
           />
           {errors?.furnishing && (
             <small className="text-danger ff-italic">
               {errors?.furnishing}
             </small>
           )}
         </Col>

         <Col sm="11">
           <Label>Beds {!isExcludedPropertyType && '*'}</Label>
           <RSelect
             options={bedsOptions}
             placeholder="Beds"
             value={dealInfo?.beds}
             onChange={(beds) => {
               setDealInfo((prev) => ({ ...prev, beds }));
               setErrors((prev) => ({ ...prev, beds: null }));
             }}
             className={errors?.beds && "border border-danger"}
           />
           {errors?.beds && (
             <small className="ff-italic text-danger">{errors?.beds}</small>
           )}
         </Col>
         <Col sm="11">
           <Label>Baths {!isExcludedPropertyType && '*'}</Label>
           <RSelect
             options={bathOptions}
             placeholder="Baths"
             value={dealInfo?.baths}
             onChange={(baths) => {
               setDealInfo((prev) => ({ ...prev, baths }));
               setErrors((prev) => ({ ...prev, baths: null }));
             }}
             className={errors?.baths && "border border-danger"}
           />
           {errors?.baths && (
             <small className="text-danger ff-italic">{errors?.baths}</small>
           )}
         </Col>
         <Col sm="11" onClick={(e) => setopenAmenitiesModal(true)}>
           <Label>Amenities *</Label>
           <RSelect
             closeMenuOnSelect={false}
             isMulti
             placeholder="Amenities"
             value={dealInfo?.amenities}
             className={errors?.amenities && "border border-danger"}
           />
           {errors?.amenities && (
             <small className="text-danger ff-italic">
               {errors?.amenities}
             </small>
           )}
         </Col>
         <Col sm="11">
           <Label>Size *</Label>
           <div className="form-control-wrap">
             <div className="input-group rounded">
               <div className="input-group-prepend">
                 <span className="input-group-text">Sqft</span>
               </div>
               <Input
                 className={`${errors?.size && `border border-danger`}  `}
                 placeholder="Enter Size"
                 type="number"
                 value={dealInfo?.size}
                 onKeyDown={(e) => {
                   const notAllowedKeys = ["e", "E", "+", "-"];

                   if (notAllowedKeys.includes(e.key)) {
                     e.preventDefault();
                   }
                 }}
                 onChange={(e) => {
                   setErrors((prev) => ({
                     ...prev,
                     size: "",
                   }));
                   setDealInfo((prev) => ({
                     ...prev,
                     size: e.target.value,
                   }));
                 }}
                 onBlur={(e) => {
                   const { value } = e.target;
                   const size = +value;
                   // if size is more than 10 digits or size is not a number, we throw error
                   if (
                     value?.length > 10 ||
                     isNaN(size) ||
                     (size <= 0 && value?.length > 0)
                   ) {
                     setErrors((prev) => ({
                       ...prev,
                       size:
                         size <= 0
                           ? "Size cannot be less than 1"
                           : "Invalid size",
                     }));

                     return;
                   }
                 }}
               />
             </div>
             {errors && errors?.size && (
               <small className="text-danger ff-italic">{errors?.size}</small>
             )}
           </div>
         </Col>

         <Col sm="11">
           <Label>Community *</Label>
           <RSelect
             placeholder="Select Community"
             options={communityOptions}
             value={dealInfo?.community}
             onChange={(community) => {
               setDealInfo((prev) => ({ ...prev, community }));
               setErrors((prev) => ({ ...prev, community: null }));
             }}
             className={errors?.community && "border border-danger"}
           />
           {errors?.community && (
             <small className="text-danger ff-italic">
               {errors?.community}
             </small>
           )}
         </Col>
         <Col sm="11">
           <Label>Sub Community</Label>
           <Input
             placeholder="Sub Community"
             value={dealInfo?.subCommunity}
             onChange={(e) =>
               setDealInfo((prev) => ({
                 ...prev,
                 subCommunity: e.target.value,
               }))
             }
           />
         </Col>
         <Col sm="11">
           <Label>Building Name {!buildingOptional && '*'}</Label>
           <RSelect
             placeholder="Building Name"
             value={dealInfo?.building}
             onChange={(building) => {
               setDealInfo((prev) => ({ ...prev, building }));
               setErrors((prev) => ({ ...prev, building: null }));
             }}
             className={errors?.building && "border border-danger"}
             options={buildingOptions}
           />
           {errors?.building && (
             <small className="text-danger ff-italic">
               {errors?.building}
             </small>
           )}
         </Col>
         <Col sm="6">
           <Label>Vacancy *</Label>
           <RSelect
             options={Object.values(VacancyStates).map((x) => ({
               label: x,
               value: x,
             }))}
             placeholder="Select Vacancy"
             value={dealInfo?.vacancy}
             onChange={(vacancy) => {
               setDealInfo((prev) => ({ ...prev, vacancy: vacancy }));
               setErrors((prev) => ({
                 ...prev,
                 vacancy: null,
               }));
             }}
             className={errors?.vacancy && "border border-danger"}
           />
           {errors?.vacancy && (
             <small className="text-danger ff-italic">
               {errors?.vacancy}
             </small>
           )}
         </Col>
         <Col
           sm="5"
           className={`d-flex align-items-center ${errors?.vacancy ? "align-self-center" : "align-self-end mb-2"}`}
           style={{ gap: "1.5rem" }}
         >
           <div className="custom-control custom-switch" style={{ flexGrow: 2 }}>
             <input
               type="checkbox"
               className={`custom-control-input`}
               id="customSwitch1"
               name="vacantOnTransfer"
               onChange={(e) => {
                 setDealInfo((prev) => ({
                   ...prev,
                   vacantOnTransfer: e.target.checked,
                 }));
                 setErrors((prev) => ({
                   ...prev,
                   vacantOnTransfer: null,
                 }));
               }}
             />
             <label className="custom-control-label" for="customSwitch1">
               Vacant On Transfer
             </label>
           </div>
           <div className="custom-control custom-control custom-switch" >
             <input
               type="checkbox"
               className={`custom-control-input`}
               id="customSwitch2"
               name="exclusive"
               onChange={(e) => {
                 setDealInfo((prev) => ({
                   ...prev,
                   exclusive: e.target.checked,
                 }));
                 setErrors((prev) => ({
                   ...prev,
                   exclusive: null,
                 }));
               }}
             />
             <label className="custom-control-label" for="customSwitch2">
               Exclusive
             </label>
           </div>
         </Col>
         <Col sm="6">
           <Label>Listing Price *</Label>
           <div className="form-control-wrap">
             <div className="input-group">
               <div className="input-group-prepend">
                 <span className="input-group-text">AED</span>
               </div>
               <Input
                 placeholder="Listing Price"
                 type="text"
                 value={dealInfo?.listingPrice}
                 onKeyDown={(e) => {
                   const notAllowedKeys = ["e", "E", "+", "-"];

                   if (notAllowedKeys.includes(e.key)) {
                     e.preventDefault();
                   }
                 }}
                 onChange={(e) => {
                   const value = +e.target.value?.trim()?.replaceAll(",", "");

                   setDealInfo((prev) => ({
                     ...prev,
                     listingPrice:
                       value > 0 ? formatValue(value) : e.target.value,
                   }));

                   setErrors((prev) => ({
                     ...prev,
                     listingPrice: null,
                   }));
                 }}
                 onBlur={(e) => {
                   const value = +e.target.value?.trim()?.replaceAll(",", "");

                   if (isNaN(value) || value <= 0) {
                     setErrors((prev) => ({
                       ...prev,
                       listingPrice:
                         value <= 0
                           ? "Cannot be less than zero or empty or zero"
                           : "Invalid price",
                     }));
                     return;
                   }

                   // if listing price is greater than biggest integer, we throw error
                   if (value > Number.MAX_SAFE_INTEGER) {
                     setErrors((prev) => ({
                       ...prev,
                       listingPrice: "Listing price is too large",
                     }));
                     return;
                   }

                   const agreedCommission = +dealInfo?.agreedCommission;

                   const agreedCommissionValue =
                     +dealInfo?.agreedCommissionValue
                       ?.trim()
                       ?.replaceAll(",", "");

                   if (
                     agreedCommission &&
                     agreedCommission <= 100 &&
                     agreedCommission > 0
                   ) {
                     setDealInfo((prev) => ({
                       ...prev,
                       agreedCommissionValue: formatValue(
                         (value * agreedCommission) / 100
                       ),
                     }));
                     setErrors((prev) => ({
                       ...prev,
                       agreedCommission: "",
                       agreedCommissionValue: "",
                     }));
                     return;
                   }

                   if (
                     agreedCommissionValue &&
                     agreedCommissionValue <= value
                   ) {
                     const agreedCommission = (
                       (agreedCommissionValue / value) *
                       100
                     ).toFixed(2);

                     setDealInfo((prev) => ({
                       ...prev,
                       agreedCommission: agreedCommission,
                     }));

                     setErrors((prev) => ({
                       ...prev,
                       agreedCommission:
                         agreedCommission > 100
                           ? "Cannot be greater than 100"
                           : "",
                       agreedCommissionValue:
                         agreedCommission > 100
                           ? "Cannot be greater than listing price"
                           : "",
                     }));
                   }
                 }}
                 className={errors?.listingPrice && "border border-danger"}
               />
             </div>
           </div>
           {errors?.listingPrice && (
             <small className="text-danger ff-italic">
               {errors?.listingPrice}
             </small>
           )}
         </Col>
         <Col sm="5">
           <Label>Off-Plan Resale *</Label>
           <RSelect
             options={['Yes', 'No'].map((x) => ({
               label: x,
               value: x,
             }))}
             placeholder="Is this an Off-Plan Resale?"
             value={dealInfo?.isOffPlanResale}
             onChange={(choice) => {
               setIsOffPlanResale(choice.value === 'Yes');
               setDealInfo((prev) => ({ ...prev, isOffPlanResale: choice }));
               if (choice.value === 'No') {
                setErrors((prev) => ({
                  ...prev,
                  isOffPlanResale: null,
                  amountAlreadyPaid: null,
                }));
               } else {
                 setErrors((prev) => ({
                   ...prev,
                   isOffPlanResale: null,
                 }));
               }
             }}
             className={errors?.isOffPlanResale && "border border-danger"}
           />
           {errors?.isOffPlanResale && (
             <small className="text-danger ff-italic">
               {errors?.isOffPlanResale}
             </small>
           )}
         </Col>
         <Col sm="11">
          <Collapse isOpen={isOffPlanResale}>
            <Label>Amount Already Paid *</Label>
            <Input
              type="number"
              required
              name='amountAlreadyPaid'
              placeholder='Enter the amount already paid'
              min={0}
              value={dealInfo?.amountAlreadyPaid}
              onChange={(e) => {
              setDealInfo(prev => ({
                ...prev,
                amountAlreadyPaid: e.target.value
              }))
              setErrors((prev) => ({
                ...prev,
                amountAlreadyPaid: null
              }))
              }}
              className={errors.amountAlreadyPaid && "border border-danger"}
            />
            {errors?.amountAlreadyPaid && (
              <small className="text-danger ff-italic">
                {errors?.amountAlreadyPaid}
              </small>
            )}
          </Collapse>
         </Col>
         {dealInfo?.listingType?.value === "Rent" ? (
           <Col sm="11">
             <Label>Number of Cheques</Label>
             <Input
               type="number"
               required
               name='numberOfCheques'
               placeholder='Enter Number of Cheques (1 - 12)'
               min={1}
               max={12}
               value={dealInfo?.numberOfCheques}
               onChange={(e) => {
                setDealInfo(prev => ({
                  ...prev,
                  numberOfCheques: e.target.value
                }))
                setErrors((prev) => ({
                  ...prev,
                  numberOfCheques: null
                }))
               }}
               className={errors.numberOfCheques && "border border-danger"}
             />
             {errors?.numberOfCheques && (
               <small className="text-danger ff-italic">
                 {errors?.numberOfCheques}
               </small>
             )}
           </Col>
         ) : null}
         <Col sm="11">
          <Label className='mb-0'>Agreed Commission</Label>
          <p 
            style={{ color: 'indigo' }} 
          >
            <em className="icon ni ni-help-fill"></em>  Note: Please ensure that the commission mentioned below will be receiving from the seller, as it will be included in Contract A. If there is no commission from the seller, please indicate "0" or ignore this.
          </p>
         </Col>
         <Col sm="5">
           <div className="form-control-wrap">
             <div className="input-group">
               <div className="input-group-prepend">
                 <span className="input-group-text">%</span>
               </div>
               <Input
                 placeholder="Enter Agreed Commission (%)"
                 type="number"
                 min={"0"}
                 max={"100"}
                 value={dealInfo?.agreedCommission}
                 onKeyDown={(e) => {
                   const notAllowedKeys = ["e", "E", "+", "-"];

                   if (notAllowedKeys.includes(e.key)) {
                     e.preventDefault();
                   }
                 }}
                 onChange={(e) => {
                   setDealInfo((prev) => ({
                     ...prev,
                     agreedCommission: e.target.value,
                   }));
                   setErrors((prev) => ({
                     ...prev,
                     agreedCommission: null,
                   }));
                 }}
                 onBlur={async (e) => {
                   const value = +e.target.value;

                   if (isNaN(value)) {
                     setErrors((prev) => ({
                       ...prev,
                       agreedCommission: "Invalid value",
                     }));
                     return;
                   }

                   const trimmedPrice = +dealInfo?.listingPrice
                     ?.trim()
                     ?.replaceAll(",", "");

                   const agreedPrice = isNaN(trimmedPrice) ? 0 : trimmedPrice;

                   if (value > 100) {
                     const confirmed = await isConfirmed(
                       "Commission rate greater than 100%. Do you want to convert to value instead?"
                     );

                     if (confirmed) {
                       const commissionRate = (
                         (value / agreedPrice) *
                         100
                       ).toFixed(2);

                       setDealInfo((prev) => ({
                         ...prev,
                         agreedCommissionValue: formatValue(value),
                         agreedCommission: commissionRate,
                       }));

                       setErrors((prev) => ({
                         ...prev,
                         agreedCommission:
                           commissionRate > 100
                             ? "Cannot be more than 100%"
                             : "",
                         agreedCommissionValue:
                           commissionRate > 100
                             ? "Cannot be more than sale price"
                             : "",
                       }));
                       return;
                     } else {
                       setErrors((prev) => ({
                         ...prev,
                         agreedCommission: "Cannot be greater than 100%",
                       }));
                       return;
                     }
                   }

                   const commissionValue = (agreedPrice * value) / 100;

                   setDealInfo((prev) => ({
                     ...prev,
                     agreedCommissionValue: formatValue(commissionValue),
                   }));
                   setErrors((prev) => ({
                     ...prev,
                     agreedCommission: "",
                     agreedCommissionValue: "",
                   }));
                 }}
                 className={
                   errors?.agreedCommission && "border border-danger"
                 }
               />
             </div>
           </div>
           {errors?.agreedCommission && (
             <small className="text-danger ff-italic">
               {errors?.agreedCommission}
             </small>
           )}
         </Col>

         <Col sm="6">
           <div className="form-control-wrap">
             <div className="input-group">
               <div className="input-group-prepend">
                 <span className="input-group-text">AED</span>
               </div>
               <Input
                 placeholder="Agreed Commission (AED)"
                 type="text"
                 value={dealInfo?.agreedCommissionValue}
                 onChange={(e) => {
                   const value = +e.target.value?.trim()?.replaceAll(",", "");

                   setDealInfo((prev) => ({
                     ...prev,
                     agreedCommissionValue:
                       value > 0 ? formatValue(value) : e.target.value,
                   }));

                   setErrors((prev) => ({
                     ...prev,
                     agreedCommissionValue: null,
                   }));
                 }}
                 onBlur={(e) => {
                   const value = +e.target.value?.trim()?.replaceAll(",", "");

                   if (isNaN(value)) {
                     setErrors((prev) => ({
                       ...prev,
                       agreedCommissionValue: "Invalid value",
                     }));
                     return;
                   }

                   const trimmedPrice = +dealInfo?.listingPrice
                     ?.trim()
                     ?.replaceAll(",", "");

                   const agreedPrice = isNaN(trimmedPrice) ? 0 : trimmedPrice;

                   const commissionRate = (value / agreedPrice) * 100;

                   setDealInfo((prev) => ({
                     ...prev,
                     agreedCommission: commissionRate?.toFixed(2),
                   }));

                   setErrors((prev) => ({
                     ...prev,
                     agreedCommission:
                       commissionRate > 100
                         ? "Cannot be greater than 100%"
                         : "",
                     agreedCommissionValue:
                       commissionRate > 100
                         ? "Cannot be more than sale price"
                         : "",
                   }));
                 }}
                 className={
                   errors?.agreedCommissionValue && "border border-danger"
                 }
               />
             </div>
           </div>
           {errors?.agreedCommissionValue && (
             <small className="text-danger ff-italic">
               {errors?.agreedCommissionValue}
             </small>
           )}
         </Col>
         <Col md={"11"}>
              <div className="form-group">
                  <Label className="form-label" htmlFor="fv-message">
                  Related Details About Property
                  </Label>
                  <div className="form-control-wrap">
                      <textarea
                          type="textarea"
                          className="form-control form-control-sm"
                          rows="5"
                          placeholder="Description about Property (if any)"
                          value={dealInfo?.relatedInfo}
                          onChange={(e) =>
                            setDealInfo((prev) => ({
                              ...prev,
                              relatedInfo: e.target.value,
                            }))
                          }
                      />
                  </div>
              </div>
          </Col>
         
         <div className="clearfix">
           <ul>
             <li>
               <Button
                 color="primary"
                 type="button"
                 onClick={addNewDeal}
                 disabled={
                   Object.entries(errors).find(
                     ([key, value]) => value || value?.length > 0
                   ) || loading
                 }
               >
                 {loading ? (
                   <>
                     <Spinner size="sm" color="light" />
                     Please Wait
                   </>
                 ) : (
                   "Next"
                 )}
               </Button>
             </li>
           </ul>
         </div>
       </Row>
       <Modal
         size="lg"
         isOpen={openAddClient}
         toggle={toggleModalAddClient}
         backdrop="static"
       >
         <ModalHeader
           toggle={toggleModalAddClient}
           close={
             <button className="close" onClick={toggleModalAddClient}>
               <Icon name="cross" />
             </button>
           }
         >
           Add New Client
         </ModalHeader>
         <ModalBody>
           <AddLeadStepOne
             callback={(data) => {
               setDealInfo((prev) => ({
                 ...prev,
                 client: {
                   ...data,
                   value: `${data?.first_name} ${data?.last_name}`,
                   label: `${data?.first_name} ${data?.last_name}`,
                 },
                 agent: data?.currentAgent
                   ? {
                       ...data?.currentAgent,
                       value: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                       label: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                     }
                   : null,
               }));

               setAssignedAgent((prev) =>
                 data?.currentAgent
                   ? {
                       ...data?.currentAgent,
                       value: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                       label: `${data?.currentAgent?.user?.first_name} ${data?.currentAgent?.user?.last_name}`,
                     }
                   : null
               );

               setopenAddClient(false);
             }}
           />
         </ModalBody>
       </Modal>
       <ConfirmModal
         cancel={cancel}
         isOpen={isOpen}
         proceed={proceed}
         prompt={prompt}
         cancelColor="danger"
         cancelText="Cancel"
         proceedColor="primary"
         proceedText="Proceed"
       />
       <RenderAmenitiesModal
         openAmenitiesModal={openAmenitiesModal}
         toggleAmentiesModal={(e) => setopenAmenitiesModal((c) => !c)}
         amenities={amenitiesOptions}
         selectedOptions={dealInfo?.amenities}
         setErrorFn={setErrors}
         onContinue={(selectedOptions) => {
           setDealInfo((prev) => ({
             ...prev,
             amenities: selectedOptions,
           }));
           setopenAmenitiesModal(false);
         }}
       />
     </PreviewCard>
   );
};
export default ListingAcquired;