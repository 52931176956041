/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import {
    Block,
    BlockDes,
    BlockHeadContent,
    BlockTitle,
    BlockBetween,
    BlockHead,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    PaginationComponent,
    PreviewAltCard,
    Row,
    DataTable,
    DataTableBody
} from '../../../components/Component';
import {
    Button,
    Modal,
    ModalBody,
    Badge,
    ModalHeader,
    Card,
    CardBody
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import { toast } from 'react-toastify';
import { format } from 'date-fns';
import 'react-phone-number-input/style.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import _ from 'lodash';
import Papa from 'papaparse';

import uuid from 'react-uuid';

const NewLeadsTable = ({
    title,
    name,
    uploadType,
}) => {
    const inputRef = useRef(null);

    const [csvFileName, setCsvFileName] = useState('');
    const [uploadLog, setUploadLog] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItemLog = currentPage * itemsPerPage;
    const indexOfFirstItemLog = indexOfLastItemLog - itemsPerPage;
    const [submitData, setSubmitData] = useState(null);

    useEffect(() => {
        fetchUploadLog();
    }, []);

    const templateKeys = {
        name: 'leadName',
        email: 'email',
        phone: 'leadMobile',
        community: 'community',
        project: 'project',
        cluster: 'cluster',
        'building name': 'buildingName',
        'building number': 'buildingNumber',
        'unit number': 'unitNumber',
        type: 'propertyType',
        beds: 'beds',
        baths: 'baths',
        size: 'size',
        'deal date': 'dealDate',
        amount: 'amount',
        'asking price': 'askingPrice',
        'party type': 'transactionType',
        agent: 'agent',
        'lead source': 'leadSource',
        address: 'address',
        notes: 'notes'
    };

    const axios = useAxiosPrivate();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (!file) {
            toast.error('Please choose a file.');
            return;
        }

        if (file.type !== 'text/csv') {
            toast.error('Only CSV files are accepted.');
            return;
        }

        const fileName = file.name;

        setCsvFileName(fileName);

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            transformHeader(header, index) {
                return templateKeys[header?.toLowerCase().replace(/\s+/g, ' ').trim()];
            },
            complete(res) {
                setSubmitData(res.data);
            },
            error: (error, f) => {
                toast.error('Encountered error while parsing the file.');
            }
        });
    };

    const handleClearData = () => {
        setCsvFileName('');

        setSubmitData(null);

        setCurrentPage(1);

        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    const handleDownloadTemplate = () => {
        const templateUrl = uploadType === 'PocketLists' 
    ? '/pocket_list_bulk_upload_sample_template.csv' 
    : '/leads_bulk_upload_sample_template.csv';

        const link = document.createElement('a');
        link.href = templateUrl;
        link.download = 'template.csv';
        link.click();
        toast.success('CSV file downloaded');
    };

    const [openAgent, setOpenAgent] = useState(false);

    const toggleModalAgent = () => {
        setOpenAgent(!openAgent);
    };

    const handleOnSubmit = async (e) => {
        setSaveLoading(false);
        e.preventDefault();

        if (Array.isArray(submitData) && submitData.length > 0) {
            // Check for required fields
            const invalid = _.find(
                submitData,
                (item) =>
                    !item?.leadName ||
                    !item?.leadMobile?.trim() ||
                    !item?.community ||
                    !item?.leadSource ||
                    (uploadType === 'PocketLists' && !item?.askingPrice) ||
                    !/\d{4,20}$/.test(item?.leadMobile?.trim())
            );

            if (invalid) {
                toast.error('Please check data and fillup required columns');
                return;
            }

            try {
                setSaveLoading(true);

                const res = await axios.post(
                    `/property-cards/dld-data/upload`,
                    { 
                        data: submitData,
                        fileName: csvFileName,
                        ...(uploadType === 'PocketLists' && { isPocketListing: true }),
                    }
                );

                toast.success(res?.data?.message);

                setCsvFileName('');

                if (inputRef.current) {
                    inputRef.current.value = null;
                }
                setSubmitData(null);

                fetchUploadLog();
            } catch (err) {
                toast.error(
                    err.response.data.message ?? 'Unexpected server error'
                );
            } finally {
                setSaveLoading(false);
            }
        } else {
            toast.error(
                'Please import a valid CSV file or map the required fields.'
            );
        }
    };

    const fetchUploadLog = async () => {
        try {
            let link = `/users/uploadlog`;
            const res = await axios.get(link);
            const result = res.data;
            setUploadLog(result);
        } catch (err) {
            console.log(err);
        }
    };

    const logItems = uploadLog.slice(indexOfFirstItemLog, indexOfLastItemLog);

    return (
        <React.Fragment>
            <Head title={title}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                {title}
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Card>
                        <CardBody>
                            <div className="row mt-3 mb-3 justify-content-end">
                                <div className="col-md-8 justify-content-end">
                                    <div className="d-flex">
                                        <Button color="light">
                                            <input
                                                type="file"
                                                id="fileInput"
                                                onChange={(event) => {
                                                    handleFileUpload(event);
                                                    // toggleMapFields();
                                                }}
                                                ref={inputRef}
                                            ></input>
                                        </Button>
                                        <Button
                                            outline={
                                                submitData &&
                                                submitData?.length > 0
                                            }
                                            color="danger"
                                            className="ml-2"
                                            onClick={handleClearData}
                                            disabled={
                                                !submitData ||
                                                !submitData?.length
                                            }
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-md-4 justify-content-end d-flex">
                                    <Button
                                        outline
                                        color="secondary"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            toggleModalAgent();
                                            fetchUploadLog();
                                        }}
                                    >
                                        <Icon name="eye" />
                                        View Upload Log
                                    </Button>
                                    <Button
                                        outline
                                        color="secondary"
                                        onClick={handleDownloadTemplate}
                                        className="ml-2"
                                    >
                                        <Icon name="download" />
                                        Download CSV Template
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Block>

                {submitData &&
                Array.isArray(submitData) &&
                submitData?.length ? (
                    <Block>
                        <BlockDes className="text-soft">
                            <div className="row mt-3 d-flex align-items-center">
                                <div className="col-md-6">
                                    <p>
                                        You have a total of {submitData?.length}{' '}
                                        {name + '(s)'}.
                                    </p>
                                </div>
                                <div
                                    className="col-md-6 d-flex justify-content-end"
                                    style={{ gap: '1rem' }}
                                >
                                    <Button
                                        color="primary"
                                        size="lg"
                                        onClick={handleOnSubmit}
                                        disabled={
                                            !submitData ||
                                            submitData?.length === 0 ||
                                            saveLoading
                                        }
                                    >
                                        {saveLoading
                                            ? 'Uploading...'
                                            : 'Upload'}
                                    </Button>
                                </div>
                            </div>
                        </BlockDes>

                        <DataTable className="card-stretch mt-3">
                            <DataTableBody compact>
                                <DataTableHead className="nk-tb-item">
                                    {submitData?.length > 0 &&
                                        Object.keys(templateKeys)?.map(
                                            (header, index) => (
                                                <DataTableRow
                                                    key={`${index}-${uuid()}`}
                                                >
                                                    <span className="sub-text text-capitalize">
                                                        {header}
                                                    </span>
                                                </DataTableRow>
                                            )
                                        )}
                                </DataTableHead>

                                {submitData
                                    ?.slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage +
                                            itemsPerPage
                                    )
                                    ?.map((row, rowIndex) => (
                                        <DataTableItem key={rowIndex}>
                                            {Object.values(templateKeys).map(
                                                (cell, cellIndex) => (
                                                    <DataTableRow
                                                        key={`${cellIndex}-${uuid()}`}
                                                    >
                                                        <span
                                                            href="#id"
                                                            onClick={(ev) =>
                                                                ev.preventDefault()
                                                            }
                                                        > 
                                                            {row[cell]}
                                                        </span>
                                                    </DataTableRow>
                                                )
                                            )}
                                        </DataTableItem>
                                    ))}
                            </DataTableBody>
                            <PreviewAltCard>
                                {submitData?.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemsPerPage}
                                        totalItems={submitData.length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">
                                            Upload CSV file to see the data
                                        </span>
                                    </div>
                                )}
                            </PreviewAltCard>
                        </DataTable>
                    </Block>
                ) : (
                    <Block>
                        <Card>
                            <div className="text-center mt-3 mb-3">
                                <span className="text-silent">
                                    Upload CSV file to see the data
                                </span>
                            </div>
                        </Card>{' '}
                    </Block>
                )}

                <Modal
                    size="xl"
                    isOpen={openAgent}
                    toggle={toggleModalAgent}
                    backdrop="static"
                    className="w-80"
                >
                    <ModalHeader
                        close={
                            <button
                                className="close"
                                onClick={toggleModalAgent}
                            >
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Upload Log
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <DataTable className="card-stretch mt-3">
                                <DataTableBody compact>
                                    <DataTableHead className="nk-tb-item">
                                        <DataTableRow>
                                            <span className="sub-text">
                                                FILE NAME
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text">
                                                UPLOAD DATE
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text">
                                                TAG
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text">
                                                TOTAL COUNT
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text">
                                                NEW
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <span className="sub-text">
                                                SKIPPED
                                            </span>
                                        </DataTableRow>
                                    </DataTableHead>

                                    {logItems?.map((item, idx) => {
                                        const skipped =
                                            item?.total_count -
                                            item?.new_leads -
                                            item?.updated_leads;
                                        const tooltipContent = `Updated Leads: ${item?.updated_leads} , Not Modified Leads: ${skipped}`;
                                        return (
                                            <DataTableItem key={idx}>
                                                <DataTableRow>
                                                    <span className="sub-text">
                                                        <Badge
                                                            size="sm"
                                                            color="outline-danger"
                                                            className="badge-dim me-1"
                                                        >
                                                            {item?.file_name}
                                                        </Badge>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="sub-text">
                                                        {' '}
                                                        {item?.upload_date &&
                                                            format(
                                                                new Date(
                                                                    item?.upload_date
                                                                ),
                                                                'dd/MM/yyyy hh:mm a'
                                                            )}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    {' '}
                                                    {item?.tag}
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <Badge color="info">
                                                        {' '}
                                                        {item?.total_count}
                                                    </Badge>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <Badge color="success">
                                                        {' '}
                                                        {item?.new_leads}
                                                    </Badge>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <Badge
                                                        color="danger"
                                                        data-tip={
                                                            tooltipContent
                                                        }
                                                    >
                                                        {' '}
                                                        {item?.total_count -
                                                            item?.new_leads}
                                                    </Badge>
                                                    <ReactTooltip />
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })}
                                </DataTableBody>
                            </DataTable>
                            <PreviewAltCard>
                                {uploadLog.slice(1)?.length > 0 ? (
                                    <PaginationComponent
                                        itemPerPage={itemsPerPage}
                                        totalItems={uploadLog.slice(1).length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <span className="text-silent">
                                            No Log Found
                                        </span>
                                    </div>
                                )}
                            </PreviewAltCard>
                        </Row>
                    </ModalBody>
                </Modal>
            </Content>
        </React.Fragment>
    );
};

export default NewLeadsTable;
