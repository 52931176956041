import { useContext, useMemo } from "react";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import { AgentReportContext } from "../../../AgentReportLayout";
import { getAssignedHotLeads, getAttendedHotLeads, getDealHotLeads, getExpiredHotLeads, getFollowUpHotLeads, getListingAcquiredHotLeads, getLostOrDisqualifiedHotLeads } from "../../../../../../api/activities/use-cases/hotLeadsQueries";
import { useQuery } from "react-query";
import { timeAsMilliseconds } from "../../../../../../utils/Utils";

export const HotLeadsQueries = {
  Assigned: 'assigned',
  Attended: 'attended',
  FollowUp: 'followUp',
  Prospect: 'prospect',
  LostOrDisqualified: 'lostOrDisqualified',
  ListingAcquired: 'listingAcquired',
  Deal: 'deal',
  Expired: 'expired'
};

export const useHotLeadsQuery = (queryName, { leadSource = null, agents = null, leader = null, page = 1, leadStatus = null }, { refetchIntervalMs } = {}) => {
  const axios = useAxiosPrivate();
  const { globalQueryParams } = useContext(AgentReportContext);

  // Use useMemo to create a stable queryParams object
  const queryParams = useMemo(() => {
    const params = queryName !== HotLeadsQueries.Expired ? { ...globalQueryParams, page, relative: '1' } : { ...globalQueryParams, page   };
    if (leadSource) params.leadSource = leadSource;
    if (agents) params.agents = agents;
    if (leader) params.leader = leader;
    if (leadStatus) params.leadStatus = leadStatus;
    return params;
  }, [queryName, globalQueryParams, page, leadSource, agents, leader, leadStatus]);

  // Use useMemo to create a stable query object
  const query = useMemo(() => {
    const queryObj = {
      queryKey: null,
      queryFn: () => {}
    };

    switch (queryName) {
      case HotLeadsQueries.Assigned:
        queryObj.queryKey = ['assignedHotLeads', queryParams];
        queryObj.queryFn = () => getAssignedHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.Attended:
        queryObj.queryKey = ['attendedHotLeads', queryParams];
        queryObj.queryFn = () => getAttendedHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.FollowUp:
        queryObj.queryKey = ['followUpHotLeads', queryParams];
        queryObj.queryFn = () => getFollowUpHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.Prospect:
        queryObj.queryKey = ['prospectHotLeads', queryParams];
        queryObj.queryFn = () => getFollowUpHotLeads(axios, { params: { ...queryParams, prospect: '1' } });
        break;
      case HotLeadsQueries.LostOrDisqualified:
        queryObj.queryKey = ['lostOrDisqualifiedHotLeads', queryParams];
        queryObj.queryFn = () => getLostOrDisqualifiedHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.Deal:
        queryObj.queryKey = ['dealHotLeads', queryParams];
        queryObj.queryFn = () => getDealHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.ListingAcquired:
        queryObj.queryKey = ['listingAcquiredHotLeads', queryParams];
        queryObj.queryFn = () => getListingAcquiredHotLeads(axios, { params: queryParams });
        break;
      case HotLeadsQueries.Expired:
        queryObj.queryKey = ['expiredHotLeads', queryParams];
        queryObj.queryFn = () => getExpiredHotLeads(axios, { params: queryParams });
        break;
      default:
        break;
    }

    return queryObj;
  }, [queryName, queryParams, axios]);

  const result = useQuery(
    query.queryKey,
    query.queryFn,
    { 
      refetchInterval: refetchIntervalMs ?? timeAsMilliseconds({ minutes: 10 }), 
      refetchOnWindowFocus: false, 
      enabled: !!queryName,
      staleTime: timeAsMilliseconds({ minutes: 10 })
    }
  );

  return result;
};
