import React, { useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import RSelectAsync from './select/ReactSelectAsync';
import { useQuery } from 'react-query';
import { timeAsMilliseconds } from '../utils/Utils';

export default function SelectAgent({
  onSelectAgent = (agent) => {},
  allowInactiveAgents = false,
  defaultOptions = [],
  selectedAgent,
  placeholder = 'Select Agent',
  isClearable,
  notStyled = false,
  useAsync = false,
  ...props
}) {
  const axios = useAxiosPrivate();

  const [searchText, setSearchText] = useState('');
  const [hasSearched, setHasSearched] = useState(false);

  const { data: initialAgents, isLoading: isLoadingInitialAgents } = useQuery({
    queryKey: ['allAgents'],
    queryFn: async () => {
      const res = await axios.get(`/agent`);
      return res?.data?.map((item) => ({
        ...item,
        value: item?.id ?? item?._id,
        label: `${item?.user?.first_name ?? ''} ${item?.user?.last_name ?? ''}`
      }));
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: timeAsMilliseconds({ hours: 1 }),
    staleTime: timeAsMilliseconds({ hours: 1 })
  });

  const fetchAgents = async () => {
    if (!useAsync) {
      return Promise.resolve(
        initialAgents?.filter((agent) =>
          searchText ? agent.label.toLowerCase().includes(searchText.toLowerCase()) : true
        ) ?? []
      );
    }

    return axios
      .get(`/agent/filter`, {
        params: {
          filter: {
            search: searchText,
            active: allowInactiveAgents ? 'all' : true
          }
        }
      })
      .then((res) => {
        return res.data?.agents?.map((item) => ({
          ...item,
          value: item?.id ?? item?._id,
          label: `${item?.user?.first_name ?? ''} ${item?.user?.last_name ?? ''}`
        }));
      })
      .catch((err) => console.error(err));
  };

  const handleInputChange = (value) => {
    setSearchText(value);
    if (value) {
      setHasSearched(true);
    }
  };

  const noOptionsMessage = ({ inputValue }) => {
    if (!hasSearched) {
      return 'Please type to look for agents';
    }
    return inputValue ? 'No matching agents found' : 'Please enter a search term';
  };

  return (
    <RSelectAsync
      loadOptionsFn={fetchAgents}
      onInputChange={handleInputChange}
      onChange={onSelectAgent}
      placeholder={placeholder}
      className={notStyled ? '' : 'status-div'}
      defaultOptions={defaultOptions.length ? defaultOptions : initialAgents}
      value={selectedAgent}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      isLoading={isLoadingInitialAgents}
      {...props}
    />
  );
}
