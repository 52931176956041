import React from "react";
import
{
  DataTableItem,
  DataTableRow,
} from "../../Component";
import { HEADER_FIELDS } from "../constants";
import Skeleton from "react-loading-skeleton";
import { useActivityFormState } from "../context/ActivityProvider";

const SHOW_ROWS = 5;

const ActivitySkeletonLoader = () =>
{
  const { ROW_HEIGHT } = useActivityFormState();
  return (
    <React.Fragment>
      { new Array( SHOW_ROWS ).fill( 0 ).map( ( _, outerIdx ) => (
        <DataTableItem key={ `activity-row-${ outerIdx }` } style={ { height: ROW_HEIGHT } }>
          { new Array( HEADER_FIELDS.length ).fill( 0 ).map( ( _, innerIdx ) => (
            <DataTableRow className={ 'p-4' } key={ `activity-row-${ outerIdx }-col-${ innerIdx }` }>
              <Skeleton style={{ height: '1.5rem' }} />
            </DataTableRow>
          ) ) }
        </DataTableItem>
      ) ) }
    </React.Fragment>
  );
};

export default ActivitySkeletonLoader;
