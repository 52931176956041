import React, { Suspense, useMemo, useState } from "react";
import { Block } from "../Component";
import { Badge, Modal } from "reactstrap";
import DndFlagComponent from "../../pages/components/dnd/dndFlag";
import { activeStatus, newRoles } from "../../utils/envConfig";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import NewAgentAvatar from "../../pages/users/components/NewAgentAvatar";
import { useQueryClient } from "react-query";
import LoaderModal from "../modals/LoaderModal";

const AssignAgent = React.lazy(() => import("../../pages/components/kanban/KanbanForms").then(module => ({ default: module.AssignAgent })));

const UserPersonalInfoDetails = ( user ) =>
{
    const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
    const [ assignAgentModal, setAssignAgentModal ] = useState( false );
    const queryClient = useQueryClient();

    const isAgent = useMemo(
      // eslint-disable-next-line eqeqeq
      () => user?.user?.user?.id != undefined && user?.user?.user?.role_duplicate?.includes(newRoles.Agent), 
      [user?.user?.user?.id, user?.user?.user?.role_duplicate]
    );

    const toggleAssignAgentModal = () =>
    {
        setAssignAgentModal( !assignAgentModal );
    };

    return (
      <div>
        <Block>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Basics</h6>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">First Name</span>
                <span className="data-value">
                  {user?.user?.user?.first_name}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Last Name</span>
                <span className="data-value">
                  {user?.user?.user?.last_name}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Email</span>
                <span className="data-value">{user?.user?.user?.email}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more disable"></span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Phone Number</span>
                <div className="data-value">
                  {user?.user?.user?.DndStatus ? (
                    <DndFlagComponent
                      lead_status={user?.user?.user?.lead_status}
                      phoneNumber={user?.user?.user?.phone}
                    />
                  ) : (
                    <span href="#id" onClick={(ev) => ev.preventDefault()}>
                      {user?.user?.user?.phone}
                    </span>
                  )}
                </div>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            {isAgent && (
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Nationality</span>
                  <span className="data-value">
                    {user?.user?.user?.nationality ?? "-"}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
            )}

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Address</span>
                <span className="data-value">
                  {user?.user?.user?.address}
                  {user?.user?.user?.address && user?.user?.user?.city && ", "}
                  {user?.user?.user?.city}
                  {user?.user?.user?.city && user?.user?.user?.country && ", "}
                  {user?.user?.user?.country}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {user?.user?.user?.preference && (
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Preferences</span>
                  <span className="data-value">
                    {user?.user?.user?.preference &&
                      !Object.values(user?.user?.user?.preference).every(
                        (x) => x === null
                      ) && (
                        <>
                          {user?.user?.user?.preference?.plotNumber && (
                            <p className="data-value">
                              Unit Number:{" "}
                              {user?.user?.user?.preference?.plotNumber}
                            </p>
                          )}
                          {user?.user?.user?.preference?.rooms && (
                            <p className="data-value">
                              Rooms: {user?.user?.user?.preference?.rooms}
                            </p>
                          )}
                          {user?.user?.user?.preference?.typeOfProperty && (
                            <p className="data-value">
                              Type of Property:{" "}
                              {user?.user?.user?.preference?.typeOfProperty}
                            </p>
                          )}
                          {user?.user?.user?.preference?.area && (
                            <p className="data-value">
                              Building/Area:{" "}
                              {user?.user?.user?.preference?.area}
                            </p>
                          )}
                        </>
                      )}
                  </span>
                </div>
                <div className="data-col data-col-end"></div>
              </div>
            )}
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Language</span>
                <span className="data-value">
                  {user?.user?.user?.languages &&
                  user?.user?.user?.languages?.length
                    ? user?.user?.user?.languages.join(" | ")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
          </div>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">More Info</h6>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">
                  {isAgent ? "Source" : "Lead Source"}
                </span>
                <span className="data-value">
                  {user?.user?.user?.lead_source}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Role</span>
                <span className="data-value">{user?.user?.user?.role}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">State</span>
                <span className="data-value">
                  <Badge
                    color={user?.user?.user?.active ? "success" : "danger"}
                  >
                    {user?.user?.user?.active
                      ? activeStatus[0]
                      : activeStatus[1]}
                  </Badge>
                </span>
                {/* <Badge color="success"> Active</Badge> */}
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Lead Status</span>
                <span className="data-value">
                  {user?.user?.user?.lead_status ?? "-"}
                </span>
                {/* <Badge color="success"> Active</Badge> */}
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Profile Created On</span>
                <span className="data-value">
                  {user?.user?.user?.created_at &&
                    format(
                      new Date(user?.user?.user?.created_at),
                      "dd/MM/yyyy hh:mm a"
                    )}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Last Update On</span>
                <span className="data-value">
                  {user?.user?.user?.updated_at &&
                    format(
                      new Date(user?.user?.user?.updated_at),
                      "dd/MM/yyyy hh:mm a"
                    )}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {user &&
              user?.user?.user?.role !== "Agent" &&
              authUser?.role &&
              authUser?.role !== newRoles?.Agent && (
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Assigned Agent</span>
                    <span
                      href="#id"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleAssignAgentModal();
                      }}
                    >
                      {user?.user?.user?.currentAgent &&
                      Object.keys(user?.user?.user?.currentAgent).length ? (
                        <React.Fragment>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <NewAgentAvatar
                                agent={user?.user?.user?.currentAgent}
                              />
                            </div>
                            <div style={{ marginLeft: "8px" }}>
                              {
                                user?.user?.user?.currentAgent?.userId
                                  ?.first_name
                              }{" "}
                              {
                                user?.user?.user?.currentAgent?.userId
                                  ?.last_name
                              }
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        <span>Not Assigned</span>
                      )}
                    </span>
                  </div>
                  <div className="data-col data-col-end"></div>
                </div>
              )}
          </div>
          {assignAgentModal && (
            <Suspense fallback={<LoaderModal />}>
              <Modal
                size="lg"
                isOpen={assignAgentModal}
                toggle={toggleAssignAgentModal}
              >
                {(
                  <AssignAgent
                    toggle={toggleAssignAgentModal}
                    user={user?.user?.user}
                    callback={() => queryClient.invalidateQueries()}
                  />
                )}
              </Modal>
            </Suspense>
          )}
        </Block>
      </div>
    );
};

export default UserPersonalInfoDetails;





