import React, { useEffect, useState } from 'react'
import { BlockTitle, Icon } from '../../../components/Component';
import { axiosPrivate } from '../../../utils/Utils';
import { getLeadSourceLogs } from '../../../api/activities/use-cases/getLeadSourceLogs';
import { format, parseISO } from 'date-fns';
import { ArrowLeft, ChevronLeft } from 'lucide-react';

function LeadSourceLog({ lead }) {

  const [leadSourceLog, setLeadSourceLog] = useState([]);

  useEffect(() => {

    const fetchLeadSourceLogs = async () => {
      try {
        const payload = { "user": lead.id };

        const leadSourceLogs = await getLeadSourceLogs(axiosPrivate, payload);

        // Check if data exists before updating state
        if (leadSourceLogs && leadSourceLogs.data) {
          setLeadSourceLog(leadSourceLogs.data);
        }
      } catch (error) {
        console.error("Error fetching lead source logs:", error);
      }
    };

    fetchLeadSourceLogs();
  }, [lead.id]);

  return (
    <>
      <div id="lead_source_log">
        <div className="card-title-group">
          <BlockTitle tag="h4" className="mt-5">Lead Source Change Log</BlockTitle>

        </div>
      </div>
      <div className="card-inner">
        <div className="timeline-horizontal">
          <ul className="timeline-horizontal-list">
            {leadSourceLog.length > 0 ? leadSourceLog.map((item, index) => (
              <li className="timeline-horizontal-item" key={item.id}>
                <div className={`timeline-horizontal-status bg-primary ${index == 0 ? "is-outline" : ""}`}></div>
                {index != (leadSourceLog.length - 1) ? (<div style={{
                  color: "#a580f5"
                  , width: "20px",
                  height: "20px",
                  position: "relative"
                  , top: "-22px",
                  right: "-22px"
                }} ><ChevronLeft></ChevronLeft></div>) : <div style={{ width: "20px", height: "20px" }}></div>}
                <div className="timeline-horizontal-date">
                  {format(parseISO(item.createdAt), "dd/MMM/yyyy hh:mm a")} <Icon name="alarm-alt"></Icon>
                </div>
                <div className="timeline-horizontal-data">
                  <h6 className="timeline-horizontal-title">{item.newLeadSource.name}</h6>

                  <div className="timeline-horizontal-des">
                    <p>from</p>
                    <p>{item.oldLeadSource.name}</p>
                    <span className="time">
                      {item?.changedBy ?
                        `By ${item.changedBy.first_name} ${item.changedBy.last_name}` :
                        "By System User"}
                    </span>
                  </div>
                </div>
              </li>
            )) : (<div
              className="timeline-horizontal-data"
              style={{
                display: "flex",
                flexDirection: "column", // Stack items vertically
                alignItems: "center",    // Center items horizontally
                justifyContent: "center", // Center items vertically
                textAlign: "center",      // Center align text
                height: "100%",           // Ensure proper vertical alignment
                gap: "10px",              // Optional: Add space between elements
              }}
            >
         <div className={`timeline-horizontal-status bg-primary is-outline`}></div>
              <div
                className="timeline-horizontal-date"
                style={{
                  fontSize: "0.9rem", // Optional: Adjust font size
                  color: "#555",      // Optional: Adjust color
                }}
              >
                {format(parseISO(lead.created_at), "dd/MMM/yyyy hh:mm a")}{" "}
                <Icon name="alarm-alt"></Icon>
              </div>
              <h6
                className="timeline-horizontal-title"
                style={{
                  margin: "0",           // Remove default margins
                  fontWeight: "bold",    // Optional: Emphasize title
                  color: "#333",         // Optional: Adjust color
                }}
              >
                {lead.lead_source}
              </h6>
              <div
                className="timeline-horizontal-des"
                style={{
                  marginTop: "10px",      // Add space above
                  fontSize: "0.9rem",     // Optional: Adjust font size
                }}
              >
                <span className="time">
                  By, <br />
                  System User
                </span>
              </div>
            </div>
            )}
          </ul>
        </div>

      </div>

    </>
  )
}

export default LeadSourceLog