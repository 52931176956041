
import React, { useMemo, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../../components/Component";
import { Button, Spinner } from "reactstrap";
import { currencyFormatter } from "../../explorer/utils";
import DatePicker from "react-datepicker";
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth, startOfYear, subMonths } from "date-fns";
import Skeleton from "react-loading-skeleton";
const RevenueView = ({ finances, queryParams, setQueryParams }) => {
  const [toggleButtonsState, setToggleButtonsState] = useState({
    thisMonth: false,
    lastMonth: false,
    thisYear: false,
  });

  const parsedTransactionParams = useMemo(
    () => queryParams?.transactions ?? {},
    [queryParams?.transactions]
  );

  const handleClear = (e) => {
    e.preventDefault();

    setToggleButtonsState((prev) => ({
      ...prev,
      thisMonth: false,
      thisYear: false,
      lastMonth: false,
    }));

    setQueryParams((prev) => ({
      ...prev,
      fromDate: null,
      toDate: null,
      transactions: {
        ...prev.transactions,
        fromPaymentDate: null,
        toPaymentDate: null,
      },
    }));
  };

  const clearToggleButtons = () => {
    setToggleButtonsState((prev) => ({
      ...prev,
      thisMonth: false,
      thisYear: false,
      lastMonth: false,
    }));
  };

  const handleThisMonthFilter = (e) => {
    e.preventDefault();
    const today = new Date();
    const startOfThisMonth = format(startOfMonth(today), "yyyy-MM-dd");
    const endOfToday = endOfDay(today);

    console.log(endOfToday);

    if (toggleButtonsState.thisMonth) {
      // the button was set on click, so disable
      clearToggleButtons();

      setQueryParams((prev) => ({
        ...prev,
        fromDate: null,
        toDate: null,
        transactions: {
          ...prev.transactions,
          fromPaymentDate: null,
          toPaymentDate: null,
        },
      }));
      return;
    }

    setToggleButtonsState((prev) => ({
      ...prev,
      thisMonth: true,
      thisYear: false,
      lastMonth: false,
    }));

    setQueryParams((prev) => ({
      ...prev,
      fromDate: startOfThisMonth,
      toDate: endOfToday.toISOString(),
      transactions: {
        ...prev.transactions,
        fromPaymentDate: startOfThisMonth,
        toPaymentDate: endOfToday.toISOString(),
      },
    }));
  };

  const handleLastMonthFilter = (e) => {
    e.preventDefault();
    const today = new Date();
    const lastMonth = subMonths(today, 1);
    const startOfLastMonth = startOfDay(startOfMonth(lastMonth));
    const endOfLastMonth = endOfDay(endOfMonth(lastMonth));

    if (toggleButtonsState.lastMonth) {
      // the button was set on click, so disable
      clearToggleButtons();

      setQueryParams((prev) => ({
        ...prev,
        fromDate: null,
        toDate: null,
        transactions: {
          ...prev.transactions,
          fromPaymentDate: null,
          toPaymentDate: null,
        },
      }));
      return;
    }

    setToggleButtonsState((prev) => ({
      ...prev,
      thisMonth: false,
      thisYear: false,
      lastMonth: true,
    }));

    setQueryParams((prev) => ({
      ...prev,
      fromDate: startOfLastMonth.toISOString(),
      toDate: endOfLastMonth.toISOString(),
      transactions: {
        ...prev.transactions,
        fromPaymentDate: startOfLastMonth.toISOString(),
        toPaymentDate: endOfLastMonth.toISOString(),
      },
    }));
  };

  const handleThisYearFilter = (e) => {
    e.preventDefault();
    const today = new Date();
    const startOfThisYear = startOfYear(today);
    const endOfToday = endOfDay(today);
    if (toggleButtonsState.thisYear) {
      // the button was set on click, so disable
      clearToggleButtons();

      setQueryParams((prev) => ({
        ...prev,
        fromDate: null,
        toDate: null,
        transactions: {
          ...prev.transactions,
          fromPaymentDate: null,
          toPaymentDate: null,
        },
      }));
      return;
    }

    setToggleButtonsState((prev) => ({
      ...prev,
      thisMonth: false,
      thisYear: true,
      lastMonth: false,
    }));

    setQueryParams((prev) => ({
      ...prev,
      fromDate: startOfThisYear.toISOString(),
      toDate: endOfToday.toISOString(),
      transactions: {
        ...prev.transactions,
        fromPaymentDate: startOfThisYear.toISOString(),
        toPaymentDate: endOfToday.toISOString(),
      },
    }));
  };

  const handleFromDateChange = (date) => {
    clearToggleButtons();
    setQueryParams((prev) => ({
      ...prev,
      fromDate: date ? startOfDay(date).toISOString() : null,
      toDate: queryParams?.toDate
        ? new Date(queryParams?.toDate).toISOString()
        : new Date().toISOString(),
      transactions: {
        ...prev.transactions,
        fromPaymentDate: date ? startOfDay(date).toISOString() : null,
      },
      page: 1,
    }));
  };

  const handleToDateChange = (date) => {
    if (date) {
      clearToggleButtons();
      setQueryParams((prev) => ({
        ...prev,
        toDate: endOfDay(date).toISOString(),
        transactions: {
          ...prev.transactions,
          toPaymentDate: endOfDay(date).toISOString(),
        },
        page: 1,
      }));
    } else {
      setQueryParams((prev) => ({
        ...prev,
        transactions: { ...prev.transactions, toPaymentDate: null },
        page: 1,
      }));
    }
  };

  const isAnyDateFilterSelected = useMemo(
    () =>
      queryParams?.toDate ||
      queryParams?.fromDate ||
      queryParams?.transactions?.toPaymentDate ||
      queryParams?.transactions?.fromPaymentDate,
    [queryParams]
  );

  return (
    <React.Fragment>
      <Head title="Revenue"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className={'mb-3'}>
                Revenue
                {finances.isLoading ? (
                  <Spinner
                    size={"lg"}
                    type="grow"
                    className="ms-4 fw-light"
                    color="primary"
                  />
                ) : null}
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <div className="d-flex justify-content-end">
            <div className="d-flex flex-wrap">
              <div className="align-self-end me-3 my-2 my-lg-0">
                <Button
                  color={toggleButtonsState?.thisYear ? "secondary" : "white"}
                  onClick={handleThisYearFilter}
                >
                  <span
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: ".5rem" }}
                  >
                    Year Until Today
                    <Icon
                      name={
                        toggleButtonsState?.thisYear ? "check-c" : "square-c"
                      }
                    />
                  </span>
                </Button>
              </div>
              <div className="align-self-end me-3 my-2 my-lg-0">
                <Button
                  color={toggleButtonsState?.lastMonth ? "secondary" : "white"}
                  onClick={handleLastMonthFilter}
                >
                  <span
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: ".5rem" }}
                  >
                    Last Month
                    <Icon
                      name={
                        toggleButtonsState?.lastMonth ? "check-c" : "square-c"
                      }
                    />
                  </span>
                </Button>
              </div>
              <div className="align-self-end me-3 my-2 my-lg-0">
                <Button
                  onClick={handleThisMonthFilter}
                  color={toggleButtonsState?.thisMonth ? "secondary" : "white"}
                >
                  <span
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: ".5rem" }}
                  >
                    This Month
                    <Icon
                      name={
                        toggleButtonsState?.thisMonth ? "check-c" : "square-c"
                      }
                    />
                  </span>
                </Button>
              </div>
              <div className="form-group">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="form-label mt-2">
                    Date Range
                  </label>
                  <div className="form-control-wrap datepicker-zindex mb-2 mb-lg-0">
                    <div className="input-daterange date-picker-range input-group datepicker-zindex">
                      <DatePicker
                        showMonthYearPicker
                        selected={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.fromPaymentDate
                            ? new Date(parsedTransactionParams?.fromPaymentDate)
                            : null
                        }
                        selectsStart
                        dateFormat={'dd/MM/yyyy'}
                        startDate={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.fromPaymentDate
                            ? new Date(parsedTransactionParams?.fromPaymentDate)
                            : null
                        }
                        endDate={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.toPaymentDate
                            ? new Date(parsedTransactionParams?.toPaymentDate)
                            : null
                        }
                        onChange={handleFromDateChange}
                        isClearable
                        wrapperClassName="start-m"
                        className="form-control datepicker-zindex"
                      />
                      <div className="input-group-addon">TO</div>
                      <DatePicker
                        showMonthYearPicker
                        selected={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.toPaymentDate
                            ? new Date(parsedTransactionParams?.toPaymentDate)
                            : null
                        }
                        selectsEnd
                        dateFormat={'dd/MM/yyyy'}
                        startDate={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.fromPaymentDate
                            ? new Date(parsedTransactionParams?.fromPaymentDate)
                            : null
                        }
                        endDate={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.toPaymentDate
                            ? new Date(parsedTransactionParams?.toPaymentDate)
                            : null
                        }
                        isClearable
                        minDate={
                          Object.keys(parsedTransactionParams).length &&
                            parsedTransactionParams?.fromPaymentDate
                            ? new Date(parsedTransactionParams?.fromPaymentDate)
                            : null
                        }
                        onChange={handleToDateChange}
                        wrapperClassName="start-m"
                        className="form-control datepicker-zindex"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isAnyDateFilterSelected ? (
            <div className="d-flex justify-content-end">
              <div className="align-self-end mt-2">
                <Button size="sm" color="danger" onClick={handleClear}>
                  Clear All Date Filters
                </Button>
              </div>
            </div>
          ) : null}
        </BlockHead>
        <Block>
          <PreviewCard className={'bg-lighter shadow-none'}>
            <table className="table table-tranx">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="bg-white py-4 text-center fs-[12px]"></th>
                  <th className="bg-white py-4 text-center fs-[12px]">Net. Amount</th>
                  <th className="bg-white py-4 text-center fs-[12px]">Number of Deals</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tb-tnx-item">
                  <td className="bg-white fw-bold fs-6" style={{ paddingBlock: '2rem' }}>Closed and Fully Paid</td>
                  <td className="bg-white py-4 text-center fs-6">
                    {finances.isLoading ? <Skeleton /> : <span className="fw-bold">{currencyFormatter({ isDecimal: true }).format(
                      finances?.data?.filter((e) => e._id === "Fully Paid")[0]?.netAmount ?? 0
                    )}</span>}
                  </td>
                  <td className="bg-white py-4 text-center fs-6">{finances.isLoading ? <Skeleton style={{ width: '3rem' }} /> :
                    finances?.data?.filter((e) => e._id === "Fully Paid")[0]?.dealCount ?? 0}</td>
                </tr>

                <tr className="tb-tnx-item">
                  <td className="bg-white fw-bold fs-6" style={{ paddingBlock: '2rem' }}>Closed and Partially Paid</td>
                  <td className="bg-white py-4 text-center fs-6">
                    {finances.isLoading ? <Skeleton /> : currencyFormatter({ isDecimal: true }).format(
                      finances?.data?.filter((e) => e._id === "Partially Paid")[0]?.netAmount ?? 0
                    )}
                  </td>
                  <td className="bg-white py-4 text-center fs-6">{finances.isLoading ? <Skeleton style={{ width: '3rem' }} /> :
                    finances?.data?.filter((e) => e._id === "Partially Paid")[0]?.dealCount ?? 0}</td>
                </tr>

                <tr className="tb-tnx-item">
                  <td className="bg-white fw-bold fs-6" style={{ paddingBlock: '2rem' }}>Closed and Unpaid</td>
                  <td className="bg-white py-4 text-center fs-6">
                    {finances.isLoading ? <Skeleton /> : currencyFormatter({ isDecimal: true }).format(
                      finances?.data?.filter((e) => e._id === "Pending")[0]?.netAmount ?? 0
                    )}
                  </td>
                  <td className="bg-white py-4 text-center fs-6">{finances.isLoading ? <Skeleton style={{ width: '3rem' }} />
                    : finances?.data?.filter((e) => e._id === "Pending")[0]?.dealCount ?? 0}</td>
                </tr>
                <tr>
                  <td className="bg-white py-4 text-end fs-6"><h6>Total:</h6> </td>
                  <td className="bg-white py-4 text-center fs-6">
                    <h6>
                      {
                        currencyFormatter({ isDecimal: true }).format(
                          (finances?.data?.filter((e) => e._id === "Fully Paid")[0]?.netAmount ?? 0)
                          + (finances?.data?.filter((e) => e._id === "Pending")[0]?.netAmount ?? 0) +
                          (finances?.data?.filter((e) => e._id === "Partially Paid")[0]?.netAmount ?? 0)

                        )

                      }
                    </h6>
                  </td>
                  <td className="bg-white py-4 text-center fs-6"></td>
                </tr>
              </tbody>
            </table>
          </PreviewCard>

        </Block>
      </Content>
    </React.Fragment>
  );
}

export default RevenueView