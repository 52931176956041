import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
  PaginationComponent
} from '../../../components/Component';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import { fetchLeadPropertyCardDetail } from '../../../redux/actions/property-cards';
import { useSelector, useDispatch } from 'react-redux';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import LeadCard from './lead-card/LeadCard';
import { HttpStatus, PropertyCardStatus, newRoles } from '../../../utils/envConfig';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import FieldDisplayView from './prop-card-detail/FieldDisplayView';
import FieldEditView from './prop-card-detail/FieldEditView';
import PropertyInfoView from './prop-card-detail/PropertyInfoView';
import PropertyInfoEdit from './prop-card-detail/PropertyInfoEdit';
import SystemInfoView from './prop-card-detail/SystemInfoView';
import { currencyFormatter, getStatusStyling } from '../utils';
import ActivityTableView from './prop-card-detail/ActivityTableView';
import PropCardNotesList from './prop-card-detail/notes/PropCardNotesList';
import PocketListingPhotoList from './prop-card-detail/attachments/PocketListingPhotoList';
import { showToast } from '../../../utils/toast/toast';
import propertyCardQueries, { PROP_CARDS_KEYS } from '../../../queries/propertyCardQueries';
import { useQuery, useQueryClient } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import ErrorModal from '../../../components/modals/ErrorModal';
import PocketListingDocumentList from './prop-card-detail/attachments/PocketListingDocumentList';

// Lazy imports
const ConfirmationModal = lazy(() => import('../../../components/modals/ConfirmationModal'));
const AssignAgentModal = lazy(() => import('./prop-card-detail/AssignAgentModal'));
const PocketListingModal = lazy(() =>
  import('../../users/components/property-cards/PocketListingModal')
);
const ConvertPocketListToListing = lazy(() => import('./modals/ConvertPocketListToListing'));

const FIFTEEN_MINUTES = 1000 * 60 * 15;
const THIRTY_MINUTES = 1000 * 60 * 30;
const LEAD_CARD_LIMIT = 5;

const PropertyCardDetailUI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const authUser = useSelector((state) => state?.user?.loggedInUser);
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);

  const [canBeCheckedOut, setCanBeCheckedOut] = useState(false);
  const [refreshView, setRefreshView] = useState(false); // this is just to force a re-render when needed
  const toggleRefresh = () => {
    setRefreshView(!refreshView);
    queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
    queryClient.invalidateQueries('propCardLogs');
  };

  const [isConfirmCheckoutOpen, setIsConfirmCheckoutOpen] = useState(false);
  const toggleConfirmCheckoutModal = () => setIsConfirmCheckoutOpen(!isConfirmCheckoutOpen);

  const [isConfirmCheckInOpen, setIsConfirmCheckInOpen] = useState(false);
  const toggleConfirmCheckInModal = () => setIsConfirmCheckInOpen(!isConfirmCheckInOpen);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isSoldModalOpen, setIsSoldModalOpen] = useState(false);
  const [isPrivateModalOpen, setIsPrivateModalOpen] = useState(false);
  const [isAssignAgentModalOpen, setIsAssignAgentModalOpen] = useState(false);

  // State to toggle between view and edit
  const [isEditHeaderFields, setIsEditHeaderFields] = useState(false);
  const toggleEditHeader = () => {
    if (isAdmin || isCheckedOutByAuthAgent) {
      setIsEditHeaderFields(!isEditHeaderFields);
    }
    return;
  };

  const [isEditSystemInfo, setIsEditSystemInfo] = useState(false);
  const toggleEditSystemInfo = () => setIsEditSystemInfo(!isEditSystemInfo);

  const [isEditPropertyInfo, setIsEditPropertyInfo] = useState(false);
  const toggleEditPropertyInfo = () => {
    if (isAdmin || isCheckedOutByAuthAgent) {
      setIsEditPropertyInfo(!isEditPropertyInfo);
    }
    return;
  };

  // loading state
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkinLoading, setCheckinLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const propertyCardDetail = useSelector((state) => state.propertyCard?.propertyCards?.data);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();
  }, []);

  useEffect(() => {
    dispatch(fetchLeadPropertyCardDetail(axios, id, true));
  }, [axios, dispatch, id, refreshView]);

  const [mappingsQueryParams, setMappingsQueryParams] = useState({
    page: 1,
    limit: LEAD_CARD_LIMIT
  });

  const { queryKey, queryFn: getMappings } = propertyCardQueries.getMappings(axios, id, {
    params: mappingsQueryParams
  });

  /**
   * @typedef {Object} QueryResult
   * @property {import('../../../api/property-cards/types').ResponseData} data
   * @property {boolean} isLoading
   * @property {boolean} isError
   * @property {Error} error
   */

  /**
   * @type QueryResult
   */
  const {
    data: leadsMappings,
    isLoading,
    isError,
    error
  } = useQuery(queryKey, getMappings, {
    refetchInterval: THIRTY_MINUTES,
    refetchOnWindowFocus: false,
    staleTime: FIFTEEN_MINUTES,
    // eslint-disable-next-line eqeqeq
    enabled: id != undefined && propertyCardDetail != undefined // only fetch if the property card Id is valid
  });

  const { data: propCardLogs, isLoading: isLogsLoading } = useQuery(
    ['propCardLogs', propertyCardDetail?.id ?? propertyCardDetail?._id],
    async () =>
      await axios.get(
        `/property-cards/get-logs?propertyCard=${propertyCardDetail?.id}&sortField=createdAt`
      ),
    {
      // eslint-disable-next-line eqeqeq
      enabled:
        propertyCardDetail != undefined &&
        (propertyCardDetail?.id ?? propertyCardDetail?._id) != undefined,
      refetchInterval: THIRTY_MINUTES,
      refetchOnWindowFocus: false,
      staleTime: FIFTEEN_MINUTES
    }
  );

  const updatePropCardInfo = useCallback(async () => {
    if (leadsMappings && leadsMappings?.data?.length && mappingsQueryParams?.page === 1) {
      setCanBeCheckedOut(
        (_) => leadsMappings?.data[0]?.available && !propertyCardDetail?.currentAgent
      );
    }
  }, [leadsMappings, mappingsQueryParams?.page, propertyCardDetail?.currentAgent]);

  useEffect(() => {
    if (propertyCardDetail && !Array.isArray(propertyCardDetail)) {
      updatePropCardInfo();
    }
  }, [axios, updatePropCardInfo, id, propertyCardDetail, refreshView]);

  const markPocketListingAsSold = async () => {
    try {
      const payload = {
        status: PropertyCardStatus.Sold
      };
      setUpdateLoading(true);
      const res = await axios.put(
        `/property-cards/${propertyCardDetail?.id ?? propertyCardDetail?._id}`,
        payload
      );
      if (res.status === HttpStatus.OK) {
        showToast('Marked as sold successfully', 'success');
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
      }
    } catch (error) {
      if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast('Too many requests. Please try again later.', 'error');
      } else {
        const serverErrorMessage = error.response.data.message;
        if (serverErrorMessage) {
          showToast(serverErrorMessage, 'error');
        } else {
          showToast('An error occurred.', 'error');
        }
      }
      console.log(error);
    } finally {
      setUpdateLoading(false);
      toggleSoldModal();
      toggleRefresh();
    }
  };
  const [isPrivateLoading, setIsPrivateLoading] = useState(false);
  const markPocketListingAsPrivate = async () => {
    try {
      const payload = {
        isPrivate: true
      };
      setIsPrivateLoading(true);
      const res = await axios.put(
        `/property-cards/${propertyCardDetail?.id ?? propertyCardDetail?._id}`,
        payload
      );
      if (res.status === HttpStatus.OK) {
        showToast('Marked as private successfully', 'success');
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
      }
    } catch (error) {
      if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast('Too many requests. Please try again later.', 'error');
      } else {
        const serverErrorMessage = error.response.data.message;
        if (serverErrorMessage) {
          showToast(serverErrorMessage, 'error');
        } else {
          showToast('An error occurred.', 'error');
        }
      }
      console.log(error);
    } finally {
      setIsPrivateLoading(false);
      togglePrivateModal();
      toggleRefresh();
    }
  };
  const checkoutPropertyCard = async () => {
    try {
      const payload = { cards: [id] };
      setCheckoutLoading(true);
      const res = await axios.post('/property-cards/checkout-leads', payload);
      if (res.status === HttpStatus.OK) {
        showToast('Checked out successfully', 'success');
        setCanBeCheckedOut(false);
        toggleConfirmCheckoutModal();
        toggleRefresh();
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
      }
    } catch (error) {
      if (error?.response?.status === HttpStatus.FORBIDDEN) {
        showToast('Must be an agent to be able to check out.', 'error');
      } else if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast('Too many requests. Please try again later.', 'error');
      } else {
        const serverErrorMessage = error.response.data.message;
        if (serverErrorMessage) {
          showToast(serverErrorMessage, 'error');
        } else {
          showToast('An error occurred.', 'error');
        }
      }
      console.log(error);
    } finally {
      setCheckoutLoading(false);
    }
  };

  const checkInPropertyCard = async () => {
    const payload = { data: [id] };
    try {
      setCheckinLoading(true);
      const res = await axios.post('/property-cards/checkin-cards', payload);
      if (res?.status === HttpStatus.OK) {
        toast.success('Checked in successfully.');
        setCanBeCheckedOut(true);
        toggleConfirmCheckInModal();
        toggleRefresh();
        queryClient.invalidateQueries(PROP_CARDS_KEYS.mappings);
      }
    } catch (error) {
      if (error?.response?.status === HttpStatus.FORBIDDEN) {
        showToast('Must be an agent to be able to check out.', 'error');
      } else if (error?.response?.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast('Too many requests. Please try again later.', 'error');
      } else {
        const serverErrorMessage = error.response.data.message;
        if (serverErrorMessage) {
          showToast(serverErrorMessage, 'error');
        } else {
          showToast('An error occurred.', 'error');
        }
      }
      console.log(error);
    } finally {
      setCheckinLoading(false);
    }
  };

  const toggleUpdateModal = () => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  };

  const toggleSoldModal = () => {
    setIsSoldModalOpen(!isSoldModalOpen);
  };

  const togglePrivateModal = () => {
    setIsPrivateModalOpen(!isPrivateModalOpen);
  };

  const toggleAssignAgentModal = useCallback(() => {
    setIsAssignAgentModalOpen(!isAssignAgentModalOpen);
  }, [isAssignAgentModalOpen]);

  const [propertyInfoFields, setPropertyInfoFields] = useState([]);
  const [systemInfoFields, setSystemInfoFields] = useState([]);

  const isPocketListing = useMemo(
    () =>
      [PropertyCardStatus.PocketListing, PropertyCardStatus.Sold].includes(
        propertyCardDetail?.status
      ),
    [propertyCardDetail?.status]
  );

  useEffect(() => {
    setPropertyInfoFields(() => {
      let fields = [
        [
          'Property Owner',
          `${propertyCardDetail?.currentOwner?.first_name ?? '-'} ${
            propertyCardDetail?.currentOwner?.last_name ?? ''
          }` ?? ''
        ],
        ['Category', propertyCardDetail?.category ?? ''],
        ['Unit Status', propertyCardDetail?.unitStatus ?? ''],
        [
          'Completion Date',
          format(new Date(propertyCardDetail?.completionDate ?? Date.now()), 'dd/MM/yyyy') ?? ''
        ]
      ];
      if (isPocketListing) {
        fields = fields.concat([
          [
            'Asking Price',
            propertyCardDetail?.askingPrice
              ? currencyFormatter({ isDecimal: true }).format(propertyCardDetail?.askingPrice)
              : ''
          ],
          [
            'Agency Valuation',
            propertyCardDetail?.agentValutionPrice
              ? currencyFormatter({ isDecimal: true }).format(
                  propertyCardDetail?.agentValutionPrice
                )
              : ''
          ],
          [
            'Pocket Listing Notes',
            propertyCardDetail?.pocketListNotes ? propertyCardDetail?.pocketListNotes : ''
          ]
        ]);
      }
      return fields;
    });

    setSystemInfoFields([
      [
        'Agent Status',
        propertyCardDetail?.currentAgent ? (
          <span
            onClick={() => {
              authUser?.role === newRoles.MasterAdmin && toggleAssignAgentModal();
            }}
          >
            <Badge color="success">Assigned</Badge> {'  '}
            <span className="sub-text">
              {propertyCardDetail?.currentAgent?.userId?.first_name}
              {'  '}
              {propertyCardDetail?.currentAgent?.userId?.last_name}
            </span>
            <span className="fs-6 cursor-pointer mx-2">
              <Icon name="edit" />
            </span>
          </span>
        ) : (
          <span
            onClick={() => {
              authUser?.role === newRoles.MasterAdmin && toggleAssignAgentModal();
            }}
          >
            <Badge color="gray">Unassigned</Badge>
            <span className="fs-6 cursor-pointer">
              <Icon name="edit" />
            </span>
          </span>
        )
      ],
      [
        'Created By',
        `${propertyCardDetail?.createdBy?.first_name ?? '-'} ${
          propertyCardDetail?.createdBy?.last_name ?? ''
        }`
      ],
      [
        'Created At',
        format(new Date(propertyCardDetail?.createdAt ?? Date.now()), "dd/MM/yyyy 'at' hh:mm a") ??
          ''
      ],
      ['Status', propertyCardDetail?.status ?? ''],
      [
        'Updated By',
        `${propertyCardDetail?.updatedBy?.first_name ?? '-'} ${
          propertyCardDetail?.updatedBy?.last_name ?? ''
        }`
      ],
      [
        'Updated At',
        format(new Date(propertyCardDetail?.updatedAt ?? Date.now()), "dd/MM/yyyy 'at' hh:mm a") ??
          ''
      ]
    ]);
  }, [authUser?.role, axios, isPocketListing, propertyCardDetail, toggleAssignAgentModal]);

  const isCheckedOutByAuthAgent =
    authAgent &&
    propertyCardDetail?.currentAgent != null &&
    (authAgent.id ?? authAgent._id)?.toString() ===
      (propertyCardDetail?.currentAgent?.id ?? propertyCardDetail?.currentAgent?._id)?.toString();

  const isAdmin = authUser.role === newRoles.Admin || authUser.role === newRoles.MasterAdmin;

  const [convertModal, setConvertModal] = useState(false);
  const [converting, setConverting] = useState(false);
  const toggleConvertModal = () => setConvertModal((c) => !c);
  const handleConvertToListing = async (payload) => {
    try {
      setConverting(true);
      const { data: deal } = await axios.patch(
        `/property-cards/pocket-listing/${id}/to-listing-acquired`,
        payload
      );
      toast.success('Converted Successfully\n Redirecting To Listing Page');
      navigate(`/deal-details/${deal?.id ?? deal._id}`);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setConverting(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Property Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3 d-flex align-items-end">
            <BlockHeadContent>
              <Link>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex mb-4"
                  onClick={() => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </Link>
              <BlockTitle tag="h3">
                Property Card/{' '}
                <strong className="text-primary small">
                  {propertyCardDetail?.referenceNumber}
                </strong>
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="btn-icon bg-white d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <Icon name="arrow-left"></Icon>
              </Button>
            </BlockHeadContent>
            <BlockHeadContent className="mt-4">
              <div className="d-flex flex-wrap-reverse" style={{ gap: '.5rem' }}>
                {PropertyCardStatus.PocketListing ? (
                  <Button onClick={toggleConvertModal} color="primary" className={'mx-2'}>
                    <Icon name="edit" className=""></Icon>
                    <span>Convert to Listing Acquired</span>
                  </Button>
                ) : null}
                {(isAdmin || isCheckedOutByAuthAgent) &&
                ![PropertyCardStatus.PocketListing, PropertyCardStatus.Sold].includes(
                  propertyCardDetail?.status
                ) ? (
                  <Button onClick={toggleUpdateModal} color="secondary">
                    <Icon name="edit" className=""></Icon>
                    <span>Convert to Pocket Listing</span>
                  </Button>
                ) : null}
                {(isAdmin || isCheckedOutByAuthAgent) &&
                isPocketListing &&
                !propertyCardDetail?.isPrivate ? (
                  <Button onClick={togglePrivateModal} className={'me-2'} color="primary">
                    <Icon name="lock-alt" className=""></Icon>
                    <span>Convert to Private Pocket Listing</span>
                  </Button>
                ) : null}
                {(isAdmin || isCheckedOutByAuthAgent) &&
                propertyCardDetail?.status === PropertyCardStatus.PocketListing ? (
                  <Button onClick={toggleSoldModal} color="secondary">
                    <Icon name="check" className=""></Icon>
                    <span>Mark as Sold</span>
                  </Button>
                ) : null}
                {(isAdmin || isCheckedOutByAuthAgent) &&
                propertyCardDetail?.status === PropertyCardStatus.PocketListing ? (
                  <a
                    href={`https://alba.homes/download-pocket/${
                      propertyCardDetail?.id ?? propertyCardDetail?._id
                    }`}
                    className="btn btn-secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View as PDF
                  </a>
                ) : null}
                {(propertyCardDetail?.availableForCheckout ?? canBeCheckedOut) &&
                propertyCardDetail?.status !== PropertyCardStatus.PocketListing ? (
                  <Button
                    onClick={toggleConfirmCheckoutModal}
                    disabled={isAdmin}
                    color="primary"
                    className="ml-2"
                  >
                    <Icon name="check"></Icon>
                    <span>Assign to Me</span>
                  </Button>
                ) : authAgent && isCheckedOutByAuthAgent ? (
                  <Button onClick={toggleConfirmCheckInModal} color="success" className="ml-2">
                    <Icon name="undo"></Icon>
                    <span>Return</span>
                  </Button>
                ) : null}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            <Row className="p-4">
              <Col lg={8}>
                {isEditHeaderFields ? (
                  <FieldEditView
                    propertyCardDetail={propertyCardDetail}
                    toggleEdit={toggleEditHeader}
                    refreshFn={toggleRefresh}
                  />
                ) : (
                  <FieldDisplayView
                    propertyCardDetail={propertyCardDetail}
                    toggleEdit={toggleEditHeader}
                  />
                )}
              </Col>

              <Col sm="4" className="">
                <div className="d-flex justify-content-end flex-wrap" style={{ gap: '.5rem' }}>
                  <span
                    className={`py-2 ${getStatusStyling(propertyCardDetail?.status ?? '')} pill `}
                  >
                    {propertyCardDetail?.status ?? 'Unknown Status'}
                  </span>
                  {propertyCardDetail?.isPrivate ? (
                    <span
                      className={`py-2 px-3 badge bg-primary-dim border border-primary text-primary fw-semibold rounded rounded-pill`}
                    >
                      Private
                    </span>
                  ) : null}
                </div>
              </Col>
            </Row>
            {/* LEAD INFORMATION */}
            <Row className="p-4">
              <Col sm="12">
                <BlockTitle tag="h5" className="mb-3">
                  Leads
                </BlockTitle>
                {isError ? (
                  <p>
                    Error fetching leads: <span className="text-danger">{error?.message}</span>
                  </p>
                ) : null}
                {isLoading ? (
                  <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                    {new Array(3).fill(0).map((_, i) => (
                      <Skeleton
                        key={`skeleton-lead-${i}`}
                        style={{
                          width: 'clamp(18rem, 22rem, 24rem)',
                          height: propertyCardDetail?.currentAgent
                            ? 'clamp(12rem, 14rem, 16rem)'
                            : 'clamp(8rem, 10rem, 12rem)'
                        }}
                      />
                    ))}
                  </div>
                ) : null}
                {leadsMappings?.found ? (
                  <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                    {leadsMappings?.data?.map((item, idx) => (
                      <LeadCard
                        leadToPropCardMapping={item}
                        key={`lead-${idx}`}
                        isCheckedOut={isCheckedOutByAuthAgent}
                        refreshFn={toggleRefresh}
                      />
                    ))}
                  </div>
                ) : isLoading ? null : (
                  <p>No leads available for this Property Card</p>
                )}
                {leadsMappings?.found && leadsMappings?.found > mappingsQueryParams?.limit ? (
                  <div className="mt-3 d-flex justify-content-start">
                    <PaginationComponent
                      currentPage={mappingsQueryParams?.page}
                      itemPerPage={mappingsQueryParams?.limit}
                      totalItems={leadsMappings?.found}
                      paginate={(newPage) =>
                        setMappingsQueryParams((prev) => ({ ...prev, page: newPage }))
                      }
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
            {/* END OF LEAD INFORMATION */}
            <Row className="flex-lg-row-reverse p-4">
              <Col sm="12" lg="7">
                {isEditPropertyInfo ? (
                  <PropertyInfoEdit
                    propertyCardDetail={propertyCardDetail}
                    shownFieldsData={propertyInfoFields}
                    toggleEdit={toggleEditPropertyInfo}
                    toggleRefresh={toggleRefresh}
                  />
                ) : (
                  <PropertyInfoView
                    propertyCardDetail={propertyCardDetail}
                    shownFieldsData={propertyInfoFields}
                    toggleEdit={toggleEditPropertyInfo}
                  />
                )}
              </Col>
              <Col sm="12" lg="5">
                <SystemInfoView
                  shownFieldsData={systemInfoFields}
                  toggleEdit={toggleEditSystemInfo}
                />
              </Col>
            </Row>
            {/* IMAGES SECTION */}
            {isPocketListing ? (
              <Row className="p-4">
                <Col lg="12" className="mt-2">
                  <PocketListingPhotoList
                    propertyCard={propertyCardDetail}
                    isAdminOrAssigned={isAdmin || isCheckedOutByAuthAgent}
                    refreshFn={toggleRefresh}
                  />
                </Col>
              </Row>
            ) : null}
            {isPocketListing ? (
              <Row className="p-4">
                <Col lg="12" className="mt-2">
                  <PocketListingDocumentList
                    propertyCard={propertyCardDetail}
                    isAdminOrAssigned={isAdmin || isCheckedOutByAuthAgent}
                    refreshFn={toggleRefresh}
                  />
                </Col>
              </Row>
            ) : null}
            {/* END OF IMAGES SECTION */}
            {/* NOTES SECTION */}
            <Row className="p-4">
              <Col lg="12" className="mt-2">
                <PropCardNotesList
                  propertyCard={propertyCardDetail}
                  isAdminOrAssigned={isAdmin || isCheckedOutByAuthAgent}
                />
              </Col>
            </Row>
            {/* END OF NOTES SECTION */}
            {/* ACTIVITY HISTORY */}
            <Row className="p-4">
              <Col lg="12" className="mt-2">
                <BlockHead>
                  <BlockHeadContent>
                    <BlockTitle>
                      <h5 className="nk-block-title title">Property Card Activity History</h5>
                    </BlockTitle>
                  </BlockHeadContent>
                </BlockHead>
                <ActivityTableView logsData={propCardLogs} isLogsLoading={isLogsLoading} />
              </Col>
            </Row>
            {/* END OF ACTIVITY HISTORY */}
          </Card>
        </Block>
      </Content>
      <Suspense fallback={<div>Loading...</div>}>
        <PocketListingModal
          isOpen={isUpdateModalOpen}
          toggleFn={toggleUpdateModal}
          refreshFn={toggleRefresh}
          card={propertyCardDetail}
        />
        <AssignAgentModal
          isOpen={isAssignAgentModalOpen}
          toggleFn={toggleAssignAgentModal}
          refreshFn={toggleRefresh}
          propCardId={propertyCardDetail?.id}
        />
        <ConfirmationModal
          isOpen={isSoldModalOpen}
          toggleFn={toggleSoldModal}
          title={'Confirm Sold Pocket Listing'}
          actionFn={markPocketListingAsSold}
          loadingState={updateLoading}
        >
          <p>Are you sure you want to mark this Pocket Listing as sold?</p>
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={isConfirmCheckoutOpen}
          toggleFn={toggleConfirmCheckoutModal}
          title={'Confirm Assignment'}
          actionFn={checkoutPropertyCard}
          loadingState={checkoutLoading}
        >
          <p>Are you sure you want to assign this Property Card to yourself?</p>
        </ConfirmationModal>
        <ConfirmationModal
          isOpen={isConfirmCheckInOpen}
          toggleFn={toggleConfirmCheckInModal}
          title={'Confirm Return'}
          actionFn={checkInPropertyCard}
          isDeletion={isPocketListing}
          loadingState={checkinLoading}
        >
          {isPocketListing ? (
            <p>
              Are you sure you want to cancel this Pocket Listing and return the property card back
              into the pool?
            </p>
          ) : (
            <p>Are you sure you want to return this Property Card back into the pool?</p>
          )}
        </ConfirmationModal>
        {propertyCardDetail?.photos && propertyCardDetail?.photos?.length >= 3 ? (
          <ConfirmationModal
            isOpen={isPrivateModalOpen}
            toggleFn={togglePrivateModal}
            title={'Confirm Private Pocket Listing'}
            actionFn={markPocketListingAsPrivate}
            loadingState={isPrivateLoading}
            dimButtons
          >
            <p>Are you sure you want to make this Pocket Listing private?</p>
          </ConfirmationModal>
        ) : (
          <ErrorModal
            isOpen={isPrivateModalOpen}
            toggleFn={togglePrivateModal}
            header={'Invalid Pocket Listing'}
          >
            <p>The listing must have at least 3 images uploaded before it can be made private.</p>
            <Button onClick={togglePrivateModal} color={'secondary'}>
              OK
            </Button>
          </ErrorModal>
        )}
        <ConvertPocketListToListing
          isAdmin={isAdmin}
          loading={converting}
          propertyCardDetail={propertyCardDetail}
          authAgent={authAgent}
          actionFn={handleConvertToListing}
          isOpen={convertModal}
          toggleFn={toggleConvertModal}
        />
      </Suspense>
    </React.Fragment>
  );
};
export default PropertyCardDetailUI;
