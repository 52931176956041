import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { HttpStatus, PropertyCardStatus } from "../../../utils/envConfig";
import {
  Badge,
  Button,
  Collapse,
  Label,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import { CHECKOUT_LIST_FIELDS } from "../constats";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { debounce } from "lodash";
import DatePicker from "react-datepicker";
import LiveTimeLeftBadge from "./LiveTimeLeftBadge";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { fetchCheckedOutPropCards } from "../../../redux/actions/property-cards";
import LoadingComponent from "../../components/loader/tableLoader";
import { fetchLoggedInAgent } from "../../../redux/actions/user";
import { showToast } from "../../../utils/toast/toast";
import ExplorerCommunityFilter from "../../components/filter-components/explorer-specific/ExplorerCommunityFilter";
import BuildingFilter from "../../components/filter-components/BuildingFilter";

const CheckedOutTableItem = ({
  propCard,
  index,
  toggleRefresh,
  toggleRefreshAgent,
  navigationState,
  selectorFn
}) => {
  const axios = useAxiosPrivate();

  const checkInPropertyCard = async (payload) => {
    try {
      setIsCheckinLoading(true);
      const res = await axios.post("/property-cards/checkin-cards", payload);
      if (res?.status === HttpStatus.OK) {
        showToast("Returned successfully.", 'success');
        toggleRefresh();
        toggleRefreshAgent();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === HttpStatus.FORBIDDEN) {
        showToast("Only Agents can checkout a property card.", 'error');
        return;
      }
      if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast("You've reached your request limit. Please wait a moment and try again later. Thank you for your patience!", 'error');
        return;
      }
      const serverErrorMessage = error.response?.data?.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, 'error');
      } else {
        showToast("An error occurred. Couldn't check in this property card.", 'error');
      }
    } finally {
      setIsCheckinLoading(false);
    }
  };

  const [isCheckinLoading, setIsCheckinLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isPocketListingConfirmOpen, setIsPocketListingConfirmOpen] = useState(false);
  const togglePocketListingModal = () =>
    setIsPocketListingConfirmOpen(!isPocketListingConfirmOpen);
  return (
    <>
      <DataTableItem
        key={index}
        className={navigationState?.includes(propCard?.id) ? "bg-info-dim" : ""} // highlight row if it was just checked out
      >
        <DataTableRow className="nk-tb-col-check">
          <div className="custom-control custom-control-sm custom-checkbox notext">
            <input
              type="checkbox"
              className="custom-control-input"
              // disabled={!canBeCheckedOut}
              defaultChecked={propCard?.checked}
              id={index + "oId-all"}
              key={Math.random()}
              onChange={(e) => selectorFn(e, propCard)}
            />
            <label
              className="custom-control-label"
              htmlFor={index + "oId-all"}
            ></label>
          </div>
        </DataTableRow>
        <DataTableRow className="text-center">
          <Link to={`/property-detail/${propCard?.id}`}>
            {propCard?.referenceNumber}
          </Link>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.propertyType}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.community?.community}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>
            {propCard?.building ? propCard?.building?.name : "Not Available"}
          </span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.unitId}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.size}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.beds === 0 ? "-" : propCard?.beds}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.baths === 0 ? "-" : propCard?.baths}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          <span>{propCard?.leadsCount ?? "-"}</span>
        </DataTableRow>
        <DataTableRow className="text-center">
          {!isExpired ? <LiveTimeLeftBadge date={propCard?.expirationDate} onStatusChange={() => setIsExpired(true)} /> : null}
        </DataTableRow>
        <DataTableRow className="text-center">
          <div className="tb-odr-btns d-flex justify-content-end align-items-center">
            <Button
              color="danger"
              size="sm"
              className="btn btn-dim me-1"
              disabled={isCheckinLoading}
              onClick={() => {
                if (propCard?.status === PropertyCardStatus.PocketListing) {
                  togglePocketListingModal();
                } else {
                  checkInPropertyCard({ data: [propCard?.id] })
                }
              }}
            >
              {isCheckinLoading ? "Loading..." : "Return"}
            </Button>
          </div>
        </DataTableRow>
      </DataTableItem>

      <ConfirmationModal
        isOpen={isPocketListingConfirmOpen}
        toggleFn={togglePocketListingModal}
        title={"Confirm Returning Pocket Listing"}
        isDeletion={true}
        loadingState={isCheckinLoading}
        actionFn={() => checkInPropertyCard({ data: [propCard?.id] })}
      >
        <p className="p-2 fs-6">
          Are you sure you want to cancel this Pocket Listing?
        </p>
      </ConfirmationModal>
    </>
  );
};

const CheckedOutList = ({ navigationState = null }) => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const navigate = useNavigate();

  const [tableSm, setTableSm] = useState(false);
  // const [checkedOutProperties, setCheckedOutProperties] = useState([]);

  // FILTERS STATE
  const [isSearchClosed, setIsSearchClosed] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [rangeStart, setRangeStart] = useState(
    query?.from_date ? new Date(query?.from_date) : null
  );
  const [rangeEnd, setRangeEnd] = useState(
    query?.to_date ? new Date(query?.to_date) : null
  );
  const [dateError, setDateError] = useState("");

  const [page, setPage] = useState(query?.page ? Number(query?.page) : 1);
  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(
    query?.limit ? Number(query?.limit) : 10
  );
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [refreshAgent, setRefreshAgent] = useState(false);
  const toggleRefresh = () => setRefresh(!refresh);
  const toggleRefreshAgent = () => setRefreshAgent(!refreshAgent);
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [isBulkCheckInModalOpen, setIsBulkCheckInModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]); // this is for use with checkboxes
  const [propertyCardsWithChecks, setPropertyCardsWithChecks] = useState([]); // this will contain all property cards with a "checked" property
  const [sort, setSort] = useState({ field: "expirationDate", order: "ASC" });

  const memoizedSelectedCommunities = useMemo(() => [selectedCommunity].filter(x => x), [selectedCommunity]);


  const toggleSortDirection = () => {
    if (sort.order === "ASC") {
      setSort({ field: "expirationDate", order: "DESC" });
    } else {
      setSort({ field: "expirationDate", order: "ASC" });
    }
    queryCheckedOut({
      sort: `${sort.field}_${sort.order}`,
    });
  };

  // Redux state
  const authAgent = useSelector((state) => state?.user?.loggedInAgent);
  const { checkedOutProperties, checkedoutLoading, checkedOutError } =
    useSelector((state) => ({
      checkedOutProperties: state?.propertyCard?.checkedoutCards,
      checkedoutLoading: state?.propertyCard?.checkedoutLoading,
      checkedOutError: state?.propertyCard?.checkedoutError,
    }));

  useEffect(() => {
    // This runs every time a filter or search changes to re-fetch the data

    const queryString = queryCheckedOut(
      {
        perPage,
        page,
        communities: selectedCommunity ? selectedCommunity?.value : null,
        buildings: selectedBuilding ? selectedBuilding?.value : null,
      },
      false
    );
    dispatch(fetchCheckedOutPropCards(axios, queryString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authAgent, location.search, refresh]);

  useEffect(() => {
    if (authAgent) {
      dispatch(fetchLoggedInAgent(axios, authAgent?.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAgent]);

  useEffect(() => {
    // this will run when the redux dispatch fetches the list of property cards
    // we save it as state to add a "checked" property later
    // this is used for the checkbox logic
    setPropertyCardsWithChecks(checkedOutProperties.data);
  }, [checkedOutProperties]);

  const queryCheckedOut = (params, updateRoute = true) => {
    // This function builds the query string and refreshes the page with the sent params
    const checkedOutParams = {
      limit: params?.perPage ?? query?.limit,
      page: params?.page,
      search: params?.search ?? query?.search,
      checkoutFrom: params?.fromDate ?? query?.checkoutFrom,
      checkoutTo: params?.toDate ?? query?.checkoutTo,
      currentAgent: authAgent?.id ?? "",
      community: params?.community ?? query?.community,
      building: params?.building ?? query?.building,
      withLeadsCount: true,
      sort: params?.sort ?? query?.sort ?? undefined,
      isPending: params?.isPending ?? query?.isPending ?? undefined,
    };

    const queryParams = new URLSearchParams();

    Object.keys(checkedOutParams).forEach((key) => {
      const value = checkedOutParams[key];
      if (Array.isArray(value)) {
        value.forEach((subValue) => queryParams.append(key, subValue));
        // eslint-disable-next-line eqeqeq
      } else if (
        // eslint-disable-next-line eqeqeq
        value != undefined &&
        value !== "null"
      ) {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();
    // console.log(queryString);

    if (updateRoute) {
      const navigateParams = {};

      if (location.search.length) {
        navigateParams.replace = true;
      }

      navigate(`${"/list-checked-out"}?${queryString}`, navigateParams);
    }
    return queryString;
  };

  const handleSearchSubmit = (event) => {
    setSearchText(event.target.value);
    debounceSearchPropertyCards({ search: event.target.value });
  };

  const handleCommunityChange = (selectedOption) => {
    setSelectedCommunity(
      _ =>
      selectedOption.value === "null"
        ? null
        : selectedOption
    );
    queryCheckedOut({
      community: selectedOption?.value,
    });
    setPage(1);
  };

  const handleBuildingChange = (selectedOption) => {
    setSelectedBuilding(
      selectedOption.value === "null"
        ? null
        : selectedOption
    );
    queryCheckedOut({
      building: selectedOption?.value,
    });
    setPage(1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchQuery = useCallback(queryCheckedOut, []);

  const debounceSearchPropertyCards = useMemo(() => {
    return debounce(updateSearchQuery, 500);
  }, [updateSearchQuery]);

  const toggleCheckInModal = () => setIsCheckInModalOpen(!isCheckInModalOpen);
  const toggleBulkCheckInModal = () =>
    setIsBulkCheckInModalOpen(!isBulkCheckInModalOpen);

  // Checkbox logic
  const selectAllPropertyCards = (event) => {
    // Global checkbox
    const isChecked = event.target.checked;
    const checkedItems = propertyCardsWithChecks.map((item) => {
      item.checked = isChecked;
      return item;
    });

    const selectedItems = checkedItems
      .filter((item) => item.checked)
      .map((item) => ({ id: item.id, ...item }));

    setSelectedItems(selectedItems);
  };

  const selectItem = (event, item) => {
    let propertyCardsCopy = checkedOutProperties.data;
    let selectedIndex = propertyCardsCopy.findIndex((x) => x?.id === item?.id);
    const isChecked = event.target.checked;
    propertyCardsCopy[selectedIndex].checked = isChecked;
    setPropertyCardsWithChecks([...propertyCardsCopy]);

    setSelectedItems((currentSelectedItems) => {
      if (isChecked) {
        // Add itemId to selectedItems if it's checked and not already present
        return [
          ...currentSelectedItems,
          { id: item?.id, ...item },
        ];
      } else {
        // Remove itemId from selectedItems if it's unchecked
        return currentSelectedItems.filter((x) => x?.id !== item?.id);
      }
    });
  };

  const [isBulkLoading, setIsBulkLoading] = useState(false);
  const checkInPropertyCards = async () => {
    // this is for bulk checkout
    if (!selectedItems.length) {
      showToast("Select some cards first!", 'error');
      return;
    }
    try {
      setIsBulkLoading(true);
      const payload = {
        data: selectedItems.map((item) => item?.id?.toString()),
      };
      const res = await axios.post("/property-cards/checkin-cards", payload);

      if (res?.status === HttpStatus.OK) {
        showToast("Successfully returned", 'success');
        setRefresh(!refresh);
        setRefreshAgent(!refreshAgent);
        toggleCheckInModal();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === HttpStatus.FORBIDDEN) {
        showToast("Only Agents can checkout a property card.", 'error');
        return;
      }
      if (error.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        showToast("You've reached your request limit. Please wait a moment and try again later. Thank you for your patience!", 'error');
        return;
      }
      const serverErrorMessage = error.response?.data?.message;
      if (serverErrorMessage) {
        showToast(serverErrorMessage, 'error');
      } else {
        showToast("An error occurred. Couldn't check in this property card.", 'error');
      }
      setSelectedItems([]);
    } finally {
      setSelectedItems([]);
      setPropertyCardsWithChecks(
        propertyCardsWithChecks.map((item) => ({ ...item, checked: false }))
      );
      toggleBulkCheckInModal();
      setIsBulkLoading(false);
    }
  };

  const isAnyFilterSet = selectedCommunity || selectedBuilding;

  const clearAllFilters = () => {
    // Reset filter states
    setSelectedCommunity(null);
    setSelectedBuilding(null);
    queryCheckedOut({ community: "null", building: "null" });
    // Refresh the data without filters
    setRefresh(!refresh);
  };

  return (
    <React.Fragment>
      <Head title={authAgent ? `${authAgent?.user?.first_name}'s Assigned Properties` : "Assigned Properties"}></Head>
      <Content>
        {/* =================== PAGE HEADER =========================== */}
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                {authAgent ? `Your Assigned Properties` : "Assigned Properties"}
                </BlockTitle>
                <BlockDes className="fs-6">
                  {authAgent ? (
                    <p>
                      You have{" "}
                      <strong className="text-primary">
                        {authAgent?.creditsBalance <= 0 ? 0 : authAgent?.creditsBalance ?? "-"}
                      </strong>{" "}
                      credits left (Out of{" "}
                      <strong className="text-primary">
                        {authAgent?.creditsLimit ?? "-"}
                      </strong>
                      )
                    </p>
                  ) : null}
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
          </BlockBetween>
          {/* ================== FILTERS =======================  */}
          <div className="d-flex">
            <div style={{ flex: 3 }}>
              <div className="d-flex justify-content-end align-items-end">
                <div style={{ marginBottom: "1rem", marginRight: "-0.8rem", display: "flex" }}>
                  <div className="form-group position-relative">
                    <Label>Date of Assignment</Label>
                    <div
                      className="position-absolute text-danger top-0"
                      style={{ right: "0" }}
                    >
                      {dateError}
                    </div>
                    <div className="form-control-wrap">
                      <div className="input-daterange date-picker-range input-group datepicker-zindex">
                        <DatePicker
                          selected={rangeStart}
                          onChange={(date) => {
                            if (rangeEnd && date > rangeEnd) {
                              setDateError(
                                "Start date cannot be greater than end date"
                              );
                              return;
                            }
                            setRangeStart(date);
                            queryCheckedOut({
                              fromDate: date ? date.toString() : "null",
                            });
                            setPage(1);
                          }}
                          selectsStart
                          startDate={rangeStart}
                          endDate={rangeEnd}
                          maxDate={rangeEnd}
                          wrapperClassName="start-m"
                          className="form-control"
                        />
                        <div className="input-group-addon">TO</div>
                        <DatePicker
                          selected={rangeEnd}
                          onChange={(date) => {
                            if (rangeStart && date < rangeStart) {
                              setDateError(
                                "Start date cannot be greater than end date"
                              );
                              return;
                            }
                            setRangeEnd(date);
                            queryCheckedOut({
                              toDate: date ? date.toString() : "null",
                            });
                            setPage(1);
                          }}
                          startDate={rangeStart}
                          endDate={rangeEnd}
                          selectsEnd
                          minDate={rangeStart}
                          wrapperClassName="end-m"
                          className="form-control datepicker-zindex"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-end">
                <ul className="nk-block-tools g-3">
                  <li>
                    <ExplorerCommunityFilter
                      placeholder="Places"
                      axiosInstance={axios}
                      dispatch={dispatch}
                      onChangeCallback={handleCommunityChange}
                      selected={selectedCommunity}
                      wide
                    />
                  </li>
                  <li>
                    <BuildingFilter
                      axiosInstance={axios}
                      dispatch={dispatch}
                      labelFormatString={`$COMMUNITY-$BUILDING`}
                      onChangeCallback={handleBuildingChange}
                      selected={selectedBuilding}
                      selectedCommunities={memoizedSelectedCommunities}
                      isDisabled={!selectedCommunity}
                      wide
                    />
                  </li>
                </ul>
              </div>
              <Collapse isOpen={selectedItems.length !== 0}>
                <div className="mt-3 d-flex justify-content-start">
                  <Button
                    color="danger"
                    onClick={() => {
                      toggleBulkCheckInModal();
                    }}
                    disabled={isBulkLoading}
                  >
                    {isBulkLoading ? "Loading..." : `Return All (${selectedItems.length})`}
                  </Button>
                </div>
              </Collapse>
            </div>
          </div>
          {isAnyFilterSet ? (
            <div className="mt-2 d-flex justify-content-end">
              <Badge
                color="danger"
                className="cursor-pointer"
                onClick={clearAllFilters}
              >
                Clear All
              </Badge>
            </div>
          ) : null}
          {/* ================== END OF FILTERS =======================  */}
        </BlockHead>
        {/* =================== END OF PAGE HEADER =========================== */}
        {/* =================== MAIN BLOCK ======================= */}
        <Block>
          <DataTable>
            {/* =================== DATA TABLE ======================= */}
            {/* ============= SEARCH CARD ================ */}
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setIsSearchClosed(!isSearchClosed);
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tableSm ? "active" : ""
                            }`}
                          onClick={() => setTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${tableSm ? "content-active" : ""
                            }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => setTableSm(false)}
                              >
                                <Icon className="arrow-left"></Icon>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!isSearchClosed && "active"
                  }`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active bg-transparent text-secondary"
                      onClick={() => {
                        setSearchText("");
                        setIsSearchClosed(!isSearchClosed);
                        queryCheckedOut({ search: "" });
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control ps-5  bg-transparent text-secondary"
                      placeholder="Search property by Unit ID"
                      value={searchText}
                      onChange={handleSearchSubmit}
                    />
                    <Button className="search-submit btn-icon bg-transparent text-secondary border-0">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* ============= END OF SEARCH CARD ================ */}
            {checkedoutLoading ? (
              <LoadingComponent />
            ) : (
              <DataTableBody>
                <DataTableHead className="nk-tb-item">
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check-all"
                        onChange={(e) => selectAllPropertyCards(e)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-all"
                      ></label>
                    </div>
                  </DataTableRow>
                  {CHECKOUT_LIST_FIELDS.map((item, idx) => (
                    <DataTableRow key={idx} className="text-center">
                      {item === "Expires After" ? (
                        <span
                          className="sub-text"
                          onClick={() => console.log(checkedOutProperties.data)}
                        >
                          {item}
                          <em
                            className="icon ni ni-sort-v cursor-pointer mx-1"
                            onClick={toggleSortDirection}
                          ></em>
                        </span>
                      ) : (
                        <span className="sub-text">{item}</span>
                      )}
                    </DataTableRow>
                  ))}
                  <DataTableRow className="nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1 my-n1"></ul>
                  </DataTableRow>
                </DataTableHead>
                {checkedOutProperties.data?.length > 0 &&
                  checkedOutProperties.data?.map((propCard, idx) => (
                    <CheckedOutTableItem
                      propCard={propCard}
                      index={idx}
                      navigationState={navigationState}
                      selectorFn={selectItem}
                      toggleRefresh={toggleRefresh}
                      toggleRefreshAgent={toggleRefreshAgent}
                      key={`item-${idx}`}
                    />
                  ))}
              </DataTableBody>
            )}
            {/* =================== END OF DATA TABLE ======================= */}
            <div className="card-inner">
              {!checkedoutLoading && checkedOutProperties.data?.length > 0 ? (
                <PaginationComponent
                  currentPage={page ?? 1}
                  paginate={(page) => {
                    setPage(page);
                    queryCheckedOut({ perPage, page }, true);
                  }}
                  itemPerPage={perPage ?? 10}
                  totalItems={checkedOutProperties?.found}
                />
              ) : !checkedoutLoading ? (
                <div className="text-center">
                  <span
                    className={`text-silent ${checkedOutError ? "text-danger" : ""
                      }`}
                  >
                    {checkedOutError
                      ? checkedOutError.response?.status ===
                        HttpStatus.FORBIDDEN
                        ? "Checked out property cards are for agents only."
                        : checkedOutError.response?.data
                      : "No data found"}
                  </span>
                </div>
              ) : null}
            </div>
          </DataTable>
        </Block>
        {/* =================== END OF MAIN BLOCK ======================= */}

        <ConfirmationModal
          isOpen={isBulkCheckInModalOpen}
          toggleFn={toggleBulkCheckInModal}
          title={"Confirm Bulk Return"}
          actionFn={checkInPropertyCards}
          loadingState={isBulkLoading}
        >
          {selectedItems?.length &&
            selectedItems?.some((propCard) => propCard?.status === PropertyCardStatus?.PocketListing)
            ? <p className="fs-6">
              There are one or more <strong>Pocket Listings</strong> among the properties you selected, this action will cancel all of them, are you sure?
            </p>
            : <p className="fs-6">
              Are you sure you want to return these Property Cards back into the
              pool?
            </p>
          }
        </ConfirmationModal>
      </Content>
    </React.Fragment>
  );
};

export default CheckedOutList;
