import React, { useState } from "react";
import
{
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Icon } from '../Component';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import
{
    HttpStatus,
    newRoles,
} from '../../utils/envConfig';
import { humanizeDate } from '../../pages/users/components/property-cards/utils';
import { formatValue } from '../../utils/envConfig';
import ConfirmationModal from "../modals/ConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useMutation, useQueryClient } from "react-query";
import { hasValue } from "../../utils/Utils";


const formatPropertyAddress = (propertyCard) => {
  const community = propertyCard?.community?.community ?? 'Unknown community';
  const building = propertyCard?.building?.name ?? 'Unknown building';
  const cluster = propertyCard?.cluster;
  
  let result = `${community}, ${building}`;
  if (cluster && cluster?.length) {
    result += `, ${cluster}`;
  }
  return result;
};

const PropertyCard = ( { data, lead } ) =>
{
    const navigate = useNavigate();
    const authAgent = useSelector( ( state ) => state?.user?.loggedInAgent );
    const authUser = useSelector( ( state ) => state?.user?.loggedInUser );
    const axios = useAxiosPrivate();

    const isAdmin =
        authUser &&
        ( authUser.role === newRoles.Admin ||
            authUser.role === newRoles.MasterAdmin );


    const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState( false );
    const queryClient = useQueryClient();
    const toggleUnlinkConfirmModal = () =>
        setIsConfirmModalOpen( !isConfirmModalOpen );

    const unlinkLeadMutation = useMutation(
        async ( id ) =>
        {
            const res = await axios.delete( `/property-cards/lead-to-card/${ id }` );
            if ( res.status !== HttpStatus.OK )
            {
                throw new Error( 'Failed to unlink lead' );
            }
            return res;
        },
        {
            onSuccess: () =>
            {
                queryClient.invalidateQueries( [ "propertyCards", lead.id ] );
                toast.success( 'Unlinked lead' );
            },
            onError: ( error ) =>
            {
                toast.error( 'An error occurred.' );
                console.log( error );
            },
            onSettled: () =>
            {
                toggleUnlinkConfirmModal();
            }
        }
    );

    const unlinkLead = () =>
    {
        unlinkLeadMutation.mutate( data?._id ?? data?.id );
    };

    return (
        <div className="card card-inner mt-5">
            <div className="d-flex align-center justify-between">
                <div className="">
                    <span className="fw-bold ">Ref No: </span>
                    <span className="">
                        { data?.propertyCard?.referenceNumber ?? '?' }{ ' ' }
                    </span>
                </div>
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="a"
                        href="#"
                        onClick={ ( ev ) => ev.preventDefault() }
                        className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1"
                    >
                        <Icon name="more-v"></Icon>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ul className="link-list-opt no-bdr">
                            <li>
                                <DropdownItem
                                        tag="a"
                                        onClick={ ( e ) =>
                                        {
                                            navigate(
                                                `/property-detail/${ data?.propertyCard?.id ??
                                                data?.propertyCard?._id
                                                }`
                                            );
                                        } }
                                    >
                                        <Icon name="eye"></Icon>
                                        <span>View Property Card</span>
                                </DropdownItem>
                            </li>

                            <li>
                                <DropdownItem
                                    tag="a"
                                    href="#dropdownuunlink"
                                    onClick={ toggleUnlinkConfirmModal }
                                >
                                    <Icon name="unlink-alt"></Icon>
                                    <span>Unlink</span>
                                </DropdownItem>
                            </li>
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div>
                <h6 className="my-2" style={ { lineClamp: "2", height: "2rem", maxHeight: "4em" } }>
                  {formatPropertyAddress(data?.propertyCard)}
                </h6>
            </div>
            <div className="">
                <div>
                    <span className="fw-bold text-dark">
                        Beds:{ ' ' }
                    </span>
                    <span>
                        { hasValue(data?.propertyCard?.beds) ? data.propertyCard?.beds : 'Unknown' }
                    </span>
                </div>
            </div>
            <div>
                <div>
                    <span className="fw-bold text-dark">
                        Area:{ ' ' }
                    </span>
                    <span>
                        { hasValue(data?.propertyCard?.size) ? Number( data?.propertyCard?.size ).toFixed( 2 ) : 'Unknown' }{ ' ' }
                        { hasValue(data?.propertyCard?.size) ? 'sqft.' : '' }
                    </span>
                </div>
                <div>
                    <span className="fw-bold text-dark">
                        Amount:{ ' ' }
                    </span>
                    <span>{ hasValue(data?.amount) ? formatValue( data?.amount ) : 'Unknown' }AED</span>
                </div>
            </div>
            <div>
                <span className="fw-bold text-dark">
                    Deal Date:{ ' ' }
                </span>
                <span>
                    { data?.date
                        ? humanizeDate( data?.date, {
                            addTime: false
                        } )
                        : 'Unknown' }
                </span>
            </div>
            <div className="d-flex my-2">
                <div className="badge badge-dim bg-primary-dim text-primary me-2">
                    { hasValue(data?.propertyCard?.propertyType) ? data?.propertyCard?.propertyType : 'Unknown Type' }
                </div>
                <div className="badge badge-dim bg-gray-dim text-dark">
                    { hasValue(data?.propertyCard?.status) ? data?.propertyCard?.status : 'Unknown Status' }
                </div>
            </div>

            <ConfirmationModal
                isDeletion={ true }
                isOpen={ isConfirmModalOpen }
                toggleFn={ toggleUnlinkConfirmModal }
                title={ 'Confirm Unlink' }
                actionFn={ unlinkLead }
            >
                <p>Are you sure you want to unlink?</p>
            </ConfirmationModal>
        </div>
    );
};

export default PropertyCard;