export const formatCurrency = (value) => {
    if (!value) return '';
    const [integerPart, decimalPart] = value.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };

// Sorter function used to sort lists of files, makes sure PDF files are last
export const sortPdfLast = (a, b) => {
  const extA = a.split('.').pop().split('?')[0].toLowerCase();
  const extB = b.split('.').pop().split('?')[0].toLowerCase();

  if (extA === 'pdf' && extB !== 'pdf') {
    return 1; // move a after b
  } else if (extA !== 'pdf' && extB === 'pdf') {
    return -1; // move a before b
  } else {
    return 0; // leave a and b unchanged with respect to each other
  }
};