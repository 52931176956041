import DatePicker from 'react-datepicker';

const DateRangeFilter = ({
  startDateState,
  endDateState,
  onStartDateChange = () => {},
  onEndDateChange = () => {},
  containerClassName = '',
  label = null
}) => {
  if (label) {
    return (
      <div className="form-group">
        <div className="d-flex flex-column">
          <label htmlFor="" className="form-label mt-2">
            {label}
          </label>
          <div className={`form-control-wrap datepicker-zindex ${containerClassName}`}>
            <div className="input-daterange date-picker-range input-group datepicker-zindex">
              <DatePicker
                selected={startDateState ? new Date(startDateState) : null}
                dateFormat="dd/MM/yyyy"
                selectsStart
                startDate={startDateState ? new Date(startDateState) : null}
                endDate={endDateState ? new Date(endDateState) : null}
                onChange={onStartDateChange}
                isClearable
                wrapperClassName="start-m"
                className="form-control datepicker-zindex"
              />

              <div className="input-group-addon">TO</div>

              <DatePicker
                selected={endDateState ? new Date(endDateState) : null}
                selectsEnd
                dateFormat="dd/MM/yyyy"
                startDate={startDateState ? new Date(startDateState) : null}
                endDate={endDateState ? new Date(endDateState) : null}
                isClearable
                minDate={startDateState ? new Date(startDateState) : null}
                onChange={onEndDateChange}
                wrapperClassName="start-m"
                className="form-control datepicker-zindex"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`form-control-wrap datepicker-zindex ${containerClassName}`}>
      <div className="input-daterange date-picker-range input-group datepicker-zindex">
        <DatePicker
          selected={startDateState ? new Date(startDateState) : null}
          dateFormat="dd/MM/yyyy"
          selectsStart
          startDate={startDateState ? new Date(startDateState) : null}
          endDate={endDateState ? new Date(endDateState) : null}
          onChange={onStartDateChange}
          isClearable
          wrapperClassName="start-m"
          className="form-control datepicker-zindex"
        />

        <div className="input-group-addon">TO</div>

        <DatePicker
          selected={endDateState ? new Date(endDateState) : null}
          selectsEnd
          dateFormat="dd/MM/yyyy"
          startDate={startDateState ? new Date(startDateState) : null}
          endDate={endDateState ? new Date(endDateState) : null}
          isClearable
          minDate={startDateState ? new Date(startDateState) : null}
          onChange={onEndDateChange}
          wrapperClassName="start-m"
          className="form-control datepicker-zindex"
        />
      </div>
    </div>
  );
};

export default DateRangeFilter;
