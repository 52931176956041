import {
  Button,
  Card,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { useConvertDealModal } from './ConvertDealContext';
import React, { useCallback, useState } from 'react';
import { Bath, BedSingle, Building, DollarSign, Expand, MapPin } from 'lucide-react';
import { currencyFormatter } from '../../../../explorer/utils';
import { DealStatus, HttpStatus, PropertyListStatus, uploadManyToS3 } from '../../../../../utils/envConfig';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import uuid from 'react-uuid';
import { showToast } from '../../../../../utils/toast/toast';

const getPurposeText = (purpose) => (purpose === 'rent' ? 'Rent' : 'Sale');
const initialFormData = {
  permitNumber: '',
  qrCodeUrl: '',
  propertyTitle: '',
  propertyDesc: '',
  status: '',
  agent_id: null,
  askingPrice: null,
  oneCheqPrice: null,
  twoCheqPrice: null,
  fourCheqPrice: null,
  sixCheqPrice: null,
  twelveCheqPrice: null,
  amenities: [],
  amenityIds: [],
  amenityObjs: [],
  size: null,
  beds: '',
  baths: '',
  furnishing: '',
  vacantOnTransfer: false,
  exclusive: false,
  isOffPlanResale: false,
  contractValidity: null,
  numberOfCheques: null,
  communityId: null,
  communityName: null,
  buildingId: null,
  buildingName: null,
  propertyTypeId: null,
  building_distances: [],
  listingType: null,
  emi: '',
  uploadedFile: null,
};

const ConvertDealToListingModal = ({ deal, approvalNote, refreshDealsCallback = async () => {} }) => {
  const { isOpen, toggle } = useConvertDealModal();
  const axios = useAxiosPrivate();

  const [isFileUpload, setIsFileUpload] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const listingRequest = deal?.new_listing_request;

  const validateFormData = useCallback(() => {
    if (!formData.permitNumber) {
      setErrors((prev) => ({ ...prev, permitNumber: 'Permit number is required.' }));
      return { valid: false };
    }
    if (!formData.qrCodeUrl && !formData.uploadedFile) {
      setErrors((prev) => ({ ...prev, qrCodeUrl: 'QR code is required.' }));
      return { valid: false };
    }

    if (!formData.propertyTitle) {
      setErrors((prev) => ({ ...prev, propertyTitle: 'Title is required.' }));
      return { valid: false };
    }

    return { valid: true };
  }, [formData.permitNumber, formData.qrCodeUrl, formData.propertyTitle, formData.uploadedFile]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, uploadedFile: file }));
    setErrors((prev) => ({ ...prev, qrCodeUrl: null }));
  };

  const submitForm = async () => {
    console.log(formData);
    try {
      const { valid } = validateFormData();
      if (!valid) return;

      setIsLoading(true);
      // Upload the file to S3 if it exists
      if (formData.uploadedFile) {
        const fileName = `qrCodes/${uuid()}.${formData.uploadedFile?.name?.split('.')?.pop()}`;
        await uploadManyToS3([{ file: formData.uploadedFile, fileName, type: 'images/*' }], axios);
        formData.qrCodeUrl = fileName;
      }

      const payload = {
        statusArray: [PropertyListStatus.Draft],
        commission: '0',
        featured: false,
        status: PropertyListStatus.Unpublished,
        listingType: listingRequest?.type,
        propertyTypeId: listingRequest?.property_type?.id,
        categoryId: listingRequest?.property_type?.categoryId,
        buildingId: listingRequest?.building?.id,
        buildingName: listingRequest?.building?.name,
        building_distances: listingRequest?.building?.distances ?? [],
        communityId: listingRequest?.community?.id,
        communityName: listingRequest?.community?.community,
        emirate: listingRequest?.emirate ?? 'DUBAI',
        size: listingRequest?.size,
        beds: listingRequest?.beds,
        baths: listingRequest?.baths,
        furnishing: listingRequest?.furnishing,
        vacantOnTransfer: listingRequest?.vacantOnTransfer,
        exclusive: listingRequest?.exclusive,
        isOffPlanResale: listingRequest?.isOffPlanResale,
        amountAlreadyPaid: listingRequest?.amountAlreadyPaid,
        contractValidity: listingRequest?.contractValidity,
        numberOfCheques: listingRequest?.numberOfCheques ?? 0,
        agent_id: deal?.assignedAgent?.id ?? deal?.assignedAgent?._id,
        property_owner_id: listingRequest?.user_id,
        amenities: (listingRequest?.amenities ?? []).map((amenity) => amenity?.amenity),
        amenityIds: (listingRequest?.amenities ?? []).map((amenity) => amenity?.id),
        amenityObjs: (listingRequest?.amenities ?? []).map((amenity) => ({ id: amenity?.id, priority: 1, quantity: 1 })),
        propertyDesc: `Auto-generated description, please update before publishing`,
        propertyTitle: formData.propertyTitle,
        permitNo: formData.permitNumber,
        qrCodeUrl: formData.qrCodeUrl,
      };

      if (listingRequest?.type?.toLowerCase() === 'rent') {
        const price = Number(listingRequest?.price ?? '0');
        let cheques = Number(listingRequest?.numberOfCheques ?? '0');
        cheques = cheques > 0 ? cheques : 12;

        payload.oneCheqPrice = price;
        payload.emi = (price / cheques).toString();
      } else {
        payload.askingPrice = listingRequest?.price;
      }

      const res = await axios.post(`/propList`, payload);
      if (res.status === HttpStatus.CREATED) {
        
        // This "if" statement is for deals that were already approved
        if (deal?.status === DealStatus.PendingApproval) {
            approvalNote &&
              approvalNote?.length &&
              approvalNote.split(" ")?.length >= 3 &&
              (await axios.post("/notes", { description: approvalNote, deal_id: deal?.id }));
      
            await axios.post(`/deals/progress/${deal?.id}`, {
              approved: true,
            });
        }

        showToast('Draft listing created successfully', 'success');
        toggle();
        refreshDealsCallback && typeof refreshDealsCallback === 'function' && (await refreshDealsCallback());
      }
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data);
      showToast(err?.response?.data?.message ?? 'Something went wrong, failed to create draft', 'error');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader className="bg-white border-0" toggle={toggle}>
        Convert Listing Acquired Deal to a Draft Listing
      </ModalHeader>
      <ModalBody className="bg-white">
        <PropertyInformation listingInfo={listingRequest} />
        <h5 className="mt-4">Add Information</h5>
        <Row className="py-2">
          <Col sm={5}>
            <Label htmlFor="permitNumber">
              Permit Number (trakheesi) <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              value={formData.permitNumber}
              className={errors?.permitNumber ? 'border-danger' : ''}
              id="permitNumber"
              placeholder="The property's permit number"
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, permitNumber: e.target.value }));
                setErrors((prev) => ({ ...prev, permitNumber: null }));
              }}
              onBlur={() => validateFormData()}
            />
            {errors?.permitNumber ? (
              <small className="text-danger">{errors.permitNumber}</small>
            ) : null}
          </Col>
          <Col sm={7}>
            <Label htmlFor="qrCodeUrl">
              QR Code (trakheesi) <span className="text-danger">*</span> or {'  '}
              <span
                role="button"
                className="text-primary"
                onClick={() => setIsFileUpload(!isFileUpload)}
              >
                {isFileUpload ? 'Provide a URL' : 'Upload as a file'}
              </span>
            </Label>
            {isFileUpload ? (
              <Input
                type="file"
                accept="image/*"
                className={errors?.qrCodeUrl ? 'border-danger' : ''}
                placeholder="Upload QR Code as an image"
                onChange={handleFileUpload}
                id="qrCodeUrl"
              />
            ) : (
              <Input
                type="text"
                value={formData.qrCodeUrl}
                className={errors?.qrCodeUrl ? 'border-danger' : ''}
                onChange={(e) => {
                  setFormData((prev) => ({ ...prev, qrCodeUrl: e.target.value }));
                  setErrors((prev) => ({ ...prev, qrCodeUrl: null }));
                }}
                onBlur={() => validateFormData()}
                placeholder="Add a link to the QR code"
                id="qrCodeUrl"
              />
            )}
            {errors?.qrCodeUrl ? <small className="text-danger">{errors.qrCodeUrl}</small> : null}
          </Col>
        </Row>
        <Row className="py-2">
          <Col sm={12}>
            <Label htmlFor="title">
              Property Title <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              value={formData.propertyTitle}
              id="propertyTitle"
              className={errors?.propertyTitle ? 'border-danger' : ''}
              placeholder="The property's title"
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, propertyTitle: e.target.value }));
                setErrors((prev) => ({ ...prev, propertyTitle: null }));
              }}
              onBlur={() => validateFormData()}
            />
            {errors?.propertyTitle ? (
              <small className="text-danger">{errors.propertyTitle}</small>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="bg-white border-0">
        <Button color="light" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" disabled={isLoading} onClick={submitForm}>
          {isLoading ? <Spinner size="sm" /> : 'Make Draft'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ConvertDealToListingModal, (prevProps, nextProps) => {
  return prevProps.deal?.id === nextProps.deal?.id;
});

const PropertyInformation = ({ listingInfo }) => {
  return (
    <Card className="border">
      <CardHeader className="bg-white p-3">
        <h5>Property</h5>
      </CardHeader>
      <div className="py-2 px-3">
        <Row>
          <Col sm={6}>
            <div className="d-flex align-items-center" style={{ gap: '0.5rem' }}>
              <span className="fw-bold">
                <MapPin size={14} />
              </span>
              <span className="text-muted">
                {`${listingInfo?.community?.community ?? 'Unknown community'}, ${
                  listingInfo?.building?.name ?? 'Unknown building'
                }`}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="d-flex align-items-center">
              <span className="fw-bold" style={{ verticalAlign: 'middle' }}>
                <Building size={14} />
              </span>
              <span className="text-muted ms-2">
                {`${listingInfo?.property_type?.propertyType ?? 'Unknown property type'} for ${
                  getPurposeText(listingInfo?.type) ?? 'Unknown purpose'
                }`}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={6}>
            <div className="d-flex align-items-center">
              <span className="fw-bold" style={{ verticalAlign: 'middle' }}>
                <DollarSign size={14} />
              </span>
              <span className="text-muted ms-2">
                {`${
                  listingInfo?.price
                    ? currencyFormatter({ isDecimal: true, decimalPlaces: 2 }).format(
                        listingInfo?.price
                      )
                    : 'Unknown'
                }`}
              </span>
            </div>
          </Col>
          <Col sm={6}>
            <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
              <div className="d-flex" style={{ gap: '0.2rem' }}>
                <span className="fw-bold">
                  <BedSingle size={14} />
                </span>
                <span className="text-muted" style={{ marginTop: '0.1rem' }}>{`${
                  listingInfo?.beds ?? 'Unknown'
                }`}</span>
              </div>
              <div className="d-flex" style={{ gap: '0.2rem' }}>
                <span className="fw-bold">
                  <Bath size={14} />
                </span>
                <span className="text-muted" style={{ marginTop: '0.1rem' }}>{`${
                  listingInfo?.baths ?? 'Unknown'
                }`}</span>
              </div>
              <div className="d-flex" style={{ gap: '0.2rem' }}>
                <span className="fw-bold">
                  <Expand size={16} />
                </span>
                <span className="text-muted" style={{ marginTop: '0.1rem' }}>
                  {`${listingInfo?.size ? `${listingInfo?.size} Sqft.` : 'Unknown'}`}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col sm={listingInfo?.numberOfCheques ? 6 : 12}>
            <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>
              {listingInfo?.furnishing ? (
                <span className="badge bg-info-dim border border-info text-info">
                  {listingInfo?.furnishing}
                </span>
              ) : null}
              {listingInfo?.vacantOnTransfer ? (
                <span className="badge bg-primary-dim border border-primary text-primary">
                  {'Vacant on Transfer'}
                </span>
              ) : null}
              {listingInfo?.exclusive ? (
                <span className="badge bg-warning-dim border border-warning text-warning">
                  {'Exclusive'}
                </span>
              ) : null}
              {listingInfo?.isOffPlanResale ? (
                <span className="badge bg-danger-dim border border-danger text-danger">
                  {'Off Plan Resale'}
                </span>
              ) : null}
            </div>
          </Col>
          {listingInfo?.numberOfCheques ? (
            <Col sm={6}>
              <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                <div className="d-flex align-items-center">
                  <span className="fw-bold">Cheques:</span>
                  <span className="text-muted ms-2">{`${listingInfo?.numberOfCheques}`}</span>
                </div>
                {listingInfo?.contractValidity ? (
                  <div className="d-flex align-items-center">
                    <span className="fw-bold">Contract:</span>
                    <span className="text-muted ms-2">{`${listingInfo?.contractValidity}`}</span>
                  </div>
                ) : null}
              </div>
            </Col>
          ) : null}
        </Row>
        {(listingInfo?.amenities ?? []).length ? (
          <Row className="my-2">
            <Col sm={12}>
              <span className="fw-bold d-block mb-2">Amenities</span>
              <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>
                {(listingInfo?.amenities ?? []).map((amenity) => (
                  <span className="badge bg-light">{amenity?.amenity}</span>
                ))}
              </div>
            </Col>
          </Row>
        ) : null}
      </div>
    </Card>
  );
};
