import React, { useState, useEffect } from "react";
import { ContractValidity, Finance, ListingType, RentalPeriod } from "../../../utils/envConfig";
import "react-toastify/dist/ReactToastify.css";
import { Button, Row, Col, Icon, RSelect } from "../../../components/Component";
import { Card, Collapse } from "reactstrap";
import { toast } from "react-toastify";

const PricingEdit = ( props ) =>
{
  const values = props.property;
  const [ videosLink, setVideosLink ] = useState( values.videos );
  const [ mLinks, setMLinks ] = useState( values.matterportLink );
  const [ feeFields, setFeeFields ] = useState( values.fees );
  const [price, setPrice] = useState(values.askingPrice ? values.askingPrice : values.oneCheqPrice);
  const [offPlanResaleVal, setOffPlanResaleVal] = useState(values.isOffPlanResale ? values.isOffPlanResale : false);

  const [ formData, setFormData ] = useState( {
    securityDeposit: values?.securityDeposit,
    askingPrice: values?.askingPrice,
    pricedrop: values?.pricedrop,
    fees: values?.fees,
    oneCheqPrice: values?.oneCheqPrice,
    twoCheqPrice: values?.twoCheqPrice,
    fourCheqPrice: values?.fourCheqPrice,
    sixCheqPrice: values?.sixCheqPrice,
    twelveCheqprice: values?.twelveCheqprice,
    rentFinance: values?.rentFinance,
    matterportLink:values?.matterportLink,
    videos:values?.videosLink,
    rentalPeriod:values?.rentalPeriod,
    isOffPlanResale: values?.isOffPlanResale,
    amountAlreadyPaid: values?.amountAlreadyPaid,
    priceEvaluation: values?.priceEvaluation
  } );

const findPercentage = (price, perc) => {
  let result = Math.round(
    (  Number(price)*
      Number( perc ) ) /
    100
  )
  return result;
};


  const handleFormData = ( input ) => ( e ) =>
  {
    // input value from the form
    const { value } = e.target;

    // updating formData state
    setFormData( ( prevState ) => ( {
      ...prevState,
      [ input ]: value,
    } ) );
    if(input==="askingPrice")setPrice(value)
    if(input==="oneCheqPrice")setPrice(value)
  };



  const handleVideoChange = ( event ) =>
  {
    let data = [ ...videosLink ];
    data[ 0 ] = event.target.value;
    setVideosLink( data );
  };
  useEffect( () =>
  {
    setFormData( ( prevState ) => ( {
      ...prevState,
      videos: videosLink,
    } ) );
  }, [ videosLink ] );
  // Video array end

  const handleMLinkChange = ( event ) =>
  {
    let data = [ ...mLinks ];
    data[ 0 ] = event.target.value;
    setMLinks( data );
  
  };
  useEffect( () =>
  {
    setFormData( ( prevState ) => ( {
      ...prevState,
      matterportLink: mLinks,
    } ) );
  }, [ mLinks ] );
  // Matterpot link end


  const [ optDefaultPeriod, setOptDefaultPeriod ] = useState();
  const [ defaultContractValidity, setDefaultContractValidity ] = useState();
  const [ optDefaultRentFinance, setOptDefaultRentFinance ] = useState();
  const [ isOffPlanResaleLabel, setIsOffPlanResaleLabel ] = useState();

  const handleSubmit =async ()=>{
    console.error(`Asking price ${formData.askingPrice} and one cheque price ${formData.oneCheqPrice}`);
  if ( !formData.askingPrice && !formData.oneCheqPrice )
    {
      toast.error( "Error in Price" );
    }
    else if (
      feeFields.length > 0 &&
      feeFields.filter(
        ( input, index ) => input.value === "" || input.name === ""
      ).length > 0
    )
    {
      toast.error( "Error in Fee Field" );
    }
    else{
    let newFormData ={...formData,fees:feeFields}
      props.propertyUpdate( newFormData ) && props.setEditPricing( false );
    }

  }
  useEffect( () =>
  {
    let data = [ ...feeFields ];
    let index = data.findIndex( ( element ) => element.name === "Commission" );

    if ( formData.commission !== "" && formData.commission !== undefined)
    {
      if ( index === -1 )
        data.push( {
          name: "Commission",
          value: findPercentage( price, formData.commission ),
        } );
      else
        data.splice( index, 1, {
          name: "Commission",
          value: findPercentage( price, formData.commission ),
        } );
        // console.log(data)
      setFeeFields( data );
  
    }
    if ( formData.commission === "" )
    {
      if ( index !== -1 ) data.splice( index, 1 );
      setFeeFields( data );
    }
    let index2 = data.findIndex(
      ( element ) => element.name === "Security Deposit"
    );
    if ( formData.securityDeposit !== ""&& formData.securityDeposit !== undefined )
    {
      if ( index2 === -1 )
        data.push( {
          name: "Security Deposit",
          value: findPercentage( price, formData.securityDeposit ),
        } );
      else
        data.splice( index2, 1, {
          name: "Security Deposit",
          value: findPercentage( price, formData.securityDeposit ),
        } );
      setFeeFields( data );
    }
    if ( formData.securityDeposit === "" )
    {
      if ( index2 !== -1 ) data.splice( index2, 1 );
      setFeeFields( data );
    }
  }, [
    formData.commission,
    formData.securityDeposit,
    formData.askingPrice,
    formData.oneCheqPrice,
    price
  ] );


  useEffect( () =>
  {
    if ( values.rentalPeriod )
    {
      setOptDefaultPeriod( {
        label: values.rentalPeriod,
        value: values.rentalPeriod,
      } );
    }
    if ( values.contractValidity )
    {
      setDefaultContractValidity( {
        label: values.contractValidity,
        value: values.contractValidity,
      } );
    }
    const offPlanResaleLabel = values.isOffPlanResale ? 'Yes' : 'No';
    if ( values.isOffPlanResale !== undefined ) {
      setIsOffPlanResaleLabel({
        label: offPlanResaleLabel,
        value: offPlanResaleLabel,
      });
    }

    if ( values.rentFinance )
    {
      setOptDefaultRentFinance( {
        label: values.rentFinance,
        value: values.rentFinance,
      } );
    }
  }, [] );
  const handlePeriod = ( e ) =>
  {
    setOptDefaultPeriod( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        rentalPeriod: e.value,
      } ) );
    }
  };
  const handleContractValidity = ( e ) =>
  {
    setDefaultContractValidity( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        contractValidity: e.value,
      } ) );
    }
  };
  const handleOffPlanResale = (e) => {
    setOffPlanResaleVal(e.value === 'Yes')
    setIsOffPlanResaleLabel(e);
    if (e) {
      setFormData((prevState) => ({
        ...prevState,
        isOffPlanResale: e.value === 'Yes',
      }));
    }
  };
  
  const handleFinance = ( e ) =>
  {
    setOptDefaultRentFinance( e );
    if ( e )
    {
      setFormData( ( prevState ) => ( {
        ...prevState,
        rentFinance: e.value,
      } ) );
    }
  };


  return (
    <>
      <Card className="card-bordered mt-4" >
        <div className="card-inner">


          <Row className="gy-2 ">
            <div className="d-flex justify-content-end align-items-end">
              <Button onClick={ () => props.setEditPricing( false ) }>
                <Icon name="cross" style={ { fontSize: "1rem", backgroundColor: "#fff", color: "red", border: "solid 1px #dbdfea", borderRadius: "0.2rem", padding: "0.3rem " } }>
                </Icon>

              </Button>
            </div>
            { values.listingType === ListingType[ 0 ] && (
              <>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                      Asking Price
                    </label>
                    <div className="form-control-wrap">
                      <div className="input-container">
                        <input
                          type="text"
                          id="first-name"
                          className="form-control"
                          name="askingPrice"
                          defaultValue={ values.askingPrice }
                          onChange={ handleFormData( "askingPrice" ) }
                        />
                        <span
                          className="input-text"
                          style={ {
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          } }
                        >
                          AED
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ) }

            { values.listingType === ListingType[ 0 ] && (
              <>
                              <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                    Our Valuation 
                    </label>
                    <div className="form-control-wrap">
                      <div className="input-container">
                        <input
                          type="text"
                          id="first-name"
                          className="form-control"
                          name="priceEvaluation"
                          defaultValue={ values.priceEvaluation }
                          onChange={ handleFormData( "priceEvaluation" ) }
                        />
                        <span
                          className="input-text"
                          style={ {
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          } }
                        >
                          AED
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                      Price Drop
                    </label>
                    <div className="form-control-wrap">
                      <div className="input-container">
                        <input
                          type="text"
                          id="first-name"
                          onInput={(e) => {
                            let newValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                            if (newValue.length > 2) { // Limit length to 2 characters
                              newValue = newValue.slice(0, 2);
                            }
                            e.target.value = newValue;
                          }}
                          className="form-control"
                          name="pricedrop"
                          defaultValue={ values.pricedrop }
                          onChange={ handleFormData( "pricedrop" ) }
                        />
                        <span
                          className="input-text"
                          style={ {
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          } }
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ) }
            <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="first-name">
                  Off-Plan Resale 
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      value={ isOffPlanResaleLabel }
                      onChange={ ( e ) =>
                      {
                        handleOffPlanResale(e)
                      } }
                      options={['Yes', 'No'].map((x) => ({
                        label: x,
                        value: x,
                      }))}
                    />
                  </div>
                </div>
            </Col>
            <Col sm="6">
            <Collapse isOpen={offPlanResaleVal}>
              <div className="form-group">
                <label className="form-label" htmlFor="first-name">
                Amount Already Paid *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="video"
                    defaultValue={ values?.amountAlreadyPaid }
                    onChange={handleFormData("amountAlreadyPaid")}
                  />
                </div>
              </div>
              </Collapse>
            </Col>

            <Col md="6">
              <div className="form-group">
                <label className="form-label" htmlFor="first-name">
                  Commission
                </label>
                <div className="form-control-wrap">
                  <div className="input-container">
                  <input
                    name="commission"
                    id="commission"
                    onInput={(e) => {
                      let newValue = e.target.value
                        .replace(/[^0-9.]/g, '') 
                        .replace(/^(\d*\.\d{3})\d*$/, '$1'); 

                      if (newValue.includes('.')) {
                        const [integerPart, decimalPart] = newValue.split('.');
                        if (integerPart.length > 2) {
                          newValue = integerPart.slice(0, 2) + '.' + decimalPart;
                        }
                        if (decimalPart.length > 3) {
                          newValue = integerPart + '.' + decimalPart.slice(0, 3);
                        }
                      } else if (newValue.length > 2) {
                        newValue = newValue.slice(0, 2);
                      }

                      e.target.value = newValue;
                    }}
                    className="form-control"
                    defaultValue={values.commission}
                    onChange={handleFormData("commission")}
                  />
                    {/* <input
                      name="commission"
                      id="commission"
                      onInput={(e) => {
                        let newValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                        if (newValue.length > 4) { // Limit length to 2 characters
                          newValue = newValue.slice(0, 4);
                        }
                        e.target.value = newValue;
                      }}
                      className="form-control"
                      defaultValue={ values.commission }
                      onChange={ handleFormData( "commission" ) }
                    /> */}
                    <span
                      className="input-text"
                      style={ {
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      } }
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm="6">
              <div className="form-group">
                <label className="form-label" htmlFor="first-name">
                  Video Link
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="video"
                    defaultValue={ values?.videos[ 0 ] }
                    onChange={ ( event ) => handleVideoChange( event ) }
                  />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-group">
                <label className="form-label" htmlFor="first-name">
                  Matterport Link
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="first-name"
                    className="form-control"
                    name="mlink"
                    defaultValue={ values?.matterportLink ?values?.matterportLink?.[ 0 ]:"" }
                    onChange={ ( event ) => handleMLinkChange( event ) }
                  />
                </div>
              </div>
            </Col>
            <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="first-name">
                    Contract Validity
                  </label>
                  <div className="form-control-wrap">
                    <RSelect
                      value={ defaultContractValidity }
                      onChange={ ( e ) =>
                      {
                        handleContractValidity( e );
                      } }
                      options={ Object.values(ContractValidity).map( ( e ) => ( {
                        label: e,
                        value: e,
                      } ) ) }
                    />
                  </div>
                </div>
            </Col>


            {/* <div className="d-flex justify-content-end align-items-end">
            <Button
              onClick={ ( e ) =>
              {
                e.preventDefault();
                props.propertyUpdate( formData ) && props.setEditCheque( false );
              } }
              color="success"
              className="w-15 d-flex mt-2 justify-content-center align-items-end"
            >
              { "Save" }
            </Button>
            <button
              className="close-btn"
              onClick={ () => props.setEditCheque( false ) }
              style={ {
                top: "1.5rem",
                right: "1rem",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              } }
            >
              <Icon name="cross" style={ { fontSize: "1.5rem" } } />
            </button>
          </div> */}
            { values.listingType === ListingType[ 1 ] && (
              <>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                      Rental Period
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        value={ optDefaultPeriod }
                        onChange={ ( e ) =>
                        {
                          handlePeriod( e );
                        } }
                        options={ RentalPeriod?.map( ( e ) => ( {
                          label: e,
                          value: e,
                        } ) ) }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="first-name">
                      1 Cheque Price
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="first-name"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                        }}
                        maxLength="12" 
                        className="form-control"
                        name="oneCheqPrice"
                        defaultValue={ values.oneCheqPrice }
                        onChange={ handleFormData( "oneCheqPrice" ) }
                      />
                            <span
                    className="input-text"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    AED
                  </span>
                    </div>
                  </div>
                </Col>
                { formData.rentalPeriod === RentalPeriod[ 0 ] && (
                  <>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                          2 Cheque Price
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                            }}
                            maxLength="12" 
                            id="first-name"
                            className="form-control"
                            name="firstName"
                            defaultValue={ values.twoCheqPrice }
                            onChange={ handleFormData( "twoCheqPrice" ) }
                          />
                              <span
                        className="input-text"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        AED
                      </span>
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                          4 Cheque Price
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                            }}
                            maxLength="12" 
                            id="first-name"
                            className="form-control"
                            name="fourCheqPrice"
                            defaultValue={ values.fourCheqPrice }
                            onChange={ handleFormData( "fourCheqPrice" ) }
                          />
                              <span
                        className="input-text"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        AED
                      </span>
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                          6 Cheque Price
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                            }}
                            maxLength="12" 
                            id="first-name"
                            className="form-control"
                            name="sixCheqPrice"
                            defaultValue={ values.sixCheqPrice }
                            onChange={ handleFormData( "sixCheqPrice" ) }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                          12 Cheque Price
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                            }}
                            maxLength="12" 
                            id="first-name"
                            className="form-control"
                            name="twelveCheqprice"
                            defaultValue={ values.twelveCheqprice }
                            onChange={ handleFormData( "twelveCheqprice" ) }
                          />
                              <span
                        className="input-text"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        AED
                      </span>
                        </div>
                      </div>
                    </Col>
                  </>
                ) }
              </>
            ) }

            { values.listingType === ListingType[ 1 ] && (
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="first-name">
                    Security Deposit
                  </label>
                  <div className="form-control-wrap">
                    
                    <input
                      type="number"
                      name="securityDeposit"
                      id="securityDeposit"
                      onInput={(e) => {
                        let newValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                        if (newValue.length > 2) { // Limit length to 2 characters
                          newValue = newValue.slice(0, 2);
                        }
                        e.target.value = newValue;
                      }}
                      className="form-control"
                      defaultValue={ values.securityDeposit }
                      onChange={ handleFormData( "securityDeposit" ) }
                    />
                     <span
                      className="input-text"
                      style={ {
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      } }
                    >
                      %
                      </span>
                  </div>
                </div>
              </Col>
            ) }
            { values.listingType === ListingType[ 1 ] && (
              <Col md="6">
                <div className="form-group">
                  <label className="form-label" htmlFor="numberOfCheques">
                    Number of Cheques
                  </label>
                  <div className="form-control-wrap">
                    
                    <input
                      type="number"
                      name="numberOfCheques"
                      id="numberOfCheques"
                      onInput={(e) => {
                        let newValue = e.target.value.replace(/[^0-9.]/g, ''); // Allow only numbers and .
                        if (Number(newValue) > 12) { // Limit length to 2 characters
                          newValue = "12"
                        }
                        if (Number(newValue) <= 0) { // Limit length to 2 characters
                          newValue = "1"
                        }
                        e.target.value = newValue;
                      }}
                      className="form-control"
                      defaultValue={ values.numberOfCheques }
                      onChange={ handleFormData( "numberOfCheques" ) }
                    />
                     <span
                      className="input-text"
                      style={ {
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      } }
                    >
                      %
                      </span>
                  </div>
                </div>
              </Col>
            ) }
            <Col sm={ 6 }>
              <div className="form-group">
                <label className="form-label">Financing</label>
                <RSelect
                 value={ optDefaultRentFinance }
                  onChange={ ( e ) =>
                  {
                    handleFinance(e);
                  } }
                  options={ Finance?.map( ( e ) => ( { label: e, value: e } ) ) }
                />
              </div>
            </Col>
            <div className="d-flex justify-content-end align-items-end">


              <div className="actions clearfix">
                <Button
                  onClick={ ( e ) =>
                  {
                    e.preventDefault();
                    handleSubmit();
                  } }
                  color="success"
                  className=" d-flex mt-2 justify-content-center align-items-end"
                >
                  { "Save" }
                </Button>
              </div>   </div>
          </Row>   </div>
      </Card>
    </>
  );
};

export default PricingEdit;
