/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Axios } from "axios";
import ErrorHandler from "../error-handlers/axios/defaultErrorHandler";

/**
 * @typedef {Object} AssignLeadsPayload
 * @property {string} agent_id - The agent to assign the leads to.
 * @property {string[]} user_ids - The list of users to assign the leads to.
 * @property {string} notes - The notes to assign the leads to (reason for reassignment).
 * @property {string?} leadSource - The lead source to assign the leads to.
 * @property {string?} leadSourceName - The lead source name to assign the leads to.
 */

/**
 * @description Assigning leads to an agent (Admin assignment)
 * @param {Axios} axiosInstance
 * @param {AssignLeadsPayload} payload
 */
export const assignLeads = async (axiosInstance, payload) => {
  try {
    const res = await axiosInstance.post('/usersAgents/assign', payload);

    return res.data;
  } catch (err) {
    ErrorHandler.handle(err);
  }
}