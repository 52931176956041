import ErrorHandler from '../error-handlers/axios/defaultErrorHandler';

export const getExpiredLeads = async (axiosInstance, options = {}) => {
  try {
    const res = await axiosInstance.get('/expired-records', options);

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};