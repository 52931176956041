import { useQuery } from 'react-query';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAllCommunityTeams } from '../../../../api/community-teams/getAllCommunityTeams';
import { showToast } from '../../../../utils/toast/toast';
import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Icon } from '../../../../components/Component';
import { timeAsMilliseconds } from '../../../../utils/Utils';
import PlacesDropdown from '../PlacesDropdown';
import BuildingFilter from '../BuildingFilter';

const ONE_HOUR = timeAsMilliseconds({ hours: 1 });

const MetaCommunityFilter = ({
  isOpen,
  toggle,
  onCommunityChange,
  onPlaceChange,
  onBuildingChange,
  onClear,
  selectedCommunities,
  selectedPlaces,
  selectedBuildings,
  axiosInstance,
  dispatch
}) => {
  const { data, isLoading } = useQuery('metaCommunities', () => getAllCommunityTeams(axiosInstance), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: ONE_HOUR,
    refetchInterval: ONE_HOUR,
    onError: (error) => {
      console.log(error);
      showToast('Could not fetch meta communities', 'error', 5000);
    }
  });

  const [selectedMetaCommunities, setSelectedMetaCommunities] = useState(selectedCommunities || []);
  const [places, setPlaces] = useState(selectedPlaces || []);
  const [buildings, setBuildings] = useState(selectedBuildings || []);

  useEffect(() => {
    setSelectedMetaCommunities(selectedCommunities || []);
    setPlaces(selectedPlaces || []);
    setBuildings(selectedBuildings || []);
  }, [selectedCommunities, selectedPlaces, selectedBuildings]);

  const handleCommunityToggle = (community) => {
    setSelectedMetaCommunities((prev) => {
      if (_.some(prev, ['id', community.id])) {
        return _.reject(prev, ['id', community.id]);
      } else {
        return [...prev, community];
      }
    });

    // Remove places that belong to the community being toggled off
    if (_.some(selectedMetaCommunities, ['id', community.id])) {
      const communityPlaceIds = community?.communities?.map(place => place?.id ?? place?._id) ?? [];
      setPlaces(prev => prev.filter(place => !communityPlaceIds.includes(place?.value)));
    } else {
      setPlaces(
        _.uniqBy([...places, ...(community?.communities ?? [])], (item) => item?.id ?? item?._id).map((item) => ({
          ...item,
          label: item?.community,
          value: item?.id ?? item?._id
        }))
      );
    }

  };

  const handlePlaceChange = (selected) => {
    setPlaces(selected);
    setBuildings((_) => []);
  };

  const handleBuildingChange = (selected) => {
    setBuildings(selected);
  };

  const [showSelected, setShowSelected] = useState(false);
  const [showSelectedBuildings, setShowSelectedBuildings] = useState(false);
  const selectedRef = useRef(null);
  const selectedBuildingsRef = useRef(null);

  useEffect(() => {
    if (places.length > 0) {
      setShowSelected(true);
    } else {
      setShowSelected(false);
    }
    if (buildings.length > 0) {
      setShowSelectedBuildings(true);
    } else {
      setShowSelectedBuildings(false);
    }
  }, [places, buildings]);

  const communitiesWithPlaces = useMemo(
    () =>
      data?.results
        ?.filter((community) => community?.communities?.length > 0)
        ?.map((team) => ({ ...team, value: team?.communities })),
    [data]
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        Choose Master Communities {!isLoading ? `(${communitiesWithPlaces?.length} total)` : null}
      </ModalHeader>
      <ModalBody className="">
        <ul className="custom-control-group">
          {communitiesWithPlaces?.map((community) => (
            <HoverCard.Root openDelay={300} closeDelay={100} key={community?.id}>
              <HoverCard.Trigger asChild>
                <li>
                  <div className="custom-control custom-checkbox custom-control-pro no-control">
                    <input
                      type="checkbox"
                      disabled={!community?.enabled}
                      checked={_.some(selectedMetaCommunities, ['id', community?.id])}
                      onChange={(e) => handleCommunityToggle(community)}
                      className="custom-control-input"
                      name={`community-${community?.id}`}
                      id={`community-${community?.id}`}
                    />
                    <label
                      className={`custom-control-label px-4 py-2 ${
                        community?.enabled ? 'text-dark fw-medium' : 'text-muted'
                      }`}
                      htmlFor={`community-${community?.id}`}
                    >
                      {community?.teamName}
                    </label>
                  </div>
                </li>
              </HoverCard.Trigger>
              <HoverCard.Portal>
                <HoverCard.Content className="HoverCardContent">
                  <Container>
                    <span className="fw-medium" style={{ fontSize: '1.002rem' }}>
                      Agents working in this community
                    </span>
                    <ul className="mt-2 d-flex flex-wrap" style={{ gap: '.5rem' }}>
                      {community?.members?.map((agent) => (
                        <li key={agent?.id ?? agent?._id}>
                          <span className="badge bg-outline-gray">
                            {agent?.first_name} {agent?.last_name}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <hr />
                    <span className="fw-medium" style={{ fontSize: '1.002rem' }}>
                      Places in this community
                    </span>
                    <ul className="mt-2 d-flex flex-wrap" style={{ gap: '.5rem' }}>
                      {community?.communities?.map((place, idx) => (
                        <li key={(place?.id ?? place?._id) + '' + idx}>
                          <span className="badge bg-outline-gray">{place?.community}</span>
                        </li>
                      ))}
                    </ul>
                  </Container>
                  <HoverCard.Arrow className="HoverCardArrow" />
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>
          ))}
        </ul>

        <hr />

        <Row>
          <Col sm={6}>
            <PlacesDropdown
              placeholder="Places"
              selected={places}
              onChangeCallback={handlePlaceChange}
              isMulti
            />
          </Col>
          <Col sm={6}>
            <BuildingFilter
              placeholder="Buildings"
              selected={buildings}
              onChangeCallback={handleBuildingChange}
              axiosInstance={axiosInstance}
              dispatch={dispatch}
              selectedCommunities={places}
              multiSelect
              unstyled
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col sm={6}>
            <span className="fw-medium" style={{ fontSize: '1.002rem' }}>
              {`You have selected ${places?.length ? places?.length : 'no'} place(s)`}
            </span>
            <div
              ref={selectedRef}
              className={`selected-places ${showSelected ? 'show' : ''}`}
              style={{
                opacity: showSelected ? 1 : 0,
                maxHeight: showSelected ? `${selectedRef.current?.scrollHeight}px` : '0px',
                overflow: 'hidden',
                transition: 'opacity 0.3s ease, max-height 0.3s ease',
              }}
            >
              <ul className="d-flex flex-wrap" style={{ gap: '.5rem' }}>
                {places?.map((place, idx) => (
                  <li key={(place?.id ?? place?._id) + '' + idx}>
                    <span className="badge bg-outline-primary">{place?.community}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col sm={6}>
            <span className="fw-medium" style={{ fontSize: '1.002rem' }}>
              {`And ${buildings?.length ? buildings?.length : 'no'} building(s)`}
            </span>
            <div
              ref={selectedBuildingsRef}
              className={`selected-places ${showSelectedBuildings ? 'show' : ''}`}
              style={{
                opacity: showSelectedBuildings ? 1 : 0,
                maxHeight: showSelectedBuildings ? `${selectedBuildingsRef.current?.scrollHeight}px` : '0px',
                overflow: 'hidden',
                transition: 'opacity 0.3s ease, max-height 0.3s ease'
              }}
            >
              <ul className="d-flex flex-wrap" style={{ gap: '.5rem' }}>
                {buildings?.map((building, idx) => (
                  <li key={(building?.id ?? building?._id) + '' + idx}>
                    <span className="badge bg-outline-primary">{building?.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>

      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <span className="text-muted fst-italic d-flex align-items-center">
            <Icon name="info" />
            <span>Hover over a community to see its places and the agents working there.</span>
          </span>
        </div>
        <div className="d-flex" style={{ gap: '.5rem' }}>
          <Button
            color="primary"
            onClick={() => {
              onCommunityChange(selectedMetaCommunities);
              onPlaceChange(places);
              onBuildingChange(buildings);
            }}
          >
            Apply
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setSelectedMetaCommunities((_) => []);
              setPlaces((_) => []);
              setBuildings((_) => []);
              onClear?.();
            }}
          >
            Clear All
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MetaCommunityFilter;
