import React, { useState, useEffect, useRef } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import ReactToPrint from "react-to-print";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import { PreviewAltCard, PreviewCard } from "../../components/preview/Preview";
import {
  fetchCommunitiesList,
  fetchPropTypesList,
  fetchBuildings,
  fetchAmenitiesList,
} from "../../redux/actions/property";
import {
  propActiveStatus,
  ListingType,
  Status,
  BathRooms,
  BedRooms,
  newRoles,
} from "../../utils/envConfig";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import PropertyCard from "./components/products/PropertyCard";
import ProductDataTableRow from "./components/PropertyDataTableRow";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { fetchAgents } from "../../redux/actions/user";
import Skeleton from "react-loading-skeleton";
import { debounce } from "lodash";
import { timeAsMilliseconds } from "../../utils/Utils";

const ProductList = () => {
  const dispatch = useDispatch();
  const printRef = useRef();

  const axios = useAxiosPrivate();

  useEffect(() => {
    dispatch(fetchPropTypesList(axios));
    dispatch(fetchCommunitiesList(axios));
    dispatch(fetchBuildings(axios));
    dispatch(fetchAmenitiesList(axios));
  }, [axios, dispatch]);

  const authUser = useSelector((state) => state?.user?.loggedInUser);

  const [queryParams, setQueryParams] = useState({
    status: null,
    statusArray: null,
    type: null,
    propertyType: null,
    communities: null,
    buildings: null,
    search: null,
    searchName: null,
    minPrice: null,
    maxPrice: null,
    beds: null,
    baths: null,
    amenities: null,
    agent: null,
    sortOption: null,
    pid: null,
    page: 1,
    sort: null, // { field: 'name', sort: 'asc' | 'desc' }
  });

  const community = useSelector((state) => state.property?.communities);
  const { buildings, buildingsLoading } = useSelector((state) => ({
    buildings: state.property?.buildings,
    buildingsLoading: state.property?.buildingsLoading,
  }));
  const amenitiesList = useSelector((state) => state.property?.amenities);
  const propType = useSelector((state) => state.property?.propertyTypes);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [flSearchName, setFlSearchName] = useState("");

  const filteredListingType = ListingType.filter(
    (type) => type !== "Sell" && type !== "Lease"
  );

  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const commOptions = community?.map((comm) => ({
    label: comm?.community,
    value: comm?.id,
  }));

  let buildingOptions = buildings?.length ? buildings : [];
  if (queryParams?.communities?.length) {
    buildingOptions = buildingOptions?.filter((building) =>
      queryParams?.communities?.map(comm => comm?.value)?.includes(building?.communityId)
    );
  }

  const buildingFilterOptions = buildingOptions?.map((building) => ({
    ...building,
    label: building?.name,
    value: building?.id ?? building?._id,
  }));

  const amentiesOptions = amenitiesList?.map((amenities) => ({
    label: amenities?.amenity,
    value: amenities?.id,
  }));

  const [sortOption, setSortOption] = useState("");

  const [optAgents, setOptAgents] = useState([]);

  const [sortByTitle, setSortByTitle] = useState("");
  const [sortByOrder, setSortByOrder] = useState("");

  const agent = useSelector((state) => state.user?.agents);

  const { isLoading, data, isFetching } = useQuery(
    ["properties", queryParams, agent],
    () => fetchProperties(),
    { refetchOnWindowFocus: false, staleTime: timeAsMilliseconds({ hours: 1}) }
  );

  const { data: propertiesActiveCount, isLoading: isActiveCountLoading } = useQuery(
    ['liveProperties', agent],
    () => fetchPropertiesPublishedCount(),
    { refetchOnWindowFocus: false, staleTime: timeAsMilliseconds({ hours: 1}) }
  );

  const { data: propertiesInactiveCount, isLoading: isInactiveCountLoading } = useQuery(
    ['unpublishedProperties', agent],
    () => fetchPropertiesUnpublishedCount(),
    { refetchOnWindowFocus: false, staleTime: timeAsMilliseconds({ hours: 1}) }
  );

  const { data: propertiesCancelledCount, isLoading: isCancelledCountLoading } = useQuery(
    ['cancelledProperties', agent],
    () => fetchPropertiesCancelledCount(),
    { refetchOnWindowFocus: false, staleTime: timeAsMilliseconds({ hours: 1}) }
  );

  const { id } = useSelector((state) => state?.user?.loggedInUser);

  useEffect(() => {
    dispatch(fetchAgents(axios));
  }, [axios, dispatch]);


  useEffect(() => {
    let options = [];
    let result =
      agent &&
      agent?.length > 0 &&
      agent
        ?.filter((agents) => agents?.user?.active)
        ?.map((a) =>
          options.push({
            value: a?.id,
            label: a?.user?.first_name + " " + a?.user?.last_name,
          })
        );
    setOptAgents(options);
  }, [agent]);
  

  async function fetchProperties() {
    try {
      let link = `/filter/propList?page=${queryParams?.page}`;

      if (queryParams?.status) {
        link = link.concat(`&status=${queryParams?.status}`);
      }

      if (queryParams?.statusArray) {
        link = link.concat(`&statusArray=${queryParams?.statusArray}`);
      }

      if (queryParams?.type) {
        link = link.concat(`&listingType=${queryParams?.type}`);
      }

      if (queryParams?.propertyType) {
        link = link.concat(`&propertyType=${queryParams?.propertyType}`);
      }
      
      if (queryParams?.communities?.length) {
        link = link.concat(`&community[]=${queryParams?.communities?.map(comm => comm.value).join(',')}`);
      }
      if (queryParams?.buildings?.length) {
        link = link.concat(`&building[]=${queryParams?.buildings?.map(building => building.value).join(',')}`);
      }

      if (queryParams?.amenities?.length) {
        link = link.concat(`&amenity[]=${queryParams?.amenities?.map(amenity => amenity.value).join(',')}`);
      }

      if (queryParams?.search) {
        link = link.concat(`&search=${queryParams?.search}`);
      }

      if (queryParams?.searchName) {
        link = link.concat(`&search=${queryParams?.searchName}`);
      }

      if (queryParams?.minPrice) {
        let cleanedMinPrice = queryParams?.minPrice.replace(/,/g, "");
        link = link.concat(`&minPrice=${cleanedMinPrice}`);
      }

      if (queryParams?.maxPrice) {
        let cleanedMaxPrice = queryParams?.maxPrice.replace(/,/g, "");
        link = link.concat(`&maxPrice=${cleanedMaxPrice}`);
      }

      if (queryParams?.beds) {
        link = link.concat(`&beds=${queryParams?.beds}`);
      }
      if (queryParams?.baths) {
        link = link.concat(`&baths=${queryParams?.baths}`);
      }

      if (queryParams?.agent) {
        link = link.concat(`&agent_id=${queryParams?.agent?.value}`);
      } else if (authUser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);

        link = link.concat(`&agent_id=${matchedId[0]}`);
      }

      if (queryParams?.sort) {
        const sortStr = `&sort[name]=${queryParams?.sort?.name}&sort[order]=${queryParams?.sort?.order}`;

        link = link.concat(sortStr);
      }

      const res = await axios.get(link);

      return res.data;
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesPublishedCount() {
    try {
      let link = `/filter/propList/count?type=live`;
      if (queryParams?.agent) {
        link = link.concat(`&agent=${queryParams?.agent?.value}`);
      } else if (authUser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);


        link = link.concat(`&agent=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);
      
      // setPropertiesActiveCount(res?.data?.count ?? 0);
      return res?.data?.count ?? 0
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesUnpublishedCount() {
    try {
      let link = `/filter/propList/count?type=unpublished`;
      if (queryParams?.agent) {
        link = link.concat(`&agent=${queryParams?.agent?.value}`);
      } else if (authUser.role === newRoles.Agent) {
        let matchedId = agent
          .filter((obj) => obj.userId === id)
          .map((obj) => obj.id);

        link = link.concat(`&agent=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);

      // setPropertiesInactiveCount(res?.data?.count ?? 0)
      return res?.data?.count ?? 0;
    } catch (err) {
      // console.log( err );
    }
  }

  async function fetchPropertiesCancelledCount() {
    try {
      let link = `/filter/propList/count?type=cancelled`;
      if (queryParams?.agent) {
        link = link.concat(`&agent=${queryParams?.agent?.value}`);
      } else if (authUser.role === newRoles.Agent) {
        let matchedId = agent.filter((obj) => obj.userId === id).map((obj) => obj.id);

        link = link.concat(`&agent=${matchedId[0]}`);
      } else {
      }

      const res = await axios.get(link);

      // setPropertiesCancelledCount(res?.data?.count ?? 0);
      return res?.data?.count ?? 0;
    } catch (err) {
      // console.log( err );
    }
  }

  const sortOptions = [
    { value: "date", label: "Newest", order: "desc" },
    { value: "date", label: "Oldest", order: "asc" },
    { value: "price", label: "Price (High to Low)", order: "desc" },
    { value: "price", label: "Price (Low to High)", order: "asc" },
  ];

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const submitIdSearch = async (e) => {
    e.preventDefault();
    setQueryParams((prev) => ({...prev, search: e.target.value, page: 1}))
  };

  const submitMinPriceSearch = async (e) => {
    e.preventDefault();
    if (!queryParams?.minPrice) {
      toast.error("Enter Minimum Price !");
    }
    setQueryParams((prev) => ({...prev, minPrice: e.target.value, page: 1}))
  };

  const submitMaxPriceSearch = async (e) => {
    e.preventDefault();
    if (!queryParams?.maxPrice) {
      toast.error("Enter Maximum Price !");
    }
    setQueryParams((prev) => ({...prev, maxPrice: e.target.value, page: 1}))
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    if (!flSearchName) {
      toast.error("Enter the text !");
    }
    setFlSearchName(flSearchName);
  };

  const handleClear = () => {
    setQueryParams((prev) => ({
      ...prev,
      type: null,
      status: null,
      statusArray: null,
      search: null,
      pid: null,
      propertyType: null,
      page: 1
    }))
    setSelectedStatus("");
    setSelectedCommunities([]);
  };

  const [sm, updateSm] = useState(false);

  const [tableSm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");


  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);
 

  const animatedComponents = makeAnimated();

  const [viewType, setViewType] = useState("normal");
  function handleResetStatus() {
    setQueryParams((prev) => ({...prev, status: null, statusArray: null, page: 1}));
    setSelectedStatus(false);
  }

  return (
    <React.Fragment>
      <Head title="Property List"></Head>
      <Content>
        <BlockHead>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>All Properties</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
          {authUser?.role && authUser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Live: {isActiveCountLoading ? <Spinner size={'sm'} /> :propertiesActiveCount ?? 0}</span>
              </BlockHeadContent>
            </BlockBetween>
          )}
          {authUser?.role && authUser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Unpublished: {isInactiveCountLoading ? <Spinner size={'sm'} /> : propertiesInactiveCount ?? 0}</span>
              </BlockHeadContent>
            </BlockBetween>
          )}
          {authUser?.role && authUser?.role !== newRoles?.Agent && (
            <BlockBetween>
              <BlockHeadContent>
                <span>Cancelled: {isCancelledCountLoading ? <Spinner size={'sm'} /> : propertiesCancelledCount ?? 0}</span>
              </BlockHeadContent>
            </BlockBetween> 
          )}
          <BlockBetween>
              <BlockHeadContent>
                <span>Result: {isLoading ? <Spinner size={'sm'} /> : data?.filteredCount ?? 0}</span>
              </BlockHeadContent>
            </BlockBetween> 
          <div className="d-flex justify-content-end align-items-end">
            <div className="toggle-wrap nk-block-tools-toggle mt-4">
              <a
                href="#more"
                className="btn btn-icon btn-trigger toggle-expand me-n1"
                onClick={(ev) => {
                  ev.preventDefault();
                  updateSm(!sm);
                }}
              >
                <Icon name="more-v"></Icon>
              </a>
              <div
                className="toggle-expand-content"
                style={{ display: sm ? "block" : "none" }}
              >
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <Button
                          onClick={(e) => submitMinPriceSearch(e)}
                        ></Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Min Price"
                        onChange={(e) => debounce(() => setQueryParams((prev) => ({...prev, minPrice: e.target.value, page: 1})), 1000)()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            debounce(() => submitMinPriceSearch(e), 1000)();
                          }
                        }}
                      />
                    </div>

                    <span class="input-group-text">To</span>

                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <Button
                          onClick={(e) => submitMaxPriceSearch(e)}
                        ></Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Max Price"
                        onChange={(e) => debounce(() => setQueryParams((prev) => ({...prev, maxPrice: e.target.value, page: 1})), 1000)()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            debounce(() => submitMaxPriceSearch(e), 1000)();
                          }
                        }}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="form-group">
                      <RSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultData={[]}
                        isMulti
                        options={commOptions}
                        placeholder="Choose Community"
                        className="rselect-propertyListing"
                        value={queryParams.communities}
                        onChange={(e) => {
                          setQueryParams((prev) => ({...prev, communities: e, buildings: null, page: 1 }))
                        }}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <RSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultData={[]}
                        isMulti
                        options={amentiesOptions}
                        placeholder="Choose Amenities"
                        className="rselect-propertyListing"
                        value={queryParams.amenities}
                        onChange={(e) => setQueryParams((prev) => ({...prev, amenities: e, page: 1 }))}
                      />
                    </div>
                  </li>
                </ul>

                <div className="d-flex justify-content-end align-items-end mt-2">
                  <ul className="nk-block-tools g-3">
                    {authUser?.role && authUser?.role !== newRoles?.Agent && (
                      <li>
                        <div className="status-div">
                          <RSelect
                            options={[
                              { value: "all", label: "All" },
                              ...optAgents,
                            ]}
                            value={queryParams?.agent ? queryParams?.agent : { value: "", label: "Filter By Agent" }}
                            placeholder="Filter By Agent"
                            onChange={(selectedOption) => {
                              if (selectedOption.value === "all") {
                                setQueryParams((prev) => ({...prev, agent: null, page: 1}));
                              } else {
                                setQueryParams((prev) => ({...prev, agent: selectedOption, page: 1}));
                              }
                            }}
                          />
                        </div>
                      </li>
                    )}
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {queryParams?.beds ? queryParams?.beds : "Beds"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {BedRooms &&
                              BedRooms.map((bed, idx) => (
                                <li key={`bed-${idx}`}>
                                  <DropdownItem
                                    onClick={() => setQueryParams((prev) => ({...prev, beds: bed, page: 1}))}
                                  >
                                    <span>{bed}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {queryParams?.baths ? queryParams?.baths : "Baths"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {BathRooms &&
                              BathRooms.map((bath, idx) => (
                                <li key={`bath-${idx}`}>
                                  <DropdownItem
                                    onClick={() => setQueryParams((prev) => ({...prev, baths: bath, page: 1}))}
                                  >
                                    <span>{bath}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {selectedStatus ? selectedStatus : "Status"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setQueryParams((prev) => ({...prev, status: Status[0], statusArray: null, page: 1}));
                                  setSelectedStatus(propActiveStatus[0]);
                                }}
                              >
                                <span>{propActiveStatus[0]}</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setQueryParams((prev) => ({...prev, status: Status[0], statusArray: null, page: 1}));
                                  setSelectedStatus(propActiveStatus[1]);
                                }}
                              >
                                <span>{propActiveStatus[1]}</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setQueryParams((prev) => ({...prev, status: Status[""], statusArray: 'Private', page: 1}));
                                  setSelectedStatus(propActiveStatus[4]);
                                }}
                              >
                                <span>Private</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setQueryParams((prev) => ({...prev, status: Status[""], statusArray: 'Draft', page: 1}));
                                  setSelectedStatus(propActiveStatus[3]);
                                }}
                              >
                                <span>Draft</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                onClick={() => {
                                  setQueryParams((prev) => ({...prev, status: Status[""], statusArray: 'Cancelled', page: 1}));
                                  setSelectedStatus(propActiveStatus[2]);
                                }}
                              >
                                <span>Cancelled</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {queryParams?.type ? queryParams?.type : "Purpose"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {filteredListingType.map((x, y) => (
                              <li>
                                <DropdownItem
                                  onClick={(ev) => {
                                    setQueryParams((prev) => ({ ...prev, type: x, page: 1 }));
                                  }}
                                >
                                  <span>{x}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {queryParams?.propertyType
                            ? propType?.map((type) =>
                              type.id === queryParams?.propertyType
                                ? type?.propertyType
                                : null
                            )
                            : "Type"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {propType &&
                              propType.map((type) => (
                                <li>
                                  <DropdownItem
                                    onClick={() => setQueryParams((prev) => ({ ...prev, propertyType: type.id, page: 1 }))}
                                  >
                                    <span>{type?.propertyType}</span>
                                  </DropdownItem>
                                </li>
                              ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {queryParams?.sort
                            ? sortOptions?.map((type) =>
                              type.value === queryParams?.sort?.name && type.order === queryParams?.sort?.order ? type?.label : ""
                            )
                            : "Sort By"}
                        </DropdownToggle>
                        <DropdownMenu start>
                          <ul className="link-list-opt no-bdr">
                            {sortOptions.map((x) => (
                              <li>
                                <DropdownItem
                                  onClick={() => {
                                    setQueryParams((prev) => ({ ...prev, sort: { name: x.value, order: x.order }, page: 1 }));
                                  }}
                                >
                                  <span>{x.label}</span>
                                </DropdownItem>
                              </li>
                            ))}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
                <div className="d-flex flex-md-row flex-column-reverse justify-content-end align-items-md-center align-items-end mt-2" style={{ gap: "1rem" }}>
                  <div className="form-control-wrap align-self-stretch flex-grow-1">
                      <div className="form-icon form-icon-right">
                        <Button onClick={(e) => submitIdSearch(e)}>
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="default-04"
                        placeholder="Search by ID or Permit No"
                        onChange={(e) => debounce(() => setQueryParams((prev) => ({...prev, search: e.target.value, page: 1})), 1000)()}
                        value={queryParams?.pid}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            debounce(() => submitIdSearch(e), 1000)();
                          }
                        }}
                      />
                  </div>
                  <RSelect
                    placeholder="Choose Building"
                    className="status-div building-width-custom"
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultData={[]}
                    isLoading={buildingsLoading}
                    loadingMessage={() => <Spinner />}
                    options={buildingFilterOptions}
                    value={queryParams.buildings}
                    onChange={(e) => setQueryParams((prev) => ({ ...prev, buildings: e, page: 1 }))}
                />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "end",
                  }}
                >
                  <div className="row mt-3 gx-1">
                    {selectedStatus && (
                      <div className="col">
                        <Badge color="primary">
                          {selectedStatus}
                          <Icon
                            name="cross-sm"
                            onClick={handleResetStatus}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}
                    
                    {queryParams?.search && (
                      <div className="col">
                        <Badge color="primary">
                          search:{queryParams?.search}
                          <Icon
                            name="cross-sm"
                            onClick={(e)=>{
                              setQueryParams((prev) => ({...prev, search: null, page: 1}));
                            }}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}


                    {queryParams?.sort && (
                      <div className="col">
                        <Badge
                          label={`${sortOption
                            ? sortOptions?.map((type) =>
                              type.value === queryParams?.sort?.name && type.order === queryParams?.sort?.order ? type?.label : ""
                            )
                            : "Sort By"
                            }`}
                          color="gray"
                        >
                          {queryParams?.sort
                            ? sortOptions?.map((type) =>
                              type.value === queryParams?.sort?.name && type.order === queryParams?.sort?.order ? type?.label : ""
                            )
                            : ""}
                          <Icon
                            name="cross-sm"
                            onClick={() => setQueryParams((prev) => ({ ...prev, sort: null, page: 1 }))}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {queryParams?.type && (
                      <div className="col">
                        <Badge label={queryParams?.type}>
                          {queryParams?.type}
                          <Icon
                            name="cross-sm"
                            onClick={() => {
                              setQueryParams((prev) => ({ ...prev, type: null, page: 1 }));
                            }}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {queryParams?.beds && (
                      <div className="col">
                        <Badge label={`Beds: ${queryParams?.beds}`} color="info">
                          {`Beds: ${queryParams?.beds}`}
                          <Icon
                            name="cross-sm"
                            onClick={() => setQueryParams((prev) => ({ ...prev, beds: null, page: 1 }))}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {queryParams?.baths && (
                      <div className="col">
                        <Badge
                          label={`Baths: ${queryParams?.baths}`}
                          color="warning"
                        >
                          {`Baths: ${queryParams?.baths}`}
                          <Icon
                            name="cross-sm"
                            onClick={() => setQueryParams((prev) => ({ ...prev, baths: null, page: 1 }))}
                          ></Icon>{" "}
                        </Badge>
                      </div>
                    )}

                    {propType ?
                      propType.map((type) =>
                        type.id === queryParams?.propertyType ? (
                          <div className="col" key={type?.id}>
                            <Badge color="danger">
                              {type?.propertyType}
                              <Icon
                                name="cross-sm"
                                onClick={() => setQueryParams((prev) => ({ ...prev, propertyType: null, page: 1 }))}
                              ></Icon>
                            </Badge>
                          </div>
                        ) : null
                      ):null}

                    {queryParams?.agent &&
                      authUser?.role &&
                      authUser?.role !== newRoles?.Agent && (
                        <div className="col">
                          <Badge label={queryParams?.agent?.label} color="black">
                            {"Agent: "}
                            {queryParams?.agent?.label}
                            <Icon
                              name="cross-sm"
                              onClick={() => {
                                setQueryParams((prev) => ({...prev, agent: null, page: 1}));
                              }}
                            ></Icon>{" "}
                          </Badge>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <div className="d-flex justify-content-end align-items-end">
              <ul className="nk-block-tools g-3 mb-2 pe-2 ">
                <Button
                  onClick={() =>
                    setViewType(viewType === "normal" ? "list" : "normal")
                  }
                  color={"primary"}
                >
                  <Icon name={"list"} className={"pe-1 "}></Icon>
                  {viewType === "normal" ? "List View" : "Grid View"}
                </Button>
              </ul>
            </div>

            {viewType === "normal" ? (
              <Row>
              {isLoading ? new Array(3).fill(0).map((x,i)=>(
                <Col
                      md="6"
                      className="col-xl-4 col-xxxl-3 px-4 my-3"
                      key={x+i}
                    >
                    <Card className="p-0">
                     <CardHeader className="p-1">
                     <Skeleton height={200} />
                     </CardHeader>
                       <CardBody className="bg-white">
                       <Skeleton className="w-50 py-2" />
                       <Skeleton className="w-90 py-3 my-1" />
                       <Skeleton count={2} className="w-40 my-2 py-1" />
                       <Skeleton count={3} className="w-30  my-2" />
                       </CardBody>
                    </Card>
                    </Col>
              )):null}
                {!isFetching && !isLoading  && data?.findPropListsOutput?.length > 0  ?
                  data?.findPropListsOutput?.map((property, i) => (
                    <Col
                      md="6"
                      className="col-xl-4 col-xxxl-3 "
                      key={property?.id}
                    >
                      <PropertyCard property={property} index={i} />
                    </Col>
                  )):null}
                {/* Pagination start */}
                {!isFetching && !isLoading && data?.findPropListsOutput && data?.findPropListsOutput?.length > 0 ? (
                  <div className="mt-3">
                    <PaginationComponent
                      currentPage={queryParams?.page ?? 1}
                      paginate={(e) => setQueryParams((prev)=>({...prev, page: e}))}
                      itemPerPage={data?.resPerPage ?? 10}
                      totalItems={data?.filteredCount ?? 0}
                    />
                  </div>
                ) : (
                  <div className="text-center">
                   {!isLoading && <span className="text-silent">No Properties Found</span>}
                  </div>
                )}
                {/* Pagination end */}
              </Row>
            ) : (
              <div>
              <BlockHead size="sm">
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockDes className="">
                          <p>You have total {data?.filteredCount ?? 0} properties.</p>
                        </BlockDes>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <Button
                            className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""
                              }`}
                            onClick={() => updateSm(!sm)}
                          >
                            <Icon name="menu-alt-r"></Icon>
                          </Button>
                          <div
                            className="toggle-expand-content"
                            style={{
                              display: sm ? "block" : "none",
                            }}
                          >
                            <ul className="nk-block-tools g-3">
                              <li>
                                <ReactToPrint
                                  content={() => printRef.current}
                                  documentTitle="Properties"
                                  removeAfterPrint
                                  bodyClass="p-4"
                                  trigger={() => (
                                    <Button
                                      color="light"
                                      outline
                                      className="btn-white"
                                    >
                                      <Icon name="download-cloud"></Icon>
                                      <span>Print</span>
                                    </Button>
                                  )}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Block>
                    <DataTable className="card-stretch">
                      <div className="card-inner position-relative card-tools-toggle userRegularList-innercard">
                        <div className="card-title-group">
                          <div className="card-tools">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color="transparent"
                                className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                              >
                                {selectedStatus ? selectedStatus : "Status"}
                              </DropdownToggle>
                              <DropdownMenu start>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      onClick={() => {
                                        setQueryParams((prev) => ({...prev, status: Status[0], page: 1}));
                                        setSelectedStatus(propActiveStatus[0]);
                                      }}
                                    >
                                      <span>{propActiveStatus[0]}</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() => {
                                        setQueryParams((prev) => ({...prev, status: Status[1], page: 1}));
                                        setSelectedStatus(propActiveStatus[1]);
                                      }}
                                    >
                                      <span>{propActiveStatus[1]}</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                           
                          </div>
                          <div className="card-tools me-n1">
                            <ul className="btn-toolbar gx-1">
                              <li>
                                <a
                                  href="#search"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    toggle();
                                  }}
                                  className="btn btn-icon search-toggle toggle-search"
                                >
                                  <Icon name="search"></Icon>
                                </a>
                              </li>
                              <li className="btn-toolbar-sep"></li>
                              <li>
                                <div className="toggle-wrap">
                                  <Button
                                    className={`btn-icon btn-trigger toggle ${tableSm ? "active" : ""
                                      }`}
                                    onClick={() => updateTableSm(true)}
                                  >
                                    <Icon name="menu-right"></Icon>
                                  </Button>
                                  <div
                                    className={`toggle-content ${tableSm ? "content-active" : ""
                                      }`}
                                  >
                                    <ul className="btn-toolbar gx-1">
                                      <li className="toggle-close">
                                        <Button
                                          className="btn-icon btn-trigger toggle"
                                          onClick={() => updateTableSm(false)}
                                        >
                                          <Icon name="arrow-left"></Icon>
                                        </Button>
                                      </li>
                                      <li>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            tag="a"
                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                          >
                                            <div className="dot dot-primary"></div>
                                            <Icon name="filter-alt"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            start
                                            className="filter-wg dropdown-menu-xl"
                                            style={{
                                              overflow: "visible",
                                            }}
                                          >
                                            <div className="dropdown-head">
                                              <span className="sub-title dropdown-title">
                                                Filter Properties
                                              </span>
                                              <div className="dropdown">
                                                <a
                                                  href="#more"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                  }}
                                                  className="btn btn-sm btn-icon"
                                                >
                                                  <Icon name="more-h"></Icon>
                                                </a>
                                              </div>
                                            </div>
                                            <div className="dropdown-body dropdown-body-rg">
                                              <Row className="gx-6 gy-3">
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {queryParams?.propertyType
                                                          ? propType?.map(
                                                            (type) =>
                                                              type.id ===
                                                                queryParams?.propertyType
                                                                ? type?.propertyType
                                                                : null
                                                          )
                                                          : "Type"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          {propType?
                                                            propType.map(
                                                              (type) => (
                                                                <li key={type?.id}>
                                                                  <DropdownItem
                                                                    onClick={() =>
                                                                      setQueryParams((prev)=>({...prev, propertyType: type.id, page: 1}))
                                                                    }
                                                                  >
                                                                    <span>
                                                                      {
                                                                        type?.propertyType
                                                                      }
                                                                    </span>
                                                                  </DropdownItem>
                                                                </li>
                                                              )
                                                            ):null}
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>

                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {selectedStatus?? "Status"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          <li>
                                                            <DropdownItem
                                                              onClick={() => {
                                                                setQueryParams((prev) => ({...prev, status: Status[0], page: 1}));
                                                                setSelectedStatus(
                                                                  propActiveStatus[0]
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                {
                                                                  propActiveStatus[0]
                                                                }
                                                              </span>
                                                            </DropdownItem>
                                                          </li>
                                                          <li>
                                                            <DropdownItem
                                                              onClick={() => {  
                                                                setQueryParams((prev) => ({...prev, status: Status[1], page: 1}));
                                                                setSelectedStatus(
                                                                  propActiveStatus[1]
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                {
                                                                  propActiveStatus[1]
                                                                }
                                                              </span>
                                                            </DropdownItem>
                                                          </li>
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                 
                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        color="transparent"
                                                        className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                                                      >
                                                        {queryParams?.type ?? "Purpose"}
                                                      </DropdownToggle>
                                                      <DropdownMenu start>
                                                        <ul className="link-list-opt no-bdr">
                                                          {filteredListingType.map(
                                                            (x, y) => (
                                                              <li key={x}>
                                                                <DropdownItem
                                                                  onClick={(
                                                                    ev
                                                                  ) => {
                                                                    setQueryParams((prev) => ({
                                                                      ...prev,
                                                                      type: x,
                                                                      page: 1
                                                                    })
                                                                    );
                                                                  }}
                                                                >
                                                                  <span>
                                                                    {x}
                                                                  </span>
                                                                </DropdownItem>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                              
                                                  </div>
                                                </Col>
                                                <Col size="6">
                                                  <div className="form-group">
                                                    <RSelect
                                                      closeMenuOnSelect={false}
                                                      components={
                                                        animatedComponents
                                                      }
                                                      defaultData={[]}
                                                      isMulti
                                                      options={commOptions}
                                                      placeholder="Community"
                                                      value={
                                                        selectedCommunities
                                                      }
                                                      onChange={(e) =>
                                                        setQueryParams((prev)=>({...prev, community: e, buildings: null, page: 1}))
                                                      }
                                                    />
                                                 
                                                  </div>
                                                </Col>
                                               
                                              </Row>
                                            </div>
                                            <div className="dropdown-foot between">
                                              <a
                                                href="#reset"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  handleClear();
                                                }}
                                                className="clickable"
                                              >
                                                Reset Filter
                                              </a>
                                              <a
                                                href="#save"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                Save Filter
                                              </a>
                                            </div>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </li>
                                      <li>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            color="tranparent"
                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                          >
                                            <Icon name="setting"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            start
                                            className="dropdown-menu-md"
                                          >
                                            <ul className="link-check">
                                              <li>
                                                <span>Show</span>
                                              </li>
                                              <li
                                                className={
                                                  itemPerPage === 10
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setItemPerPage(10);
                                                  }}
                                                >
                                                  10
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  itemPerPage === 15
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setItemPerPage(15);
                                                  }}
                                                >
                                                  15
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                            <ul className="link-check">
                                              <li>
                                                <span>Sort By</span>
                                              </li>
                                              <li
                                                className={
                                                  sort === "newest"
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  value="newest"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                  }}
                                                >
                                                  Newest
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "oldest"
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <DropdownItem
                                                  value="oldest"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                  }}
                                                >
                                                  Oldest
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "asc" ? "active" : ""
                                                }
                                              >
                                                <DropdownItem
                                                  value={"asc"}
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                  }}
                                                >
                                                  Price (low to high)
                                                </DropdownItem>
                                              </li>
                                              <li
                                                className={
                                                  sort === "asc" ? "active" : ""
                                                }
                                              >
                                                <DropdownItem
                                                  value={"desc"}
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    handleSortChange(ev);
                                                  }}
                                                >
                                                  Price (high to low)
                                                </DropdownItem>
                                              </li>
                                             
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Activity (DESC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Activity (ASC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Modified (DESC)
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#dropdownitem"
                                                >
                                                  Last Modified (ASC)
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          className={`card-search search-wrap ${!onSearch && "active"
                            }`}
                        >
                          <div className="card-body">
                            <div className="search-content">
                              <Button
                                className="search-back btn-icon toggle-search active"
                                onClick={() => {
                                  setFlSearchName("");
                                  toggle();
                                }}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                              <input
                                type="text"
                                className="border-transparent form-focus-none form-control"
                                placeholder="Search by user or email"
                                value={flSearchName}
                                onChange={(e) =>
                                  setFlSearchName(e.target.value)
                                }
                              />
                              <Button
                                className="search-submit btn-icon"
                                onClick={(e) => submitSearch(e)}
                              >
                                <Icon name="search"></Icon>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={printRef} id="printableId">
                        <DataTableBody>
                          <DataTableHead>
                            <DataTableRow className="nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  // onChange={ ( e ) => selectorCheck( e ) }
                                  id="uid"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="uid"
                                ></label>
                              </div>
                            </DataTableRow>
                            <DataTableRow>
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("referNo");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  ID
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow className="d-sm-none d-xxl-table-cell">
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                Agent
                              </span>
                            </DataTableRow>
                            <DataTableRow size="mb" className="w-20">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("propertyTitle");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Title
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>

                            <DataTableRow size="md">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();

                                  setSortOption(
                                    sortOption === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Price
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("listingType");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Purpose
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="lg">
                              <span className="sub-text">Owner Name</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="sub-text">Owner Phone</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("status");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text">
                                  Status
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                last Activity
                              </span>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <span className="sub-text d-sm-none d-xxl-inline-block">
                                Last Modified
                              </span>
                            </DataTableRow>
                            <DataTableRow
                              size="md"
                              className="d-sm-none d-xxl-inline-block"
                            >
                              <div
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortByTitle("createdAt");
                                  setSortByOrder(
                                    sortByOrder === "asc" ? "desc" : "asc"
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="sub-text d-sm-none d-xxl-inline-block">
                                  Time in Stock
                                  <Icon className="ms-1">
                                    <span>&darr;</span>
                                    <span>&uarr;</span>
                                  </Icon>
                                </span>
                              </div>
                            </DataTableRow>
                          </DataTableHead>
                          {/*Head*/}
                          {data?.findPropListsOutput &&
                            data?.findPropListsOutput?.length > 0 &&
                            data?.findPropListsOutput?.map((item) => {
                              return (
                                <ProductDataTableRow property={item} />
                              );
                            })}
                        </DataTableBody>
                      </div>
                    </DataTable>

                    {/* Pagination start */}
                    <PreviewAltCard>
                      {!isFetching &&
                        !isLoading &&
                        data?.findPropListsOutput &&
                        data?.findPropListsOutput?.length > 0 ? (
                        <div className="mt-3">
                          <PaginationComponent
                            currentPage={queryParams?.page ?? 1}
                            paginate={(e) => setQueryParams((prev)=>({...prev, page: e}))}
                            itemPerPage={data?.resPerPage ?? 10}
                            totalItems={data?.filteredCount ?? 0}
                          />
                        </div>
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">
                            No Properties Found
                          </span>
                        </div>
                      )}
                    </PreviewAltCard>
                    {/* Pagination end */}
                  </Block>
              </div>
            )}
          </PreviewCard>
        </Block>

        {/* ! ADD FIRST MISSING CODE HERE t1.txt ! */}
      </Content>
      {/* ! ADD SECOND MISSING CODE HERE t2.txt ! */}
    </React.Fragment>
  );
};

export default ProductList;
