import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useRef } from 'react';
import Head from '../../../layout/head/Head';
import { Button, Card, CardImg, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { Block, Icon, RSelect } from '../../../components/Component';
import AddFooterTemplate from './AddFooterTemplate';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import AddDeveloperModal from '../../components/popup/AddDeveloperModal';
import { TagsInput } from 'react-tag-input-component';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDragAndDrop from '../../../utils/hooks/useDragAndDrop';
import InputFile from './InputFile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import { useQuery } from 'react-query';
import { fetchAllOffPlanTags } from '../../../api/off-plan-tags/fetchAllOffPlanTags';
import ToggleSwitch from '../../../components/input/switch/ToggleSwitch';

const AddOffPlanStepFour = (props) => {
  let { development, setDevelopment, setFilesToUpload, setFinalPlanDetails } = props;
  const axios = useAxiosPrivate();
  const { handleSubmit } = useForm();
  const [tags, setTags] = useState(development.keywords);
  const [offPlanTags, setOffPlanTags] = useState(new Set());

  const {
    active: developmentLogoActive,
    errorMessage: developmentLogoStatus,
    dragenter: developmentLogoDragEnter,
    dragleave: developmentLogoDragLeave,
    drop: developmentLogoDrop,
    handleFileChange: developmentLogoFileChange,
    files: developmentLogoFiles,
    removeImage: removeDevelopmentLogoImage
  } = useDragAndDrop({
    inputType: 'image'
  });

  useEffect(() => {
    setDevelopment((prevState) => ({
      ...prevState,
      keywords: tags
    }));
  }, [tags]);

  const {
    data: tagData,
    isLoading: tagLoading,
    isError: tagError
  } = useQuery(['all-offplan-tags'], () => fetchAllOffPlanTags(axios), {
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  const [searchDeveloper, setSearchDeveloper] = useState('');
  const [selectedFooter, setSelectedFooter] = useState();
  const selectFooterDropdownRef = useRef(null);

  const [developer, setDeveloper] = useState({
    name: null,
    description: null,
    logo: null,
    headerTitle: null
  });

  const [searchFooter, setSearchFooter] = useState('');

  const [developers, setDevelopers] = useState([]);

  const [footerTemplates, setFooterTemplates] = useState([]);

  const filteredDevelopers = developers?.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchDeveloper?.toLowerCase())
  );

  const submitForm = (data) => {
    // props.next();
    if (
      (!developer?.id && !developer?._id && !developer?.value) ||
      !selectedFooter ||
      (!selectedFooter?.id && !selectedFooter?._id && !selectedFooter?.value) ||
      !developmentLogoFiles[0]?.file
    )
      return toast.error('Please fill required fields');

    const developmentLogo = developmentLogoFiles[0]?.file;

    const fileName = `offPlans/photos/${uuid()}.${developmentLogo?.name?.split('.')?.pop()}`;

    setFilesToUpload((prev) => ({
      ...prev,
      devLogo: {
        file: developmentLogo,
        type: 'image/*',
        fileName
      }
    }));

    setFinalPlanDetails((prev) => ({
      ...prev,
      developer: developer?.id ?? developer?._id ?? developer?.value,
      footer: selectedFooter?.id ?? selectedFooter?._id ?? selectedFooter?.value,
      developmentLogo: fileName,
      tags: Array.from(offPlanTags)
    }));

    props.next();
  };

  const [isOpenFooterModal, setIsOpenFooterModal] = useState(false);

  const toggleModalFooter = () => {
    setIsOpenFooterModal(!isOpenFooterModal);
  };

  const [isOpenDeveloperModal, setIsOpenDeveloperModal] = useState(false);

  const toggleModalDeveloper = () => {
    setIsOpenDeveloperModal(!isOpenDeveloperModal);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDevelopment((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const getFootersAndDevelopers = async () => {
    try {
      const developersReq = axios.get(`/off-plan/plan-developer`);

      const footersReq = axios.get(`/off-plan/footer-template`);

      const [{ data: developersRes }, { data: footersRes }] = await Promise.all([
        developersReq,
        footersReq
      ]);
      footersRes && setFooterTemplates(footersRes);
      developersRes && setDevelopers(developersRes);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getFootersAndDevelopers();
  }, []);

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4">
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Select Developer*</label>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <RSelect
                      defaultValue="select Developer"
                      options={filteredDevelopers.map((dev) => ({
                        ...dev,
                        value: dev._id ?? dev?.id,
                        label: dev.name
                      }))}
                      value={developer}
                      isMulti={false}
                      onChange={(selectedOption) => {
                        setDeveloper(selectedOption);
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    className="ms-2"
                    type="button"
                    onClick={toggleModalDeveloper}
                  >
                    <Icon name="plus"></Icon> Add New Developer
                  </Button>
                </div>
              </div>

              <AddDeveloperModal
                isOpen={isOpenDeveloperModal}
                toggle={toggleModalDeveloper}
                callback={(developer) => {
                  setDevelopers((prev) => [...prev, { ...developer }]);
                  setDeveloper({
                    ...developer,
                    value: developer?.id ?? developer?._id,
                    label: developer?.name
                  });
                }}
              />
            </Col>
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Footer Template*</label>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <RSelect
                      options={footerTemplates
                        ?.filter((temp) =>
                          temp?.name?.toLowerCase()?.includes(searchFooter?.toLowerCase())
                        )
                        .map((template) => ({
                          ...template,
                          value: template.id,
                          label: template.name
                        }))}
                      value={selectedFooter}
                      isMulti={false}
                      onChange={(selectedOption) => {
                        setSelectedFooter(selectedOption);
                        selectFooterDropdownRef.current?.hideDropDown();
                      }}
                    />
                  </div>
                  <Button
                    color="primary"
                    className="ms-2"
                    type="button"
                    onClick={toggleModalFooter}
                  >
                    <Icon name="plus"></Icon> Add New Footer
                  </Button>
                </div>
              </div>

              <AddFooterTemplate
                isOpen={isOpenFooterModal}
                toggle={toggleModalFooter}
                callback={(footer) => {
                  console.log(footer);
                  setFooterTemplates((prev) => [...prev, footer]);
                  setSelectedFooter({
                    ...footer,
                    value: footer?.id ?? footer?._id,
                    label: footer?.name
                  });
                }}
              />
            </Col>
            <Col>
              <Label>Development Logo*</Label>
              <div>
                <InputFile
                  active={developmentLogoActive}
                  dragenter={developmentLogoDragEnter}
                  dragleave={developmentLogoDragLeave}
                  drop={developmentLogoDrop}
                  errorMessage={developmentLogoStatus}
                  files={developmentLogoFiles}
                  handleFileChange={developmentLogoFileChange}
                  removeImage={removeDevelopmentLogoImage}
                  inputType="image"
                  inputProps={{ accept: 'image/*' }}
                  multiple
                />

                {developmentLogoFiles.length > 0 && (
                  <section>
                    <h6 className="mt-2 mb-2">Uploaded Images</h6>
                    <div className="image-list">
                      <LazyLoadComponent key={developmentLogoFiles[0]?.file?.name}>
                        <Card className="position-relative" style={{ width: '15rem' }}>
                          <CardImg
                            src={URL.createObjectURL(developmentLogoFiles[0]?.file)}
                            alt={developmentLogoFiles[0]?.file?.name}
                          />

                          <Button
                            className="position-absolute"
                            style={{
                              top: '0',
                              left: '0'
                            }}
                            color="danger"
                            onClick={() => removeDevelopmentLogoImage(0)}
                          >
                            <Icon name="trash"> </Icon>
                          </Button>
                        </Card>
                      </LazyLoadComponent>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <Label className="form-label" htmlFor="city">
                  keywords
                </Label>
                <TagsInput
                  value={tags}
                  onChange={(newTags) => setTags(newTags)}
                  name="Tags"
                  placeHolder="enter tags then press enter"
                />
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <Label className="form-label" htmlFor="city">
                  Tags
                </Label>
                {tagError ? (
                  <div>
                    <p className="text-danger">Error fetching tags</p>
                  </div>
                ) : (
                  <div className="d-flex flex-wrap" style={{ gap: '2rem' }}>
                    {tagLoading && <Spinner color="secondary" />}
                    {!tagLoading &&
                      tagData?.map((item) => (
                        <ToggleSwitch
                          label={item.name}
                          checked={offPlanTags.has(item.name)}
                          id={`${item.name}-${item.id}`}
                          key={`${item.name}-${item.id}-switch`}
                          onChange={(ev) => {
                            if (ev?.target.checked) {
                              setOffPlanTags((prev) => new Set([...prev, item.name]));
                            } else {
                              setOffPlanTags(
                                (prev) => new Set([...prev].filter((tag) => tag !== item.name))
                              );
                            }
                          }}
                        />
                      ))}
                  </div>
                )}
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <Label className="form-label" htmlFor="city">
                  Meta Title
                </Label>
                <Input
                  type="text"
                  name="metaTitle"
                  defaultValue={development?.metaTitle}
                  onChange={handleChange}
                  placeholder="enter off plan meta title"
                ></Input>
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  Meta Description
                </label>
                <textarea
                  type="textarea"
                  className="form-control form-control-sm"
                  rows="5"
                  placeholder="Write Meta Description"
                  name="metaDescription"
                  value={development.metaDescription}
                  onChange={handleChange}
                  id="developmentDesc"
                />
              </div>
            </Col>
          </Row>
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                  Next
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};

export default AddOffPlanStepFour;
