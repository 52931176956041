import imageCompression from 'browser-image-compression';
import { options as globalConfig } from './config';

export default async function compressImage(image, options) {
  try {
    const compressedFile = await imageCompression(
      image,
      options ?? globalConfig
    );
    return compressedFile;
  } catch (error) {
    console.log(error);
    return image;
  }
}

export async function compressImageAsWebP(image, options) {
  try {
    const compressedFile = await imageCompression(
      image,
      options ?? globalConfig
    );

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Conversion to WebP failed'));
              }
            },
            'image/webp', // output format
            1 // quality
          );
        };
      };
      reader.readAsDataURL(compressedFile);
    });
  } catch (error) {
    console.log(error);
    return image;
  }
}
