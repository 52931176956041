import ErrorHandler from "../error-handlers/axios/defaultErrorHandler"

/**
 * @typedef {Object} OffPlanTag
 * @property {string} id - Tag ID
 * @property {string} name - Tag name
 * @property {string} slug - Tag slug
 * @property {string} createdBy - User ID who created the tag
 * @property {string} createdAt - Tag creation date
 * @property {string} updatedAt - Tag last update date
 */

/**
 * @description Fetches all off-plan tags
 * @param {Axios} axiosInstance
 * @returns {Promise<Array<OffPlanTag>>} apiResponse
 */
export const fetchAllOffPlanTags = async (axiosInstance) => {
  try {
    const res = await axiosInstance.get(`/off-plan-tags/all`);

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}