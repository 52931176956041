import { fetchPropertyCardMappings } from "../api/property-cards/fetchPropertyCardLeads";

export const PROP_CARDS_KEYS = {
  mappings: ['propertyCardMappings'],
  mappingsByCard: (propertyCardId) => ['propertyCardMappings', propertyCardId]
}
const fetchLeadPropertyCards = async ( leadId, page = 1, itemsPerPage = 6, axios ) =>
{
    try
    {
        const { data } = await axios.get( `/property-cards/lead-to-card?lead=${ leadId }&page=${ page }&limit=${ itemsPerPage }&sortBy=updatedAt` );
        return data;
    } catch ( error )
    {
        throw error;
    }
};
const createPropertyCard = async ( newPropertyCard, axios ) =>
{
    try
    {
        const { data } = await axios.post( `/property-cards`, newPropertyCard );
        return data.data;
    } catch ( error )
    {
        throw error;
    }
};
const propertyCardQueries = {
    list: ( leadId, page, itemsPerPage, axios ) => ( {
        queryKey: [ 'propertyCards', leadId, page, itemsPerPage ],
        queryFn: () => fetchLeadPropertyCards( leadId, page, itemsPerPage, axios ),
    } ),
    create: ( axios ) => ( {
        mutationFn: ( newPropertyCard ) => createPropertyCard( newPropertyCard, axios ),
    } ),
    /**
   * Fetches lead mappings for a specific property card.
   *
   * @param {Axios} axios - The Axios instance to use for making requests.
   * @param {string | number} propertyCardId - The ID of the property cards associated with the leads.
   * @param {import("axios").AxiosRequestConfig} [options] - Optional parameters for the query.
   * @returns {import('react-query').QueryConfig<any, Error, any>} - A React Query configuration object.
   */
    getMappings: (axios, propertyCardId, options) => ({
      queryKey: [ ...PROP_CARDS_KEYS.mappings, propertyCardId, options?.params ],
      queryFn: () => fetchPropertyCardMappings(axios, propertyCardId, options),
    })

};

export default propertyCardQueries;