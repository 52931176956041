import React, { useEffect, useState } from "react";
import { Block } from "../../../components/Component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DnDKitDemo from "./DnDKitDemo";
import InputFile from "./InputFile";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";
import { fetchAmenities } from "../../../redux/actions/property";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteFromS3, uploadToS3 } from "../../../utils/envConfig";
import { showToast } from "../../../utils/toast/toast";
import { useLocation, useNavigate } from "react-router-dom";


const AddOffPlanStepThreeEdit = ( props ) =>
{
    let {
        setFilesToUpload,
        amenitiesFilesDev,
        offPlanUpdate,
    } = props;
      const dispatch = useDispatch();
    const axios = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();
 
    const {
        active: activeAmenities,
        errorMessage: amenitiesStatus,
        dragenter: amenitiesDragEnter,
        dragleave: amenitiesDragLeave,
        drop: amenitiesDropVideo,
        handleFileChange: amenitiesFileChange,
        files: amenitiesFiles ,
        setFiles: setAmenitiesFiles,
        removeImage: removeAmenityImage,
    } = useDragAndDrop({
        multiple: true,
        inputType: "image",
        maxFilesLength: 10,
        minFilesLength: 8,
        labelName: "title",
    });

    const { handleSubmit } = useForm();

    const [toBeUpdated, setToBeUpdated] = useState({});
    const [disabled, setDisabled] = useState(false);

    const [toBeDeleted, setToBeDeleted] = useState({
        amenities: [],
        developmentPhotos: [],
        others: [],
        developmentLogo: "",
    });
    const submitForm = async ( data ) =>
    {
        const isAdded = await addAmenitiesToFinal();
        if (isAdded) {
            navigate(`${location.pathname}?tab=4`, { replace: true });
            props.next();
        }
    };
    const addAmenitiesToFinal = async () => {
        if (!amenitiesFiles.length || amenitiesFiles.length < 4) {
            showToast("Please add at least 4 amenities", "error");
        }

        const nullAmenity = amenitiesFiles.find(
            (item) => /*!item.description ||*/ !item.file || !item.title
        );

        if (nullAmenity) {
            showToast("All amenities must have a name and an image.", "error");
            return false;
        }

        const filesToDelete = [];

        const filesToUpload = [];

        const amenityList = amenitiesFiles?.map((item) => {
            if (typeof item?.file === "string") {
                return item;
            }

            const fileExt = item?.file?.name?.split(".")?.pop();

            const fileName = `offPlans/amenities/${uuid()}.${fileExt}`;

            filesToDelete.push(item?.prevPhoto);

            filesToUpload.push({
                file: item?.file,
                type: "image/*",
                fileName,
            });

            return {
                ...item,
                fileName,
                image: URL.createObjectURL(item?.file),
                photo: fileName,
            };
        });

        filesToUpload.length &&
            setFilesToUpload((prev) => ({
                ...prev,
                amenities: filesToUpload,
            }));

        filesToDelete.length &&
            setToBeDeleted((prev) => ({ ...prev, amenities: filesToDelete }));

        setToBeUpdated((prev) => ({
            ...prev,
            amenities: amenityList?.map(
                ({ photo, fileName, description, title, file }) => ({
                    title,
                    description,
                    photo,
                })
            ),
        }));

        setAmenities(amenityList);
        await saveAndExit();
        return true;
    };
    const saveAndExit = async () => {
        if (!amenitiesFiles.length)
            return alert("Please add amenities");

        const nullAmenity = amenitiesFiles.find(
            (item) =>
                // !item.description ||
                !item.file || !item.title
        );

        if (nullAmenity)
            return alert(
                "All amenities must have a name, title and image \n Hint: Remove irrelvant rows."
            );

        try {
            setDisabled(true);
            const amenityList = await Promise.all(
                amenitiesFiles?.map(async (item) => {
                    if (
                        typeof item?.file === "string"
                    ) {
                        return item;
                    }

                    const fileExt = item?.file?.name
                        ?.split(".")
                        ?.pop();

                    const fileName = `offPlans/amenities/${uuid()}.${fileExt}`;


                    const s3url = await uploadToS3(
                        item.file,
                        fileName,
                        item?.file?.type ?? "image/*",
                        axios
                    );

                    await deleteFromS3(item?.prevPhoto,axios);

                    return {
                        title: item?.title,
                        description:
                            item.description ?? "",
                        photo: fileName,
                        image: URL.createObjectURL(
                            item?.file
                        ),
                    };
                })
            );

           await offPlanUpdate({
                amenities: amenityList?.map(
                    ({
                        photo,
                        description,
                        title,
                    }) => ({
                        title,
                        description,
                        photo,
                    })
                ),
            });
        } catch (err) {
            // console.log(err);
            toast.error(err?.message);
        } finally {
            setDisabled(false);
        }
    }

    
    const [ amenities, setAmenities ] = useState( [] );

    useEffect( () =>
    {
      dispatch( fetchAmenities(axios) );
    }, [ dispatch ] );
    
    useEffect( () =>
    {
      setAmenitiesFiles(amenitiesFilesDev)
    }, [ amenitiesFilesDev ] );

    return (
        <React.Fragment>
            <Head title="Off Plan"></Head>
            <Block>
                <form id='add-development-form'
                    onSubmit={ handleSubmit( submitForm ) }>
                        <div>
                          <InputFile
                            active={activeAmenities}
                            dragenter={amenitiesDragEnter}
                            dragleave={amenitiesDragLeave}
                            drop={amenitiesDropVideo}
                            errorMessage={amenitiesStatus}
                            files={amenitiesFiles}
                            handleFileChange={amenitiesFileChange}
                            inputType="image"
                            removeImage={removeAmenityImage}
                            multiple
                            inputProps={{
                                accept: "image/*",
                            }}
                          />
                        </div>
                    {amenitiesFiles&&amenitiesFiles.length>0 &&
                    <DnDKitDemo images={amenitiesFiles} setImages={setAmenitiesFiles} removeImage={removeAmenityImage}/>
                    }
                    <div className="actions clearfix">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
                            <li>
                                <Button color="primary" type="submit">
                                    Save &  Next
                                </Button>
                            </li>
                            <li>
                                <Button color="light" onClick={ props.prev } disabled>
                                    Previous
                                </Button>
                            </li>
                        </ul>
                    </div>
                </form>
            </Block></React.Fragment>
    );
};

export default AddOffPlanStepThreeEdit;
