import ErrorHandler from '../error-handlers/axios/defaultErrorHandler';

export const getHotExplorerLeads = async (axiosInstance, options = {}) => {
  try {
    const res = await axiosInstance.get('/expired-records/expired-hot-leads', options);

    return res.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
};
