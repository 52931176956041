import React, { useCallback, useReducer } from 'react';
import AgentWarFrontView from './AgentWarFrontView';
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'react-redux';
import { loggedInAgentSelector } from '../../../redux/selectors/user';
import { useQuery } from 'react-query';
import { getAssignedHotLeads } from '../../../api/activities/use-cases/hotLeadsQueries';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { hasValue, timeAsMilliseconds } from '../../../utils/Utils';
import { LeadStatus } from '../../../utils/envConfig';

export const AgentWarFrontContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_QUERY_PARAMS':
      return { ...state, ...action.queryParams };
    default:
      throw new Error();
  }
};

const AgentWarFrontContainer = () => {
  const now = new Date();
  const axios = useAxiosPrivate();

  const authAgent = useSelector(loggedInAgentSelector);

  const [queryParams, dispatch] = useReducer(reducer, {
    fromCompletionDate: startOfDay(now).toISOString(),
    toCompletionDate: endOfDay(now).toISOString(),
    leadSource: null,
    agents: null,
    page: 1,
    sortOrder: { label: 'Oldest First', value: 'oldest' }, // can be recent or oldest
    leadStatus: LeadStatus.Fresh
  });

  const updateQueryParams = useCallback(
    (queryParams) => dispatch({ type: 'UPDATE_QUERY_PARAMS', queryParams }),
    []
  );

  const params = { 
    ...queryParams, 
    leadSource: queryParams.leadSource?.value, 
    agents: hasValue(queryParams.agents) ? queryParams.agents?.value : authAgent?.id, 
    sortOrder: queryParams.sortOrder?.value 
  };

  const { data, isLoading, isError, isFetching, error, refetch } = useQuery(
    ['agentFreshLeads', params],
    () => getAssignedHotLeads(axios, { params }),
    {
      refetchInterval: timeAsMilliseconds({ minutes: 15 }),
      refetchOnWindowFocus: false,
      enabled: hasValue(authAgent) || hasValue(queryParams.agents),
      staleTime: timeAsMilliseconds({ minutes: 15 }),
    }
  );

  return (
    <AgentWarFrontContext.Provider value={{ queryParams, updateQueryParams, data, isLoading, isError, error, isFetching, refetch }}>
      <AgentWarFrontView />
    </AgentWarFrontContext.Provider>
  );
};

export default AgentWarFrontContainer;
