import * as types from '../types';
import { s3URL } from '../../utils/envConfig';
import queryString from "query-string"


export const fetchAllProperties = (link, axios) => async (dispatch) => {
    try {
        const res = await axios.get(link);
        dispatch({
            type: types.GET_PROPERTIES,
            payload: res.data.findPropListsOutput
        });
    } catch (error) {
        // console.log(error)
    }
};

export const fetchPropertiesAdmin = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/propList`);
        dispatch({
            type: types.GET_PROPERTIES_ADMIN,
            payload: res.data
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchProperty = (id, axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/propList/${id}`);
        dispatch({
            type: types.GET_PROPERTY,
            payload: res.data
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchFeatured = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/search/query?featured=true`);
        dispatch({
            type: types.GET_FEATURED_PROPERTIES,
            payload: res.data.findPropListsOutput
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchDeal = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/search/query/property?dealOfWeek=true`);
        dispatch({
            type: types.GET_DEAL_OF_THE_WEEK,
            payload: res.data.findPropListsOutput
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchAmenities = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/amenity`);
        if (res?.data?.length) {
            const newAmenities = await Promise.all(
                res.data.map(async (item) => {

                    return {
                        ...item,
                        image: s3URL + item?.icon,
                        url: item?.icon
                    };
                })
            );
            dispatch({
                type: types.GET_AMENITIES,
                payload: newAmenities
            });
        }
    } catch (error) {
        // console.log(error)
    }
};

export const fetchAmenitiesList = (axios) => async (dispatch) => {
    dispatch({
        type: types.GET_AMENITIES_LIST_STARTED
    });
    try {
        const res = await axios.get(`/amenity`);
        if (res?.data?.length) {
            dispatch({
                type: types.GET_AMENITIES_LIST,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: types.GET_AMENITIES_LIST_FAILED,
            error
        });
        // console.log(error)
    }
};

/**
 * @deprecated don't use this function anymore, it's only here until we remove completely
 */
export const fetchCommunities = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/community`);

        if (res?.data?.length) {
            const newCommunities = await Promise.all(
                res.data.map(async (item) => {
                    if (!item?.icon) {
                        return {
                            ...item,
                            image: null
                        }
                    }
                    return {
                        ...item,
                        image: s3URL + item?.icon
                    };
                })
            );
            dispatch({
                type: types.GET_COMMUNITIES,
                payload: newCommunities
            });
        }
    } catch (error) {
        console.log(error)
    }
};

export const fetchCommunitiesList = (axios) => async (dispatch) => {
    dispatch({
        type: types.GET_COMMUNITIES_LIST_STARTED,
    });
    try {
        const res = await axios.get(`/community`);
        dispatch({
            type: types.GET_COMMUNITIES_LIST,
            payload: res?.data,
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: types.GET_COMMUNITIES_LIST_FAILED,
            error: error,
        });
    }
};

export const fetchPropTypes = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/propType`);
        const newPropTypes = await Promise.all(
            res.data.map(async (item) => {
                return {
                    ...item,
                    image: s3URL + item?.icon,
                    url: item?.icon
                };
            })
        );
        dispatch({
            type: types.GET_PROP_TYPES,
            payload: newPropTypes
        });
    } catch (error) {
        // console.log(error)
    }
};

export const fetchPropTypesList = (axios) => async (dispatch) => {
    dispatch({ type: types.GET_PROP_TYPES_STARTED })
    try {
        const res = await axios.get(`/propType`);
        dispatch({
            type: types.GET_PROP_TYPES_LIST,
            payload: res?.data
        });
    } catch (error) {
        // console.log(error)
        dispatch({
            type: types.GET_PROP_TYPES_FAILED,
            error
        });
    }
};

export const fetchCategory = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/category`);
        const newCat = await Promise.all(
            res.data.map(async (item) => {
                return {
                    ...item,
                    image: s3URL + item?.icon,
                    url: item?.icon
                };
            })
        );
        dispatch({
            type: types.GET_CATEGORY,
            payload: newCat
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchAmenityCategory = (axios) => async (dispatch) => {
    try {
        const res = await axios.get(`/amenityCategory`);
        dispatch({
            type: types.GET_AM_CATEGORY,
            payload: res.data
        });
    } catch (error) {
        // console.log(error)
    }
};
export const fetchBuildings = (axios, query = {}, { isAgent } = {}) => async (dispatch) => {
    dispatch({ type: types.GET_BUILDINGS_STARTED });
    try {
        const stringifiedQuery = isAgent ? queryString.stringify({ ...(query ?? {}), isForAgent: "true" }) : queryString.stringify(query);
        const url = isAgent ? `/buildings/with-auth?${stringifiedQuery}` : `/buildings?${stringifiedQuery}`;
        const res = await axios.get(url);
        dispatch({
            type: types.GET_BUILDING,
            payload: res.data
        });
    } catch (error) {
        // console.log(error)
        dispatch({
            type: types.GET_BUILDINGS_FAILED,
            error
        });
    }
};

export const fetchBuildingsList = (axios, queryString) => async (dispatch) => {
    dispatch({ type: types.GET_BUILDINGS_STARTED });
    try {
        const url = queryString ? `/buildings/filter${queryString}` : `/buildings/filter`
        const res = await axios.get(url);
        dispatch({
            type: types.GET_BUILDINGS,
            payload: res.data
        });
    } catch (error) {
        // console.log(error)
        dispatch({
            type: types.GET_BUILDINGS_FAILED,
            error
        });
    }
};

export const fetchFilteredCommunities = (axios, queryString) => async (dispatch) => {
    dispatch({ type: types.GET_FILTERED_COMMUNITIES_STARTED });
    try {
        const url = queryString ? `/community/filter${queryString}` : `/community/filter`
        const res = await axios.get(url);
        dispatch({
            type: types.GET_FILTERED_COMMUNITIES,
            payload: res.data
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: types.GET_FILTERED_COMMUNITIES_FAILED,
            error
        });
    }
};

export const fetchFilteredAmenities = (axios, queryString) => async (dispatch) => {
    dispatch({ type: types.GET_FILTERED_AMENITIES_STARTED });
    try {
        const url = queryString ? `/amenity/filter${queryString}` : `/amenity/filter`
        const res = await axios.get(url);
        dispatch({
            type: types.GET_FILTERED_AMENITIES,
            payload: res.data
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: types.GET_FILTERED_AMENITIES_FAILED,
            error
        });
    }
};

export const createBuilding = (axios, payload, successCallback) => async (dispatch) => {
    dispatch({
        type: types.CREATE_BUILDING_STARTED
    });
    try {
        const res = await axios.post(`/buildings`, payload);
        dispatch({
            type: types.CREATE_BUILDING,
            payload: res.data,
            status: res.status
        })
        if (successCallback && typeof successCallback === "function") {
            successCallback();
        }

        return Promise.resolve(res.data);
    } catch (error) {
        dispatch({
            type: types.CREATE_BUILDING_FAILED,
            error
        })
    }
};

export const fetchOwnerProperties = (axios, ownerId) => async (dispatch) => {
    try {
        if (!ownerId) {
            throw new Error('Invalid property owner id');
        }

        if (!axios || typeof axios.get !== 'function') {
            throw new Error('Invalid axios instance');
        }

        const { data } = await axios.get(
            `/propList/owner-properties/${ownerId}?with_property_type=true`
        );

        dispatch({
            type: types.GET_OWNER_PROPERTIES,
            payload: {
                key: ownerId,
                data
            }
        });
    } catch (error) {
        // console.log(error);
    }
};

export const fetchPublishedPropertiesXml = (axios) => async (dispatch) => {
    dispatch({ type: types.GET_XML_STARTED });
    try {
        const res = await axios.get(`/propList/xml-feed`);
        dispatch({
            type: types.GET_XML,
            payload: res.data
        });
    } catch (error) {
        dispatch({ type: types.GET_XML_FAILED, error: error });
    }
}

export const fetchPropertyXml = (axios, propListId) => async (dispatch) => {
    dispatch({ type: types.GET_SINGLE_XML_STARTED });
    try {
        const res = await axios.get(`/propList/${propListId}/xml`);
        dispatch({
            type: types.GET_SINGLE_XML,
            payload: res.data
        });
    } catch (error) {
        dispatch({ type: types.GET_SINGLE_XML_FAILED, error: error });
    }
}