import { useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ProfileDealsView from "./ProfileDealsView";
import dealQueries from "../../queries/dealQueries";
import { useQuery } from "react-query";
/**
 * @typedef {Object} Props
 * @property {import("../../api/agents/fetchAgentByUserId").AgentResponse} agent - The user information to fetch deals for.
 */

const THIRTY_MINUTES = 30 * 60 * 1000;

/**
 * Container component for managing deals table's state.
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} - The rendered container component.
 */
const ProfileDealsAgentContainer = ({ agent }) => {
  const axios = useAxiosPrivate();
  const agentId = useMemo(() => agent?.id ?? agent?._id, [agent]);

  const { queryKey, queryFn: getDeals } = dealQueries.getMostRecentByAgent(
    axios,
    agentId,
    5
  );

  const { data, error, isLoading, isError } = useQuery(queryKey, getDeals, {
    staleTime: THIRTY_MINUTES,
    refetchInterval: THIRTY_MINUTES,
    refetchOnWindowFocus: false,
    enabled: !!agentId,
  });

  return <ProfileDealsView dealsData={{ data, error, isLoading, isError }} isAgent agent={agent} />;
};

export default ProfileDealsAgentContainer;
