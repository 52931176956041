import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from "reactstrap";
import { Icon, RSelect } from "../../../../components/Component";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { HttpStatus } from "../../../../utils/envConfig";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addCommunityTeamMember } from "../../../../redux/actions/community-teams";

const AddMemberModal = ({
  communityTeamId,
  existingMembers,
  isOpen,
  toggleFn,
  refreshFn,
}) => {

  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const [agentData, setAgentData] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const { loading, error } = useSelector(state => state.communityTeam);

  const fetchAgents = async () => {
    try {
      const res = await axios.get(`/agent`);
      if (res.status === HttpStatus.OK) {
        setAgentData(res?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Couldn't fetch agents.");
      toggleFn();
    }
  };

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter out the agents that are already part of this team before setting the options
    const existingMembersIDs = existingMembers.map(
      (mem) => (mem?.id ?? mem?._id)
    );
    setAgentOptions(
        agentData
        .filter((agent) => {
            return !existingMembersIDs.includes(agent?.userId);
        })
        .map((agent) => ({
            label: `${agent?.user?.first_name ?? ""} ${
            agent?.user?.last_name ?? ""
            }`,
            value: agent?.userId ?? agent?.user?.id,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData, existingMembers]);

  const handleAddingAgent = async () => {
    const payload = {
      newMember: selectedAgent?.value
    };
    dispatch(addCommunityTeamMember(
      axios,
      communityTeamId,
      payload,
      () => toggleFn(),
      () => {
        toast.success("Added successfully!");
        refreshFn();
      }
    ));
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message ?? "Error occurred while adding agent.");
    }
  }, [error]);

  return (
    <Modal isOpen={isOpen} toggle={toggleFn} size="md">
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
          <h5 className="text-center">Add Agent to Master Community</h5>
          <div className="mb-2">
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleFn();
              }}
              className="close mt-2 text-center"
            >
              <Icon name="cross-sm"></Icon>
            </a>
          </div>
        </div>
        <RSelect
          placeholder="Select Agent"
          value={selectedAgent}
          options={agentOptions}
          onChange={(option) => {
            setSelectedAgent(option);
          }}
        />
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary" onClick={handleAddingAgent} disabled={loading}>
            {loading ? "Adding..." : "Submit"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddMemberModal;
