import ErrorHandler from "../../error-handlers/axios/defaultErrorHandler";


export const getLeadSourceLogs = async (axiosInstance,payload, options) => {
  try {
    const res = await axiosInstance.post(`/leadSources/log/lead`,payload, options);

    return res?.data;
  } catch (error) {
    ErrorHandler.handle(error);
  }
}