import React, { useState } from "react";
import { Block } from "../../../components/Component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "reactstrap";
import Head from "../../../layout/head/Head";
import { useForm } from "react-hook-form";
import InputFile from "./InputFile";
import useDragAndDrop from "../../../utils/hooks/useDragAndDrop";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DnDKitDemo from "./DnDKitDemo";


const AddOffPlanStepFive = (props) => {
  let {
    setFilesToUpload,
    setFinalPlanDetails,
  } = props;

  const { handleSubmit } = useForm();
  const {
    active: activeDevelopments,
    errorMessage: developmentsStatus,
    dragenter: developmentsDragEnter,
    dragleave: developmentsDragLeave,
    drop: developmentsDropVideo,
    handleFileChange: developmentsFileChange,
    files: developmentsFiles,
    setFiles: setDevelopmentsFiles,
    removeImage: removeDevelopmentImage,
  } = useDragAndDrop({
    multiple: true,
    inputType: "image",
    maxFilesLength: 10,
    minFilesLength: 8,
    labelName: "label",
    keepOriginal: true,
  });
  const addPhotosToFinal = async () => {
    if (developmentsFiles.length < 8)
      return toast.error("Please add at least 8 photos");
    if (developmentsFiles.length > 10)
      return toast.error("Cannot add more than 10 photos");

    const nullLabels = developmentsFiles.find(
      (file) => file?.label === "" || !file.label
    );

    if (nullLabels) return toast.error("All files must have a label.");

    let filesToUpload = [];

    const photos = await Promise.all(
      developmentsFiles.map(async (file) => {
        const fileName = `offPlans/photos/${uuid()}.${file?.file?.name
          ?.split(".")
          ?.pop()}`;

        filesToUpload.push({
          type: "image/*",
          file: file?.file,
          fileName,
        });

        return {
          ...file,
          file: fileName,
        };
      })
    );

    setFilesToUpload((prev) => ({ ...prev, devPhotos: filesToUpload }));

    setFinalPlanDetails((prev) => ({
      ...prev,
      developmentPhotos: photos,
    }));
    props.next();
  };

  const submitForm = (data) => {
    addPhotosToFinal();
  };

  return (
    <React.Fragment>
      <Head title="Off Plan"></Head>
      <Block>
        <form id="add-development-form" onSubmit={handleSubmit(submitForm)}>
          <p className="font-bold text-2xl text-center pb-2">
            Select development photos (minimum 8)
          </p>
          <InputFile
            active={activeDevelopments}
            dragenter={developmentsDragEnter}
            dragleave={developmentsDragLeave}
            drop={developmentsDropVideo}
            errorMessage={developmentsStatus}
            files={developmentsFiles}
            handleFileChange={developmentsFileChange}
            inputType="image"
            removeImage={removeDevelopmentImage}
            multiple
            inputProps={{
              accept: "image/*",
            }}
          />
          {developmentsFiles && developmentsFiles.length > 0 && (
            <DnDKitDemo
              images={developmentsFiles}
              setImages={setDevelopmentsFiles}
              removeImage={removeDevelopmentImage}
              desc={false}
              handlePropertyPhotosLabelChange
            />
          )}
          <div className="actions clearfix">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 justify-content-between">
              <li>
                <Button color="primary" type="submit">
                  Next
                </Button>
              </li>
              <li>
                <Button color="light" onClick={props.prev} disabled>
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
    </React.Fragment>
  );
};

export default AddOffPlanStepFive;
