import NewLeadsTable from "../../components/table/NewLeadsTable";


const PocketListUpload = () => {
    return (
        <>
            <NewLeadsTable title={"Upload Pocket Lists"} name={"Lead"} role={null} uploadType="PocketLists"/>
        </>
    );
};

export default PocketListUpload;
