import useAxiosPrivate from '../hooks/useAxiosPrivate';
import RSelectAsync from './select/ReactSelectAsync';

export default function SelectDevelopment({
  onChange = (agent) => {},
  defaultOptions = [],
  selectedDevelopment,
  isClearable,
  className,
  notStyled = false,
  ...props
}) {
  const axios = useAxiosPrivate();

  async function searchByName(input) {
    try {
      const response = await axios.get(`/off-plan?search=${input}`);
      return response?.data?.filteredOutput.map((item) => ({
        ...item,
        value: item?.developmentName,
        label: `${item?.developmentName} By ${item?.developer?.name ?? ''}`
      }));
    } catch (err) {
      console.error('Error fetching developments:', err);
      return [];
    }
  }

  const noOptionsMessage = () => {
    return 'No matching developments found';
  };

  return (
    <RSelectAsync
      loadOptionsFn={searchByName}
      onChange={onChange}
      placeholder="Choose Development"
      className={notStyled ? '' : className}
      defaultOptions={defaultOptions}
      value={selectedDevelopment}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      {...props}
    />
  );
}
