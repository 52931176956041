/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Input } from "reactstrap";
import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../../components/Component";
import CardInner from "../../../../../components/cards/CardInner";
import LoadingComponent from "../../../../components/loader/tableLoader";

const OperationsSettingsView = ({ settingsState, setIsEditState }) => {
  const { globalSettings, loading, error } = settingsState;
  
  return (
    <CardInner size="lg">
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Operations Settings</BlockTitle>
            <BlockDes>
              <p>General settings for the flow of operations. Allows configuration of business rules and workflows.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            <Button
              className="d-none d-lg-inline"
              color="primary"
              style={{ marginTop: "-2rem" }}
              onClick={() => setIsEditState(true)}
            >
              Edit
            </Button>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <a
              href="#"
              className="toggle btn btn-icon btn-trigger mt-n1"
              data-target="userAside"
            >
              <Icon name="menu-alt-r" />
            </a>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {error ? (
        <BlockHeadContent>
          <h6 className="text-danger">Failed to get settings</h6>
        </BlockHeadContent>
      ) : loading ? (
        <LoadingComponent />
      ) : (
        <>
          <BlockContent>
            <div className="gy-3">
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="businessHours" onClick={() => console.log(globalSettings.global)}>
                      Business Hours
                    </label>
                    <span class="form-note">The part of the day that the business is open for operations. This is used to determine the time window for agent reassignment, notifications, and other activities.</span>
                  </div>
                  <Input
                    name="businessHours"
                    type="text"
                    value={`From ${globalSettings.global.businessHours.start ?? "08:30"} to ${globalSettings.global.businessHours.end ?? "20:30"}`}
                    disabled
                    className="w-25"
                  />
                </div>
              </div>
              <div className="g-item">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "1rem" }}
                >
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor="preferredNotificationTime">
                      Preferred Notification Time
                    </label>
                    <span class="form-note">The preferred time for notifications to be sent to agents. This is used to determine the time at which agents would receive notifications (for expiring leads for example).</span>
                  </div>
                  <Input
                    type="text"
                    name="preferredNotificationTime"
                    value={globalSettings.global.preferredExpirationTime ?? "10:00"}
                    disabled
                    className="w-25"
                  />
                </div>
              </div>
            </div>
          </BlockContent>
          
          {/* Button renders on smaller screens */}
          <Button
            className="mt-4 d-lg-none"
            color="primary"
            onClick={() => setIsEditState(true)}
          >
            Edit
          </Button>
        </>
      )}
    </CardInner>
  );
};

export default OperationsSettingsView;
