import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import { Button, ButtonGroup } from 'reactstrap';
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from '../../../components/Component';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import DateRangeFilter from '../../components/filter-components/DateRangeFilter';
import { HotLeadsQueries } from './components/panel-cards/hooks/useHotLeadsQuery';
import HotLeadsTable from './components/panel-cards/components/HotLeadsTable';

const AgentReportPanelCards = React.lazy(() =>
  import('./components/panel-cards/AgentReportPanelCards')
);

export const AgentReportContext = React.createContext();
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_QUERY_PARAMS':
      return { ...state, ...action.queryParams };
    default:
      throw new Error();
  }
};

export const HotLeadTableTabs = [
  { label: 'Leads Assigned', key: 0, name: HotLeadsQueries.Assigned, readableName: 'assigned' },
  { label: 'Leads Attended', key: 1, name: HotLeadsQueries.Attended, readableName: 'attended' },
  { label: 'Follow-Ups', key: 2, name: HotLeadsQueries.FollowUp, readableName: 'follow-up' },
  { label: 'Prospects', key: 3, name: HotLeadsQueries.Prospect, readableName: 'prospect' },
  { label: 'Lost/Disqualified', key: 4, name: HotLeadsQueries.LostOrDisqualified, readableName: 'lost or disqualified' },
  { label: 'Expired', key: 5, name: HotLeadsQueries.Expired, readableName: 'expired' }
];

export const HotLeadsTableView = { All: 0, Team: 1 };

const AgentReportLayout = () => {
  const now = new Date();

  const [globalQueryParams, dispatch] = useReducer(reducer, {
    types: null,
    statuses: null,
    fromCreationDate: null,
    toCreationDate: null,
    fromCompletionDate: startOfDay(now).toISOString(),
    toCompletionDate: endOfDay(now).toISOString(),
    fromScheduledDate: null,
    toScheduledDate: null,
    agents: null,
    leader: null,
    leadSource: null,
    page: 1
  });

  const [activeTableTab, setActiveTableTab] = useState(HotLeadTableTabs[0]);
  const [selectedQuery, setSelectedQuery] = useState(HotLeadTableTabs[0].name);

  const switchTab = useCallback((key) => {
    // find the tab by its number or name
    const keyName = typeof key === 'number' ? 'key' : 'name';

    const foundTab = HotLeadTableTabs.find((tab) => tab[keyName] === key) ?? HotLeadTableTabs[0];
    setActiveTableTab((_) => foundTab);
  }, []);

  const updateGlobalQueryParams = useCallback(
    (queryParams) => dispatch({ type: 'UPDATE_QUERY_PARAMS', queryParams }),
    []
  );

  const [isToday, setIsToday] = useState(true);
  const [isYesterday, setIsYesterday] = useState(false);
  const [tableView, setTableView] = useState(HotLeadsTableView.All);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);

  const handleTodayClicked = useCallback(
    (e) => {
      e.preventDefault();
      setIsToday(true);
      setIsYesterday(false);
      const now = new Date();
      updateGlobalQueryParams({
        fromCompletionDate: startOfDay(now).toISOString(),
        toCompletionDate: endOfDay(now).toISOString()
      });
    },
    [updateGlobalQueryParams]
  );

  const handleYesterdayClicked = useCallback(
    (e) => {
      e.preventDefault();
      setIsToday(false);
      setIsYesterday(true);
      const yesterday = addDays(new Date(), -1);
      updateGlobalQueryParams({
        fromCompletionDate: startOfDay(yesterday).toISOString(),
        toCompletionDate: endOfDay(yesterday).toISOString()
      });
    },
    [updateGlobalQueryParams]
  );

  const handleFromDateChange = (date) => {
    const now = new Date();

    if (date) {
      setIsToday(false);
      setIsYesterday(false);
      updateGlobalQueryParams({
        fromCompletionDate: startOfDay(date).toISOString()
      });
    } else {
      updateGlobalQueryParams({
        fromCompletionDate: startOfDay(now).toISOString(),
        toCompletionDate: endOfDay(now).toISOString()
      });
    }
  };

  const handleToDateChange = (date) => {
    const now = new Date();

    if (date) {
      setIsToday(false);
      setIsYesterday(false);
      updateGlobalQueryParams({
        toCompletionDate: endOfDay(date).toISOString()
      });
    } else {
      updateGlobalQueryParams({
        toCompletionDate: endOfDay(now).toISOString()
      });
    }
  };

  useEffect(() => {
    const now = new Date();

    const startDate = globalQueryParams.fromCompletionDate;
    const endDate = globalQueryParams.toCompletionDate;

    if (
      new Date(startDate).valueOf() === startOfDay(now).valueOf() &&
      new Date(endDate).valueOf() === endOfDay(now).valueOf()
    ) {
      setIsToday(true);
      setIsYesterday(false);
    }
  }, [globalQueryParams.fromCompletionDate, globalQueryParams.toCompletionDate]);

  return (
    <AgentReportContext.Provider
      value={{
        globalQueryParams,
        updateGlobalQueryParams,
        isToday,
        isYesterday,
        activeTableTab,
        switchTab,
        tableView,
        selectedTeamLeader,
        setSelectedTeamLeader,
        selectedQuery,
        setSelectedQuery
      }}
    >
      <React.Fragment>
        <Head title="War Front - Agent Reports"></Head>
        <Content>
          <BlockHead className={'pb-4'}>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle>Hot Lead War Front</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                <ButtonGroup>
                  <Button
                    className="align-self-end"
                    color={'primary'}
                    outline={!(tableView === HotLeadsTableView.All)}
                    onClick={() => {
                      setTableView((_) => HotLeadsTableView.All);
                      setSelectedTeamLeader((_) => null);
                    }}
                  >
                    All
                  </Button>
                  <Button
                    className="align-self-end"
                    color={'primary'}
                    outline={!(tableView === HotLeadsTableView.Team)}
                    onClick={() => setTableView((_) => HotLeadsTableView.Team)}
                  >
                    Team View
                  </Button>
                </ButtonGroup>
              </div>
              <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                <Button
                  className="align-self-end"
                  color={'primary'}
                  outline={!isToday}
                  onClick={handleTodayClicked}
                >
                  Today
                </Button>
                <Button
                  className="align-self-end"
                  color={'primary'}
                  outline={!isYesterday}
                  onClick={handleYesterdayClicked}
                >
                  Yesterday
                </Button>
                <DateRangeFilter
                  label={'Pick a date range'}
                  startDateState={globalQueryParams.fromCompletionDate}
                  endDateState={globalQueryParams.toCompletionDate}
                  onStartDateChange={handleFromDateChange}
                  onEndDateChange={handleToDateChange}
                />
              </div>
            </div>
          </BlockHead>

          <div className="d-flex flex-column flex-lg-row" style={{ gap: '1rem' }}>
            <div className="align-self-start" style={{ flexGrow: 2 }}>
              <HotLeadsTable />
            </div>
            <div className="flex-grow-1 align-self-start">
              <AgentReportPanelCards />
            </div>
          </div>
        </Content>
      </React.Fragment>
    </AgentReportContext.Provider>
  );
};

export const useAgentReport = () => React.useContext(AgentReportContext);

export default AgentReportLayout;
