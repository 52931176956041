
export const getAllCommunityTeams = async (axios, query) => {
    try {
      const url = query ? `/communityTeams/all?${query}` : "/communityTeams/all?limit=1000";
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(error);
      return { found: 0, total: 0, results: [] };
    }
  };